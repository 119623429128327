import React from 'react';
import {Alert, Button} from "shards-react";
import PropTypes from "prop-types";

function ConfirmAlert({confirmAlert, setConfirmAlert}) {

  const cancelConfirmAlert = () => {
    setConfirmAlert({
      ...confirmAlert,
      visible: false
    })
  }

  if (confirmAlert.visible)
    return (
      <Alert theme="light"
             className="primary-alert text-dark fixed-top offset-md-3 offset-lg-2 mb-0 alert alert-danger fade show">
        {confirmAlert.confirmMsg}
        <Button className="mx-2 py-2 btn-normal" size="sm"
                theme="primary" onClick={e => {
          confirmAlert.onConfirm();
          setConfirmAlert({
            ...confirmAlert,
            visible:false
          })
        }
        }>Yes</Button>
        <Button className="mx-2 py-2" size="sm"
                theme="white" onClick={cancelConfirmAlert}>Cancel</Button>
      </Alert>
    )

  return null;
}

ConfirmAlert.propTypes = {
  confirmAlert: PropTypes.object.isRequired,
  setConfirmAlert: PropTypes.func.isRequired,
}

export default ConfirmAlert;
