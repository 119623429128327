import React, {useEffect, useState} from 'react';
import {
  approve_leave,
  clear_sub_leave_details,
  clear_calculated_leave_info,
  get_calculated_leave_info,
  get_subs_leave_details,
  sub_save_leave_details,
} from "../../redux/actions/user";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {setAPIDate, setDateForTable, setInputDate} from "../../utils/date";
import {
  Button,
  Card, Col,
  Container, DatePicker, Form, FormInput, FormSelect, FormTextarea,
  ListGroup,
  ListGroupItem, Row
} from "shards-react";
import {get_worker_leave_policy_names_list} from "../../redux/actions/worker/leaves";
import Alerts from "../../components/common/Alerts";
import ConfirmAlert from "../../components/common/ConfirmAlert";
let typingTimer = null;
function SubscriberAddEditLeave({

                                  workerLeavePolicyNamesList,
                                  subLeaveDetails,
                                  get_worker_leave_policy_names_list,
                                  calculatedLeaveInfo,
                                  clear_calculated_leave_info,
                                  get_calculated_leave_info,
                                  get_subs_leave_details,
                                  clear_sub_leave_details,
                                  sub_save_leave_details,
                                  changePageMeta,
                                  title
                                }) {

  let {refNumber} = useParams();
  const history = useHistory();

  //added Worker Name in the page title once we get response from backend
  useEffect(() => {
      changePageMeta(`${title} - ${location.state.workerName}`)
  }, []);

  useEffect(() => {
    if (refNumber && refNumber !== '0') {
      get_subs_leave_details(refNumber);
    }
  }, [refNumber]);

  useEffect(() => {
    get_worker_leave_policy_names_list(location.state.userId);
    return () => {
      clear_sub_leave_details();
    }
  }, []);

  const [formData, setFormData] = useState({
    userId: null,
    requestedDate: new Date().toISOString().slice(0, 10),
    leaveType: '',
    from: '',
    to: '',
    leaveDays: null,
    leaveHours: null,
    status: '',
    notes: '',
    hoursInputDisabled: false
  });

  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });

  useEffect(() => {
    if (Object.keys(subLeaveDetails).length !== 0) {
      setFormData({
        ...formData,
        userId: subLeaveDetails.userId,
        requestedDate: setDateForTable(subLeaveDetails.requestedDate),
        leaveType: subLeaveDetails.leaveType,
        from: setAPIDate(subLeaveDetails.fromDate),
        to: setAPIDate(subLeaveDetails.toDate),
        leaveDays: subLeaveDetails.leaveDays,
        leaveHours: subLeaveDetails.leaveHours,
        status: subLeaveDetails.status,
        notes: subLeaveDetails.notes,
        hoursInputDisabled: false
      });
    }
  }, [subLeaveDetails]);

  useEffect(() => {
    if (calculatedLeaveInfo && Object.keys(calculatedLeaveInfo).length !== 0) {
      setFormData({
        ...formData,
        leaveDays: calculatedLeaveInfo.leaveDays,
        leaveHours: calculatedLeaveInfo.leaveHours,
        hoursInputDisabled:false
      });
    }
  }, [calculatedLeaveInfo]);

  useEffect(() => {
    if (formData.from && formData.to) {
      get_calculated_leave_info(setInputDate(formData.from), setInputDate(formData.to), formData.leaveDays, formData.leaveHours, location.state.userId);
    }
    return () => {
      clear_calculated_leave_info();
    };
  }, [formData.from, formData.to]);


  useEffect(() => {
    clearTimeout(typingTimer);
    if (formData.from && formData.to && formData.leaveHours &&formData.leaveHours!==calculatedLeaveInfo.leaveHours) {
      typingTimer = setTimeout(() => {
        setFormData({...formData,hoursInputDisabled:true})
        get_calculated_leave_info(setInputDate(formData.from), setInputDate(formData.to), formData.leaveDays, formData.leaveHours, location.state.userId);
      }, 1000);
    }
    return () => {
      //remove timer
    };
  }, [formData.leaveHours]);

  const location = useLocation();
  useEffect(() => {
    if (typeof location.state !== 'undefined') {
      // Window.location.reload(true);
      setFormData({
        ...formData,
        userId: location.state.userId,
      });
    }
  }, [location.state]);


  const onChange = (e) => {
    e.target.setCustomValidity("")
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  const {
    userId,
    from,
    to,
    leaveDays,
    leaveHours,
    notes,
  } = formData;
  const onSubmit = (e) => {
    e.preventDefault();
    sub_save_leave_details(
      userId,
      refNumber,
      formData.leavePolicyId,
      setInputDate(from),
      setInputDate(to),
      leaveHours,
      notes
    );
    history.goBack();
  };

  var leavePolicyOptions = workerLeavePolicyNamesList.map((leavePolicy) => {
    return <option
      value={leavePolicy.leavePolicyId}>{leavePolicy.policyName}</option>;
  });

  let onCancel=()=>{
    setConfirmAlert({
      confirmMsg: "Are you sure you want to exit this screen without completing your leave application?",
      visible: true,
      onConfirm: () => {
        history.goBack();
      }
    })
  };

  return (
    <React.Fragment>
      <ConfirmAlert confirmAlert={confirmAlert}
                    setConfirmAlert={setConfirmAlert}/>
      <Alerts/>
      <Container fluid className="main-content-container p-4">
        <Card small className="mb-4 w-100">
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Form onSubmit={onSubmit}>
                    <Row form>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="requestedDate">Requested Date</label>
                        <FormInput
                          id="requestedDate"
                          placeholder="Requested Date"
                          name='requestedDate'
                          readOnly
                          value={formData.requestedDate}
                          onChange={onChange}
                        />
                      </Col>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="leavePolicyId">Leave Type</label>
                        <FormSelect id="leavePolicyId" name='leavePolicyId'
                                    value={formData.leavePolicyId}
                                    onInvalid={e => e.target.setCustomValidity("Choose Leave Type")}
                                    onChange={onChange}
                                    required>
                          <option value=''>Select</option>
                          {leavePolicyOptions}
                        </FormSelect>
                      </Col>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="from">From</label>
                        <div>
                          <DatePicker
                            id='from'
                            autoComplete='off'
                            name='from'
                            dateFormat='yyyy-MM-dd'
                            placeholderText="yyyy-mm-dd"
                            selected={formData.from}
                            onChange={(fromDate) =>
                              setFormData({...formData, from: fromDate})
                            }
                            value={formData.from}
                            required
                          />
                        </div>
                      </Col>


                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="to">To</label>
                        <div>
                          <DatePicker
                            id='to'
                            autoComplete='off'
                            name='to'
                            required
                            placeholderText="yyyy-mm-dd"
                            dateFormat='yyyy-MM-dd'
                            selected={formData.to}
                            onChange={(toDate) =>
                              setFormData({...formData, to: toDate})
                            }
                            value={formData.to}
                          />
                        </div>
                      </Col>


                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="leaveDays">Leave Days</label>
                        <FormInput
                          id="leaveDays"
                          name='leaveDays'
                          readOnly
                          value={(formData.leaveDays)?parseFloat(formData.leaveDays).toFixed(2):""}
                          onChange={onChange}
                        />
                      </Col>


                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="leaveHours">Leave Hours</label>
                        <FormInput
                          id="leaveHours"
                          name='leaveHours'
                          value={formData.leaveHours}
                          readOnly={formData.hoursInputDisabled}
                          onChange={onChange}
                        />
                      </Col>

                      <Col sm="12" md="12" className="form-group p-2 m-0">
                        <label htmlFor="notes">My Notes</label>
                        <FormTextarea
                          id="notes"
                          rows="10"
                          name="notes"
                          value={formData.notes}
                          onChange={onChange}
                        />
                      </Col>

                    </Row>
                    <Row className="px-3">
                      <Col sm="12" md="12" lg="12"
                           className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">

                        <Button className="mx-1 py-2 mx-md-2" size="sm"
                                theme="accent">Submit</Button>

                        <Button className="mx-1 py-2 mx-md-2" size="sm"
                                theme="accent"
                                onClick={onCancel}>Cancel</Button>

                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>
    </React.Fragment>
  );
}

SubscriberAddEditLeave.propTypes = {
  get_subs_leave_details: PropTypes.func.isRequired,
  subLeaveDetails: PropTypes.object.isRequired,
  sub_save_leave_details: PropTypes.func.isRequired,
  clear_calculated_leave_info: PropTypes.func.isRequired,
  get_calculated_leave_info: PropTypes.func.isRequired,
  approve_leave: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  subLeaveDetails: state.user.subLeaveDetails,
  workerLeavePolicyNamesList: state.leaves.workerLeavePolicyNamesList,
  calculatedLeaveInfo: state.user.calculatedLeaveInfo
});
export default connect(mapStateToProps, {
  get_worker_leave_policy_names_list,
  get_subs_leave_details,
  sub_save_leave_details,
  clear_sub_leave_details,
  clear_calculated_leave_info,
  get_calculated_leave_info,
  approve_leave,
})(SubscriberAddEditLeave);

