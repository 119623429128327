import api from "../../../utils/api";
import {setAlert} from "../alert";
import {
  FILTER_DASHBOARD_PAY_PERIOD_LIST,
  GET_DASHBOARD_PAY_PERIOD_LIST,
  GET_DASHBOARD_QUICK_FILTER_NAME_LIST,
  GET_SUB_DASHBOARD_DATA
} from "../types";


export const get_dashboard_quick_filter_name_list = () => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getDashboardQuickFilterNameList`
    );
    dispatch({
      type: GET_DASHBOARD_QUICK_FILTER_NAME_LIST,
      payload: res.data,
    });
  } catch (error) {
    const success = error.response.data.success;
    if (!success) {
      dispatch(setAlert(error.response.data.message, "danger"));
    }
  }
};

export const get_dashboard_pay_period_list = () => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getDashboardPayPeriodList`,
      {
        params: {
          filter:"All"
        }
      }
    );
    dispatch({
      type: GET_DASHBOARD_PAY_PERIOD_LIST,
      payload: res.data,
    });
  } catch (error) {
    const success = error.response.data.success;
    if (!success) {
      dispatch(setAlert(error.response.data.message, "danger"));
    }
  }
};

export const filter_dashboard_pay_period_list = (filter) => async (dispatch) => {
  try {
    let params={}
    if(filter)
      params.filter=filter;
    const res = await api.get(
      `api/subscriber/getDashboardPayPeriodList`,
      {
        params: params
      }
    );
    dispatch({
      type: FILTER_DASHBOARD_PAY_PERIOD_LIST,
      payload: res.data,
    });
  } catch (error) {
    const success = error.response.data.success;
    if (!success) {
      dispatch(setAlert(error.response.data.message, "danger"));
    }
  }
};

export const get_sub_dashboard_data = (fromPayPeriodId, toPayPeriodId) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getDashboardData`,
      {
        params: {
          fromPayPeriodId,
          toPayPeriodId
        }
      }
    );
    dispatch({
      type: GET_SUB_DASHBOARD_DATA,
      payload: res.data,
    });
  } catch (error) {
    const success = error.response.data.success;
    if (!success) {
      dispatch(setAlert(error.response.data.message, "danger"));
    }
  }
};
