import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { b64toBlob, getBase64MimeType } from '../../../utils/file'

import PropTypes from 'prop-types';
import {get_user_expense_item_receipt_file} from "../../../redux/actions/worker/expenses";

const ExpenseItemReceiptPopup = ({
                                    isAuthenticated,
                                    user,
                                    receiptFile,
                                    get_user_expense_item_receipt_file,
                                  }) => {
  let { userId,refNumber } = useParams();
  useEffect(() => {
    get_user_expense_item_receipt_file(userId||user.userId, refNumber);
  }, [refNumber]);


  let receiptDoc;
  if (receiptFile) {
    let imageMimeTypes = ['jpg', 'jpeg', 'gif', 'png', 'svg']
    const mimeType = getBase64MimeType(receiptFile)
    if (mimeType === 'pdf') {
      receiptDoc = <object className='popup-receipt-viewer popup-receipt-viewer-pdf' data={b64toBlob(receiptFile)} />
    } else if (imageMimeTypes.includes(mimeType)) {
      receiptDoc = <img className='popup-receipt-viewer' src={b64toBlob(receiptFile)} />
    } else {
      receiptDoc = <a className='themebtn'>Download Receipt</a>
    }

  }

  if (!isAuthenticated) {
    return <Redirect to='/login' />;
  }

  return (
      <div className="popup-receipt-viewer-pdf">
        {receiptDoc}
      </div>
  );
};

ExpenseItemReceiptPopup.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  receiptFile: PropTypes.any.isRequired,
  get_user_expense_item_receipt_file: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  receiptFile: state.expenses.receiptFile,
});

export default connect(mapStateToProps, {
  get_user_expense_item_receipt_file,
})(ExpenseItemReceiptPopup);
