import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Col, Container, Navbar, Row} from "shards-react";
import PageTitle from "../common/PageTitle";
import NavbarToggle from "./MainNavbar/NavbarToggle";
import NavbarNav from "./MainNavbar/NavbarNav/NavbarNav";


const CBoxNavbar = ({title, subtitle , stickyTop}) => {
  const classes = classNames(
    "main-navbar",
    "bg-white",
    stickyTop && "sticky-top"
  );

  return (
    <div className={classes} >
      <Container fluid={true} className="p-0">
        <Navbar type="light" className="align-items-stretch flex-nowrap p-0 pl-md-4">
          <NavbarToggle/>
          <Row noGutters className="page-header ">
            <Col className={`d-flex d-md-inline-block align-items-center`}>
              <PageTitle title={title} subtitle={subtitle} md="12"
                         className="ml-sm-auto mr-sm-auto p-0 py-1"/>
            </Col>
          </Row>
          <NavbarNav/>
        </Navbar>
      </Container>
    </div>
  );
};

CBoxNavbar.propTypes = {
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool
};

CBoxNavbar.defaultProps = {
  stickyTop: true
};

export default CBoxNavbar;
