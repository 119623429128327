import React,  { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Row,
  Button,
  Container,
  FormCheckbox
} from "shards-react";
import Table from './Table';
import { useLocation, useHistory } from "react-router-dom";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { setDateForTable } from '../../utils/date';
import {default as queryString} from "qs";
import { formatNumberAmount, getFrequencyLabel } from '../../utils/general';
function EapInfo({
  formData,
  userProfile,
  formDataRef,
  sectionformDataRef,
  clientMarkups
}) {

  const [viewHistory, setViewHistory] = useState(false);
  const [isDisabledEAP, disableEAP] = useState(false);
  const location = useLocation();
  const history = useHistory();
  let queryParams = queryString.parse(location.search.replace("?", ""))
  
  const tableColumns = [
    {
      Header: <span className="text-wrap">EAP Provider</span>,
      accessor: "EAPProvider",
      maxWidth: 120,
      minWidth: 120,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",

    },
    {
      Header: <span className="text-wrap">EAP Start Date</span>,
      accessor: "EAPStartDate",
      maxWidth: 110,
      minWidth: 110,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => setDateForTable(row.EAPStartDate)
    },
    {
      Header: <span className="text-wrap">EAP End Date</span>,
      accessor: "EAPEndDate",
      maxWidth: 100,
      minWidth: 100,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => setDateForTable(row.EAPEndDate)
    },
    {
      Header: <span className="text-wrap">EAP Deduction Frequency</span>,
      accessor: "EAPDeductionFrequency",
      maxWidth: 186,
      minWidth: 186,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: row=> getFrequencyLabel(row.EAPDeductionFrequency)
    },

    {
      Header: <span className="text-wrap">EAP Contribution Currency</span>,
      accessor: "EAPContributionCurrency",
      maxWidth: 190,
      minWidth: 190,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",

    },

    {
      Header: <span className="text-wrap">EAP Contribution Amount</span>,
      accessor: "EAPContributionAmount",
      maxWidth: 184,
      minWidth: 184,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-right",
      cell : row=> formatNumberAmount(row.EAPContributionAmount, "en-US", 2, 2)
    },
    {
      Header: <span className="text-wrap">Ref#</span>,
      accessor: "EAPId",
      maxWidth: 50,
      minWidth: 50,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => (<Link to={{ pathname: `/EAP-info-form`, search:`?role=${queryParams.role}`, state: { userProfile, role: queryParams.role, row, formData, formDataRef, sectionformDataRef,clientMarkups } }}>{row.EAPId === 0 ? 'New' : row.EAPId}</Link>)
    },


  ];

  const togleHistory = () => {
    setViewHistory(!viewHistory);

  }

  useEffect(()=>{

    if(formData.EAPApplicable === 'Yes'){
      disableEAP(true); 
    }

  },[formData])
  return (
    <Card id="EapInfo" small

      className="mb-5 w-100 d-flex personalParent">
      <span className="iconBox"><i className="fa-solid fa-umbrella iconStyle" /></span>
      <h4 className="m-0 section-title headerStyle pr-0">Employee Assistance Program Information <spen className="d-inline" ><FormCheckbox id="history" name="history"
        className="d-inline"
        checked={isDisabledEAP} onChange={() => {
          formData.EAPApplicable = formData.EAPApplicable == 'Yes' ? 'No' :'Yes';
          disableEAP(!isDisabledEAP)
        }}
        toggle small></FormCheckbox></spen></h4>
      
      <Container fluid className="file-manager__filters border-bottom">
        <Row className="mb-4">

          <Col lg="10"
            className="d-flex flex-row-reverse">

            <div className='d-flex flex-column ml-3 section-card-header'>
              <spen className="header-checkbox">
                <FormCheckbox id="history" name="history"
                  checked={viewHistory} onChange={togleHistory}
                  toggle small><spen className="font-weight-bold text-center">View EAP History</spen></FormCheckbox>
              </spen>
              <spen className="font-weight-bold text-center">


              </spen>
              <spen className="font-weight-bold font-italic">Note - Highlighted in blue row is the active EAP information.</spen>
            </div>
            <div></div>

          </Col>
          
          <Col lg="2"
            className=" pt-0 pl-0 pb-0 pr-2 file-manager__filters__search d-flex align-items-center justify-content-center justify-content-md-end col-6" >

<Button disabled={!isDisabledEAP} className="my-2 py-2 pr-0 pl-0 mx-2 w-100" size='sm'
                theme="accent"
                onClick={() => { 

                  history.push({
                    pathname: `/EAP-info-form`,
                    search:`?role=${queryParams.role}`,
                    state: {
                      userProfile,
                      refNum: 0,
                      role:queryParams.role,
                      formData,
                      formDataRef, 
                      sectionformDataRef,
                      clientMarkups
                    },
                  });
                }}
                ><spen>Add EAP</spen></Button>


          {/*<Link className="btn-primary" to={{ pathname: `/placement-info-form`, state: { userProfile, refNum: 0 } }} disabled={userProfile.personalInfo === undefined} theme="accent" state>
            Add Compensation
              </Link>*/}
          </Col>

        </Row>
       

        <div className='scrollabe-table-container'>
          {

            (formData && formData.EAPInfos) ? 
              (<Table headers={tableColumns} rows={formData.EAPInfos} viewHistory={viewHistory} />)
             :
              (<Table headers={tableColumns} rows={[]} viewHistory={viewHistory} />)
          }
        </div>
      </Container>




    </Card>
  );
}

export default EapInfo;
