import React, { useEffect, useState } from 'react';
import {
  Button,
  Card, CardBody, CardHeader,
  Col, Container, DatePicker,
  Form, FormCheckbox,
  FormInput, FormSelect,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import { Link, useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  delete_leave_policy_details,
  get_leave_policy_details,
  get_leave_policy_location_list, save_leave_policy_details,save_leave_policy_level_details,
  delete_leave_policy_level_details
} from "../../redux/actions/subscriber/leavePolicy";
import InputBox from "../../components/common/InputBox";
import InfoTooltip from "../../components/common/InfoTooltip";
import ReactTable from "react-table";
import {
  get_leave_accrual_date_type_list,
  get_leave_accrual_every_string_list, get_leave_pay_out_limit_type_list,
  get_leave_pay_out_time_type_list,
  get_leave_period_string_list,
  get_leave_quota_reset_type_list
} from "../../redux/actions/user";
import { setAPIDate, setInputDate } from "../../utils/date";
import { setAlert } from "../../redux/actions/alert";
import ConfirmAlert from "../../components/common/ConfirmAlert";

function SubEditLeavePolicy({
  leavePolicyDetails,
  leaveQuotaResetTypeList,
  leavePeriodStringList,
  leaveAccrualDateTypeList,
  leaveAccrualEveryStringList,
  leavePayOutTimeTypeList,
  leavePayOutLimitTypeList,
  leavePolicyLocationList,
  leavePolicyLevelDetails,

  get_leave_policy_details,
  get_leave_quota_reset_type_list,
  get_leave_period_string_list,
  get_leave_accrual_date_type_list,
  get_leave_accrual_every_string_list,
  get_leave_pay_out_time_type_list,
  get_leave_pay_out_limit_type_list,
  get_leave_policy_location_list,
  delete_leave_policy_details,
  save_leave_policy_details,
  save_leave_policy_level_details,
  delete_leave_policy_level_details,
  setAlert,
}) {
  const [formData, setFormData] = useState({
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 5,

    leavePolicyId: 0,
    leaveName: "",
    entitledFlag: true,
    waitingPeriod: "",
    proRateLeaves: "",
    paidTimeOff: "",
    leaveQuotaResetType: "",
    leaveQuotaResetDate: "",
    allowFutureLeaves: "",
    accrualBeginType: "",
    accrualUnitType: "",
    maximumAccrual: "",
    allowAccruals: "",
    allowCarryOver: "",
    allowNegativeCarryOver: "",
    maximumNegativeAllowed:"",
    maximumNegativeAllowedUnit:"",
    carryOverType:"",
    maximumCarryOver: "",
    useCarryOverWithin: "",
    allowLeavePayout: "",

    payoutTimeType: "",
    payoutTime: "",
    payoutLimitType: "",
    payoutLimit: "",
    
    leavePolicyLocationNote: "",
    //new field change
    allowLeaveEntitlementRules: "",
    allowAdditionalLeaveEntitlementRules: "",
    additionalAccrualRulesList:[],
    allowAdditionalAccrualRules: "",
    leavesAccrueEvery: "",
    accrualOnDate: "",
    firstDay: "",
    secondDay: "",
    accrualLimitType: "",
    maximumAccrualUnit: "",
    levelCount: 1,
    accrualOn:""
    
  });

  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });

  const [state, setState] = useState({
    items: [{ uniq:Math.random(), id: 0, accrue: "", unit: "",timeUnit:"", afterWorkingFor:"",accrualDate: "", accrualOn:"", recurring:"" }]
  });

  let history = useHistory();
  let { leavePolicyId } = useParams();

useEffect(() => {
  if (leavePolicyLevelDetails && Object.keys(leavePolicyLevelDetails).length !== 0) {
    setFormData({
      ...formData,
      leavePolicyId: leavePolicyLevelDetails.leavePolicyId,
      
    });  
    var currentItem = state.items[state.items.length - 1];
    currentItem.id = leavePolicyLevelDetails.additionalAccrualRulesList[leavePolicyLevelDetails.additionalAccrualRulesList.length - 1].id;
    if(leavePolicyDetails && Object.keys(leavePolicyDetails).length !== 0)
    {
    if(leavePolicyDetails.additionalAccrualRulesList)
    {
      var shouldAdd = leavePolicyDetails.additionalAccrualRulesList ? state.items.length > leavePolicyDetails.additionalAccrualRulesList.length : true;
      if(shouldAdd)
        leavePolicyDetails.additionalAccrualRulesList.push(currentItem);
    }
    else
    {
      leavePolicyDetails.additionalAccrualRulesList = [];
      leavePolicyDetails.additionalAccrualRulesList.push(currentItem);
    }
  } 
  }
}, [leavePolicyLevelDetails]);

  useEffect(() => {
    if (leavePolicyDetails && Object.keys(leavePolicyDetails).length !== 0) {
      if (leavePolicyDetails.leavePolicyId == leavePolicyId) {
        setFormData({
          ...formData,
          leavePolicyId: leavePolicyDetails.leavePolicyId,
          leaveName: leavePolicyDetails.leaveName,
          entitledFlag: leavePolicyDetails.entitledFlag,
          waitingPeriod: leavePolicyDetails.waitingPeriod,
          proRateLeaves: leavePolicyDetails.proRateLeaves,
          paidTimeOff: leavePolicyDetails.paidTimeOff,
          leaveQuotaResetType: leavePolicyDetails.leaveQuotaResetType,
          leaveQuotaResetDate: setAPIDate(leavePolicyDetails.leaveQuotaResetDate),
          allowFutureLeaves: leavePolicyDetails.allowFutureLeaves,
          accrualBegins: leavePolicyDetails.accrualBegins,
          accrualBeginType: leavePolicyDetails.accrualBeginType,
          accrualUnit: leavePolicyDetails.accrualUnit,
          accrualUnitType: leavePolicyDetails.accrualUnitType,
          accrualEvery: leavePolicyDetails.accrualEvery,
          accrualDateType: leavePolicyDetails.accrualDateType,
          firstAccrualOn: setAPIDate(leavePolicyDetails.firstAccrualOn),
          maximumAccrual: leavePolicyDetails.maximumAccrual,
          maximumAccrualType: leavePolicyDetails.maximumAccrualType,
          allowCarryOver: leavePolicyDetails.allowCarryOver,
          allowNegativeCarryOver: leavePolicyDetails.allowNegativeCarryOver,
          carryOverType: leavePolicyDetails.carryOverType,
          maximumNegativeAllowed: leavePolicyDetails.maximumNegativeAllowed,
          maximumNegativeAllowedUnit : leavePolicyDetails.maximumNegativeAllowedUnit ,
          maximumCarryOver: leavePolicyDetails.maximumCarryOver,
          useCarryOverWithin: leavePolicyDetails.useCarryOverWithin,
          allowLeavePayout: leavePolicyDetails.allowLeavePayout,
          payoutTimeType: leavePolicyDetails.payoutTimeType,
          payoutTime: leavePolicyDetails.payoutTime,
          payoutLimitType: leavePolicyDetails.payoutLimitType,
          payoutLimit: leavePolicyDetails.payoutLimit,
          leavePolicyLocationNote: leavePolicyDetails.leavePolicyLocationNote,

          //new field change
          allowLeaveEntitlementRules: leavePolicyDetails.allowLeaveEntitlementRulesFlag,
          allowAdditionalLeaveEntitlementRules: leavePolicyDetails.allowAdditionalLeaveEntitlementRules,
          allowAdditionalAccrualRules: leavePolicyDetails.allowAdditionalAccrualRulesFlag,
          leavesAccrueEvery: leavePolicyDetails.leavesAccrueEvery,
          accrualOnDate: leavePolicyDetails.accrualOnDate,
          firstDay: leavePolicyDetails.firstDay,
          secondDay: leavePolicyDetails.secondDay,
          accrualLimitType: leavePolicyDetails.accrualLimitType,
          maximumAccrualUnit: leavePolicyDetails.maximumAccrualUnit,


        });
        state.items = leavePolicyDetails.additionalAccrualRulesList;
        state.items.map(item => {
          item.accrualDate = setAPIDate(item.accrualDate);
          item.uniq=Math.random();
        });
      } else {
        history.replace("/sub-add-leave-policy/" + leavePolicyDetails.leavePolicyId);
      }
    }
  }, [leavePolicyDetails]);


  useEffect(() => {
    get_leave_quota_reset_type_list();
    get_leave_period_string_list();
    get_leave_accrual_date_type_list();
    get_leave_accrual_every_string_list();
    get_leave_pay_out_time_type_list();
    get_leave_pay_out_limit_type_list();
  }, []);

  useEffect(() => {
    if (leavePolicyId && leavePolicyId !== "0")
      get_leave_policy_details(leavePolicyId);
  }, [leavePolicyId]);

  useEffect(() => {
    if (leavePolicyId && leavePolicyId !== "0")
      get_leave_policy_location_list(leavePolicyId);
  }, [leavePolicyId]);

  let addListItem = () => {
    //var currentLevelCount = state.items.length + 1;
    const newItem = { uniq:Math.random(), id: 0, accrue: "", unit: "", timeUnit:"", afterWorkingHour:"", accrualOn:"",accrualDate:"", recurring:"" };
    setState({
      items: [...state.items, newItem]
    });

  };

  let saveLevel = (e, ruleId) => {  
    var currentItem = state.items.filter(v=>v.id == ruleId)[0];
    ruleId = formData.leavePolicyId === 0 ? 0 : state.items.length > (leavePolicyDetails.additionalAccrualRulesList?leavePolicyDetails.additionalAccrualRulesList.length:0) ? 0 :ruleId;
    //save level
    save_leave_policy_level_details(formData.leavePolicyId,ruleId,currentItem.accrue,currentItem.unit,currentItem.timeUnit,currentItem.afterWorkingFor,currentItem.accrualOn,setInputDate(currentItem.accrualDate),currentItem.recurring);
  };

  let onInputChange = (e, currentId) => {
    
    const newArr = state.items.map(item => {
      if (item.uniq == currentId) {
        return {
          ...item,
          [e.target.name]: e.target.value
        };
      } else {
        return item;
      }
    });

    setState({
      items: newArr
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  let createList = () => {
    if(!state.items || state.items.length == 0)
    {
    const newItem = {uniq:Math.random(), id: 0, accrue: "", unit: "", timeUnit:"", afterWorkingFor:"", accrualOn:"",accrualDate:"", recurring:"" };
    setState({
      items: [...state.items, newItem]
    });
    }
    const { items } = state;
    var count = 0;
    return items.map(item => {
      count++;
      return (
        <div className="position-relative col-12">
          <div className="tableHeader d-flex mt-3">
            <label className="text-bold m-0 mx-1" htmlFor="allowAdditionalAccrualRulesFlagLevel">Level {count}
            </label>

          </div>
          <Card small className="mb-5 w-100 mt-3 d-flex">
            <ListGroup flush className="">
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Row form>

                      <InputBox label="Accrue " id="accrue"
                        name="accrue"
                        value={item.accrue}
                        disabled={!formData.entitledFlag || formData.allowAdditionalAccrualRules != "Yes"}
                        onChange={e => onInputChange(e, item.uniq)}
                        required
                        placeholder="Enter Accrue"
                        toolTipMSG= "Set how much additional balance can be accrued"
                      />

                      <Col sm="12" md="6" lg="3"
                        className="form-group p-2 m-0">
                        <label htmlFor="unit">Units</label>
                        <InfoTooltip
                            msg="Choose a unit for additional accrual" />
                        <FormSelect id="unit"
                          name='unit'
                          onChange={e => onInputChange(e, item.uniq)}
                          required
                          value={item.unit}
                          disabled={!formData.entitledFlag || formData.allowAdditionalAccrualRules != "Yes"}>
                          <option value=''>Select Units</option>
                          <option value='Days'>Days</option>
                          <option value='Hours'>Hours</option>
                        </FormSelect>
                      </Col>

                      <InputBox label="After Working for " id="afterWorkingFor"
                        name="afterWorkingFor"
                        value={item.afterWorkingFor}
                        disabled={!formData.entitledFlag || formData.allowAdditionalAccrualRules != "Yes"}
                        onChange={e => onInputChange(e, item.uniq)}
                        required
                        placeholder="Enter After Working for "
                        toolTipMSG="Set a time period where additional balance can be accrued"
                      />
                      <Col sm="12" md="6" lg="3"
                        className="form-group p-2 m-0">
                        <label htmlFor="timeUnit">Time Unit</label>
                        <InfoTooltip
                            msg="Set the time unit" />
                        <FormSelect id="timeUnit" name='timeUnit'
                          onChange={e => onInputChange(e, item.uniq)}
                          required
                          value={item.timeUnit}
                          disabled={!formData.entitledFlag || formData.allowAdditionalAccrualRules != "Yes"}>
                          <option value=''>Select Time Unit</option>
                          <option value='Months'>Months</option>
                          <option value='Years'>Years</option>
                        </FormSelect>
                      </Col>

                      <Col sm="12" md="6" lg="3"
                        className="form-group p-2 m-0">
                        <label htmlFor="accrualOn">Accrues On</label>
                        <InfoTooltip
                            msg="Choose when additional balance accrues" />
                        <FormSelect id="accrualOn" name='accrualOn'
                          onChange={e => onInputChange(e, item.uniq)}
                          required
                          value={item.accrualOn}
                          disabled={!formData.entitledFlag || formData.allowAdditionalAccrualRules != "Yes"}>
                          <option value=''>Select Accrues On</option>
                          {/* <option value='Calendar'>Calendar</option>
                          <option value='HiredAnniversary'>Hired Anniversary</option>
                          <option value='Custom'>Custom</option> */}
                           {leaveQuotaResetTypeListOptions}
                        </FormSelect>
                      </Col>

                      <Col sm="12" md="6" lg="3"
                        className="form-group p-2 m-0">
                        <label htmlFor="accrualDate">Date</label>
                        <InfoTooltip
                            msg="Set the custom accrual date" />
                        <div>
                          <DatePicker
                            id='accrualDate'
                            autoComplete='off'
                            name='accrualDate'
                            dateFormat='yyyy-MM-dd'
                            selected={item.accrualDate}
                            
                            onChange={(date) =>
                              {
                              const newArr = state.items.map(itemq => {
                                if (itemq.uniq == item.uniq) {
                                  return {
                                    ...itemq,
                                    accrualDate: date
                                  };
                                } else {
                                  return itemq;
                                }
                              });
                          
                              setState({
                                items: newArr
                              });
                              
                            }}
                            value={item.accrualDate}
                            disabled={!formData.entitledFlag || item.accrualOn != "Custom"}
                            required
                           placeholderText="YYYY-MM-DD"
                          />
                        </div>
                        
                      </Col>


                      <Col sm="12" md="6" lg="3"
                        className="form-group p-2 m-0">
                        <label htmlFor="recurring">Recurring
                        </label>
                        <InfoTooltip
                            msg="Make this accrual policy recurring" />
                        <FormSelect id="recurring"
                          name='recurring'
                          onChange={e => onInputChange(e, item.uniq)}
                          required
                          value={item.recurring}
                          disabled={!formData.entitledFlag || formData.allowAdditionalAccrualRules != "Yes"}>
                          <option value=''>Select Recurring </option>
                          <option value='Yes'>Yes</option>
                          <option value='No'>No</option>
                        </FormSelect>
                      </Col>
                    </Row>
                    <Row>
                    <Col sm="12" md="12" lg="12"
                          className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">
                            
                          <Button className="mx-1 py-2 mx-md-2" size="sm"
                            theme="accent" type="button" 
                            disabled={!formData.entitledFlag  || formData.allowAdditionalAccrualRules !== "Yes"}
                            onClick={e => deleteLevel(e, item)}>Delete</Button>
                   </Col>
                   </Row>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>


        </div>
      );
    });
  };

  let onSubmit = (e) => {
    e.preventDefault();


    //setFormData({ ...formData, "additionalAccrualRulesList": state.items });
    formData.additionalAccrualRulesList=JSON.stringify(state.items);
    var validateNegativeCarryover=true;
    if(formData.allowNegativeCarryOver==="Yes"){
      if(formData.maximumNegativeAllowed==="" ){
        validateNegativeCarryover=false;
        setAlert("Please Enter Max Negative Allowed.", "danger");
      }
      if(formData.maximumNegativeAllowedUnit==="" ){
        validateNegativeCarryover=false;
        setAlert("Please Enter Max Negative Allowed Unit.", "danger");
      }
    }
if(validateNegativeCarryover)
{
    save_leave_policy_details({
      ...formData,
      leaveQuotaReset: formData.leaveQuotaResetType,
      leaveQuotaResetDate: setInputDate(formData.leaveQuotaResetDate),
      firstAccrualOn: setInputDate(formData.firstAccrualOn)
    });
  }
  }

  let onDelete = e => {
    e.preventDefault();
    setConfirmAlert({
      ...confirmAlert,
      confirmMsg: 'Are you sure you want to delete this leave policy?',
      visible: true,
      onConfirm: () => {
        delete_leave_policy_details(leavePolicyId);
        history.goBack();
      }
    })
  };
 // deleteLevel
  let deleteLevel = (e, rule) => {

  
    e.preventDefault();
    setConfirmAlert({
      ...confirmAlert,
      confirmMsg: 'Are you sure you want to delete Level?',
      visible: true,
      onConfirm: () => {
        var shouldDelete = leavePolicyDetails.additionalAccrualRulesList ? state.items.length === leavePolicyDetails.additionalAccrualRulesList.length : false;
       
       // var currentItem = state.items.filter(v=>v.id == ruleId)[0];
       
        if(shouldDelete)
        { const index = state.items.findIndex(v => v.id === rule.id); //use id instead of index
        if (index > -1) { 
           state.items.splice(index, 1); 
         }
          delete_leave_policy_level_details(formData.leavePolicyId, rule.id);
          var i = leavePolicyDetails.additionalAccrualRulesList.findIndex(v => v.id === rule.id);
          if (i > -1) { 
            leavePolicyDetails.additionalAccrualRulesList.splice(i, 1);
          }
        }else
        {
          const index = state.items.findIndex(v => v.uniq === rule.uniq); //use id instead of index
          if (index > -1) { 
             state.items.splice(index, 1); 
           }
        }
      }
    })
  };

  let onConfigureLocation = e => {
    if (leavePolicyId && leavePolicyId !== "0")
      history.push("/sub-leave-policy-location-config/" + leavePolicyId);
    else
      setAlert("First save the leave policy and then try again.", "danger");
  };

  const onChange = (e) => {
       
    setFormData({ ...formData, [e.target.name]: e.target.value});
  };

  const onMaximumCarryOverChange = (e) => {
    let val = e.target.value;
    if(val >= 1)
    {
      if((formData.carryOverType === 'Percentage' || formData.carryOverType === 'Number') && val > 100)
      {
        setAlert("Value can not be more than 100.", "danger");
      }
      else if((formData.carryOverType === 'Entitlement') && (val > 3))
      {
        setAlert("Value should be between 1 and 3.", "danger");
      }
      else
        setFormData({ ...formData, [e.target.name]: e.target.value});
    }
  };

  const onChangeType = (e) => {
  
    //setFormData({ ...formData, [e.target.name]: e.target.value});
    if(e.target.value === 'Percentage')
    {
      setFormData({ ...formData,[e.target.name]: e.target.value,'maximumCarryOver': 50});
    }
    if(e.target.value === 'Number')
    {
      setFormData({ ...formData, [e.target.name]: e.target.value,'maximumCarryOver': 5});
    }
    if(e.target.value === 'Entitlement')
    {
      setFormData({ ...formData, [e.target.name]: e.target.value,'maximumCarryOver': 1});
    }
    if(e.target.value === '')
    {
      setFormData({ ...formData, [e.target.name]: e.target.value,'maximumCarryOver': -1});
    }
   
  };
  const onYesNoToggle = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: (formData[e.target.name] === "Yes") ? "No" : "Yes"
    });
  };

  var leaveQuotaResetTypeListOptions = leaveQuotaResetTypeList.map(option => {
    return (<option value={option}>{option}</option>)
  })
  var leavePeriodStringListOptions = leavePeriodStringList.map(option => {
    return (<option value={option}>{option}</option>)
  })
  var leaveAccrualDateTypeListOptions = leaveAccrualDateTypeList.map(option => {
    return (<option value={option}>{option}</option>)
  })
  var leaveAccrualEveryStringListOptions = leaveAccrualEveryStringList.map(option => {
    return (<option value={option}>{option}</option>)
  })
  var leavePayOutTimeTypeListOptions = leavePayOutTimeTypeList.map(option => {
    return (<option value={option}>{option}</option>)
  })
  var leavePayOutLimitTypeListOptions = leavePayOutLimitTypeList.map(option => {
    return (<option value={option}>{option}</option>)
  })

  let weekDays = ['Mon','Tue','Wed','Thu','Fri','Sat','Sun'];
  let monthDays = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'];

  let custom = ['Month','Year'];
  let noncustom = ['Week','Two Weeks','15 Days','Month','Year'];

  var customOptions = custom.map(option => {
    return (<option value={option}>{option}</option>)
  })
  var noncustomOptions = noncustom.map(option => {
    return (<option value={option}>{option}</option>)
  })

  var weekDaysOptions = weekDays.map(option => {
    return (<option value={option}>{option}</option>)
  })
  var monthDaysOptions = monthDays.map(option => {
    return (<option value={option}>{option}</option>)
  })

  let carryovertype = ['Percentage','Number','Entitlement'];
  
  var carryovertypeOptions = carryovertype.map(option => {
    return (<option value={option}>{option}</option>)
  })

  

  const tableColumns = [
    {
      Header: "Country",
      accessor: "countryName",
      className: "justify-content-start"
    },
    {
      Header: "Region",
      accessor: "regionName",
      className: "justify-content-start"
    },
    {
      Header: "Minimum Leaves",
      accessor: "minimumLeaves",
      className: "justify-content-end",
      maxWidth: 150
    },
    {
      Header: "Action",
      maxWidth: 100,
      Cell: row => <Row>
        <Col><Link
          to={{
            pathname: '/sub-leave-policy-location-config/' + leavePolicyId,
            state: { leavePolicyLocationId: row.original.leavePolicyLocationId}
          }}>Edit</Link></Col>
      </Row>
    }
  ];
  return (
    <>
      <ConfirmAlert confirmAlert={confirmAlert}
        setConfirmAlert={setConfirmAlert} />
      <Container fluid className="main-content-container p-4">
        <Form onSubmit={onSubmit}>
          <div className="position-relative">

            <Card small className="mb-5 w-100">
              <h4 className="m-0 section-title idStyle">Ref# {leavePolicyId}</h4>

              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>

                      <Row form>

                        <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                          <label htmlFor="leaveName">Leave Policy Name</label>
                          <InfoTooltip
                            msg="Name the leave policy" />
                          <FormInput
                            id="leaveName"
                            name='leaveName'
                            value={formData.leaveName}
                            onChange={onChange}
                            placeholder="Enter Leave Policy Name"
                          />
                        </Col>

                        <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                          <label htmlFor="entitledFlag">Leave Category</label>
                          <InfoTooltip
                            msg="Make the leave policy limited or unlimited" />
                          <FormSelect id="entitledFlag" name='entitledFlag'
                            onChange={e => {
                              if (e.target.value !== "true") {
                                formData.clientId = 0;
                                formData.leavePolicyId = 0;
                                formData.entitledDays = "";
                                formData.proRateLeaves = "";
                                formData.leaveQuotaResetType = "";
                                formData.leaveQuotaResetDate = "";
                                formData.allowFutureLeaves = "";
                                formData.accrualBegins = "";
                                formData.accrualBeginType = "";
                                formData.accrualUnit = "";
                                formData.accrualUnitType = "";
                                formData.accrualEvery = "";
                                formData.accrualDateType = "";
                                formData.firstAccrualOn = "";
                                formData.maximumAccrual = "";
                                formData.maximumAccrualType = "";
                                formData.allowAccruals = "";
                                formData.allowCarryOver = "";
                                formData.allowNegativeCarryOver = "";
                                formData.maximumNegativeAllowed = "";
                                formData.maximumNegativeAllowedUnit  = "";
                                formData.carryOverType = "";
                                formData.maximumCarryOver = "";
                                formData.useCarryOverWithin = "";
                                formData.allowLeavePayout = "";
                                formData.payoutTimeType = "";
                                formData.payoutTime = "";
                                formData.payoutLimitType = "";
                                formData.payoutLimit = "";
                                formData.leavePolicyLocationNote = "";
                              }
                              setFormData({
                                ...formData,
                                entitledFlag: e.target.value === "true"
                              })
                            }}
                            value={formData.entitledFlag}
                            required>
                            <option value="true">Limited Leaves</option>
                            <option value="false">Unlimited Leaves</option>
                          </FormSelect>
                        </Col>

                        <InputBox
                          placeholder="Enter Waiting Period"
                          label="Waiting Period"
                          id="waitingPeriod"
                          name='waitingPeriod'
                          value={formData.waitingPeriod}
                          type="number"
                          toolTipMSG = "Apply a waiting period to the leave policy"
                          subLabel="days"
                          onChange={onChange} />




                        <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                          <label htmlFor="paidTimeOff">Paid Leave</label>
                          <InfoTooltip
                            msg="Make the leave policy paid time-off" />
                          <FormSelect id="paidTimeOff" name='paidTimeOff'
                            onChange={onChange}
                            value={formData.paidTimeOff}
                            required>
                            <option value=''>Select Paid Leaves</option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </FormSelect>
                        </Col>

                        <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                          <label htmlFor="proRateLeaves">Prorate Leaves</label>
                          <InfoTooltip
                            msg="Pro-rate the leave policy" />
                          <FormSelect id="proRateLeaves" name='proRateLeaves'
                            onChange={onChange}
                            value={formData.proRateLeaves}
                            disabled={!formData.entitledFlag}
                            required>
                            <option value=''>Select Prorate Leaves</option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </FormSelect>
                        </Col>


                      </Row>

                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </div>

          <div className="position-relative">
            <div className="tableHeader d-flex">
              <label className="text-bold m-0 mx-1" htmlFor="allowLeaveEntitlementRules">Leave Entitlement Rules
                <InfoTooltip
                  msg="Define how Employees with this Policy will Accrue their Time-Off" /></label>
              <FormCheckbox
                id="allowLeaveEntitlementRules"
                name='allowLeaveEntitlementRules'
                className="m-0"
                onChange={e => {
                  if (formData[e.target.name] === "Yes") {
                    formData[e.target.name] = "No";
                    formData.accrualBegins = "";
                    formData.accrualBeginType = "";
                    formData.accrualUnit = "";
                    formData.accrualUnitType = "";
                    formData.accrualEvery = "";
                    formData.accrualDateType = "";
                    formData.firstAccrualOn = "";
                    formData.maximumAccrual = "";
                    formData.maximumAccrualType = ""
                  } else
                    formData[e.target.name] = "Yes"
                  setFormData({
                    ...formData,
                  })
                }}
                checked={formData.entitledFlag && (formData.allowLeaveEntitlementRules === "Yes")}
                toggle small />
            </div>
            <Card small className="mb-5 w-100">
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Row form>

                        <Col sm="12" md="6" lg="3"
                          className="form-group p-2 m-0">
                          <label htmlFor="leaveQuotaResetType">Reset
                            Date</label>
                            <InfoTooltip
                            msg="Choose a reset date for the leave policy" />
                          <FormSelect id="leaveQuotaResetType"
                            name='leaveQuotaResetType'
                            onChange={onChange}
                            value={formData.leaveQuotaResetType}
                            disabled={!formData.entitledFlag || formData.allowLeaveEntitlementRules !== "Yes"}
                            required>
                            <option value=''>Select Reset
                              Date
                            </option>
                            {leaveQuotaResetTypeListOptions}
                          </FormSelect>
                        </Col>

                        <Col sm="12" md="6" lg="3"
                          className="form-group p-2 m-0">
                          <label htmlFor="leaveQuotaResetDate">Custom Reset
                            Date</label>
                            <InfoTooltip
                            msg="Choose a custom reset date for the policy" />
                          <div>
                            <DatePicker
                              id='leaveQuotaResetDate'
                              autoComplete='off'
                              name='leaveQuotaResetDate'
                              dateFormat='yyyy-MM-dd'
                              selected={formData.leaveQuotaResetDate}
                              disabled={!formData.entitledFlag || formData.leaveQuotaResetType !== "Custom"}
                              onChange={(date) =>
                                setFormData({
                                  ...formData,
                                  leaveQuotaResetDate: date
                                })
                              }
                              value={formData.leaveQuotaResetDate}
                              required
                              placeholderText="YYYY-MM-DD"
                            />
                          </div>
                        </Col>
                        <Col sm="12" md="6" lg="3"
                          className="form-group p-2 m-0">
                          <label htmlFor="allowFutureLeaves">Allow Future
                            Leaves</label>
                          <InfoTooltip
                            msg="Allow workers to take leaves not yet accrued" />
                          <FormSelect id="allowFutureLeaves"
                            name='allowFutureLeaves'
                            onChange={e => {
                              formData[e.target.name]=e.target.value;
                              if (formData[e.target.name] === "No") {
                               
                                formData.allowNegativeCarryOver = "";  
                                formData.maximumNegativeAllowed = "";
                                formData.maximumNegativeAllowedUnit  = "";
                              } else
                                formData[e.target.name] = "Yes"
                              setFormData({
                                ...formData,
                              })
                            }
                            }
                            value={formData.allowFutureLeaves}
                            disabled={!formData.entitledFlag || formData.allowLeaveEntitlementRules !== "Yes"}
                            required>
                            <option value=''>Select Allow Future
                              Leaves
                            </option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </FormSelect>
                        </Col>


                        <Col sm="12" md="6" lg="3"
                          className="form-group p-2 m-0">
                          <label htmlFor="leavesAccrueEvery">Leaves Accrue Every</label>
                          <InfoTooltip
                            msg="Define how leave accrues" />
                          <FormSelect id="leavesAccrueEvery" name='leavesAccrueEvery'
                            onChange={onChange}
                            required
                            value={formData.leavesAccrueEvery}
                            disabled={!formData.entitledFlag || formData.allowLeaveEntitlementRules !== "Yes"}>
                            <option value=''>Select Leaves Accrue Every</option>
                            {/* <option value='Calendar'>Calendar</option>
                            <option value='HiredAnniversary'>Hired Anniversary</option>
                            <option value='Custom'>Custom</option> */}
                             {leaveQuotaResetTypeListOptions}
                          </FormSelect>
                        </Col>

                        <Col sm="12" md="6" lg="3"
                          className="form-group p-2 m-0">
                          <label htmlFor="accrualOnDate">On Date</label>
                          <InfoTooltip
                            msg="Set a custom accrual date" />
                          <FormSelect id="accrualOnDate"
                            name='accrualOnDate'
                            onChange={onChange}
                            required
                            value={formData.accrualOnDate}
                            disabled={!formData.entitledFlag || formData.allowLeaveEntitlementRules !== "Yes"}>
                            <option value=''>Select On Date</option>
                            {formData.leavesAccrueEvery === "Custom" ? customOptions : noncustomOptions}
                            
                          </FormSelect>
                        </Col>

                        <Col sm="12" md="6" lg="3"
                          className="form-group p-2 m-0">
                          <label htmlFor="firstDay">First Day
                          </label>
                          <InfoTooltip
                            msg="Set the first accrual date within your selected time period" />
                          <FormSelect id="firstDay"
                            name='firstDay'
                            onChange={onChange}
                            required
                            value={formData.firstDay}
                            //disabled={!formData.entitledFlag || formData.accrualOnDate == "Year" }
                            disabled={formData.allowLeaveEntitlementRules != "Yes" ? true : !formData.entitledFlag ? true : formData.accrualOnDate === "Year"}>
                              <option value=''>Select First Day</option>
                              {formData.accrualOnDate === "Week" ? weekDaysOptions : monthDaysOptions}
                            
                          </FormSelect>
                        </Col>

                        <Col sm="12" md="6" lg="3"
                          className="form-group p-2 m-0">
                          <label htmlFor="secondDay">Second  Day
                          </label>
                          <InfoTooltip
                            msg="Set the second accrual date within your selected time period" />
                          <FormSelect id="secondDay"
                            name='secondDay'
                            onChange={onChange}
                            required
                            value={formData.secondDay}
                            disabled={formData.allowLeaveEntitlementRules != "Yes" ? true : !formData.entitledFlag ? true : formData.accrualOnDate === "Year" || formData.accrualOnDate == "Year" || formData.accrualOnDate == "Week" || formData.accrualOnDate == "Month"}>
                            <option value=''>Select Second  Day</option>
                            {monthDaysOptions}
                          </FormSelect>
                        </Col>

                        <Col sm="12" md="6" lg="3"
                          className="form-group p-2 m-0">
                          <label htmlFor="accrualLimitType">Accrual Limit Type </label>
                          <InfoTooltip
                            msg="Set the accrual type" />
                          <FormSelect id="accrualLimitType"
                            name='accrualLimitType'
                            onChange={onChange}
                            required
                            value={formData.accrualLimitType}
                            disabled={!formData.entitledFlag || formData.allowLeaveEntitlementRules !== "Yes"}>
                            <option value=''>Select Accrual Limit Type</option>

                            <option value='Days'>Days</option>
                            <option value='Hours'>Hours</option>
                          </FormSelect>
                        </Col>

                        <InputBox label="Max Accrual" id="maximumAccrual"
                          name="maximumAccrual"
                          value={formData.maximumAccrual}
                          disabled={!formData.entitledFlag || formData.allowLeaveEntitlementRules !== "Yes"}
                          onChange={onChange}
                          required
                          placeholder="Enter Max Accrual"
                          toolTipMSG= "Create a maximum for leave accrual"
                        />

                        <Col sm="12" md="6" lg="3"
                          className="form-group p-2 m-0">
                          <label htmlFor="maximumAccrualUnit">Max Accrual
                            Unit </label>
                            <InfoTooltip
                            msg="Choose a unit for the maximum accrual" />
                          <FormSelect id="maximumAccrualUnit"
                            name='maximumAccrualUnit'
                            onChange={onChange}
                            required
                            value={formData.maximumAccrualUnit}
                            disabled={!formData.entitledFlag || formData.allowLeaveEntitlementRules !== "Yes"}>
                            <option value=''>Select Accrual
                              Unit</option>

                            <option value='Days'>Days</option>
                            <option value='Hours'>Hours</option>
                          </FormSelect>
                        </Col>

                      </Row>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </div>



          <div className="position-relative">
            <div className="tableHeader d-flex">
              <label className="text-bold m-0 mx-1" htmlFor="allowAdditionalAccrualRules">Additional Accrual Rules
                <InfoTooltip
                  msg="Define how Employees with this Policy will Accrue their Additional Time-Off" /></label>
              <FormCheckbox
                id="allowAdditionalAccrualRules"
                name='allowAdditionalAccrualRules'
                className="m-0"
                onChange={e => {
                  if (formData[e.target.name] === "Yes") {
                    formData[e.target.name] = "No";
                    formData.accrualBegins = "";
                    formData.accrualBeginType = "";
                    formData.accrualUnit = "";
                    formData.accrualUnitType = "";
                    formData.accrualEvery = "";
                    formData.accrualDateType = "";
                    formData.firstAccrualOn = "";
                   
                    formData.maximumAccrualType = ""
                  } else
                    formData[e.target.name] = "Yes"
                  setFormData({
                    ...formData,
                  })
                }}
                checked={formData.entitledFlag && (formData.allowAdditionalAccrualRules === "Yes")}
                toggle small />
            </div>



            <Card small className="mb-5 w-100 ">


              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row >
                    <Col>
                      <Row form style={{ height: "400px", overflow:"auto"}}>
                      {createList()}
                      </Row>


                      <Row className="px-3">

                        <Col sm="12" md="12" lg="12"
                          className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">
                          <Button className="mx-1 py-2 mx-md-2" size="sm"
                            theme="accent" type="button" onClick={addListItem}  disabled={!formData.entitledFlag || formData.allowAdditionalAccrualRules !== "Yes"}
                          >Add Level</Button>


                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>


          </div>


          <div className="position-relative">
            <div className="tableHeader d-flex">
              <label className="text-bold m-0 mx-1" htmlFor="allowCarryOver">Leave
                Carryover Rules
                <InfoTooltip
                  msg="Define Carryover in % or Cap Amount or Entitlement" /></label>
              <FormCheckbox
                id="allowCarryOver"
                name='allowCarryOver'
                className="m-0"
                onChange={e => {
                  if (formData[e.target.name] === "Yes") {
                    formData[e.target.name] = "No";
                    formData.allowNegativeCarryOver = "";
                    formData.carryOverType = "";
                    formData.maximumCarryOver = "";
                    formData.useCarryOverWithin = "";
                    formData.maximumNegativeAllowed = "";
                    formData.maximumNegativeAllowedUnit  = "";
                  } else
                    formData[e.target.name] = "Yes"
                  setFormData({
                    ...formData,
                  })
                }
                }
                checked={formData.entitledFlag && (formData.allowCarryOver === "Yes")}
                toggle small />
            </div>
            <Card small className="mb-5 w-100">
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Row form>
                     
                        <Col sm="12" md="6" lg="3"
                          className="form-group p-2 m-0">
                          <label htmlFor="allowNegativeCarryOver">Allow Negative
                            Carryover</label>
                          <InfoTooltip
                            msg="Allow workers to take leaves not yet accrued" />
                          <FormSelect id="allowNegativeCarryOver"
                            name='allowNegativeCarryOver'
                            onChange={onChange}
                            disabled={!formData.entitledFlag || formData.allowCarryOver !== "Yes" || formData.allowFutureLeaves !== "Yes"}
                            value={formData.allowNegativeCarryOver}>
                            <option value=''>Select Allow Negative
                              Carryover
                            </option>
                            <option value='No'>No</option>
                            <option value='Yes'>Yes</option>
                          </FormSelect>
                        </Col>
                        <InputBox label="Max Negative Allowed"
                          id="maximumNegativeAllowed"
                          name="maximumNegativeAllowed"
                         
                          value= {formData.maximumNegativeAllowed}
                          disabled={!formData.entitledFlag || formData.allowCarryOver !== "Yes" || formData.allowNegativeCarryOver !== "Yes"}
                          onChange={onChange}
                         
                          placeholder="Enter Max Negative Allowed"
                          type='number'
                          toolTipMSG= "Set maximum Negative allowed"
                        />
                        <Col sm="12" md="6" lg="3"
                          className="form-group pl-2 pt-2 pb-2 m-0">
                          <label htmlFor="maximumNegativeAllowedUnit">Max Negative Allowed Unit </label>
                          <InfoTooltip
                            msg="Choose the Unit for Maximum Negative allowed" />
                          <FormSelect id="maximumNegativeAllowedUnit"
                            name='maximumNegativeAllowedUnit'
                            onChange={onChange}
                            disabled={!formData.entitledFlag || formData.allowCarryOver !== "Yes" || formData.allowNegativeCarryOver !== "Yes"}
                            value={formData.maximumNegativeAllowedUnit}>
                            <option value=''>Select Max Negative Allowed Unit
                            </option>
                            <option value='Days'>Days</option>
                            <option value='Hours'>Hours</option>
                          </FormSelect>
                        </Col>
                       
                        </Row>
                       
                        <Row form>
                        <Col sm="12" md="6" lg="3"
                          className="form-group p-2 m-0">
                          <label htmlFor="carryOverType">Carryover Type
                          </label>
                          <InfoTooltip
                            msg="Define the Carryover Type as a %, or a flat cap, or multiple of their basic entitlement" />
                          <FormSelect id="carryOverType"
                            name='carryOverType'
                            onChange={onChangeType}
                            disabled={!formData.entitledFlag || formData.allowCarryOver !== "Yes"}
                            value={formData.carryOverType}>
                            <option value=''>Select Carryover Type

                            </option>
                          
                            {carryovertypeOptions}
                          </FormSelect>
                        </Col>

                        <InputBox label="Max Carryover"
                          id="maximumCarryOver"
                          name="maximumCarryOver"
                         
                          value= {formData.maximumCarryOver}
                          disabled={!formData.entitledFlag || formData.allowCarryOver !== "Yes"}
                          onChange={onMaximumCarryOverChange}
                         
                          placeholder="Enter Max Carryover"
                          type='number'
                          toolTipMSG= "Set the carryover maximum"
                        />

                        <InputBox label="Use Carryover Within" subLabel="days"
                          id="useCarryOverWithin"
                          name="useCarryOverWithin"
                         
                          value={formData.useCarryOverWithin}
                          disabled={!formData.entitledFlag || formData.allowCarryOver !== "Yes"}
                          onChange={onChange}
                          placeholder="Enter Use Carryover Within"
                          toolTipMSG= "Set an expiration period for carryover"
                        />
                     
                     </Row>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </div>

          <div className="position-relative">
            <div className="tableHeader d-flex">
              <label className="text-bold m-0 mx-1" htmlFor="allowLeavePayout">Leave Payout Rules</label>
              <FormCheckbox
                id="allowLeavePayout"
                name='allowLeavePayout'
                className="m-0"
                onChange={e => {
                  if (formData[e.target.name] === "Yes") {
                    formData[e.target.name] = "No";
                    formData.payoutTimeType = "";
                    formData.payoutTime = "";
                    formData.payoutLimitType = "";
                    formData.payoutLimit = "";
                  } else
                    formData[e.target.name] = "Yes"
                  setFormData({
                    ...formData,
                  })
                }
                }
                checked={formData.entitledFlag && (formData.allowLeavePayout === "Yes")}
                toggle small />
            </div>
            <Card small className="mb-4 w-100">
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>

                      <Row form>
                        <Col sm="12" md="6" lg="3"
                          className="form-group p-2 m-0">
                          <label htmlFor="payoutTimeType">Payout Time</label>
                          <FormSelect id="payoutTimeType" name='payoutTimeType'
                            onChange={onChange}
                            value={formData.payoutTimeType}
                            disabled={!formData.entitledFlag || formData.allowLeavePayout !== "Yes"}
                            required>
                            <option value=''>Select Payout Time</option>
                            {leavePayOutTimeTypeListOptions}
                          </FormSelect>
                        </Col>

                        <InputBox label="Payout Limit" id="payoutLimit"
                          name="payoutLimit"
                          placeholder="Enter Payout Limit"
                          value={formData.payoutLimit}
                          disabled={!formData.entitledFlag || formData.allowLeavePayout !== "Yes"}
                          onChange={onChange} />

                        <Col sm="12" md="6" lg="3"
                          className="form-group p-2 m-0">
                          <label htmlFor="payoutLimitType">Payout Limit
                            Type</label>
                          <FormSelect id="payoutLimitType"
                            name='payoutLimitType'
                            onChange={onChange}
                            value={formData.payoutLimitType}
                            disabled={!formData.entitledFlag || formData.allowLeavePayout !== "Yes"}
                            required>
                            <option value=''>Select Payout Limit Type</option>
                            {leavePayOutLimitTypeListOptions}
                          </FormSelect>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </div>


          {/*action buttons*/}
          <Row className="px-3">
            <Col sm="12" md="12" lg="12"
              className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">
              <Button className="mx-1 py-2 mx-md-2" size="sm"
                theme="accent" type="submit"
              >Save</Button>
              {
                (leavePolicyId && leavePolicyId !== "0") &&
                <Button className="mx-1 py-2 mx-md-2" size="sm"
                  theme="accent" onClick={onDelete}>Delete</Button>
              }
              <Button className="mx-1 py-2 mx-md-2" size="sm"
                theme="accent"
                onClick={e => history.goBack()}>Cancel</Button>
            </Col>
          </Row>
        </Form>

        <div className="position-relative">
          <span className="tableHeader">Configure Regional Leaves</span>
          <Card className="tableCard mt-4 p-0 border-card">
            <CardHeader className="p-0 pt-2">
              <Container fluid className="file-manager__filters border-bottom">
                <Row>
                  {/*Filters :: Page Size*/}
                  <Col lg="6"
                    className="file-manager__filters__rows d-flex align-items-center col-12">
                    <span>Show</span>
                    <FormSelect
                      size="sm"
                      name='pageSize'
                      value={formData.pageSize}
                      onChange={onChange}
                    >
                      {formData.pageSizeOptions.map((size, idx) => (
                        <option key={idx} value={size}>
                          {size} rows
                        </option>
                      ))}
                    </FormSelect>
                  </Col>

                  <Col lg="6"
                    className="file-manager__filters__search d-flex justify-content-center justify-content-lg-end col-12">
                    <Button
                      className="mx-1 my-2 py-2 mx-md-2" size="sm"
                      theme="accent" onClick={onConfigureLocation}>Configure
                      Regions</Button>
                  </Col>


                </Row>
              </Container>
            </CardHeader>
            <CardBody className="p-0">
              <div className="">
                <ReactTable
                  columns={tableColumns}
                  data={leavePolicyLocationList}
                  pageSize={Number(formData.pageSize)}
                  showPageSizeOptions={false}
                  resizable={false}
                  noDataText="No results found"
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
}

SubEditLeavePolicy.propTypes = {
  leavePolicyDetails: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  leavePolicyDetails: state.leavePolicy.leavePolicyDetails,
  leaveQuotaResetTypeList: state.user.leaveQuotaResetTypeList,
  leavePeriodStringList: state.user.leavePeriodStringList,
  leaveAccrualDateTypeList: state.user.leaveAccrualDateTypeList,
  leaveAccrualEveryStringList: state.user.leaveAccrualEveryStringList,
  leavePayOutTimeTypeList: state.user.leavePayOutTimeTypeList,
  leavePayOutLimitTypeList: state.user.leavePayOutLimitTypeList,
  leavePolicyLocationList: state.leavePolicy.leavePolicyLocationList,
  leavePolicyLevelDetails: state.leavePolicy.leavePolicyLevelDetails
});
export default connect(mapStateToProps, {
  get_leave_policy_details,
  get_leave_quota_reset_type_list,
  get_leave_period_string_list,
  get_leave_accrual_date_type_list,
  get_leave_accrual_every_string_list,
  get_leave_pay_out_time_type_list,
  get_leave_pay_out_limit_type_list,
  get_leave_policy_location_list,
  delete_leave_policy_details,
  save_leave_policy_details,
  save_leave_policy_level_details,
  delete_leave_policy_level_details,
  setAlert
})(SubEditLeavePolicy)
