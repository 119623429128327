import React, {useEffect, useState} from 'react';
import {
  Button,
  Card, CardBody,
  CardHeader,
  Col,
  Container, FormSelect,
  Row
} from "shards-react";
import PropTypes from 'prop-types';
import ReactTable from "react-table";
import RangeDatePicker from "../../components/common/RangeDatePicker";
import {setDateForTable, setInputDate} from "../../utils/date";
import {connect} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import {
} from "../../redux/actions/user";
import {
  clear_sheet_ref_num,
  get_expense_status_list,
  get_user_expense_list
} from "../../redux/actions/worker/expenses";
import {getStatusClass, numberToMonetary} from "../../utils/general";
import {default as queryString} from "qs";

function ExpenseReports({  user,
                          get_expense_status_list,
                          expenseStatusList,
                          get_user_expense_list,
                          expenseList,
                          clear_sheet_ref_num}) {

  let location = useLocation();
  let queryParams = queryString.parse(location.search.replace("?", ""))

  const [state, setState] = useState({
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
    from:  '',
    to:  '',
    status:  '',
  });

  useEffect(() => {
    get_expense_status_list();
    return () => {
      clear_sheet_ref_num();
    }
  }, []);

  useEffect(() => {
    let newState = {
      ...state, from: queryParams.from || "",
      to: queryParams.to || "",
      status: queryParams.status || ""
    }
    filterExpenses(newState.from, newState.to, newState.status)
    setState(newState)
  }, [queryParams.from,
    queryParams.to,
    queryParams.status]);

  let filterExpenses = (from=state.from, to=state.to, status=state.status) => {
    get_user_expense_list(user.userId,  setInputDate(from), setInputDate(to), status);
  }

  let onStartDateChanged = (value) => {
    setState({
      ...state,
      from: value
    })
  }

  let onEndDateChanged = (value) => {
    setState({
      ...state,
      to: value
    })
  }


  const onChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  };



  const tableData = expenseList;
  const {pageSize, pageSizeOptions} = state;


  const tableColumns = [
    {
      Header: "Date",
      accessor: "date",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        setDateForTable(row.original.date),
    },
    {
      Header: "Report Name",
      accessor: "name",
      minWidth: 300,
      className: "justify-content-start text-left text-wrap text-break"
    },
    {
      Header: "Submitted Total",
      accessor: "submittedTotal",
      maxWidth: 160,
      className: "justify-content-end text-wrap text-break",
      Cell:row=>numberToMonetary(row.original.submittedTotal)
    },

    {
      Header: "Approved Total",
      accessor: "approvedTotal",
      maxWidth: 160,
      className: "justify-content-end text-wrap text-break",
      Cell:row=>numberToMonetary(row.original.approvedTotal)
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 100,
      className: "text-center",
      Cell: row =>
        <span
          className={getStatusClass(row.original.status)}>{row.original.status}</span>,
    },

    {
      Header: "Ref#",
      accessor: "refNum",
      maxWidth: 100,
      className: "text-center",
      Cell: row =>
        <Link to={`/edit-expense-report/${row.original.refNum}`}>{row.original.refNum}</Link>
    }
  ];


  const expenseStatus = expenseStatusList.map((status) => (
    <option key={status} value={status}>{status}</option>
  ));


  return (
    <Container fluid className="main-content-container px-4 pb-4">

      <div className="page-header tableSearch flex-column flex-md-row py-2 d-flex justify-content-end bg-white mt-4 ">
        <div className="d-flex align-items-center p-2">
          <FormSelect name='status' value={state.status} onChange={onChange}>
            <option value=''>All Status
            </option>
            {expenseStatus}
          </FormSelect>
        </div>
        <div className="d-flex align-items-center p-2">
          <RangeDatePicker className="justify-content-end"
                           onStartDateChanged={onStartDateChanged}
                           onEndDateChanged={onEndDateChanged} name="range"/>
        </div>

        <div className="d-flex align-items-center justify-content-end p-2">
          <Button className="mx-2 py-2 my-2" size='sm' theme="accent" onClick={e=>filterExpenses()}>Search</Button>
        </div>
      </div>


      <Card className="tableCard p-0">
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {/* Filters :: Page Size */}
              <Col className="file-manager__filters__rows d-flex justify-content-center justify-content-md-start align-items-center col-6">
                <span>Show</span>
                <FormSelect
                  size="sm"
                  name='pageSize'
                  value={state.pageSize}
                  onChange={onChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size} rows
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col
                className="file-manager__filters__search d-flex justify-content-center justify-content-md-end col-6">
                <Link to="/add-expense-report/0">
                  <Button className="mx-2 py-2 my-2" size='sm'
                              theme="accent">Add Report</Button>
                </Link>
              </Col>


            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
              columns={tableColumns}
              data={tableData}
              pageSize={Number(pageSize)}
              showPageSizeOptions={false}
              resizable={false}
              noDataText="No results found"
            />
          </div>
        </CardBody>
      </Card>
    </Container>
  );
}

ExpenseReports.propTypes = {
  user: PropTypes.object.isRequired,
  get_expense_status_list: PropTypes.func.isRequired,
  expenseStatusList: PropTypes.array.isRequired,
  get_user_expense_list: PropTypes.func.isRequired,
  expenseList: PropTypes.array.isRequired,
  clear_sheet_ref_num:PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  expenseStatusList: state.expenses.expenseStatusList,
  expenseList: state.expenses.expenseList,
})
export default connect(mapStateToProps, {
  get_expense_status_list,
  get_user_expense_list,
  clear_sheet_ref_num,
})(ExpenseReports);
