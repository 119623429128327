import { Button, Card, Col, Container, DatePicker, Form, FormCheckbox, FormSelect, ListGroup, ListGroupItem, Row } from "shards-react";
import InfoTooltip from "../../../components/common/InfoTooltip";
import { useEffect, useRef, useState } from "react";
import { setAlert } from "../../../redux/actions/alert";
import { validateFileName } from "../../../utils/general";
import { connect } from "react-redux";
import {  get_payroll_taximort_type, save_import_data } from "../../../redux/actions/subscriber/payroll";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {setInputDate } from "../../../utils/date";

 function ImportData({ 
    payrollTaxImportType,
    setAlert,
    save_import_data,
    get_payroll_taximort_type
 }) {

    const [formData, setFormData] = useState({
        importType: '',
        payPeriod: '',
        importDuplicates:false,
        startDate:'',
        file: null
    })
    const [isSubmitting, setSubmitting] = useState(false);
    const fileRef = useRef(null)
    const history = useHistory();
    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
    const resetFile = () => {
        if (fileRef.current) {
            fileRef.current.value = ""; 
        }
      };
    const onFileChange = (e) => {
        e.preventDefault();
        const supportedExtensions = formData.importType === 'Payroll Tax' ? ['.csv', '.CSV'] : ['.xlsx', '.XLSX'];
        let file = e.target.files[0];
        let fileExtension = file.name.substring(file.name.lastIndexOf('.'), file.name.length);

        let valid = validateFileName(file.name);

        if (!valid) {
            setAlert("File name should only contain letters [a-z, A-Z], numbers [0-9], space [' '], period [.], underscore [_] and hyphen [-].", "danger");
            resetFile();
            return;
        }
        if (!supportedExtensions.includes(fileExtension.toLowerCase())) {
            setAlert(`Please attach  ${formData.importType === 'Payroll Tax' ? '.CSV' : '.XLSX'} only.`, "danger");
            resetFile();
            return;
        }

        setFormData({...formData, file:file});

    }

    const payrollTaxImportTypeOptions = (payrollTaxImportType || []).map(importType => <option key={importType} value={importType}>{importType}</option>)
    const onSubmit =  async (e)=>{
        e.preventDefault();
        if(isSubmitting) return;
        setSubmitting(true);
        const formDataToSubmit = {...formData};
        formDataToSubmit.startDate = setInputDate(formDataToSubmit.startDate);
        const saved = await save_import_data({...formDataToSubmit});
        // if(saved) 
        //     history.goBack();
        // else
        setSubmitting(false);

    }
    useEffect(()=>{
        get_payroll_taximort_type();
    },[])
    return (
        <Container fluid className="main-content-container p-4">
            <Card small className="mb-4 w-100 ">
                <ListGroup flush>
                    <Form onSubmit={onSubmit}>
                        <ListGroupItem className="border-card p-3 w-100">
                            <Row className="mb-3 h-100">
                                <Col lg="4" className="d-flex flex-row justify-content-between">
                                    <label htmlFor="importType" className="w-50 mt-2">Import Type</label>
                                    <FormSelect
                                        name="importType"
                                        id="importType"
                                        value={formData.importType}
                                        onChange={onChange}
                                    >
                                        <option key="importType0">Select Import Type</option>
                                        {payrollTaxImportTypeOptions}

                                    </FormSelect>
                                </Col>
                                <Col lg="3" className="d-flex flex-row justify-content-between mt-2">
                                    <label htmlFor="importDuplicates" className="w-70 pl-5">Do not import duplicates</label>
                                    <InfoTooltip cssClassName="text-start custom-tooltip-inner" msg="For Ceridian Payroll tax: Rows with the same PPE and employee number <br/>that have been already imported will not be imported again." />

                                    <FormCheckbox
                                        name="importDuplicates"
                                        id="importDuplicates"
                                        value={formData.importDuplicates}
                                        checked={formData.importDuplicates}
                                        onChange={()=>setFormData({ ...formData, importDuplicates: !formData.importDuplicates })}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3 h-100">
                                <Col lg="4" className="d-flex flex-row justify-content-between">
                                    <label htmlFor="payPeriod" className="w-50 mt-2">Pay Period</label>
                                    <FormSelect
                                        name="payPeriod"
                                        id="payPeriod"
                                        value={formData.payPeriod}
                                        onChange={onChange}
                                    >
                                        <option>Select Pay Period</option>
                                    </FormSelect>
                                </Col>
                            </Row>
                            <Row className="mb-2 h-100">
                                <Col lg="4" className="d-flex flex-row justify-content-between ">
                                    <label htmlFor="startDate" className="w-50 mt-2 mr-n2">Start Date</label>
                                    
                                    <DatePicker
                                        id='startDate'
                                        autoComplete='off'
                                        name='startDate'
                                        dateFormat='yyyy-MM-dd'
                                        value={formData.startDate}
                                        selected={formData.startDate}
                                        onChange={(date) =>
                                            setFormData({ ...formData, 'startDate': date })
                                        }
                                        placeholderText="YYYY-MM-DD"
                                    />
                                    
                                </Col>
                            </Row>
                            <Row className="mb-2 h-100">
                                <Col lg="4" className="d-flex flex-row justify-content-between">
                                    <div>
                                        <label htmlFor="file" className="mt-2 w-50">File Upload</label>
                                        <input name="file" id="file" type="file" onChange={onFileChange} ref={fileRef} accept={formData.importType === 'Payroll Tax' ? '.csv' : ".xlsx"} />
                                    </div>
                                    <div className="d-flex align-items-end">
                                        <Button >Submit</Button>

                                    </div>

                                </Col>
                            </Row>
                        </ListGroupItem>
                    </Form>
                </ListGroup>
            </Card>
        </Container>
    )
}

const mapStateToProps = (state=>({
    payrollTaxImportType:state.payroll.payrollTaxImportType
}))
export default connect(mapStateToProps, {
    setAlert,
    save_import_data,
    get_payroll_taximort_type
})(ImportData)