import React, { useEffect, useRef, useState } from 'react';
import {
    Card,
    Col, Form, FormSelect, DatePicker,
    ListGroup,
    ListGroupItem,
    Row,
    Container,
    Button,
    Tooltip
} from "shards-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InputBox from '../../common/InputBox';
import { useLocation } from 'react-router-dom';
import { setAlert } from "../../../redux/actions/alert";
import {
    clear_user_profile,
    get_client_list,
    get_compensation_types,
    get_country_list,
    sub_get_currency_list,
    get_deal_types_types,
    get_region_list,
    get_subscriber_list,
    get_hr_Consultant,
    get_user_profile,
    get_worker_types, reset,
    get_pay_component_list,
    save_user_profile,
    update_isSave_user_profile
} from "../../../redux/actions/user";
import { setAPIDate, setDateForTable, setInputDate } from '../../../utils/date';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import InfoTooltip from '../../common/InfoTooltip';
import { compareObjects, fixedDecimalPlaces, formatNumberAmount, getFrequencyNumber, groupByElements, toNumber } from '../../../utils/general';
import StandardFormInput from '../../common/StandardFormInput';
import { STANDARD_FORMATS } from '../../../utils/constants';

function CompensationForm(props) {


    const location = useLocation();

    const [notesCharacters, seNotesCharacters] = useState(100);
    const history = useHistory();
    const [hasError, setHasError] = useState(false);
    const formDataVal = useRef({})
    const [salaryDecimalPlaces, setSalaryDecimalPlaces] = useState(2)
    const [disabledSave, setDisabledSave] = useState(location.state.row !== undefined)
    const [payRateDecimalPlaces, setPayRateDecimalPlaces] = useState(4);
    const onChange = (e) => {

        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const onChangeStandardInput = (name,value) => {
        setFormData({...formData, [name]: value});

    };
    const currentCompensations = (location.state.formData && location.state.formData.compensationInfos) ? location.state.formData.compensationInfos : [];
    const groupedCompensations = location.state.formData.compensationInfos.length > 0 ? groupByElements(JSON.parse(JSON.stringify(location.state.formData.compensationInfos)).sort((a,b)=> b.cicompensationId - a.cicompensationId)) : {};


    const onMonetaryAmountChanged = (e, decimalPlaces) => {

        setFormData({...formData, [e.target.name] : toNumber(e.target.value, decimalPlaces)})
    };
    const onSave = async (e) => {

        e.preventDefault();


            for (const compensation of currentCompensations) {
                let currentDate = new Date(setDateForTable(compensation.ciEffectiveDate)).setHours(0, 0, 0, 0);
                let selectedDate = new Date(formData.ciEffectiveDate).setHours(0, 0, 0, 0);
                if(formData.cicompensationId === compensation.cicompensationId) continue;

                if (Number(compensation.ciPayComponentId) === Number(formData.ciPayComponentId) && selectedDate === currentDate) {
                    props.setAlert("Details with the same Effective Date exists.", "danger");
                    return 0;
                }
            }

        let updatedFormData = { ...formData };
        if ('ciEffectiveDate' in updatedFormData) updatedFormData.ciEffectiveDate =  setInputDate(updatedFormData.ciEffectiveDate);
        if ('ciSalaryOrRate' in updatedFormData) updatedFormData.ciSalaryOrRate = fixedDecimalPlaces(updatedFormData.ciSalaryOrRate,salaryDecimalPlaces);
        if ('ciBillRate' in updatedFormData) updatedFormData.ciBillRate = fixedDecimalPlaces(updatedFormData.ciBillRate, 2);
        if ('ciOTpayRate' in updatedFormData) updatedFormData.ciOTpayRate = fixedDecimalPlaces(updatedFormData.ciOTpayRate , 4);
        if ('ciOTbillRate' in updatedFormData) updatedFormData.ciOTbillRate = fixedDecimalPlaces(updatedFormData.ciOTbillRate, 4);
        if ('ciPayComponentId' in updatedFormData) updatedFormData.ciPayComponentId =  updatedFormData.ciPayComponentId === '' ? 0 : updatedFormData.ciPayComponentId;
            delete updatedFormData['telnetPayRate'];
        formDataVal.current = {...updatedFormData}
        let isSaved = false;

        if(location.state.row){

      let currentCompensationForms = location.state.formData.compensationInfos;
      let updatedCompensations = [];
      if (currentCompensationForms) {
        updatedCompensations = currentCompensationForms.map(compensation => {
          if (compensation.cicompensationId === updatedFormData.cicompensationId) {
            return {
              userId:updatedFormData.userId,
              cicompensationId: updatedFormData.cicompensationId,
                ciPayType: updatedFormData.ciPayType,
                ciPaymentMethod: updatedFormData.ciPaymentMethod,
                ciPayComponentId: updatedFormData.ciPayComponentId,
                ciSalaryOrRate: updatedFormData.ciSalaryOrRate,
                ciCompensationCurrency: updatedFormData.ciCompensationCurrency,
                ciPayPeriodFrequency: updatedFormData.ciPayPeriodFrequency,
                ciPayRate: updatedFormData.ciPayRate,
                ciBillRate: updatedFormData.ciBillRate,
                ciOvertimeExempt: updatedFormData.ciOvertimeExempt,
                ciOTpayRate: updatedFormData.ciOTpayRate,
                ciOTbillRate: updatedFormData.ciOTbillRate,
                ciEffectiveDate : updatedFormData.ciEffectiveDate,
                notes: updatedFormData.notes,
            };
          }
          else {
            return compensation;
          }


        })
      }
            //location.state.formData.compensationInfos = updatedCompensations
            location.state.formData = { ...location.state.formData, compensationInfos: [...updatedCompensations]}

            isSaved =  true;
        }else{

            // if(formData.userId && formData.userId !== '0'){
            //     let updatedData = {userId:formData.userId, subscriberId: location.state.formData.subscriberId, compensationInfos: [{...updatedFormData}]}
            //     props.save_user_profile(updatedData);
            // }
            // else
            // {

            // }

             //location.state.formData.compensationInfos.push({...updatedFormData})
             location.state.formData = { ...location.state.formData, compensationInfos: [{ ...updatedFormData }, ...location.state.formData.compensationInfos]}

             isSaved =  true;
        }

        if (isSaved) {
            location.state.formData.compensationInfos.sort(function(c1, c2){return c2.ciEffectiveDate - c1.ciEffectiveDate});

            let userId = formData.userId === undefined ? 0 : formData.userId;
            let roleStr = (formData.userId !== undefined && location.state.role !== undefined) ? `?role=${location.state.role}` : '?role=';
            history.push({
                pathname: `/manage-user-profile/${userId}`,
                search: `${roleStr}&section=employment&subSection=CompensationInfo`,
                state: { formData, formData: location.state.formData, formDataRef: location.state.formDataRef, sectionformDataRef: location.state.sectionformDataRef },
            });

        }


    }

    // useEffect(()=>{
    //     if(props.isSaveUserProfile){
    //         let compensationpensation1 = props.userProfile.compensationInfos[0];
    //         let compensationpensation2 = props.userProfile.compensationInfos[1] !== undefined ? props.userProfile.compensationInfos[1] : props.userProfile.compensationInfos[0];

    //         if(Number(compensationpensation1.ciPayComponentId) === Number(formDataVal.current.ciPayComponentId)){
    //             formDataVal.current.cicompensationId = compensationpensation1.compensationId;

    //         }else{
    //             formDataVal.current.cicompensationId = compensationpensation2.compensationId;
    //         }

    //         location.state.sectionformDataRef.compensationInfos = [{...formDataVal.current}, ...location.state.sectionformDataRef.compensationInfos]
    //         location.state.formData =  { ...location.state.formData, compensationInfos: [{ ...formDataVal.current}, ...location.state.formData.compensationInfos]}

    //         onCancel()
    //     }

    // },[props.isSaveUserProfile])

    const onCancel = ()=>{
        let userId = formData.userId === undefined ? 0 : formData.userId;
            let roleStr = (formData.userId !== undefined && location.state.role !== undefined) ? `?role=${location.state.role}` : '?role=';
            props.update_isSave_user_profile(false);
            history.push({
                pathname: `/manage-user-profile/${userId}`,
                search: `${roleStr}&section=employment&subSection=CompensationInfo`,
                state: { formData, formData: location.state.formData, formDataRef: location.state.formDataRef, sectionformDataRef: location.state.sectionformDataRef },
            });
    }
    const calculatePayRate = (e, decimalPlaces) => {
        let placementInfos = location.state.formData.placementInfos;
        //let payTypeList = ['Hourly', 'Hourly - OT', 'Allowance - Pay Period', 'Allowance - Pay Period - Flat Fee', 'PEO - Agency Fee', 'ER RRSP', 'Commissions', 'ER DPSP', 'Bonus', 'Benefits Deduction - LTD', 'Benefits Premium - Health', 'RRSP - Deduction']
        let allowanceTypeTypeList = ['Allowance - Pay Period', 'Allowance - Pay Period - Flat Fee', 'Allowance - Monthly', 'Bonus', 'Commissions', 'Other Expenses'];
        let name = e.target.name;
        let val = '';
        if(name === 'ciSalaryOrRate'){
            val = Number(e.target.value);//toNumber(e.target.value, 2)
        }else{
            val = e.target.value;
        }

        let ciPayType = name === 'ciPayType' ? val : formData.ciPayType;
        let ciPayRate = 0.0000;
        let ciSalaryOrRate = name === 'ciSalaryOrRate' ? val : formData.ciSalaryOrRate;

        let ciPayPeriodFrequency = name === 'ciPayPeriodFrequency' ? val : formData.ciPayPeriodFrequency;
        let ciPayComponentId = name === 'ciPayComponentId' ? val : formData.ciPayComponentId

        let selectedPayComponent = props.payComponentList.filter(payComponent => Number(payComponent.refNum) === Number(ciPayComponentId))
        let payRateDecimals = selectedPayComponent[0] && selectedPayComponent[0].name === 'Hourly'? 4 : 2
        setPayRateDecimalPlaces(payRateDecimals);

        if(ciPayType === 'Hourly' && placementInfos && placementInfos.length > 0 && placementInfos[0].workerType === 'Employee Hourly'){
            setFormData({ ...formData, ciPayRate: formData.telnetPayRate, ciPayPeriodFrequency: ciPayPeriodFrequency, ciSalaryOrRate : ciSalaryOrRate, ciPayType : ciPayType, ciPayComponentId:ciPayComponentId });
            return;
        }else
        if (ciPayType === 'Hourly') {
            setFormData({ ...formData, ciPayRate: ciSalaryOrRate, ciPayPeriodFrequency: ciPayPeriodFrequency, ciSalaryOrRate : ciSalaryOrRate, ciPayType : ciPayType, ciPayComponentId:ciPayComponentId });

            return 0;
        }

        let frequency = getFrequencyNumber(ciPayPeriodFrequency);

        // if(selectedPayComponent && selectedPayComponent.length > 0 && allowanceTypeTypeList.includes(selectedPayComponent[0].name)){

        //     ciPayRate = (frequency === 0 || (Number(ciSalaryOrRate) === 0)) ? 0 : ((Number(ciSalaryOrRate) / frequency) )
        // }
        if(selectedPayComponent[0] && selectedPayComponent[0].name === 'Hourly'){

        switch (ciPayPeriodFrequency) {
            case 'Weekly':
                let weeklyHoursPerPay = location.state.formData.weeklyHoursPerPay

                if (weeklyHoursPerPay === undefined || weeklyHoursPerPay === 0) {
                    weeklyHoursPerPay = 1;
                }

                ciPayRate = ((Number(ciSalaryOrRate) / 52) / weeklyHoursPerPay)
                break;
            case 'Bi-weekly':

                let biWeeklyHoursPerPay = location.state.formData.biWeeklyHoursPerPay;

                if (biWeeklyHoursPerPay === undefined || biWeeklyHoursPerPay === 0) {
                    biWeeklyHoursPerPay = 1;
                }
                ciPayRate = ((Number(ciSalaryOrRate) / 26) / biWeeklyHoursPerPay)
                break;
            case 'Semi-monthly':

                let semiMonthlyHoursPerPay = location.state.formData.semiMonthlyHoursPerPay;

                if (semiMonthlyHoursPerPay === undefined || semiMonthlyHoursPerPay === 0) {
                    semiMonthlyHoursPerPay = 1;
                }
                ciPayRate = ((Number(ciSalaryOrRate) / 24) / semiMonthlyHoursPerPay)
                break;
            case 'Monthly':
                let monthlyHoursPerPay = location.state.formData.monthlyHoursPerPay;

                if (monthlyHoursPerPay === undefined || monthlyHoursPerPay === 0) {
                    monthlyHoursPerPay = 1;
                }
                ciPayRate = ((Number(ciSalaryOrRate) / 12) / monthlyHoursPerPay)
                break;
            default:
        }
    }
    else{
        ciPayRate = (frequency === 0 || (Number(ciSalaryOrRate) === 0)) ? 0 : ((Number(ciSalaryOrRate) / frequency) )
    }

        setFormData({ ...formData, 'ciPayPeriodFrequency': ciPayPeriodFrequency, 'ciPayRate': ciPayRate.toFixed(payRateDecimals), 'ciPayType': ciPayType, ciSalaryOrRate : ciSalaryOrRate, 'ciPayComponentId':ciPayComponentId })
    }
    const onDateChange = (name, value) => {

        let hireDate = setInputDate(location.state.formData.hireDate);
        let selectedDate = setInputDate(value);
       

        if(selectedDate < hireDate){
            props.setAlert("Effective Date must not be less than the Hire Date.", "danger");
                return 0;
        }
        let currentCompensations = location.state.formData.compensationInfos;
            for (const compensation of currentCompensations) {
                let currentDate = compensation.ciEffectiveDate;
                if(formData.cicompensationId === compensation.cicompensationId) continue;

                if (Number(compensation.ciPayComponentId) === Number(formData.ciPayComponentId) && selectedDate === currentDate) {
                    props.setAlert("Details with the same Effective Date exists.", "danger");
                    return 0;
                }
            }



        setHasError(!hasError)
        setFormData({ ...formData, [name]: value });

    };

    var payComponentListOption = props.payComponentList && props.payComponentList.map((cmp) => {
        return <option key={cmp.name + cmp.refNum} value={cmp.refNum}>{cmp.name}</option>;
    });
    var currencyListOptions = props.currencyList && props.currencyList.map((option) => {
        
        return <option key={option.currencyCode + option.currencyId}
            value={option.currencyCode}>{option.currencySymbol + ' ' + option.currencyCode}</option>;
    });


    const [formData, setFormData] = useState({

        userId: location.state.formData.userId,
        ciPayType: '',
        ciPaymentMethod: '',
        ciPayComponentId: '0',
        ciSalaryOrRate: '',
        ciCompensationCurrency: location.state.formData.baseCurrency,
        ciPayPeriodFrequency: '',
        ciPayRate: '',
        ciBillRate: '',
        ciOvertimeExempt: '',
        ciOTpayRate: '',
        ciOTbillRate: '',
        ciEffectiveDate: location.state.formData.compensationInfos.length > 0 ? new Date() : location.state.formData.hireDate,//location.state.userProfile.userId === undefined ? location.state.formData.hireDate : new Date(),
        notes: '',
        cicompensationId: 1,
        telnetPayRate:0.0000

    });


    useEffect(() => {

        if (location.state.userProfile.firstName) {
            props.changePageMeta(`Compensation - ${location.state.userProfile.personalInfo.firstName} ${location.state.userProfile.personalInfo.lastName}`, location.state.row === undefined ? `ADD` : `EDIT`)
        }
        else {
            props.changePageMeta(`Compensation - ${location.state.formData.firstName} ${location.state.formData.lastName}`, location.state.row === undefined ? `ADD` : `EDIT`)

        }

        props.get_worker_types();
        props.sub_get_currency_list();
        props.get_deal_types_types();
        props.get_compensation_types();
        props.get_pay_component_list();
        if (location.state.row) {

            let currentCompensation = { ...location.state.row }
            setFormData({
                ...formData,
                userId: currentCompensation.userId,
                cicompensationId: currentCompensation.cicompensationId,
                ciPayType: currentCompensation.ciPayType,
                ciPaymentMethod: currentCompensation.ciPaymentMethod,
                ciPayComponentId: currentCompensation.ciPayComponentId,
                ciSalaryOrRate: currentCompensation.ciSalaryOrRate,
                ciCompensationCurrency: currentCompensation.ciCompensationCurrency,
                ciPayPeriodFrequency: currentCompensation.ciPayPeriodFrequency,
                ciPayRate: currentCompensation.ciPayRate,
                ciBillRate: currentCompensation.ciBillRate,
                ciOvertimeExempt: currentCompensation.ciOvertimeExempt,
                ciOTpayRate: currentCompensation.ciOTpayRate,
                ciOTbillRate: currentCompensation.ciOTbillRate,
                notes: currentCompensation.notes,
                ciEffectiveDate: setAPIDate(currentCompensation.ciEffectiveDate),
                telnetPayRate : currentCompensation.ciPayRate

            })
        }else{
            if(location.state.formData.compensationInfos[0]){
                //setFormData({...formData, cicompensationId : Number(location.state.formData.compensationInfos[0].cicompensationId) + 1})
                let compensationIds = location.state.formData.compensationInfos.map(compensation => Number(compensation.cicompensationId));
                //let groups = groupByElements(location.state.formData.compensationInfos);
                if(Object.keys(groupedCompensations).length === 1){
                    setFormData({...formData,
                        cicompensationId: Math.max(...compensationIds) + 1,
                        ciPayType: location.state.formData.compensationInfos[0].ciPayType,
                        ciPaymentMethod: location.state.formData.compensationInfos[0].ciPaymentMethod,
                        ciPayComponentId: location.state.formData.compensationInfos[0].ciPayComponentId,
                        ciSalaryOrRate: location.state.formData.compensationInfos[0].ciSalaryOrRate,
                        ciCompensationCurrency: location.state.formData.compensationInfos[0].ciCompensationCurrency,
                        ciPayPeriodFrequency: location.state.formData.compensationInfos[0].ciPayPeriodFrequency,
                        ciPayRate: location.state.formData.compensationInfos[0].ciPayRate,
                        ciBillRate: location.state.formData.compensationInfos[0].ciBillRate,
                        ciOvertimeExempt: location.state.formData.compensationInfos[0].ciOvertimeExempt,
                        ciOTpayRate: location.state.formData.compensationInfos[0].ciOTpayRate,
                        ciOTbillRate: location.state.formData.compensationInfos[0].ciOTbillRate,
                        telnetPayRate : location.state.formData.compensationInfos[0].ciPayRate
                       })
                }else{
                setFormData({...formData,
                     cicompensationId: Math.max(...compensationIds) + 1,
                     ciPayType: location.state.formData.compensationInfos[0].ciPayType,
                     ciPaymentMethod: location.state.formData.compensationInfos[0].ciPaymentMethod,
                     ciCompensationCurrency: location.state.formData.compensationInfos[0].ciCompensationCurrency,
                     ciOvertimeExempt: location.state.formData.compensationInfos[0].ciOvertimeExempt,
                    })
                }
            }
        }

    }, [])
    
    useEffect(()=>{
        if(location.state.row){     
            setDisabledSave(compareObjects(location.state.row, formData))
        }
    },[formData])

    useEffect(()=>{
        setSalaryDecimalPlaces(formData.ciPayType === 'Hourly' ? 4 : 2)
    },[formData.ciPayType])

    useEffect(()=>{
        if(formData.ciSalaryOrRate !== ''){
            let e ={target:{name:'ciSalaryOrRate', value:formData.ciSalaryOrRate}}
            calculatePayRate(e);
        }

    }, [formData.ciSalaryOrRate])
    useEffect(()=>{
        if(Object.keys(groupedCompensations).length > 0 && Number(formData.ciPayComponentId) > 0 && !location.state.row){
      
            let selectedCompensation = groupedCompensations[formData.ciPayComponentId];
            setFormData({
                ...formData,
                ciSalaryOrRate: selectedCompensation ? selectedCompensation[0].ciSalaryOrRate : '',
                ciPayPeriodFrequency: selectedCompensation ? selectedCompensation[0].ciPayPeriodFrequency : '',
                ciPayRate: selectedCompensation ? selectedCompensation[0].ciPayRate : '',
                ciBillRate: selectedCompensation ? selectedCompensation[0].ciBillRate : '',
                ciOTpayRate: selectedCompensation ? selectedCompensation[0].ciOTpayRate : '',
                ciOTbillRate: selectedCompensation ? selectedCompensation[0].ciOTbillRate : '',
                telnetPayRate : selectedCompensation ? selectedCompensation[0].ciPayRate : 0.0000
            })
        }

    }, [formData.ciPayComponentId])
    return (
        <>
            <Container fluid className="main-content-container p-0">
                <Form onSubmit={onSave}>
                    <Card small className=" w-100" style={{ borderRadius: 0 }}>
                        <Row className="w-100" style={{ marginLeft: '-4px' }}>
                            <Col lg="12" className="form-group m-1 p-1">
                                <Card small className="mb-0 w-100" style={{ borderRadius: 0 }}>
                                    <Row className="m-1">
                                        <Col lg="8" className="d-flex flex-row">
                                            {/*<h4 className="m-0">Placement - First Name Last Name</h4>*/}
                                        </Col>
                                        <Col lg="4" className="d-flex flex-row-reverse">
                                            <Button onClick={onCancel} className="my-2 py-2 mx-2" size='sm'
                                                theme="accent">Cancel</Button>

                                            <Button className="my-2 py-2 mx-2" size='sm' disabled={disabledSave}
                                                theme="accent">Save</Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card small className="mb-4 w-100 p-3" style={{ borderRadius: 0 }}>


                        <Card small className="mb-4 mt-5 w-100">
                            <h4 className="m-0 section-title idStyle">Ref# {formData.cicompensationId}</h4>
                            <ListGroup flush>
                                <ListGroupItem className="border-card p-3">

                                    <Row>
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Pay Type</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='ciPayType' value={formData.ciPayType}
                                                onChange={calculatePayRate} className='d-block'>
                                                <option value=''>Select Pay Type</option>
                                                <option value='Salaried'>Salaried</option>
                                                <option value='Hourly'>Hourly</option>
                                            </FormSelect>
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="ciEffectiveDate">Effective Date</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <DatePicker
                                                id='ciEffectiveDate'

                                                autoComplete='off'
                                                name='ciEffectiveDate'
                                                dateFormat='yyyy-MM-dd'
                                                selected={formData.ciEffectiveDate}
                                                onChange={(date) =>
                                                    onDateChange("ciEffectiveDate", date)
                                                }
                                                value={formData.ciEffectiveDate}
                                                placeholderText="YYYY-MM-DD"
                                            />
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Payment Method</label>
                                            <FormSelect name='ciPaymentMethod' value={formData.ciPaymentMethod}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Payment Method</option>
                                                <option value='Ceridian'>Ceridian</option>
                                                {/*<option value='BMO-EFT'>BMO-EFT</option>*/}
                                                <option value='CIBC-EFT'>CIBC-EFT</option>
                                            </FormSelect>
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2"></Col>
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Pay Component</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect name='ciPayComponentId' value={formData.ciPayComponentId}
                                                required={true}
                                                onChange={(e) => calculatePayRate(e)} className='d-block'>
                                                <option value=''>Select Pay Component</option>
                                                {payComponentListOption}
                                            </FormSelect>
                                        </Col>

                                        {/*<InputBox placeholder="0.00" label="Salary or Rate" id='ciSalaryOrRate' name='ciSalaryOrRate'
                                            toolTipMSG="If Pay Type is Salaried: Enter Annual Salary & Hourly: Enter Hourly Rate"
                                            value={formData.ciSalaryOrRate}
                                            align='right'
                                            required={true}
                                            onChange={(e) => calculatePayRate(e, 2)} />*/}
                                        <StandardFormInput
                                        id="ciSalaryOrRate"
                                        name='ciSalaryOrRate'
                                        label="Salary or Rate"
                                        standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                        dataMaxFracDigits={salaryDecimalPlaces}
                                        toolTipMSG="If Pay Type is Salaried: Enter Annual Salary & Hourly: Enter Hourly Rate"
                                        type="text"
                                        value={formData.ciSalaryOrRate}
                                        onChangeStandardInput={onChangeStandardInput}
                                        required
                                        placeholder={salaryDecimalPlaces === 2 ? '0.00' : '0.0000'}
                                        align='right'
                                        />

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Compensation Currency</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='ciCompensationCurrency' value={formData.ciCompensationCurrency}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Compensation Currency</option>
                                                {currencyListOptions}
                                            </FormSelect>
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Pay Period Frequency</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <InfoTooltip msg="Define the Frequency to pay the Worker." />
                                            <FormSelect required={true} name='ciPayPeriodFrequency' value={formData.ciPayPeriodFrequency}
                                                onChange={calculatePayRate} className='d-block'>
                                                <option value=''>Select Pay Period Frequency</option>
                                                <option value='Weekly'>Weekly (52 Cycles)</option>
                                                <option value='Bi-weekly'>Bi-weekly (26 Cycles)</option>
                                                <option value='Semi-monthly'>Semi-monthly (24 Cycles)</option>
                                                <option value='Monthly'>Monthly (12 Cycles)</option>
                                            </FormSelect>
                                        </Col>

                                        <InputBox disabled={true} placeholder="Enter Pay Rate" label="Pay Rate" id='ciPayRate' name='ciPayRate'
                                            toolTipMSG="The amount of money paid for each hour of work."
                                            value={formatNumberAmount(formData.ciPayRate, 'en-US', payRateDecimalPlaces, payRateDecimalPlaces )}
                                            align='right'
                                            required={true}
                                            //onChange={(e) => onMonetaryAmountChanged(e, 2)} 
                                            />

                                        <InputBox placeholder="0.00" label="Bill Rate" id='ciBillRate' name='ciBillRate'
                                            toolTipMSG="This amount is a combination of both the Pay Rate and markup."
                                            value={formData.ciBillRate}
                                            align='right'
                                            required={true}
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)} />

                                        <Col lg="3" className="form-group m-0 p-2"></Col>
                                        <Col lg="3" className="form-group m-0 p-2"></Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Overtime Exempt</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='ciOvertimeExempt' value={formData.ciOvertimeExempt}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Overtime Exempt</option>
                                                <option value='Yes'>Yes</option>
                                                <option value='No'>No</option>
                                            </FormSelect>
                                        </Col>

                                        <InputBox placeholder="0.0000" label="OT Pay Rate" id='ciOTpayRate' name='ciOTpayRate'
                                            value={formData.ciOTpayRate}
                                            align='right'
                                            onChange={(e) => onMonetaryAmountChanged(e, 4)} />

                                        <InputBox placeholder="0.0000" label="OT Bill Rate" id='ciOTbillRate' name='ciOTbillRate'
                                            align='right'
                                            value={formData.ciOTbillRate}
                                            onChange={(e) => onMonetaryAmountChanged(e, 4)} />

                                        {/*<InputBox placeholder="Enter Days Per Week" label="Days Per Week" id='daysPerWeek'
          name='daysPerWeek'
          value={daysPerWeek}
          onChange={(e) => onMonetaryAmountChanged(e, 2)} />
          */}





                                        {/*<InputBox placeholder="Enter Notice Period" label="Notice Period" subLabel="Months" id='noticePeriod' name='noticePeriod'*/}
                                        {/*          value={noticePeriod}*/}
                                        {/*          onChange={onChange}/>*/}

                                        {/* <InputBox type='number' label="Exchange Rate for Salary" id='exchangeRateForSalary' name='exchangeRateForSalary'
          value={exchangeRateForSalary}
          onChange={onChange} />
        */}





                                        {/*<InputBox dataMaxFracDigits="4" label="Employee Wage" subLabel="CAD/Hourly" id='employeeWage' name='employeeWage'
          value={employeeWage}
          onChange={onChange} />
      */}




                                    </Row>
                                    <Row>
                                        <Col>
                                            <label htmlFor="notes">Notes</label>
                                            <textarea placeholder='Enter notes' maxLength="100" className="form-control" value={formData.notes} rows="3" id="notes" name="notes" onChange={(e) => {

                                                if(formData.notes.length >= 99){

                                                    props.setAlert("Notes can not exceed more than 100 charaters.", "danger")
                                                }
                                                onChange(e)
                                            }}>
                                                {formData.notes}
                                            </textarea>
                                            <span>Maximum {notesCharacters} characters.</span>
                                        </Col>
                                    </Row>

                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </Card>
                </Form>
            </Container>
        </>
    );
}

CompensationForm.propTypes = {
    userProfile: PropTypes.object.isRequired,
    get_user_profile: PropTypes.func.isRequired,
    user: PropTypes.object,
    workerTypes: PropTypes.array.isRequired,
    dealTypes: PropTypes.array.isRequired,
    currencyList: PropTypes.array.isRequired,
    setAlert: PropTypes.func.isRequired,
    success: PropTypes.any,
    reset: PropTypes.func.isRequired,
    payComponentList: PropTypes.array.isRequired,

}

const mapStateToProps = (state) => {

    return {
        userProfile: state.user.userProfile,
        isSaveUserProfile: state.user.isSaveUserProfile,
        user: state.user.user,
        workerTypes: state.user.workerTypes,
        dealTypes: state.user.dealTypes,
        currencyList: state.user.currencyList,
        message: state.user.message,
        success: state.user.success,
        alerts: state.alert,
        moduleName: state.user.moduleName,
        payComponentList: state.user.payComponentList,
        isSaveUserPlacement: state.isSaveUserPlacement
    }
}
export default connect(mapStateToProps, {

    get_worker_types,
    get_user_profile,
    clear_user_profile,
    get_subscriber_list,
    get_hr_Consultant,
    sub_get_currency_list,
    get_country_list,
    get_compensation_types,
    get_region_list,
    get_deal_types_types,
    get_client_list,
    get_pay_component_list,
    setAlert,
    reset,
    save_user_profile,
    update_isSave_user_profile
})(CompensationForm);
