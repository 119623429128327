import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container, DatePicker,
  FormInput,
  FormSelect, InputGroup, InputGroupAddon, InputGroupText,
  Row
} from "shards-react";
import ReactTable from "react-table";
import {Link, useLocation} from "react-router-dom";
import {setDateForTable, setInputDate} from "../../utils/date";
import {getStatusClass} from "../../utils/general";
import {
  get_hr_consultant_list,
  get_message_category_list,
  get_message_status_list,
  sub_search_conversations_paginated,
  get_assigned_to_msg_center_list,
  get_sub_status_list
} from "../../redux/actions/user";
import classNames from "classnames";
import {default as queryString} from "qs";

function SubMessageCenter({
                            get_message_category_list,
                            get_message_status_list,
                            get_hr_consultant_list,
                            sub_search_conversations_paginated,
                            get_assigned_to_msg_center_list,
                            user,
                            messageCategoryList,
                            messageStatusList,
                            numPages,
                            MessageCenterhrConsultantList,
                            conversationList,
                            assignedUserList,
                            showHeading,
                            subStatusList,
                            get_sub_status_list
                          }) {
  let location = useLocation();
  let queryParams = queryString.parse(location.search.replace("?", ""))

  let initialState = {
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
    filterState:{
      page: 0,
      worker:queryParams.worker || "",
      clientContact:queryParams.clientContact || "",
      client:queryParams.client || "",
      HRConsultant:queryParams.HRConsultant || (user.userRoles.filter(role => role.role === "Subscriber HR").length) ? user.displayName : "",
      category:queryParams.category || "",
      status:queryParams.status || "",
      from:queryParams.from || "",
      to:queryParams.to || "",
      assigned:queryParams.assigned||false,
      assignedTo:queryParams.assignedTo || "",
      empStatus: queryParams.empStatus || "",
    },
  };
  initialState.tableState=initialState.filterState
  const [formData, setFormData] = useState(initialState);



  useEffect(() => {
    get_hr_consultant_list()
    get_message_category_list()
    get_message_status_list()
    get_assigned_to_msg_center_list()
    get_sub_status_list();
  }, []);


  const onChange = (e) => {
    setFormData({...formData,filterState:{...formData.filterState,[e.target.name]: e.target.value}});
  };
  let resetState = () => {
    setFormData(initialState)
  }

  let onSubmit = (e) => {
    e.preventDefault();
    setFormData({...formData,tableState: formData.filterState})
  }

  let searchConversations = () => {
    sub_search_conversations_paginated(formData.tableState.worker, formData.tableState.clientContact, formData.tableState.client, formData.tableState.HRConsultant, formData.tableState.category, formData.tableState.status, formData.tableState.assigned, formData.tableState.assignedTo, setInputDate(formData.tableState.from), setInputDate(formData.tableState.to), formData.tableState.empStatus, formData.pageSize, formData.tableState.page + 1)
  }
  let searchsortConversations = (sort) => {
    
    var sortcolumn= sort[0].id;
    
    var fieldName="";
if(sortcolumn==='userName'){

  fieldName='name';
}
if(sortcolumn==='userRole')
{
  fieldName='role';
}
if(sortcolumn==='clientName')
{
  fieldName='client';
}
if(sortcolumn==='categoryName')
{
  fieldName='category';
}
if(sortcolumn==='hrConsultant')
{
  fieldName='hrConsultant';
}
if(sortcolumn==='assignToUserList')
{
  fieldName='assignTo';
}
if(sortcolumn==='createdDate')
{
  fieldName='date';
}
if(sortcolumn==='status')
{
  fieldName='status';
}
if(sortcolumn==='refNum')
{
  fieldName='refNum';
}
if(sortcolumn==='employmentStatus')
{
  fieldName='employmentStatus';
}

   var sortorder= sort[0].desc;
   var sort=1;
   if(sortorder)
   {
    sort=0
   }
    sub_search_conversations_paginated(formData.tableState.worker, formData.tableState.clientContact, formData.tableState.client, formData.tableState.HRConsultant, formData.tableState.category, formData.tableState.status, formData.tableState.assigned, formData.tableState.assignedTo,setInputDate(formData.tableState.from), setInputDate(formData.tableState.to),formData.tableState.empStatus, formData.pageSize, formData.tableState.page + 1,sort,fieldName)
  }
  useEffect(() => {
    searchConversations()
  }, [formData.tableState]);


  let messageCategoryOptions = messageCategoryList.map(category => <option
   key={category.categoryName + category.categoryId} value={category.categoryName}>{category.categoryName}</option>)
  let messageStatusOptions = messageStatusList.map((status, i) => <option
   key={status + i} value={status}>{status}</option>)
  let hrConsultantListOptions = MessageCenterhrConsultantList.map((hrConsultant, i) => <option
   key={hrConsultant + i} value={hrConsultant}>{hrConsultant}</option>)
  let assignedUserListOptions = assignedUserList.map((assignedToMsg, i) => <option
    key={assignedToMsg + i}  value={assignedToMsg}>{assignedToMsg}</option>)
  var substatusListOptions = subStatusList && subStatusList.map(
    (substatus, i) => {
      return <option key={substatus + i} value={substatus}>{substatus}</option>;
    }
  );

  const tableColumns = [
    {
      Header: "Worker Name",
      accessor: "userName",
      className: "justify-content-start text-left text-wrap text-break"
    },
    {
      Header: "Employment Status",
      accessor: "employmentStatus",
      className: "justify-content-center text-center text-wrap text-break",
      Cell: row =>
        <span
          className={getStatusClass(row.original.employmentStatus)}>{row.original.employmentStatus}</span>,
    },

    {
      Header: "Role",
      accessor: "userRole",
      maxWidth: 130,
      className: "justify-content-start text-left text-wrap text-break"
    },


    {
      Header: "Client",
      accessor: "clientName",
      maxWidth: 160,
      className: "justify-content-start text-left text-wrap text-break"
    },
    {
      Header: "Category",
      accessor: "categoryName",
      maxWidth: 160,
      className: "justify-content-start text-left text-wrap text-break"
    },

    {
      Header: "HR Consultant",
      accessor: "hrConsultant",
      className: "justify-content-start text-left text-wrap text-break"
    },
    {
      Header: "Assigned To",
      accessor: "assignToUserList",
      maxWidth: 160,
      className: "justify-content-start text-left text-wrap text-break"
    },
    {
      Header: "Date",
      accessor: "createdDate",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        setDateForTable(row.original.createdDate),
    },

    {
      Header: "Status",
      accessor: "status",
      maxWidth: 100,
      className: "text-center",
      Cell: row =>
        <span
          className={getStatusClass(row.original.status)}>{row.original.status}</span>,
    },

    {
      Header: "Ref#",
      accessor: "refNum",
      maxWidth: 80,
      className: "text-center",
      Cell: row =>
        <Link to={{
          pathname: "/sub-message-activity/" + row.original.userId + "/" + row.original.refNum,
          state: {status: row.original.status, userId: row.original.userId}
        }}>{row.original.refNum}</Link>
    }
  ];
  return (
    <Container fluid
               className={"main-content-container px-4 pb-4 " + (showHeading ? "position-relative" : "")}>
      {showHeading && <span className="tableHeader b-2">Message Center</span>}
      <form id="filterForm" onSubmit={onSubmit}
            className={"message-center-filter page-header tableSearch flex-column flex-md-row py-2 justify-content-end bg-white mt-4 " + (showHeading ? "pt-4" : "")}>

        <Row className="px-3">

          <Col className="p-0 col-md-2">
            <div className="d-flex align-items-center p-2">
              <FormInput
                name='worker'
                value={formData.filterState.worker}
                onChange={onChange}
                placeholder="Worker"
              />
            </div>
          </Col>
          <Col className="p-0 col-md-2">
            <div className="d-flex align-items-center p-2">
              <FormInput
                name='clientContact'
                value={formData.filterState.clientContact}
                onChange={onChange}
                placeholder="Client Contact"
              />
            </div>
          </Col>
          <Col className="p-0 col-md-2">
            <div className="d-flex align-items-center p-2">
              <FormInput
                name='client'
                value={formData.filterState.client}
                onChange={onChange}
                placeholder="Client"
              />
            </div>
          </Col>
          
          <Col className="p-0 col-md-2">
            <div className="d-flex align-items-center p-2">
              <FormSelect name='status' value={formData.filterState.status}
                          onChange={onChange}>
                <option value=''>All Status</option>
                {messageStatusOptions}
              </FormSelect>
            </div>
          </Col>
          <Col className="p-0 col-md-4">
            <div className="d-flex align-items-center p-2">
              <InputGroup
                className={classNames("d-flex", "my-auto", "date-range")}>
                <DatePicker
                  name="from"
                  size="sm"
                  selected={formData.filterState.from}
                  onChange={(date) => {
                    setFormData({...formData, filterState:{...formData.filterState,from: date}})
                  }}
                  dateFormat='yyyy-MM-dd'
                  placeholderText="Start Date"
                  dropdownMode="select"
                  autoComplete='off'
                  className="text-center"
                />
                <DatePicker
                  name="to"
                  size="sm"
                  selected={formData.filterState.to}
                  onChange={(date) => {
                    setFormData({...formData, filterState:{...formData.filterState,to: date}})
                  }}
                  dateFormat='yyyy-MM-dd'
                  placeholderText="End Date"
                  dropdownMode="select"
                  autoComplete='off'
                  className="text-center"
                  popperPlacement="top-end"
                />
                <InputGroupAddon type="append">
                  <InputGroupText>
                    <i className="material-icons">&#xE916;</i>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </div>
          </Col>
          </Row>
          <Row className="px-3">
          <Col className="p-0 col-md-2">
            <div className="d-flex align-items-center p-2">
              <FormSelect name='HRConsultant' value={formData.filterState.HRConsultant}
                          onChange={onChange}>
                <option value=''>All HR Consultants</option>
                {hrConsultantListOptions} 
              </FormSelect>
            </div>
          </Col>
          <Col className="p-0 col-md-2">
            <div className="d-flex align-items-center p-2">
              <FormSelect name='assignedTo' value={formData.filterState.assignedTo}
                          onChange={onChange}>
                <option value=''>Assigned To</option>
               {assignedUserListOptions} 
              </FormSelect>
            </div>
          </Col>
          <Col className="p-0 col-md-2">
            <div className="d-flex align-items-center p-2">
              <FormSelect name='category' value={formData.filterState.category}
                          onChange={onChange}>
                <option value=''>All Categories</option>
                {messageCategoryOptions} 
              </FormSelect>
            </div>
          </Col>
          <Col className="p-0 col-md-2">
            <div className="d-flex align-items-center p-2">
              <FormSelect name='empStatus' value={formData.filterState.empStatus}
                onChange={onChange}>
                <option value=''>All Employment Status</option>
                {substatusListOptions}
              </FormSelect>
            </div>
          </Col>

        </Row>

      </form>
      <Card className="tableCard p-0">
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {/* Filters :: Page Size */}
              <Col
                className="file-manager__filters__rows d-flex justify-content-center justify-content-md-start align-items-center col-6 col-lg-2 order-lg-0">
                <span>Show</span>
                <FormSelect
                  size="sm"
                  name='pageSize'
                  value={formData.pageSize}
                  onChange={e=>{setFormData({...formData,pageSize:e.target.value,tableState:{...formData.tableState,page:0}})}}
                >
                  {formData.pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size} rows
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col
                className="file-manager__filters__search d-flex justify-content-center justify-content-md-end col-6 col-lg-3 order-lg-2">
                <Button form="filterForm" type="submit"
                        className="mx-2 py-2 my-2" size='sm'
                        theme="accent">Search</Button>

                <Button onClick={resetState} className="mx-2 py-2 my-2"
                        size='sm'
                        theme="accent">Clear</Button>
              </Col>

              <Col
                className="col-12 col-lg-7 d-flex align-items-center order-lg-1">
                <p
                  className={"m-0 " + (formData.tableState.status ? "invisible" : "")}>{`Note - This section shows Unread and In-Process status items of Active & On Leave Users.`}</p>
              </Col>


            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
             
             manual
              columns={tableColumns}
              key={formData.tableState.page}
              data={conversationList}
              pageSize={Number(formData.pageSize)}
              pages={numPages}
              page={formData.tableState.page}
              onPageChange={(pageIndex) => {
                setFormData({...formData, tableState: {...formData.tableState,page: pageIndex}})
              }}
              showPageSizeOptions={false}
              resizable={false}
              onSortedChange={(newSort, column) => {
                searchsortConversations(newSort)              
               
              }}
            />
          </div>
        </CardBody>
      </Card>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  messageCategoryList: state.user.messageCategoryList,
  messageStatusList: state.user.messageStatusList,
  numPages: state.user.numPages,
  MessageCenterhrConsultantList: state.user.MessageCenterhrConsultantList,
  conversationList: state.user.conversationList,
  assignedUserList: state.user.assignedUserList,
  subStatusList: state.user.subStatusList,
});

export default connect(mapStateToProps, {
  get_message_category_list,
  get_message_status_list,
  get_hr_consultant_list,
  sub_search_conversations_paginated,
  get_assigned_to_msg_center_list,
  get_sub_status_list
})(SubMessageCenter);
