import {
  ADD_CLIENT_NOTE,
  CLEAR_NOTE_LISTS, CLEAR_USER_PERMISSION, DELETE_CLIENT_NOTE,
  DELETE_NOTE,
  DELETE_NOTE_ERROR,
  GET_NOTE_DETAILS,
  GET_NOTE_DETAILS_ERROR,
  GET_NOTE_LIST,
  GET_NOTE_LIST_ERROR,
  GET_PENSION_NOTE_LIST,
  GET_PENSION_NOTE_LIST_ERROR,
  GET_SUBSCRIBER_NOTE_LIST,
  GET_SUBSCRIBER_NOTE_LIST_ERROR,
  GET_SEVERANCE_NOTE_LIST,
  GET_SEVERANCE_NOTE_LIST_ERROR, GET_USER_PERMISSION,
  SAVE_CLIENT_NOTE,
  SAVE_NOTE,
  SAVE_NOTE_ERROR
} from "../types";
import api from "../../../utils/api";
import {setAlert} from "../alert";

export const get_pension_note_list = (
  userId,
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getNoteList?userId=${userId}&noteType=2`
    );
    dispatch({
      type: GET_PENSION_NOTE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_PENSION_NOTE_LIST_ERROR,
    });
  }
};
export const get_subscriber_note_list = (
  userId,
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getNoteList?userId=${userId}&noteType=1`
    );
    dispatch({
      type: GET_SUBSCRIBER_NOTE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SUBSCRIBER_NOTE_LIST_ERROR,
    });
  }
};

export const get_severance_note_list = (
  userId,
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getNoteList?userId=${userId}&noteType=3`
    );
    dispatch({
      type: GET_SEVERANCE_NOTE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SEVERANCE_NOTE_LIST_ERROR,
    });
  }
};

export const clear_note_lists = () => async (dispatch) => {
  dispatch({
    type: CLEAR_NOTE_LISTS
  });
};


export const get_note_list = (
  userId,
  noteType
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getNoteList`,
      {
        params: {
          userId: userId,
          noteType: noteType,
        }
      }
    );
    dispatch({
      type: GET_NOTE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_NOTE_LIST_ERROR,
    });
  }
};

export const get_note_details = (
  refNum
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getNoteDetails?refNum=${refNum}`
    );
    dispatch({
      type: GET_NOTE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_NOTE_DETAILS_ERROR,
    });
  }
};
export const save_note = (
  userId,
  refNum,
  conversationId,
  noteType,
  noteText
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/saveNote`,
      {
        params: {
          userId: userId,
          conversationId: conversationId,
          noteType: noteType,
          noteText: noteText,
          refNum: refNum
          
        }
      }
    );
    dispatch(setAlert("Note added!", "success"));
    dispatch({
      type: SAVE_NOTE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_NOTE_ERROR,
    });
  }
};
export const delete_note = (
  refNum
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/deleteNote?refNum=${refNum}`
    );
    dispatch({
      type: DELETE_NOTE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: DELETE_NOTE_ERROR,
    });
  }
};

export const get_client_note_list = (
  subscriberId,
  clientId
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getClientNoteList`,
      {
        params: {
          subscriberId:subscriberId,
          clientId: clientId
        }
      }
    );
    dispatch({
      type: GET_NOTE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const save_client_note = (
  clientId,
  refNum,
  noteText
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/saveClientNote`,
      {
        params: {
          clientId: clientId,
          refNum: refNum,
          noteText: noteText
        }
      }
    );
    if (refNum === "0") {
      dispatch(setAlert("Note added!", "success"));
      dispatch({
        type: ADD_CLIENT_NOTE,
        payload: res.data,
      });
    } else {
      dispatch(setAlert("Note saved!", "success"));
      dispatch({
        type: SAVE_CLIENT_NOTE,
        payload: res.data,
      });
    }
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const get_client_note_details = (
  clientId,
  refNum
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getClientNoteDetails`, {
        params: {
          refNum: refNum,
          clientId: clientId
        }
      }
    );
    dispatch({
      type: GET_NOTE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const delete_client_note = (
  clientId,
  refNum
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/deleteClientNote`,
      {
        params: {
          refNum: refNum,
          clientId: clientId
        }
      }
    );

    dispatch(setAlert("Note deleted!", "success"));
    dispatch({
      type: DELETE_CLIENT_NOTE,
      payload: refNum,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};


export const get_user_permission = (userId) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/subscriber/getUserPermission?userId=${userId}`,
    );

    dispatch({
      type: GET_USER_PERMISSION,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};


export const set_user_permission = (userId, role, personalInfo, contactInfo, employmentInfo, compensationInfo, allowanceInfo, leavesInfo, benefitsInfo, HSAInfo, pensionInfo, journeyInfo, leavesModule, expenseModule,approveExpense, approveLeaves,notificationExpenseFlag,notificationLeavesFlag,notificationMessageFlag) => async (dispatch) => {
  try {
    let params={
      userId,
      personalInfo,
      contactInfo,
      employmentInfo,
      compensationInfo,
      allowanceInfo,
      leavesInfo,
      benefitsInfo,
      HSAInfo,
      pensionInfo,
      journeyInfo,
      leavesModule,
      expenseModule,
      approveExpense,
      approveLeaves,
      notificationExpenseFlag,
      notificationLeavesFlag,
      notificationMessageFlag
    }
    params[role]=true
    const res = await api.get(
      `api/subscriber/setUserPermission`,
      {
        params: params
      }
    );

    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const clear_user_permissions = () => async (dispatch) => {
  dispatch({
    type: CLEAR_USER_PERMISSION,
  });
};
