import api from "../../../utils/api";
import {
  CLEAR_LEAVE_DETAILS,
  GET_ENTITLED_LEAVE_LIST,
  GET_ENTITLED_LEAVE_LIST_ERROR,
  GET_LEAVE_BALANCE,
  GET_LEAVE_BALANCE_ERROR,
  GET_LEAVE_DETAILS,
  GET_LEAVE_DETAILS_ERROR,
  GET_LEAVE_TYPES,
  GET_LEAVE_TYPES_ERROR,
  GET_LEAVES_LIST,
  GET_LEAVES_LIST_ERROR,
  GET_LEAVES_STATUS,
  GET_LEAVES_STATUS_ERROR, GET_WORKER_LEAVE_POLICY_NAMES_LIST,
  GET_WORKER_LEAVE_POLICY_NAMES_LIST_ERROR,
  SAVE_LEAVE_DETAILS,
  SAVE_LEAVE_DETAILS_ERROR,
  WITHDRAW_LEAVE,
  WITHDRAW_LEAVE_ERROR,
  CLEAR_LEAVE_BALANCE
} from "../types";
import {setAlert} from "../alert";
import {redirect} from "../user";


//get leaves list
export const get_leaves_list = (userId, from, to, status, leavetype) => async (
  dispatch
) => {
  try {
    const res = await api.get(
      `api/leaves/getUserLeavesList`,
      {
        params: {
          userId: userId,
          from: from,
          to: to,
          status: status,
          leaveType: leavetype,
        }
      }
    );
    dispatch({
      type: GET_LEAVES_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      if (err.response.status === 401)
        dispatch(redirect('/error403'))
      else
        dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_LEAVES_LIST_ERROR,
    });
  }
};

//get leaves list
export const get_worker_leave_policy_names_list = (userId = "") => async (
  dispatch
) => {
  try {
    const res = await api.get(
      `api/leaves/getWorkerLeavePolicyNameList?userId=${userId}`
    );
    dispatch({
      type: GET_WORKER_LEAVE_POLICY_NAMES_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success && err.response.status !== 401) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_WORKER_LEAVE_POLICY_NAMES_LIST_ERROR,
    });
  }
};

export const get_leave_details = (userId, refNumber) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/leaves/getLeaveDetails?userId=${userId}&refNumber=${refNumber}`
    );

    dispatch({
      type: GET_LEAVE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_LEAVE_DETAILS_ERROR,
    });
  }
};

export const empty_leave_details = () => ({type: GET_LEAVE_DETAILS_ERROR});
export const save_leave_details = (
  userId,
  refNumber,
  leavePolicyId,
  from,
  to,
  leaveDays,
  leaveHours,
  notes
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/leaves/saveLeaveDetails`,
      {
        params: {
          userId: userId,
          refNumber: refNumber,
          leavePolicyId: leavePolicyId,
          from: from,
          to: to,
          leaveDays: leaveDays,
          leaveHours: leaveHours,
          notes: notes,
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_LEAVE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_LEAVE_DETAILS_ERROR,
    });
  }
};

export const withdraw_leave = (userId, refNumber) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/leaves/withdrawLeave?userId=${userId}&refNumber=${refNumber}`
    ); 
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: WITHDRAW_LEAVE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: WITHDRAW_LEAVE_ERROR,
    });
  }
};


//get leaves status
export const get_leaves_status = () => async (dispatch) => {
  try {
    const res = await api.get("api/leaves/getLeaveStatusList");
    dispatch({
      type: GET_LEAVES_STATUS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success && err.response.status !== 401) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_LEAVES_STATUS_ERROR,
    });
  }
};

//get leaves types
export const get_leaves_types = () => async (dispatch) => {
  try {
    const res = await api.get("api/leaves/getLeaveTypeList");
    dispatch({
      type: GET_LEAVE_TYPES,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_LEAVE_TYPES_ERROR,
    });
  }
};

export const get_leaves_balance = (userId, year, leavePolicyId) => async (dispatch) => {
  try {
    const res = await api.get("api/leaves/getLeaveBalance",{
      params: {
        userId: userId,
        year: year,
        leavePolicyId: leavePolicyId
      }
    });

    dispatch({
      type: GET_LEAVE_BALANCE,
      payload: res.data.workerLeavePolicy,
    });
  } catch (err) {
    
    dispatch({
      type: GET_LEAVE_BALANCE_ERROR,
    });
  }
};

export const clear_leaves_balance = (userId, year, leavePolicyId) => async (dispatch) => {
    dispatch({
      type: CLEAR_LEAVE_BALANCE,
    });
}

export const get_applicable_to_worker_leaves_balance =  (userId, year, leavePolicyId) => async (dispatch) => {
  try {
    const res = await api.get("api/leaves/getLeaveBalance",{
      params: {
        userId: userId,
        year: year,
        leavePolicyId: leavePolicyId
      }
    });

    return res.data.workerLeavePolicy;
    
  } catch (err) {

    return null;
    
  }
};

export const get_entitled_leaves_list = (userId = "") => async (dispatch) => {
  try {
    const res = await api.get(`api/leaves/getWorkerLeavePolicyList`, {
      params: {
        userId: userId
      }
    });

    dispatch({
      type: GET_ENTITLED_LEAVE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success && err.response.status !== 401) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_ENTITLED_LEAVE_LIST_ERROR,
    });
  }
};
export const clear_leave_details = () => async (dispatch) => {
  dispatch({
    type: CLEAR_LEAVE_DETAILS,
  });
};
