import {
  CLEAR_CLIENT_LEAVE_POLICY_DETAILS,
  CLEAR_LEAVE_POLICY_CACHES, DELETE_CLIENT_LEAVE_POLICY_DETAILS,
  DELETE_LEAVE_POLICY_DETAILS,
  DELETE_LEAVE_POLICY_LOCATION,
  GET_CLIENT_LEAVE_POLICY_DETAILS,
  GET_CLIENT_LEAVE_POLICY_DETAILS_ERROR,
  GET_LEAVE_POLICY_DETAILS,
  GET_LEAVE_POLICY_DETAILS_ERROR,
  GET_LEAVE_POLICY_LOCATION_DETAILS,
  GET_LEAVE_POLICY_LOCATION_DETAILS_ERROR,
  GET_LEAVE_POLICY_LOCATION_LIST,
  GET_LEAVE_POLICY_LOCATION_LIST_ERROR,
  GET_LEAVE_POLICY_NAMES_LIST,
  GET_LEAVE_POLICY_NAMES_LIST_ERROR, SAVE_CLIENT_LEAVE_POLICY_DETAILS,
  SAVE_LEAVE_POLICY_DETAILS,
  SAVE_LEAVE_POLICY_LOCATION_DETAILS,
  SAVE_LEAVE_POLICY_LEVEL_DETAILS,
  SAVE_LEAVE_POLICY_LEVEL_DETAILS_ERROR
} from "../../actions/types";


const initialState = {
  leavePolicyLocationList: [],
  leavePolicyLocationDetails:{},
  leavePolicyDetails:{},
  leavePolicyNamesList:[],
  clientLeavePolicyDetails:{},
  leavePolicyLevelDetails:{}
}
export const leavePolicy = (state = initialState, action) => {
  switch (action.type) {

    case GET_LEAVE_POLICY_LOCATION_LIST:
      return {
        ...state,
        leavePolicyLocationList: action.payload.leavePolicyLocationList,
      };
    case GET_LEAVE_POLICY_LOCATION_LIST_ERROR:
      return {
        ...state,
        leavePolicyLocationList: []
      };

    case GET_LEAVE_POLICY_LOCATION_DETAILS:
      return {
        ...state,
        leavePolicyLocationDetails: action.payload.leavePolicyLocation,
      };
    case GET_LEAVE_POLICY_LOCATION_DETAILS_ERROR:
      return {
        ...state,
        leavePolicyLocationDetails: {}
      };

    case SAVE_LEAVE_POLICY_LOCATION_DETAILS:
      return {
        ...state,
        leavePolicyLocationDetails: {}
      };

    case DELETE_LEAVE_POLICY_LOCATION:
      return {
        ...state,
        leavePolicyLocationList: state.leavePolicyLocationList.filter(location=>{
          return location.leavePolicyLocationId!==action.payload.leavePolicyLocationId
        })
      };

    case GET_LEAVE_POLICY_DETAILS:
      return {
        ...state,
        leavePolicyDetails: action.payload.leavePolicy
      };

    case GET_LEAVE_POLICY_DETAILS_ERROR:
      return {
        ...state,
        leavePolicyDetails: {}
      };


    case DELETE_LEAVE_POLICY_DETAILS:
      return {
        ...state,
        leavePolicyDetails: {}
      };

    case SAVE_LEAVE_POLICY_DETAILS:
      return {
        ...state,
        leavePolicyDetails: action.payload.leavePolicy
      };

    case CLEAR_LEAVE_POLICY_CACHES:
      return {
        ...state,
        leavePolicyDetails: {},
        leavePolicyLocationList:[],
        leavePolicyLocationDetails:{},
        leavePolicyLevelDetails: {}
      };

      case GET_LEAVE_POLICY_NAMES_LIST:
      return {
        ...state,
        leavePolicyNamesList: action.payload.leavePolicyList
      };

    case GET_LEAVE_POLICY_NAMES_LIST_ERROR:
      return {
        ...state,
        leavePolicyNamesList: [],
      };


    case GET_CLIENT_LEAVE_POLICY_DETAILS:
      return {
        ...state,
        clientLeavePolicyDetails: action.payload.leavePolicy
      };

    case GET_CLIENT_LEAVE_POLICY_DETAILS_ERROR:
      return {
        ...state,
        clientLeavePolicyDetails: {},
      };


    case SAVE_CLIENT_LEAVE_POLICY_DETAILS:
      return {
        ...state
      };

      case DELETE_CLIENT_LEAVE_POLICY_DETAILS:
      return {
        ...state,
        clientLeavePolicyDetails: {}
      };

  case CLEAR_CLIENT_LEAVE_POLICY_DETAILS:
      return {
        ...state,
        clientLeavePolicyDetails: {}
      };

      case SAVE_LEAVE_POLICY_LEVEL_DETAILS:
        return {
          ...state,
          leavePolicyLevelDetails: action.payload.data
        };
      case SAVE_LEAVE_POLICY_LEVEL_DETAILS_ERROR:
        return{
          ...state,
          leavePolicyLevelDetails: {}
        }

    default:
      return state;
  }
};
export default leavePolicy
