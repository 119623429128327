import {
  GET_CLIENT_EMPLOYMENT_STATUS_LIST,
  GET_CLIENT_EMPLOYMENT_STATUS_LIST_ERROR,
  GET_CLIENT_VIEW_WORKER_PROFILE,
  CLEAR_CLIENT_VIEW_WORKER_PROFILE,
  GET_CLIENT_VIEW_WORKER_PROFILE_ERROR,
  GET_WORKER_JOURNEY_FOR_CLIENT,
  GET_WORKER_JOURNEY_FOR_CLIENT_ERROR,
  SEARCH_WORKER_FOR_CLIENT_LIST,
  SEARCH_WORKER_FOR_CLIENT_LIST_ERROR
} from "../../actions/types";

const initialState = {
  clientEmploymentStatusList: [],
  clientWorkerList: [],
  workerForClientProfile: {},
  workerJourneyForClient: [],
}
export const clientWorkers = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_EMPLOYMENT_STATUS_LIST:
      return {
        ...state,
        clientEmploymentStatusList: action.payload.employmentStatusList,
      };
    case GET_CLIENT_EMPLOYMENT_STATUS_LIST_ERROR:
      return {
        ...state,
        clientEmploymentStatusList: [],
      };

    case SEARCH_WORKER_FOR_CLIENT_LIST:
      return {
        ...state,
        clientWorkerList: action.payload.workerList,
      };
    case SEARCH_WORKER_FOR_CLIENT_LIST_ERROR:
      return {
        ...state,
        clientWorkerList: [],
      };

    case GET_CLIENT_VIEW_WORKER_PROFILE:
      return {
        ...state,
        workerForClientProfile: action.payload.workerProfile,
      }
    case GET_CLIENT_VIEW_WORKER_PROFILE_ERROR:
      return {
        ...state,
        workerForClientProfile: {},
      };
      case CLEAR_CLIENT_VIEW_WORKER_PROFILE:
        return {
          ...state,
          workerForClientProfile: {},
        };


    case GET_WORKER_JOURNEY_FOR_CLIENT:
      return {
        ...state,
        workerJourneyForClient: action.payload.userJourneyList,
      };
    case GET_WORKER_JOURNEY_FOR_CLIENT_ERROR:
      return {
        ...state,
        workerJourneyForClient: [],
      };

    default:
      return state;
  }
};
export default clientWorkers
