import React from 'react';
import {
  Card,
  CardHeader, Col, Form,
  ListGroup,
  ListGroupItem, Row,
} from "shards-react";
import PropTypes from "prop-types";
import './PersonalInfo.scss'

const PersonalInfo = (props) => {
  const {viewProfile} = props
  return (
    <Card id='personal' small className={"details-card mb-5 w-100 d-flex personalParent "+props.className}>
    {viewProfile?
      <CardHeader className="border-bottom">
        <h4 className="m-0 section-title">Personal Information</h4>
      </CardHeader>:
      <>
      <img className="avatarImg" src={require("../../../images/avatars/img_avatar2.png")} alt="avatar" />
      <h4 className="m-0 section-title headerStyle">Personal Information</h4>
      </>
      }
      <ListGroup flush>

        <ListGroupItem className={viewProfile?"p-3":"p-3 mt-3"}>
          <Row>
            <Col>
              <Form>
                <Row form>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Employment Status</label>
                    <span className={viewProfile?"  form-control":"inputBoxClr   form-control"}>{props.employmentStatus}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Pronoun</label>
                    <span className={viewProfile?"  form-control":"inputBoxClr   form-control"}>{props.pronoun}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Legal First Name</label>
                    <span className={viewProfile?"  form-control":"inputBoxClr   form-control"}>{props.firstName}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Legal Last Name</label>
                    <span
                      className={viewProfile?"  form-control":"inputBoxClr   form-control"}>{props.lastName}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Nickname</label>
                    <span className={viewProfile?"  form-control":"inputBoxClr   form-control"}>{props.nickName}</span>
                  </Col>

                </Row>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>


      </ListGroup>
    </Card>
  );
};

PersonalInfo.propTypes = {
  dateOfBirth: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  maritalStatus: PropTypes.string.isRequired,
  nickName: PropTypes.string.isRequired,
  pronoun: PropTypes.string.isRequired,
}

PersonalInfo.defaultProps = {
  dateOfBirth: '',
  firstName: '',
  lastName: '',
  maritalStatus: '',
  nickName: '',
  pronoun: '',
}

export default PersonalInfo;
