import React, {useEffect, useRef, useState} from 'react';
import {
  Alert,
  Button,
  Col,
  Container, DatePicker, Form,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import PersonalInfo from "../../components/manage-user-profile/PersonalInfo";
import {ContactInfo} from "../../components/manage-user-profile/ContactInfo";
import PerksInfo from "../../components/manage-user-profile/PerksInfo";
import {connect} from "react-redux";
import {
  clear_user_profile,
  delete_user_journey_for_sub,
  get_benefits_class_list,
  get_benefits_coverage_list,
  get_city_details,
  get_city_list,
  get_client_contact_list,
  get_client_list,
  get_compensation_types,
  get_country_list,
  sub_get_currency_list,
  get_deal_types_types,
  get_employment_status_list,
  get_employment_sub_status_list,
  get_marital_status_list,
  get_pension_plan_frequency_list,
  get_pension_plan_list,
  get_perks_frequency_list,
  get_preferred_email_list,
  get_pronoun_list,
  get_region_list,
  get_subscriber_list,
  get_salesconsultant,
  get_hr_Consultant,
  get_user_journey_for_sub,
  get_user_profile,
  get_visa_status_types,
  get_worker_types, reset,
  save_user_profile,
  set_user_account_status,
  set_user_password,
  update_user_profile,
  get_app_modules,
  get_pay_component_list,
  calculate_years_of_service,
  calculate_age,
  get_user_default_values,
  get_client_markup_values,
  update_isSave_user_profile
} from "../../redux/actions/user";
import PropTypes from "prop-types";
import BenefitsInfo from "../../components/manage-user-profile/BenefitsInfo";
import EmploymentInfo
  from "../../components/manage-user-profile/EmploymentInfo";
import Leaves from "../../components/manage-user-profile/Leaves";
import PensionPlan from "../../components/manage-user-profile/PensionPlan";
import PlacementData from "../../components/manage-user-profile/PlacementData";
import CompensationInfo from '../../components/manage-user-profile/CompensationInfo';
import RetainerInfo from '../../components/manage-user-profile/RetainerInfo';
import EapInfo from '../../components/manage-user-profile/EapInfo';
import UserJourney from "../../components/manage-user-profile/UserJourney";
import Integration from "../../components/manage-user-profile/Integration";
import TabBar from "../../components/manage-user-profile/TabBar";
import Roles from "../../components/manage-user-profile/Roles";
import ApproverSettings from "../../components/manage-user-profile/ApproverSettings";
import EmergencyContactInfo from '../../components/manage-user-profile/EmergencyContactInfo';
import IndependentContractorInfo from '../../components/manage-user-profile/IndependentContractorInfo';
import AccountSettings
  from "../../components/manage-user-profile/AccountSettings";
import { user_disable_2fa } from "../../redux/actions/settings";
import { setAlert } from "../../redux/actions/alert";
import {
  setAPIDate,
  setDateForTable,
  setInputDate
} from "../../utils/date";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {
  monetaryToNumber, numberToMonetary,
  setAllowanceTypeString,
  setLeaveTypeString,
  setLeavePolicyString,
  setApproverInfoString,
  fixedDecimalPlaces,
  toNumber,
  groupByElements
} from "../../utils/general";
import Allowances from "../../components/manage-user-profile/Allowances";
import SubscriberNotes
  from "../../components/manage-user-profile/SubscriberNotes";
import SeveranceNotes from '../../components/manage-user-profile/SeveranceNotes';
import {
  clear_note_lists,
  get_pension_note_list,
  get_subscriber_note_list,
  get_severance_note_list
} from "../../redux/actions/subscriber/subscriberUsers";
import PensionNotes from "../../components/manage-user-profile/PensionNotes";
import {default as queryString} from "qs";
import Documents from '../../components/manage-user-profile/forms/Documents';
import  UnloadPageConfirm from "../../components/common/UnloadPageConfirm";


const ManageUserProfile = (props) => {
  const [Saved, setSaved] = useState(props.isSaveUserProfile);
  const history = useHistory();
  let {id} = useParams();
  const [isDisabledSaveButton, setDisabledSaveButton] = useState(id !== "0")
  const [containerColorClass, setContainerColorClass] = useState('')
  const location = useLocation();
  let queryParams = queryString.parse(location.search.replace("?", ""))
  let editPermissions = props.user.userRoles.filter(item => item.role === "Subscriber Admin" || item.role === "Subscriber HR").length;
  const unblockHandle = useRef();
  const [cancelForm, setCancelForm] = useState(false);
  const [currentLocation, setCurrentLocation] = useState('/sub-search-users')
 //props.isSaveUserProfile=false;
  let enableSections = true;
  //added User Name in the page title once we get response from backend
  useEffect(() => {
    unblockHandle.current = history.block((targetLocation) => {

      if(['/sub-search-leaves', '/sub-search-users', '/sub-home', '/sub-search-expenses', '/sub-search-clients', '/sub-message-center', '/sub-generate-reports', '/sub-search-holidays', '/sub-search-leave-policy', '/sub-configuration'].includes(targetLocation.pathname) && !isDisabledSaveButton)
      {
        setCurrentLocation(targetLocation.pathname)
         handlePageUnload()

         if(props.isSaveUserProfile || cancelForm){
          return true;
         }

      }
      else{
        return true;
      }
      return false;
    });
    return function () {
      props.update_isSave_user_profile(false);
      unblockHandle.current && unblockHandle.current()

    }
  })

  useEffect(() => {
    if (props.userProfile && Object.keys(props.userProfile).length > 0)
    {
      props.userProfile.personalInfo = props.userProfile.personalInfo === null ? {} : props.userProfile.personalInfo;
      props.userProfile.allowanceTypes = props.userProfile.allowanceTypes === null ? [] : props.userProfile.allowanceTypes;
      props.userProfile.benefitsInfo = props.userProfile.benefitsInfo === null ? {} : props.userProfile.benefitsInfo;
      props.userProfile.contactInfo = props.userProfile.contactInfo === null ? {} : props.userProfile.contactInfo;
      props.userProfile.employmentInfo = props.userProfile.employmentInfo === null ? {} : props.userProfile.employmentInfo;
      props.userProfile.hrInfo = props.userProfile.hrInfo === null ? {} : props.userProfile.hrInfo;
      props.userProfile.integrations = props.userProfile.integrations === null ? {} : props.userProfile.integrations;
      props.userProfile.leavePolicies = props.userProfile.leavePolicies === null ? [] : props.userProfile.leavePolicies;
      props.userProfile.pensionPlan = props.userProfile.pensionPlan === null ? {} : props.userProfile.pensionPlan;
      props.userProfile.leaveTypes = props.userProfile.leaveTypes === null ? [] : props.userProfile.leaveTypes;
      props.userProfile.perksInfo = props.userProfile.perksInfo === null ? {} : props.userProfile.perksInfo;
      props.userProfile.roles = props.userProfile.roles === null ? {} : props.userProfile.roles;
      props.userProfile.emergencyContactInfo= props.userProfile.emergencyContactInfo === null ? {} : props.userProfile.emergencyContactInfo;
      props.userProfile.independentContractor = props.userProfile.independentContractor === null ? {} : props.userProfile.independentContractor;
      props.userProfile.approverList = props.userProfile.approverList === null ? [] : props.userProfile.approverList;
    }
    if (props.userProfile && Object.keys(props.userProfile).length > 0 && props.userProfile.personalInfo && Object.keys(props.userProfile.personalInfo).length > 0 && (props.userProfile.personalInfo.firstName.trim() || props.userProfile.personalInfo.lastName.trim())) {
      let userName=`${props.userProfile.personalInfo.firstName} ${props.userProfile.personalInfo.lastName} ${(props.userProfile.personalInfo.nickName?`(${props.userProfile.personalInfo.nickName})`:'')}`;
      if (queryParams.role) {
        if (queryParams.role === "Client User" || queryParams.role === "Client HR" || queryParams.role === "Client Billing" || queryParams.role === "Client Admin") {
          props.changePageMeta(`${props.title} - ${props.userProfile.employmentInfo.clientName} - ${userName}`)
        } else if (queryParams.role === "Subscriber User" || queryParams.role === "Subscriber HR" || queryParams.role === "Subscriber Billing" || queryParams.role === "Subscriber Admin") {
          props.changePageMeta(`${props.title} - ${props.userProfile.employmentInfo.subscriberName} - ${userName}`)
        } else {
          props.changePageMeta(`${props.title} - ${userName}`)
        }
      } else {
        props.changePageMeta(`${props.title} - ${userName}`)
      }
    }
  }, [props.userProfile]);

  useEffect(()=>{

    let weeklyHoursPerPay =  numberToMonetary(formData.hoursPerWeek,4);
    let biWeeklyHoursPerPay = numberToMonetary((formData.hoursPerWeek*52)/26,4);
      let semiMonthlyHoursPerPay = numberToMonetary((formData.hoursPerWeek*52)/24,4);
      let monthlyHoursPerPay =  numberToMonetary((formData.hoursPerWeek*52)/12,4);
      setFormData({...formData,
        subscriberId: props.defaultValues.subscriberId !== undefined ? props.defaultValues.subscriberId : '',
        //clientBillingContactName: props.defaultValues.clientBillingContactName !== undefined ?  props.defaultValues.clientBillingContactName : '',
        //clientContactId: props.defaultValues.directSupervisorId !== undefined ?  props.defaultValues.directSupervisorId : '',
        baseCurrency : props.defaultValues.baseCurrency !== undefined ? props.defaultValues.baseCurrency : '',
        // hrConsultantId : props.defaultValues.hrConsultantId !== undefined ? props.defaultValues.hrConsultantId : '',
        // salesConsultantId : props.defaultValues.salesConsultantId !== undefined ? props.defaultValues.salesConsultantId : '',
        weeklyHoursPerPay:weeklyHoursPerPay,
        biWeeklyHoursPerPay:biWeeklyHoursPerPay,
        semiMonthlyHoursPerPay:semiMonthlyHoursPerPay,
        monthlyHoursPerPay:monthlyHoursPerPay
      })

      },[props.defaultValues])

  useEffect(() => {
    if (id && id !== "0" && !(location.state && location.state.formData)) {
      props.get_user_profile(id)
      props.get_user_journey_for_sub(id);
      
    }
    if(id && id !== "0"){
      props.get_severance_note_list(id);
      props.get_pension_note_list(id);
      props.get_subscriber_note_list(id);

    }
    //props.get_client_markup_values();
    if(id && id == 0 && !(location.state && location.state.formData)){
      props.get_user_default_values();

    }
    return () => {
      props.clear_user_profile();
      props.clear_note_lists();
    }
  }, []);

  const [pageUnloadConfirm, setPageUnloadConfirm] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null,
    onCancell: null
  });
  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });

  const cancelConfirmAlert = () => {
    setConfirmAlert({
      ...confirmAlert,
      visible: false
    })
  }
  const handlePageUnload = ()=>{
    setPageUnloadConfirm({
      confirmMsg: 'Data entered is not saved, do you wish to save the information ?',
      visible: true,
      onConfirm: () => {
        submitForm();

      },
      onCancel: ()=>{
        setCancelForm(!cancelForm)
      }
    })
   }


  useEffect(() => {
    if (props.message) {
      if (props.success) {
        props.setAlert(props.message, "success");
      } else {
        props.setAlert(props.message, "danger");
      }
    }
    props.reset()
  }, [props.message, props.success]);

  let formDataRef = useRef({});
  let sectionformDataRef = useRef({});
  const [formData, setFormData] = useState({

    activeSection : '',
    baseCurrency : '',
    benefitsInfos: [],
    compensationInfos: [],
    pensionInfos: [],
    placementInfos: [],
    EAPInfos: [],
    HSAInfos: [],
    retainerInfos: [],
    benefitsApplicable: 'No',
    pensionApplicable: 'No',
    password: '',
    benefitsCertificateNo: '',
    accountStatus: null,
    benefitsClass: '',
    benefitsCoverage: '',
    perksFrequency: '',
    pensionPlanFrequency: '',
    pensionPlan: '',
    visaStatus: '',
    workerType: '',
    benefitsEndDate: '',
    benefitsStartDate: '',
    clientContribution: '',
    contribution: '',
    addressLine1: '',
    addressLine2: '',
    cityId: '',
    cityName: '',
    countryId: '',
    countryName: '',

    emailPersonal: '',
    emailWork: '',
    preferredEmail: '',
    linkedIn: '',
    phoneHome: '',
    phoneMobile: '',
    phoneWork: '',
    phoneWorkExt: '',
    regionId: '',
    regionName: '',
    website: '',
    zip: '',
    domainId: null,
    allowanceCar: 0,
    allowanceLiving: 0,
    allowancePhone: 0,
    clientContactId: null,
    finmodCandidateId: "",
    clientContactName: '',
    clientId: '',
    clientName: '',
    compensation: 0,
    ciPayType: '',
    ciCompensationCurrency: '',
    department: '',
    employmentStatus: '',
    hrConsultantId:'',
    salesConsultantId:'',
    jobTitle: '',
    payrollId: '',
    hireDate: '',
    endDate: '',
    subStatus: 'Active',
    subscriberId: null,
    subscriberName: '',
    hrDealType: '',
    placementId: '',
    talnetCandidateId: '',
    talnetClientContactId: '',
    leaveTypes: [],
    leavePolicies: [],
    allowanceTypes: [],
    dateOfBirth: '',
    firstName: '',
    lastName: '',
    maritalStatus: 'Not Specified',
    nickName: '',
    pronoun: '',
    requiredTwoFA: 0,
    clientAdminFlag: false,
    clientUserFlag: location.state && location.state.role === 'clientUserFlag',
    subscriberAdminFlag: false,
    subscriberUserFlag: false,
    systemAdminFlag: false,
    workerFlag: location.state && location.state.role === 'workerFlag',
    IsSaveWorker:false,
    IsSaveClientUser:false,
    IsSaveClientHR:false,
    IsSaveClientBilling:false,
    IsSaveClientAdmin:false,
    clientHRFlag: false,
    clientBillingFlag: false,
    subscriberHRFlag: false,
    subscriberBillingFlag: false,
    subscriberApiIntegrationFlag: false,
    subdomainId: null,
    userId: 0,
    loginEmail: '',
    clientBillingContactName: '',
    salutation: '',

    HSAProvider: '',
    HSAAmount: 0,
    HSACarryOver: '',
    HSAWaitingPeriod: 0,

    hrPlacementFee: "",

    noticePeriod:"2",
    exchangeRateForSalary:'',

    paymentMethod: '',
    employeeWage: 0,
    hoursPerWeek:'40.00',
    daysPerWeek : '5',
    planStartDate: '',
    planNo: 0,
    vestingPeriod: 0,
    myMatch: '',
    myMatchStartDate: '',
    clientMatch: '',
    clientMatchStartDate: '',
    clientCap: '',
    effectiveDate: '',
    empEffectiveDate : '',
    isRequiredFlag: true,
    queryParamsRole: queryParams.role,
    isValid: true,

    emergencyFirstName: '',
    emergencyLastName: '',
    relationShip:'',
    emergencyHomePhone: '',
    emergencyEmail: '',

    contractorsCompanyName: '',
    GSTOrHSTTax: '',
    GSTOrHSTTaxPercentage: 0.00,
    GSTOrHSTTaxAmount: 0.00,
    taxNumber: '',
    workersCompExemption: '',
    workersWCBNumber: '',
    IsClientEditable: 0,
    moduleName: '',
    approverId: '0',
    approverName: '',
    approverList: [],

    middleName: '',
    age: 0,

    addressType:'',
    emergencyPhoneWork:'',
    empCountryId: '',
    empCountryName: '',
    empRegionId: '',
    empRegionName: '',
    yearsOfService:0,
    noticePeriodUnit:'Weeks',
    vacationPay:4,
    weeklyHoursPerPay:  '',
    biWeeklyHoursPerPay: '',
    semiMonthlyHoursPerPay: '',
    monthlyHoursPerPay:  '',
    hrFeeType:'',
    hrEffectiveDate:'',
    hrPlacementFeeCurrency:'',
    hrFeeFrequency:'',
    hrPayComponentId:'',
    hrPlacementInvoiceFee:'',

    ciPayComponentId:'',
    ciEffectiveDay:'',
    ciPayPeriodFrequency:'',
    ciOTpayRate:0.00,
    ciOTbillRate:0.00,
    ciSalaryOrRate:0.00,
    ciPaymentMethod:'',
    ciBillRate: "",
    ciPayRate: "",
    ciOvertimeExempt: '',

    retainerApplicable:'No',
    rtPayType:'',
    rtSalaryOrRate:'',
    rtCurrency:'',
    rtPayComponentId:'',
    rtEffectiveDate:'',
    rtReason:'',
    rtHours:'',
    rtPayRate:'',
    rtCurrentRetainerFee:'',
    rtPreviousRetainerFee:0.00,
    retainerWeeks:'',

    biBenefitsApplicable:'',
    biBenefitsCurrency:'',
    biBenefitsDeductionFrequency:'',
    biEmployerPayComponentId:'',
    biEmployer:'',
    biEmployeePayComponentId:'',
    biWorker:'',
    biLtdAmount:'',
    biLifeAdnDAmount:'',
    biTotalTaxableBenefitsAmount:'',
    biHealthAmount:'',
    biBenefitsMarkUp:'',
    biBenefitsMarkupAmount:'',
    biTotalMarkupAmount:'',

    rpPlanAvailable:'',
    rpPlanProvider:'',
    rpMatchCapAmount:'',
    rpWorkerContributionEndDate:'',
    rpWorkerContributionAmount:'',
    rpClientPayComponentId:'',
    rpClientContributionEndDate:'',
    rpClientContributionAmount:'',
    rpWorkerPayComponentId:'',
    rpMarkup:'',
    rpTotalClientContribution:'',
    rpPayrollTaxes:'',
    rpCurrency:'',
    rpTotalMarkupAmount:'',

    HSAApplicable:'No',
    HSAStartDate:'',
    HSAEndDate:'',
    HSAContributionCurrency:'',
    HSAPayComponentId:'',
    HSAMarkup:'',
    HSAMarkupAmount:'',
    HSAWaitingPeriodUnit:'',
    employmentType:'Full Time',

    EAPApplicable:'No',
    EAPProvider:'',
    EAPStartDate:'',
    EAPEndDate:'',
    EAPContributionCurrency:'',
    EAPContributionAmount:'',
    EAPDeductionFrequency:'',
    EAPPayComponentId:'',
    EAPMarkup:'',
    EAPMarkupAmount:''
  });

  let roleSelected=formData.workerFlag
    || formData.clientUserFlag
    || formData.subscriberUserFlag
    || formData.subscriberApiIntegrationFlag
    || formData.clientHRFlag
    || formData.subscriberHRFlag
    || formData.clientBillingFlag
    || formData.subscriberBillingFlag
    || formData.clientAdminFlag
    || formData.subscriberAdminFlag
    || formData.systemAdminFlag


  function getMonetaryAllowanceTypes(allowanceTypes) {
    return allowanceTypes.map((allowanceType) => {
      allowanceType.amount = numberToMonetary(allowanceType.amount, 4);
      return allowanceType;
    })
  }

  useEffect(()=>{

    if((id == 0 && !formData.workerFlag) && (formData.clientAdminFlag
       || formData.clientUserFlag
       || formData.clientHRFlag
       || formData.clientBillingFlag
       || formData.subscriberHRFlag
       || formData.subscriberBillingFlag
       || formData.subscriberAdminFlag
       || formData.subscriberUserFlag
       || formData.subscriberApiIntegrationFlag
       || formData.systemAdminFlag)){
      setFormData({...formData, empEffectiveDate:new Date()})
    }
    else if((id== 0 && formData.workerFlag)){
      setFormData({...formData, empEffectiveDate : formData.hireDate});
    }
  },[formData.workerFlag,
    formData.clientUserFlag,
    formData.clientAdminFlag,
    formData.clientHRFlag,
    formData.clientBillingFlag,
    formData.hireDate,
    formData.subscriberHRFlag,
    formData.subscriberBillingFlag,
    formData.subscriberAdminFlag,
    formData.subscriberUserFlag,
    formData.subscriberApiIntegrationFlag,
    formData.systemAdminFlag])

  const checkSections = (key) => {
//console.log(key)
    let s1 = JSON.stringify(formData[key])
    let s2 = JSON.stringify(sectionformDataRef.current[key])

      return s1 === s2;

  }
  const isChangedFormData = () => {

    for (let formDataKey of Object.keys(formData)) {

      if (Array.isArray(formData[formDataKey])) {
        if (['benefitsInfos', 'pensionInfos', 'placementInfos','hrInfos', 'compensationInfos', 'retainerInfos', 'EAPInfos', 'HSAInfos',].includes(formDataKey)) {

          let section = checkSections(formDataKey);


          // if (section.length > 0) {
          //   return false;
          // }
          if(!section)
          {

            return false;
          }

          continue
        }
        else {
          if (JSON.stringify(formData[formDataKey]) !== JSON.stringify(formDataRef.current[formDataKey])) {
            return false;
          }
          continue;
        }


      }
      if (formDataKey === 'activeSection' || formDataKey === 'baseCurrency' || formDataKey === 'benefitsApplicable' || formDataKey === 'retainerApplicable') {
        continue;
      }

      if (formData[formDataKey] !== null && String(formData[formDataKey]) !== String(formDataRef.current[formDataKey])) {
        return false;
      }

    }

    return true;

  }
  useEffect(() => {
    if (id !== "0") {

      setDisabledSaveButton(isChangedFormData());

    }
  }, [formData])

  useEffect(() => {
    if (typeof location.state !== 'undefined') {
      setFormData({
        ...formData,
        [location.state.role]: true,
        clientId: location.state.clientId,
        clientName: location.state.clientName,
        subscriberId: location.state.subscriberId,
        subscriberName: location.state.subscriberName,
      });
    }
  }, [location.state]);

  const getBenefitsFormInfos = (benefitsInfoList) => {

    let assignedBenefits = benefitsInfoList.map(benefit => {
      return {
        userId:id,
        benefitsId: benefit.benefitsId,
        benefitsCertificateNo: benefit.benefitsCertificateNo,
        benefitsClass: benefit.benefitsClass,
        benefitsCoverage: benefit.benefitsCoverage,
        benefitsEndDate: String(benefit.benefitsEndDate),
        benefitsStartDate: String(benefit.benefitsStartDate),
        clientContribution: fixedDecimalPlaces(benefit.clientContribution, 2),
        contribution: fixedDecimalPlaces(benefit.contribution, 2),
        biBenefitsApplicable: benefit.biBenefitsApplicable,
        biBenefitsCurrency: benefit.biBenefitsCurrency,
        biBenefitsDeductionFrequency: benefit.biBenefitsDeductionFrequency,
        biEmployerPayComponentId: benefit.biEmployerPayComponentId,
        biEmployer: fixedDecimalPlaces(benefit.biEmployer, 2),
        biEmployeePayComponentId: benefit.biEmployeePayComponentId,
        biWorker: fixedDecimalPlaces(benefit.biWorker, 2),
        biLtdAmount: fixedDecimalPlaces(benefit.biLtdAmount, 2),
        biLifeAdnDAmount: fixedDecimalPlaces(benefit.biLifeAdnDAmount, 2),
        biTotalTaxableBenefitsAmount: fixedDecimalPlaces(benefit.biTotalTaxableBenefitsAmount, 2),
        biHealthAmount: fixedDecimalPlaces(benefit.biHealthAmount, 2),
        biBenefitsMarkUp: benefit.biBenefitsMarkUp,
        biTotalMarkupAmount: fixedDecimalPlaces(benefit.biTotalMarkupAmount, 2),
        biBenefitsMarkupAmount: fixedDecimalPlaces(benefit.biBenefitsMarkupAmount, 2),
        notes: benefit.notes,
      }
    })


    sectionformDataRef.current = {...sectionformDataRef.current, benefitsInfos:[...assignedBenefits]}
    if (location.state && location.state.formData) {
      return [...location.state.formData.benefitsInfos];
    }


    return assignedBenefits;
  }

  const getCompensationFormInfos = (compensationInfoList) => {


    let assignedCompensations = compensationInfoList.map(compensation => {
      let selectedPayComponent = props.payComponentList.filter(payComponent => Number(payComponent.refNum) === Number(compensation.ciPayComponentId))
      let payRateDecimals = selectedPayComponent[0] && selectedPayComponent[0].name === 'Hourly'? 4 : 2

      return {
        userId: id,
        cicompensationId: compensation.cicompensationId,
        ciPayType: compensation.ciPayType,
        ciPaymentMethod: compensation.ciPaymentMethod,
        ciPayComponentId: compensation.ciPayComponentId,
        ciSalaryOrRate: fixedDecimalPlaces(compensation.ciSalaryOrRate, compensation.ciPayType === 'Hourly' ? 4 : 2),
        ciCompensationCurrency: compensation.ciCompensationCurrency,
        ciPayPeriodFrequency: compensation.ciPayPeriodFrequency,
        ciPayRate: fixedDecimalPlaces(compensation.ciPayRate, payRateDecimals),
        ciBillRate: fixedDecimalPlaces(compensation.ciBillRate, 2),
        ciOvertimeExempt: compensation.ciOvertimeExempt,
        ciOTpayRate: fixedDecimalPlaces(compensation.ciOTpayRate, 4),
        ciOTbillRate: fixedDecimalPlaces(compensation.ciOTbillRate, 4),
        ciEffectiveDate: String(compensation.ciEffectiveDate),
        notes: compensation.notes,
      }
    })

    sectionformDataRef.current = {...sectionformDataRef.current, compensationInfos:[...assignedCompensations]}

    if (location.state && location.state.formData) {
      return [...location.state.formData.compensationInfos];
    }
    return assignedCompensations;
  }

  const getPensionFormInfos = (pensionInfoList) => {

       let assignedPensions = pensionInfoList.map(pension => {
      return {
        userId: id,
        pensionPlanId: pension.pensionPlanId,
        pensionPlan: pension.pensionPlan,
        frequency: pension.frequency,
        //myMatch: pension.myMatch,
        //myMatchStartDate: pension.myMatchStartDate,
        //clientMatchStartDate: pension.clientMatchStartDate,
        //planStartDate: pension.planStartDate,
        //clientMatch: pension.clientMatch,
        planNo: pension.planNo,
        clientCap: pension.clientCap,
        vestingPeriod: pension.vestingPeriod,
        rpPlanAvailable: pension.rpPlanAvailable,
        rpPlanProvider: pension.rpPlanProvider,
        rpMatchCapAmount: pension.rpMatchCapAmount,
        myMatchStartDate: String(pension.myMatchStartDate),
        rpWorkerContributionEndDate: String(pension.rpWorkerContributionEndDate),
        rpWorkerContributionAmount: pension.rpWorkerContributionAmount,
        rpClientPayComponentId: pension.rpClientPayComponentId,
        clientMatchStartDate: String(pension.clientMatchStartDate),
        rpClientContributionEndDate: String(pension.rpClientContributionEndDate),
        rpClientContributionAmount: pension.rpClientContributionAmount,
        rpWorkerPayComponentId: pension.rpWorkerPayComponentId,
        rpWorkerContribution: fixedDecimalPlaces(pension.rpWorkerContribution, pension.rpWorkerContributionType === 'Percentage' ? 2 : 4),
        rpMarkup: pension.rpMarkup,
        rpMarkupAmount: fixedDecimalPlaces(pension.rpMarkupAmount, 2),
        rpCurrency: pension.rpCurrency,
        rpClientContribution: fixedDecimalPlaces(pension.rpClientContribution, pension.rpClientContributionType === 'Percentage' ? 2 : 4 ),
        rpTotalMarkupAmount: fixedDecimalPlaces(pension.rpTotalMarkupAmount, 2),
        rpTotalClientContribution: pension.rpTotalClientContribution,
        rpPayrollTaxes: fixedDecimalPlaces(pension.rpPayrollTaxes, 3),
        rpWorkerContributionType: pension.rpWorkerContributionType,
        rpClientContributionType: pension.rpClientContributionType,
        //ciCompensationCurrency: pension.ciCompensationCurrency,
        pensionNote: pension.pensionNote,
      }
    })

    sectionformDataRef.current = {...sectionformDataRef.current, pensionInfos:[...assignedPensions]}

    if (location.state && location.state.formData) {
      return [...location.state.formData.pensionInfos];

    }
    return assignedPensions;
  }

  const getPlacementFormInfos = (placementInfoList) => {

    let assignedPlacements = placementInfoList.map(placement => {
      return {
        userId: id,
        hrinfoId: placement.hrinfoId,
        workerType: placement.workerType,
        hrDealType: placement.hrDealType,
        hoursPerWeek: placement.hoursPerWeek,
        hrPlacementFee: fixedDecimalPlaces(placement.hrPlacementFee,2),
        daysPerWeek: placement.daysPerWeek,
        exchangeRateForSalary: placement.exchangeRateForSalary,
        employeeWage: placement.employeeWage,
        hrFeeType: placement.hrFeeType,
        hrEffectiveDate: String(placement.hrEffectiveDate),
        hrPlacementFeeCurrency: placement.hrPlacementFeeCurrency,
        //hrPlacementFeeAmount: placement.placementFeeAmount,
        hrFeeFrequency: placement.hrFeeFrequency,
        hrPayComponentId: placement.hrPayComponentId,
        hrPlacementInvoiceFee: fixedDecimalPlaces(placement.hrPlacementInvoiceFee, 2),
        subscriberNote: placement.subscriberNote,
      }
    })

    sectionformDataRef.current = {...sectionformDataRef.current, placementInfos:[...assignedPlacements]}


    if (location.state && location.state.formData) {
      return [...location.state.formData.placementInfos];
    }

    return assignedPlacements;

  }

  const getRetainerFormInfos = (retainerInfoList) => {

    let assignedRetainers = retainerInfoList.map(retainer => {
      return {
        userId: id,
        retainerApplicable: retainer.retainerApplicable,
        rtPayType: retainer.rtPayType,
        rtSalaryOrRate: fixedDecimalPlaces(retainer.rtSalaryOrRate, retainer.ciPayType === 'Hourly' ? 4 : 2),
        rtCurrency: retainer.rtCurrency,
        rtPayComponentId: retainer.rtPayComponentId,
        rtEffectiveDate: String(retainer.rtEffectiveDate),
        rtReason: retainer.rtReason,
        rtHours: fixedDecimalPlaces(retainer.rtHours, 2),
        rtPayRate:fixedDecimalPlaces(retainer.rtPayRate, 2),
        rtPreviousRetainerFee: retainer.rtPreviousRetainerFee,
        rtCurrentRetainerFee: retainer.rtCurrentRetainerFee,
        retainerWeeks: fixedDecimalPlaces(retainer.retainerWeeks, 2),
        hoursPerWeek: retainer.hoursPerWeek,
        notes: retainer.notes,
        retainerId: retainer.retainerId,
      }
    })

    sectionformDataRef.current = {...sectionformDataRef.current, retainerInfos:[...assignedRetainers]}

    if (location.state && location.state.formData) {
      return [...location.state.formData.retainerInfos];
    }
    return assignedRetainers;

  }

  const getEAPFormInfos = (EAPInfoList) => {


    let assignedEaps = EAPInfoList.map(eap => {
      return {
        userId: id,
        EAPId: eap.EAPId,
        EAPApplicable: eap.EAPApplicable,
        EAPProvider: eap.EAPProvider,
        EAPStartDate: String(eap.EAPStartDate),
        EAPEndDate: String(eap.EAPEndDate),
        EAPContributionCurrency: eap.EAPContributionCurrency,
        EAPContributionAmount: fixedDecimalPlaces(eap.EAPContributionAmount, 2),
        EAPDeductionFrequency: eap.EAPDeductionFrequency,
        EAPPayComponentId: eap.EAPPayComponentId,
        //EAPPayComponent: eap.EAPPayComponent,
        EAPMarkup: eap.EAPMarkup,
        EAPMarkupAmount: fixedDecimalPlaces(eap.EAPMarkupAmount, 2),
        notes: eap.notes,
      }
    })

    sectionformDataRef.current = {...sectionformDataRef.current, EAPInfos:[...assignedEaps]}

    if (location.state && location.state.formData) {
      return [...location.state.formData.EAPInfos];
    }
    return assignedEaps;

  }

  const getHSAFormInfos = (HSAInfoList) => {

      let assignedHSAs = HSAInfoList.map(hsa => {
      return {
        userId: id,
        HSAAmount: fixedDecimalPlaces(hsa.HSAAmount, 2),
        HSACarryOver: hsa.HSACarryOver,
        HSAProvider: hsa.HSAProvider,
        HSAWaitingPeriod: hsa.HSAWaitingPeriod,
        HSAWaitingPeriodUnit: hsa.HSAWaitingPeriodUnit,
        frequency: hsa.frequency,
        HSAApplicable: hsa.HSAApplicable,
        HSAStartDate: String(hsa.HSAStartDate),
        HSAEndDate: String(hsa.HSAEndDate),
        HSAContributionCurrency: hsa.HSAContributionCurrency,
        HSAPayComponentId: hsa.HSAPayComponentId,
        HSAMarkup: hsa.HSAMarkup,
        HSAMarkupAmount: fixedDecimalPlaces(hsa.HSAMarkupAmount, 2),
        notes: hsa.notes,
        HSAId: hsa.HSAId,
      }
    })

    sectionformDataRef.current = {...sectionformDataRef.current, HSAInfos:[...assignedHSAs]}

    if (location.state && location.state.formData) {
      return [...location.state.formData.HSAInfos];
    }
    return assignedHSAs;

  }
  const calculatePayRate = (compensation, hoursPerPay) => {

    let payType = compensation.payType;

    let salaryOrRate = compensation.salaryOrRate;

    let payPeriodFrequency = compensation.payPeriodFrequency;


    if (payType === 'Hourly') {

        compensation.payRate = salaryOrRate;
        return compensation; //payRate = salaryOrRate
    }

    let payRate = 0;
    switch (payPeriodFrequency) {
        case 'Weekly':
            let weeklyHoursPerPay = hoursPerPay.weeklyHoursPerPay

            if (weeklyHoursPerPay === undefined || weeklyHoursPerPay === 0) {
                weeklyHoursPerPay = 1;
            }
            payRate = ((Number(salaryOrRate) / 52) / weeklyHoursPerPay)
            break;
        case 'Bi-weekly':

            let biWeeklyHoursPerPay = hoursPerPay.biWeeklyHoursPerPay;

            if (biWeeklyHoursPerPay === undefined || biWeeklyHoursPerPay === 0) {
                biWeeklyHoursPerPay = 1;
            }
            payRate = ((Number(salaryOrRate) / 26) / biWeeklyHoursPerPay)
            break;
        case 'Semi-monthly':

            let semiMonthlyHoursPerPay = hoursPerPay.semiMonthlyHoursPerPay;

            if (semiMonthlyHoursPerPay === undefined || semiMonthlyHoursPerPay === 0) {
                semiMonthlyHoursPerPay = 1;
            }
            payRate = ((Number(salaryOrRate) / 24) / semiMonthlyHoursPerPay)
            break;
        case 'Monthly':
            let monthlyHoursPerPay = hoursPerPay.monthlyHoursPerPay;

            if (monthlyHoursPerPay === undefined || monthlyHoursPerPay === 0) {
                monthlyHoursPerPay = 1;
            }
            payRate = ((Number(salaryOrRate) / 12) / monthlyHoursPerPay)
            break;
        default:
    }

    compensation.payRate = payRate;

    return compensation;
  }
  useEffect(() => {
    function getApiFormData(formData) {
      let clonedUserProfile = JSON.parse(JSON.stringify(props.userProfile));

      let benefitsInfoList = clonedUserProfile.benefitsInfos ? clonedUserProfile.benefitsInfos : [];
      let benefitsFormData = getBenefitsFormInfos(benefitsInfoList);

      let compensationInfoList = clonedUserProfile.compensationInfos ? clonedUserProfile.compensationInfos : [];

      let compensationFormData = getCompensationFormInfos(compensationInfoList);

      let pensionInfoList = clonedUserProfile.pensionPlanInfos ? clonedUserProfile.pensionPlanInfos : [];

      let pensionFormData = getPensionFormInfos(pensionInfoList);

      let placementInfoList = clonedUserProfile.hrInfos ? clonedUserProfile.hrInfos : [];

      let placementFormData = getPlacementFormInfos(placementInfoList);

      let retainerInfoList = clonedUserProfile.retainerInfos ? clonedUserProfile.retainerInfos : [];
      let retainerFormData = getRetainerFormInfos(retainerInfoList);

      let EAPInfoList = clonedUserProfile.EAPInfos ? clonedUserProfile.EAPInfos : [];

      let eapFormData = getEAPFormInfos(EAPInfoList);

      let HSAInfoList = clonedUserProfile.HSAInfos ? clonedUserProfile.HSAInfos : [];
      let hsaFormData = getHSAFormInfos(HSAInfoList);

      return {
        ...formData,
        benefitsInfos: [...benefitsFormData],
        compensationInfos: [...compensationFormData],
        pensionInfos: [...pensionFormData],
        placementInfos: [...placementFormData],
        retainerInfos: [...retainerFormData],
        EAPInfos : [...eapFormData],
        HSAInfos : [...hsaFormData],
        benefitsApplicable: (location.state && location.state.formData) ? location.state.formData.benefitsApplicable : clonedUserProfile.facilityApplicable ? clonedUserProfile.facilityApplicable.benefitsApplicable : 'No',
        pensionApplicable: (location.state && location.state.formData) ? location.state.formData.pensionApplicable : clonedUserProfile.facilityApplicable ? clonedUserProfile.facilityApplicable.pensionApplicable : 'No',
        retainerApplicable: (location.state && location.state.formData) ? location.state.formData.retainerApplicable : clonedUserProfile.facilityApplicable ? clonedUserProfile.facilityApplicable.retainerApplicable : 'No',
       EAPApplicable: (location.state && location.state.formData) ? location.state.formData.EAPApplicable : clonedUserProfile.facilityApplicable ? clonedUserProfile.facilityApplicable.EAPApplicable : 'No',
       HSAApplicable:(location.state && location.state.formData) ? location.state.formData.HSAApplicable : clonedUserProfile.facilityApplicable ? clonedUserProfile.facilityApplicable.HSAApplicable : 'No',
       //benefitsCertificateNo: clonedUserProfile.benefitsInfo.benefitsCertificateNo,
        //benefitsClass: clonedUserProfile.benefitsInfo.benefitsClass,
        //benefitsCoverage: clonedUserProfile.benefitsInfo.benefitsCoverage,
       // biBenefitsApplicable: clonedUserProfile.benefitsInfo.biBenefitsApplicable,
        //biBenefitsCurrency: clonedUserProfile.benefitsInfo.biBenefitsCurrency,
        //biBenefitsDeductionFrequency: clonedUserProfile.benefitsInfo.biBenefitsDeductionFrequency,
        // biEmployerPayComponentId: clonedUserProfile.benefitsInfo.biEmployerPayComponentId,
        // biEmployer: numberToMonetary(clonedUserProfile.benefitsInfo.biEmployer),
        // biEmployeePayComponentId: clonedUserProfile.benefitsInfo.biEmployeePayComponentId,
        // biWorker: numberToMonetary(clonedUserProfile.benefitsInfo.biWorker),
        // biLtdAmount:numberToMonetary(clonedUserProfile.benefitsInfo.biLtdAmount),
        // biLifeAdnDAmount: numberToMonetary(clonedUserProfile.benefitsInfo.biLifeAdnDAmount),
        // biTotalTaxableBenefitsAmount: numberToMonetary(clonedUserProfile.benefitsInfo.biTotalTaxableBenefitsAmount),
        // biHealthAmount: numberToMonetary(clonedUserProfile.benefitsInfo.biHealthAmount),
        // biBenefitsMarkUp: clonedUserProfile.benefitsInfo.biBenefitsMarkUp,
        // biBenefitsMarkupAmount: numberToMonetary(clonedUserProfile.benefitsInfo.biBenefitsMarkupAmount),
        // biTotalMarkupAmount: numberToMonetary(clonedUserProfile.benefitsInfo.biTotalMarkupAmount),

        // EAPProvider: clonedUserProfile.eapInfo.EAPProvider,
        // EAPStartDate: setAPIDate(clonedUserProfile.eapInfo.EAPStartDate),
        // EAPEndDate: setAPIDate(clonedUserProfile.eapInfo.EAPEndDate),
        // EAPContributionCurrency: clonedUserProfile.eapInfo.EAPContributionCurrency,
        // EAPContributionAmount: numberToMonetary(clonedUserProfile.eapInfo.EAPContributionAmount),
        // EAPDeductionFrequency: clonedUserProfile.eapInfo.EAPDeductionFrequency,
        // EAPPayComponentId: clonedUserProfile.eapInfo.EAPPayComponentId,
        // EAPMarkup: clonedUserProfile.eapInfo.EAPMarkup,
        // EAPMarkupAmount: numberToMonetary(clonedUserProfile.eapInfo.EAPMarkupAmount),

        // perksFrequency: clonedUserProfile.perksInfo.frequency,
        // HSAEndDate: setAPIDate(clonedUserProfile.perksInfo.HSAEndDate),
        // HSAContributionCurrency: clonedUserProfile.perksInfo.HSAContributionCurrency,
        // HSAPayComponentId: clonedUserProfile.perksInfo.HSAPayComponentId,
        // HSAMarkup: clonedUserProfile.perksInfo.HSAMarkup,
        // HSAMarkupAmount: numberToMonetary(clonedUserProfile.perksInfo.HSAMarkupAmount),
        // HSAWaitingPeriodUnit: clonedUserProfile.perksInfo.HSAWaitingPeriodUnit,

        //rpTotalClientContribution: numberToMonetary(clonedUserProfile.pensionPlan.rpTotalClientContribution),
        // rpPayrollTaxes: numberToMonetary(clonedUserProfile.pensionPlan.rpPayrollTaxes),
        // pensionPlanFrequency: clonedUserProfile.pensionPlan.frequency,
        // rpPlanAvailable: clonedUserProfile.pensionPlan.rpPlanAvailable,
        // rpPlanProvider: clonedUserProfile.pensionPlan.rpPlanProvider,
        // rpMatchCapAmount: numberToMonetary(clonedUserProfile.pensionPlan.rpMatchCapAmount),
        // rpWorkerContributionEndDate: setAPIDate(clonedUserProfile.pensionPlan.rpWorkerContributionEndDate),
        // rpWorkerContributionAmount: numberToMonetary(clonedUserProfile.pensionPlan.rpWorkerContributionAmount),
        // rpClientPayComponentId: clonedUserProfile.pensionPlan.rpClientPayComponentId,
        // rpClientContributionEndDate: setAPIDate(clonedUserProfile.pensionPlan.rpClientContributionEndDate),
        // rpClientContributionAmount: numberToMonetary(clonedUserProfile.pensionPlan.rpClientContributionAmount),
        // rpWorkerPayComponentId: clonedUserProfile.pensionPlan.rpWorkerPayComponentId,
        // rpMarkup: clonedUserProfile.pensionPlan.rpMarkup,
        // rpCurrency: clonedUserProfile.pensionPlan.rpCurrency,
        // rpTotalMarkupAmount: numberToMonetary(clonedUserProfile.pensionPlan.rpTotalMarkupAmount),
        // rpMarkupAmount: numberToMonetary(clonedUserProfile.pensionPlan.rpMarkupAmount),
        // pensionPlan: clonedUserProfile.pensionPlan.pensionPlan,
        // benefitsStartDate: setAPIDate(
        //   clonedUserProfile.benefitsInfo.benefitsStartDate
        // ),
        // benefitsEndDate: setAPIDate(clonedUserProfile.benefitsInfo.benefitsEndDate),
        // clientContribution: numberToMonetary(clonedUserProfile.benefitsInfo.clientContribution),
        // contribution: numberToMonetary(clonedUserProfile.benefitsInfo.contribution),
        accountStatus: clonedUserProfile.accountStatus,
        addressLine1: clonedUserProfile.contactInfo.addressLine1,
        addressLine2: clonedUserProfile.contactInfo.addressLine2,
        cityId: clonedUserProfile.contactInfo.cityId,
        cityName: clonedUserProfile.contactInfo.cityName,
        countryId: clonedUserProfile.contactInfo.countryId,
        countryName: clonedUserProfile.contactInfo.countryName,
        regionId: clonedUserProfile.contactInfo.regionId,
        regionName: clonedUserProfile.contactInfo.regionName,
        emailPersonal: clonedUserProfile.contactInfo.emailPersonal,
        emailWork: clonedUserProfile.contactInfo.emailWork,
        preferredEmail: clonedUserProfile.contactInfo.preferredEmail,
        linkedIn: clonedUserProfile.contactInfo.linkedIn,
        phoneHome: clonedUserProfile.contactInfo.phoneHome,
        phoneMobile: clonedUserProfile.contactInfo.phoneMobile,
        phoneWork: clonedUserProfile.contactInfo.phoneWork,
        phoneWorkExt: clonedUserProfile.contactInfo.phoneWorkExt,
        website: clonedUserProfile.contactInfo.website,
        zip: clonedUserProfile.contactInfo.zip,
        domainId: clonedUserProfile.domainId,
        allowanceCar: clonedUserProfile.employmentInfo.allowanceCar,
        allowanceLiving: clonedUserProfile.employmentInfo.allowanceLiving,
        allowancePhone: clonedUserProfile.employmentInfo.allowancePhone,
        clientContactId: clonedUserProfile.employmentInfo.clientContactId,
        clientContactName: clonedUserProfile.employmentInfo.clientContactName,
        clientName: clonedUserProfile.employmentInfo.clientName,
        clientId: clonedUserProfile.employmentInfo.clientId,

        IsClientEditable:clonedUserProfile.employmentInfo.clientId,
        compensation: numberToMonetary(clonedUserProfile.employmentInfo.compensation, 4),
        //ciPayType: clonedUserProfile.compensationInfo.ciPayType,
        //ciCompensationCurrency: clonedUserProfile.compensationInfo.ciCompensationCurrency,
        department: clonedUserProfile.employmentInfo.department,
        employmentStatus: clonedUserProfile.employmentInfo.employmentStatus,
        hrConsultantId: clonedUserProfile.employmentInfo.hrConsultantId,
        salesConsultantId: clonedUserProfile.employmentInfo.salesConsultantId,
        jobTitle: clonedUserProfile.employmentInfo.jobTitle,
        payrollId: clonedUserProfile.employmentInfo.payrollId,
        hireDate: setAPIDate(clonedUserProfile.employmentInfo.hireDate),
        endDate: setAPIDate(clonedUserProfile.employmentInfo.endDate),
        subStatus: clonedUserProfile.employmentInfo.subStatus,
        subscriberId: clonedUserProfile.employmentInfo.subscriberId,
        subscriberName: clonedUserProfile.employmentInfo.subscriberName,
        clientBillingContactName: clonedUserProfile.employmentInfo.clientBillingContactName,
        noticePeriodUnit: clonedUserProfile.employmentInfo.noticePeriodUnit,
        yearsOfService: numberToMonetary(clonedUserProfile.employmentInfo.yearsOfService),
        weeklyHoursPerPay: numberToMonetary(clonedUserProfile.employmentInfo.hoursPerWeek, 4),//numberToMonetary(clonedUserProfile.employmentInfo.weeklyHoursPerPay,4),
        biWeeklyHoursPerPay: numberToMonetary((clonedUserProfile.employmentInfo.hoursPerWeek * 52) / 26, 4),//numberToMonetary(clonedUserProfile.employmentInfo.biWeeklyHoursPerPay,4),
        semiMonthlyHoursPerPay: numberToMonetary((clonedUserProfile.employmentInfo.hoursPerWeek * 52) / 24, 4),//numberToMonetary(clonedUserProfile.employmentInfo.semiMonthlyHoursPerPay,4),
        monthlyHoursPerPay: numberToMonetary((clonedUserProfile.employmentInfo.hoursPerWeek * 52) / 12, 4),//numberToMonetary(clonedUserProfile.employmentInfo.monthlyHoursPerPay,4),
        vacationPay: clonedUserProfile.employmentInfo.vacationPay,
        empCountryId: clonedUserProfile.employmentInfo.empCountryId,
        empRegionId: clonedUserProfile.employmentInfo.empRegionId,
        hrPlacementFee: numberToMonetary(clonedUserProfile.hrInfo.hrPlacementFee),
        //ciBillRate: numberToMonetary(clonedUserProfile.compensationInfo.ciBillRate,4),
        //retainerWeeks: numberToMonetary(clonedUserProfile.retainerInfo.retainerWeeks),
        daysPerWeek: numberToMonetary(clonedUserProfile.employmentInfo.daysPerWeek),
        hoursPerWeek: numberToMonetary(clonedUserProfile.employmentInfo.hoursPerWeek),
        hrConsultant: clonedUserProfile.hrInfo.hrConsultant,
        empEffectiveDate: setAPIDate(clonedUserProfile.employmentInfo.empEffectiveDate),
        //ciPayRate: numberToMonetary(clonedUserProfile.compensationInfo.ciPayRate, 4),
        salesConsultant: clonedUserProfile.hrInfo.salesConsultant,
        visaStatus: clonedUserProfile.hrInfo.visaStatus,
        workerType: clonedUserProfile.hrInfo.workerType,
        noticePeriod: clonedUserProfile.employmentInfo.noticePeriod,
        exchangeRateForSalary: clonedUserProfile.hrInfo.exchangeRateForSalary,


        employeeWage: numberToMonetary(clonedUserProfile.hrInfo.employeeWage),
        hrFeeType: clonedUserProfile.hrInfo.hrFeeType,
        hrEffectiveDate: setAPIDate(clonedUserProfile.hrInfo.hrEffectiveDate),
        hrPlacementFeeCurrency: clonedUserProfile.hrInfo.hrPlacementFeeCurrency,
        hrFeeFrequency: clonedUserProfile.hrInfo.hrFeeFrequency,
        hrPayComponentId: clonedUserProfile.hrInfo.hrPayComponentId,
        hrPlacementInvoiceFee: numberToMonetary(clonedUserProfile.hrInfo.hrPlacementInvoiceFee),


        //ciPayComponentId: clonedUserProfile.compensationInfo.ciPayComponentId,
        //this field is not in table
        // ciEffectiveDay: setAPIDate (clonedUserProfile.compensationInfo.ciEffectiveDay),
        // ciPayPeriodFrequency: clonedUserProfile.compensationInfo.ciPayPeriodFrequency,
        // ciOTpayRate: numberToMonetary(clonedUserProfile.compensationInfo.ciOTpayRate,4),
        // ciOTbillRate:numberToMonetary(clonedUserProfile.compensationInfo.ciOTbillRate,4),
        // ciSalaryOrRate: numberToMonetary(clonedUserProfile.compensationInfo.ciSalaryOrRate),
        // ciPaymentMethod: clonedUserProfile.compensationInfo.ciPaymentMethod,
        // ciOvertimeExempt: clonedUserProfile.compensationInfo.ciOvertimeExempt,

         //this field is not in table
        //retainerApplicable: clonedUserProfile.retainerInfo.retainerApplicable,
       // rtPayType: clonedUserProfile.retainerInfo.rtPayType,
        //rtSalaryOrRate: numberToMonetary(clonedUserProfile.retainerInfo.rtSalaryOrRate),
        // rtCurrency: clonedUserProfile.retainerInfo.rtCurrency,
        // rtPayComponentId: clonedUserProfile.retainerInfo.rtPayComponentId,
        // rtEffectiveDate: setAPIDate(clonedUserProfile.retainerInfo.rtEffectiveDate),
        // rtReason: clonedUserProfile.retainerInfo.rtReason,
        // rtHours: clonedUserProfile.retainerInfo.rtHours,
        // rtPayRate: numberToMonetary(clonedUserProfile.retainerInfo.rtPayRate,4),
        // rtCurrentRetainerFee: numberToMonetary(clonedUserProfile.retainerInfo.rtCurrentRetainerFee),
        // rtPreviousRetainerFee: numberToMonetary(clonedUserProfile.retainerInfo.rtPreviousRetainerFee),
        hrDealType: clonedUserProfile.hrInfo.hrDealType,
        placementId: clonedUserProfile.integrations.placementId,
        talnetCandidateId: clonedUserProfile.integrations.candidateId,
        talnetClientContactId: clonedUserProfile.integrations.clientContactId,
        finmodCandidateId: clonedUserProfile.integrations.finmodCandidateId,

        leaveTypes: clonedUserProfile.leaveTypes,
        leavePolicies: clonedUserProfile.leavePolicies,
        allowanceTypes: getMonetaryAllowanceTypes(clonedUserProfile.allowanceTypes),
        dateOfBirth: setAPIDate(clonedUserProfile.personalInfo.dateOfBirth),
        firstName: clonedUserProfile.personalInfo.firstName,
        lastName: clonedUserProfile.personalInfo.lastName,
        middleName: clonedUserProfile.personalInfo.middleName,
        maritalStatus: clonedUserProfile.personalInfo.maritalStatus,
        nickName: clonedUserProfile.personalInfo.nickName,
        pronoun: clonedUserProfile.personalInfo.pronoun,
        age: numberToMonetary(clonedUserProfile.personalInfo.age),


        requiredTwoFA: clonedUserProfile.requiredTwoFA,

        clientAdminFlag: clonedUserProfile.roles.clientAdminFlag,
        clientUserFlag: clonedUserProfile.roles.clientUserFlag,
        subscriberAdminFlag: clonedUserProfile.roles.subscriberAdminFlag,
        subscriberUserFlag: clonedUserProfile.roles.subscriberUserFlag,
        systemAdminFlag: clonedUserProfile.roles.systemAdminFlag,
        workerFlag: clonedUserProfile.roles.workerFlag,
        IsSaveWorker: clonedUserProfile.roles.workerFlag,
        IsSaveClientUser: clonedUserProfile.roles.clientUserFlag,
        IsSaveClientHR: clonedUserProfile.roles.clientHRFlag,
        IsSaveClientBilling: clonedUserProfile.roles.clientBillingFlag,
        IsSaveClientAdmin: clonedUserProfile.roles.clientAdminFlag,
        clientHRFlag: clonedUserProfile.roles.clientHRFlag,
        clientBillingFlag: clonedUserProfile.roles.clientBillingFlag,
        subscriberHRFlag: clonedUserProfile.roles.subscriberHRFlag,
        subscriberBillingFlag: clonedUserProfile.roles.subscriberBillingFlag,
        subscriberApiIntegrationFlag: clonedUserProfile.roles.subscriberApiIntegrationFlag,


        subdomainId: clonedUserProfile.subdomainId,
        userId: clonedUserProfile.userId,
        loginEmail: clonedUserProfile.loginEmail,

        //HSAProvider: clonedUserProfile.perksInfo.HSAProvider,
        //HSAAmount: numberToMonetary(clonedUserProfile.perksInfo.HSAAmount),
        //HSACarryOver: numberToMonetary(clonedUserProfile.perksInfo.HSACarryOver),
        //HSAWaitingPeriod: clonedUserProfile.perksInfo.HSAWaitingPeriod,

        // planStartDate: setAPIDate(clonedUserProfile.pensionPlan.planStartDate),
        // planNo: numberToMonetary(clonedUserProfile.pensionPlan.planNo),
        // vestingPeriod: clonedUserProfile.pensionPlan.vestingPeriod,
        // myMatch: numberToMonetary(clonedUserProfile.pensionPlan.myMatch),
        // myMatchStartDate: setAPIDate(clonedUserProfile.pensionPlan.myMatchStartDate),
        // clientMatch: numberToMonetary(clonedUserProfile.pensionPlan.clientMatch),
        // clientMatchStartDate: setAPIDate(clonedUserProfile.pensionPlan.clientMatchStartDate),
        // clientCap: numberToMonetary(clonedUserProfile.pensionPlan.clientCap),


        emergencyFirstName: clonedUserProfile.emergencyContactInfo.firstName,
        emergencyLastName: clonedUserProfile.emergencyContactInfo.lastName,
        relationShip: clonedUserProfile.emergencyContactInfo.relationShip,
        emergencyHomePhone: clonedUserProfile.emergencyContactInfo.homePhone,
        emergencyEmail: clonedUserProfile.emergencyContactInfo.email,
        emergencyPhoneWork: clonedUserProfile.emergencyContactInfo.emergencyPhoneWork,

        contractorsCompanyName: clonedUserProfile.independentContractor.contractorsCompanyName,
        GSTOrHSTTax: clonedUserProfile.independentContractor.GSTOrHSTTax,
        GSTOrHSTTaxPercentage: fixedDecimalPlaces(clonedUserProfile.independentContractor.GSTOrHSTTaxPercentage, 2),
        GSTOrHSTTaxAmount: fixedDecimalPlaces(clonedUserProfile.independentContractor.GSTOrHSTTaxAmount, 2),
        taxNumber: clonedUserProfile.independentContractor.taxNumber,
        workersCompExemption: clonedUserProfile.independentContractor.workersCompExemption,
        workersWCBNumber: clonedUserProfile.independentContractor.workersWCBNumber,
        approverList: clonedUserProfile.approverList,
        approverInfos: clonedUserProfile.approverInfos,
        addressType:clonedUserProfile.contactInfo.addressType,
        employmentType:clonedUserProfile.employmentInfo.employmentType,
        baseCurrency:clonedUserProfile.subscriberBaseCurrency
      }
    }

    if (props.userProfile &&
      Object.keys(props.userProfile).length !== 0 &&
      typeof props.userProfile !== 'undefined'
    ) {
      formDataRef.current=getApiFormData(formData)
      setFormData({...getApiFormData(formData)});
    }
  }, [props.userProfile]);

  useEffect(() => {
    props.get_employment_status_list();
    props.get_employment_sub_status_list();
    props.sub_get_currency_list();
    props.get_country_list();
    props.get_city_details();
    props.get_compensation_types();
    props.get_worker_types();
    props.get_visa_status_types();
    props.get_deal_types_types();
    props.get_subscriber_list();
    props.get_benefits_coverage_list();
    props.get_perks_frequency_list();
    props.get_pension_plan_frequency_list();
    props.get_pension_plan_list();
    props.get_preferred_email_list();
    props.get_benefits_class_list();
    props.get_marital_status_list();
    props.get_pronoun_list();
    props.get_salesconsultant();
    props.get_hr_Consultant();
    props.get_app_modules();
    props.get_pay_component_list();
  }, []);

  useEffect(() => {

    // function  getApiFormData(formData){
    //   const clonedFormData = JSON.parse(JSON.stringify(location.state.formData));

    //   return{
    //     ...formData,
    //     benefitsInfos: [...clonedFormData.benefitsInfos],
    //     compensationInfos: [...clonedFormData.compensationInfos],
    //     pensionInfos: [...clonedFormData.pensionInfos],
    //     placementInfos: [...clonedFormData.placementInfos],
    //     retainerInfos:[...clonedFormData.retainerInfos],
    //     EAPInfos : [...clonedFormData.EAPInfos],
    //     HSAInfos : [...clonedFormData.HSAInfos],
    //     benefitsApplicable: clonedFormData.benefitsApplicable,
    //     pensionApplicable: clonedFormData.pensionApplicable,
    //     retainerApplicable:clonedFormData.retainerApplicable,
    //     benefitsClass: clonedFormData.benefitsClass,
    //     benefitsCoverage: clonedFormData.benefitsCoverage,
    //     biBenefitsApplicable: clonedFormData.biBenefitsApplicable,
    //     biBenefitsCurrency: clonedFormData.biBenefitsCurrency,
    //     biBenefitsDeductionFrequency: clonedFormData.biBenefitsDeductionFrequency,
    //     biEmployerPayComponentId: clonedFormData.biEmployerPayComponentId,
    //     biEmployer: numberToMonetary(clonedFormData.biEmployer),
    //     biEmployeePayComponentId: clonedFormData.biEmployeePayComponentId,
    //     biWorker: numberToMonetary(clonedFormData.biWorker),
    //     biLtdAmount: numberToMonetary(clonedFormData.biLtdAmount),
    //     biLifeAdnDAmount: numberToMonetary(clonedFormData.biLifeAdnDAmount),
    //     biTotalTaxableBenefitsAmount: numberToMonetary(clonedFormData.biTotalTaxableBenefitsAmount),
    //     biHealthAmount: numberToMonetary(clonedFormData.biHealthAmount),
    //     biBenefitsMarkUp: clonedFormData.biBenefitsMarkUp,
    //     biBenefitsMarkupAmount: numberToMonetary(clonedFormData.biBenefitsMarkupAmount),
    //     biTotalMarkupAmount: numberToMonetary(clonedFormData.biTotalMarkupAmount),
    //     empEffectiveDate: setAPIDate(clonedFormData.employmentInfo.empEffectiveDate),
    //     EAPApplicable: clonedFormData.EAPApplicable,
    //     EAPProvider: clonedFormData.EAPProvider,
    //     EAPStartDate: clonedFormData.EAPStartDate,
    //     EAPEndDate: clonedFormData.EAPEndDate,
    //     EAPContributionCurrency: clonedFormData.EAPContributionCurrency,
    //     EAPContributionAmount: numberToMonetary(clonedFormData.EAPContributionAmount),
    //     EAPDeductionFrequency: clonedFormData.EAPDeductionFrequency,
    //     EAPPayComponentId: clonedFormData.EAPPayComponentId,
    //     EAPMarkup: clonedFormData.EAPMarkup,
    //     EAPMarkupAmount: numberToMonetary(clonedFormData.EAPMarkupAmount),

    //     perksFrequency: clonedFormData.frequency,
    //     HSAApplicable: clonedFormData.HSAApplicable,
    //     HSAEndDate: clonedFormData.HSAEndDate,
    //     HSAContributionCurrency: clonedFormData.HSAContributionCurrency,
    //     HSAPayComponentId: clonedFormData.HSAPayComponentId,
    //     HSAMarkup: clonedFormData.HSAMarkup,
    //     HSAMarkupAmount: numberToMonetary(clonedFormData.HSAMarkupAmount),
    //     HSAWaitingPeriodUnit: clonedFormData.HSAWaitingPeriodUnit,

    //     rpTotalClientContribution: numberToMonetary(clonedFormData.rpTotalClientContribution),
    //     rpPayrollTaxes: numberToMonetary(clonedFormData.rpPayrollTaxes),
    //     pensionPlanFrequency: clonedFormData.frequency,
    //     rpPlanAvailable: clonedFormData.rpPlanAvailable,
    //     rpPlanProvider: clonedFormData.rpPlanProvider,
    //     rpMatchCapAmount: numberToMonetary(clonedFormData.rpMatchCapAmount),
    //     rpWorkerContributionEndDate: clonedFormData.rpWorkerContributionEndDate,
    //     rpWorkerContributionAmount: numberToMonetary(clonedFormData.rpWorkerContributionAmount),
    //     rpClientPayComponentId: clonedFormData.rpClientPayComponentId,
    //     rpClientContributionEndDate: clonedFormData.rpClientContributionEndDate,
    //     rpClientContributionAmount: numberToMonetary(clonedFormData.rpClientContributionAmount),
    //     rpWorkerPayComponentId: clonedFormData.rpWorkerPayComponentId,
    //     rpMarkup: clonedFormData.rpMarkup,
    //     rpCurrency: clonedFormData.rpCurrency,
    //     rpTotalMarkupAmount: numberToMonetary(clonedFormData.rpTotalMarkupAmount),
    //     rpMarkupAmount: numberToMonetary(clonedFormData.rpMarkupAmount),
    //     pensionPlan: clonedFormData.pensionPlan,
    //     benefitsStartDate:
    //       clonedFormData.benefitsStartDate,
    //     benefitsEndDate: clonedFormData.benefitsEndDate,
    //     clientContribution: numberToMonetary(clonedFormData.clientContribution),
    //     contribution: numberToMonetary(clonedFormData.contribution),
    //     accountStatus: clonedFormData.accountStatus,
    //     addressLine1: clonedFormData.addressLine1,
    //     addressLine2: clonedFormData.addressLine2,
    //     cityId: clonedFormData.cityId,
    //     cityName: clonedFormData.cityName,
    //     countryId: clonedFormData.countryId,
    //     countryName: clonedFormData.countryName,
    //     regionId: clonedFormData.regionId,
    //     regionName: clonedFormData.regionName,
    //     emailPersonal: clonedFormData.emailPersonal,
    //     emailWork: clonedFormData.emailWork,
    //     preferredEmail: clonedFormData.preferredEmail,
    //     linkedIn: clonedFormData.linkedIn,
    //     phoneHome: clonedFormData.phoneHome,
    //     phoneMobile: clonedFormData.phoneMobile,
    //     phoneWork: clonedFormData.phoneWork,
    //     phoneWorkExt: clonedFormData.phoneWorkExt,
    //     website: clonedFormData.website,
    //     zip: clonedFormData.zip,
    //     domainId: clonedFormData.domainId,
    //     allowanceCar: clonedFormData.allowanceCar,
    //     allowanceLiving: clonedFormData.allowanceLiving,
    //     allowancePhone: clonedFormData.allowancePhone,
    //     clientContactId: clonedFormData.clientContactId,
    //     clientContactName: clonedFormData.clientContactName,
    //     clientName: clonedFormData.clientName,
    //     clientId: clonedFormData.clientId,

    //     IsClientEditable: clonedFormData.clientId,
    //     compensation: numberToMonetary(clonedFormData.compensation, 4),
    //     ciPayType: clonedFormData.ciPayType,
    //     ciCompensationCurrency: clonedFormData.ciCompensationCurrency,
    //     department: clonedFormData.department,
    //     employmentStatus: clonedFormData.employmentStatus,
    //     hrConsultantId: clonedFormData.hrConsultantId,
    //     salesConsultantId: clonedFormData.salesConsultantId,
    //     jobTitle: clonedFormData.jobTitle,
    //     payrollId: clonedFormData.payrollId,
    //     hireDate: clonedFormData.hireDate,
    //     endDate: clonedFormData.endDate,
    //     subStatus: clonedFormData.subStatus,
    //     subscriberId: clonedFormData.subscriberId,
    //     subscriberName: clonedFormData.subscriberName,
    //     clientBillingContactName: clonedFormData.clientBillingContactName,
    //     noticePeriodUnit: clonedFormData.noticePeriodUnit,
    //     yearsOfService: numberToMonetary(clonedFormData.yearsOfService),
    //     weeklyHoursPerPay: numberToMonetary(clonedFormData.weeklyHoursPerPay),
    //     biWeeklyHoursPerPay: numberToMonetary(clonedFormData.biWeeklyHoursPerPay),
    //     semiMonthlyHoursPerPay: numberToMonetary(clonedFormData.semiMonthlyHoursPerPay),
    //     monthlyHoursPerPay: numberToMonetary(clonedFormData.monthlyHoursPerPay),
    //     vacationPay: clonedFormData.vacationPay,
    //     empCountryId: clonedFormData.empCountryId,
    //     empRegionId: clonedFormData.empRegionId,
    //     hrPlacementFee: numberToMonetary(clonedFormData.hrPlacementFee),
    //     ciBillRate: numberToMonetary(clonedFormData.ciBillRate, 4),
    //     retainerWeeks: numberToMonetary(clonedFormData.retainerWeeks),
    //     daysPerWeek: numberToMonetary(clonedFormData.daysPerWeek),
    //     hoursPerWeek: numberToMonetary(clonedFormData.hoursPerWeek),
    //     hrConsultant: clonedFormData.hrConsultant,
    //     ciPayRate: numberToMonetary(clonedFormData.ciPayRate, 4),
    //     salesConsultant: clonedFormData.salesConsultant,
    //     visaStatus: clonedFormData.visaStatus,
    //     workerType: clonedFormData.workerType,
    //     noticePeriod: clonedFormData.noticePeriod,
    //     exchangeRateForSalary: clonedFormData.exchangeRateForSalary,


    //     employeeWage: numberToMonetary(clonedFormData.employeeWage),
    //     hrFeeType: clonedFormData.hrFeeType,
    //     hrEffectiveDate: clonedFormData.hrEffectiveDate,
    //     hrPlacementFeeCurrency: clonedFormData.hrPlacementFeeCurrency,
    //     hrFeeFrequency: clonedFormData.hrFeeFrequency,
    //     hrPayComponentId: clonedFormData.hrPayComponentId,
    //     hrPlacementInvoiceFee: numberToMonetary(clonedFormData.hrPlacementInvoiceFee),


    //     ciPayComponentId: clonedFormData.ciPayComponentId,
    //     //this field is not in table
    //     ciEffectiveDay: clonedFormData.ciEffectiveDay,
    //     ciPayPeriodFrequency: clonedFormData.ciPayPeriodFrequency,
    //     ciOTpayRate: numberToMonetary(clonedFormData.ciOTpayRate, 4),
    //     ciOTbillRate: numberToMonetary(clonedFormData.ciOTbillRate, 4),
    //     ciSalaryOrRate: numberToMonetary(clonedFormData.ciSalaryOrRate),
    //     ciPaymentMethod: clonedFormData.ciPaymentMethod,
    //     ciOvertimeExempt: clonedFormData.ciOvertimeExempt,

    //     //this field is not in table
    //     retainerApplicable: clonedFormData.retainerApplicable,
    //     rtPayType: clonedFormData.rtPayType,
    //     rtSalaryOrRate: numberToMonetary(clonedFormData.rtSalaryOrRate),
    //     rtCurrency: clonedFormData.rtCurrency,
    //     rtPayComponentId: clonedFormData.rtPayComponentId,
    //     rtEffectiveDate: clonedFormData.rtEffectiveDate,
    //     rtReason: clonedFormData.rtReason,
    //     rtHours: clonedFormData.rtHours,
    //     rtPayRate: numberToMonetary(clonedFormData.rtPayRate, 4),
    //     rtCurrentRetainerFee: numberToMonetary(clonedFormData.rtCurrentRetainerFee),
    //     rtPreviousRetainerFee: numberToMonetary(clonedFormData.rtPreviousRetainerFee),

    //     hrDealType: clonedFormData.hrDealType,
    //     placementId: clonedFormData.placementId,
    //     talnetCandidateId: clonedFormData.candidateId,
    //     talnetClientContactId: clonedFormData.clientContactId,
    //     finmodCandidateId: clonedFormData.finmodCandidateId,

    //     leaveTypes: clonedFormData.leaveTypes,
    //     leavePolicies: clonedFormData.leavePolicies,
    //     allowanceTypes: getMonetaryAllowanceTypes(clonedFormData.allowanceTypes),
    //     dateOfBirth: clonedFormData.dateOfBirth,
    //     firstName: clonedFormData.firstName,
    //     lastName: clonedFormData.lastName,
    //     middleName: clonedFormData.middleName,
    //     maritalStatus: clonedFormData.maritalStatus,
    //     nickName: clonedFormData.nickName,
    //     pronoun: clonedFormData.pronoun,
    //     age: numberToMonetary(clonedFormData.age),


    //     requiredTwoFA: clonedFormData.requiredTwoFA,

    //     clientAdminFlag: clonedFormData.clientAdminFlag,
    //     clientUserFlag: clonedFormData.clientUserFlag,
    //     subscriberAdminFlag: clonedFormData.subscriberAdminFlag,
    //     subscriberUserFlag: clonedFormData.subscriberUserFlag,
    //     systemAdminFlag: clonedFormData.systemAdminFlag,
    //     workerFlag: clonedFormData.workerFlag,
    //     IsSaveWorker: clonedFormData.workerFlag,
    //     IsSaveClientUser: clonedFormData.clientUserFlag,
    //     IsSaveClientHR: clonedFormData.clientHRFlag,
    //     IsSaveClientBilling: clonedFormData.clientBillingFlag,
    //     IsSaveClientAdmin: clonedFormData.clientAdminFlag,
    //     clientHRFlag: clonedFormData.clientHRFlag,
    //     clientBillingFlag: clonedFormData.clientBillingFlag,
    //     subscriberHRFlag: clonedFormData.subscriberHRFlag,
    //     subscriberBillingFlag: clonedFormData.subscriberBillingFlag,
    //     subscriberApiIntegrationFlag: clonedFormData.subscriberApiIntegrationFlag,


    //     subdomainId: clonedFormData.subdomainId,
    //     userId: clonedFormData.userId,
    //     loginEmail: clonedFormData.loginEmail,

    //     HSAProvider: clonedFormData.HSAProvider,
    //     HSAAmount: numberToMonetary(clonedFormData.HSAAmount),
    //     HSACarryOver: numberToMonetary(clonedFormData.HSACarryOver),
    //     HSAWaitingPeriod: clonedFormData.HSAWaitingPeriod,

    //     planStartDate: clonedFormData.planStartDate,
    //     planNo: numberToMonetary(clonedFormData.planNo),
    //     vestingPeriod: clonedFormData.vestingPeriod,
    //     myMatch: numberToMonetary(clonedFormData.myMatch),
    //     myMatchStartDate: clonedFormData.myMatchStartDate,
    //     clientMatch: numberToMonetary(clonedFormData.clientMatch),
    //     clientMatchStartDate: clonedFormData.clientMatchStartDate,
    //     clientCap: numberToMonetary(clonedFormData.clientCap),


    //     emergencyFirstName: clonedFormData.firstName,
    //     emergencyLastName: clonedFormData.lastName,
    //     relationShip: clonedFormData.relationShip,
    //     emergencyHomePhone: clonedFormData.homePhone,
    //     emergencyEmail: clonedFormData.email,
    //     emergencyPhoneWork: clonedFormData.emergencyPhoneWork,

    //     contractorsCompanyName: clonedFormData.contractorsCompanyName,
    //     GSTOrHSTTax: clonedFormData.GSTOrHSTTax,
    //     GSTOrHSTTaxPercentage: clonedFormData.GSTOrHSTTaxPercentage,
    //     GSTOrHSTTaxAmount: clonedFormData.GSTOrHSTTaxAmount,
    //     taxNumber: clonedFormData.taxNumber,
    //     workersCompExemption: clonedFormData.workersCompExemption,
    //     workersWCBNumber: clonedFormData.workersWCBNumber,
    //     approverList: clonedFormData.approverList,
    //     approverInfos: clonedFormData.approverInfos,
    //     addressType: clonedFormData.addressType,
    //     employmentType: clonedFormData.employmentType

    //   }
    // }

    if(location.state && location.state.formData){

      if (Object.keys(location.state.formData).length > 0  && (location.state.formData.firstName.trim() || location.state.formData.lastName.trim())) {
        let userName = `${location.state.formData.firstName} ${location.state.formData.lastName} ${(location.state.formData.nickName ? `(${location.state.formData.nickName})` : '')}`;
        if (queryParams.role) {
          if (queryParams.role === "Client User" || queryParams.role === "Client HR" || queryParams.role === "Client Billing" || queryParams.role === "Client Admin") {
            props.changePageMeta(`${props.title} - ${location.state.formData.clientName} - ${userName}`)
          } else if (queryParams.role === "Subscriber User" || queryParams.role === "Subscriber HR" || queryParams.role === "Subscriber Billing" || queryParams.role === "Subscriber Admin") {
            props.changePageMeta(`${props.title} - ${location.state.formData.subscriberName} - ${userName}`)
          } else {
            props.changePageMeta(`${props.title} - ${userName}`)
          }
        } else {
          props.changePageMeta(`${props.title} - ${userName}`)
        }
      }
      if(location.state && location.state.formData){
        setFormData({ ...formData, isRequiredFlag: queryParams.role !== undefined ? (queryParams.role !== "Subscriber Admin" && queryParams.role !== "System Admin") : false, ...location.state.formData});
        formDataRef.current = location.state.formDataRef;
        sectionformDataRef.current = location.state.sectionformDataRef;
      }
    }
    else{
      setFormData({ ...formData, isRequiredFlag: queryParams.role !== undefined ? (queryParams.role !== "Subscriber Admin" && queryParams.role !== "System Admin") : false });

    }

  }, []);

  useEffect(() => {
    if (formData.subscriberId)
      props.get_client_list(formData.subscriberId);
      props.sub_get_currency_list(formData.subscriberId);
  }, [formData.subscriberId]);
  useEffect(() => {
    if (props.isSaveUserProfile && props.isSaveUserProfile !=false){

      if(Saved!==props.isSaveUserProfile){

        setSaved(props.isSaveUserProfile)
        //alert(count);
        history.push(currentLocation);
        //history.goBack();
      }

    }

  },[props.isSaveUserProfile]);
  useEffect(() => {
    if (cancelForm){

        history.push(currentLocation);

    }

  },[cancelForm]);

  useEffect(()=>{
    if(id === '0' && props.clientMarkups && !(location.state && location.state.formData)){
      setFormData({...formData, salesConsultantId: props.clientMarkups.salesConsultantId, hrConsultantId: props.clientMarkups.hrConsultantId})
    }
  },[props.clientMarkups])
  useEffect(()=>{
    if(id === '0' && props.contactList && props.contactList.length > 0 && !(location.state && location.state.formData)){
      setFormData({...formData, clientContactId:props.contactList[0].userId})
    }
  },[props.contactList])
  useEffect(()=>{
    if(id === '0' && formData.moduleName === 'Expense'){
      setFormData({...formData, clientBillingContactName:formData.approverName})
    }
  },[formData.moduleName, formData.approverId])


  useEffect(() => {
    if (formData.clientId && formData.clientId !== '' && formData.clientId != 0)
      {
        props.get_client_contact_list(formData.subscriberId,formData.clientId);
        props.get_client_markup_values(formData.clientId);
     }
  }, [formData.clientId]);

  useEffect(()=>{

    if(!(location.state && location.state.formData)){
      let weeklyHoursPerPay =  numberToMonetary(formData.hoursPerWeek,4);
      let biWeeklyHoursPerPay = numberToMonetary((formData.hoursPerWeek*52)/26,4);
      let semiMonthlyHoursPerPay = numberToMonetary((formData.hoursPerWeek*52)/24,4);
      let monthlyHoursPerPay =  numberToMonetary((formData.hoursPerWeek*52)/12,4);

      if(formData.compensationInfos[0]){
        formData.compensationInfos[0] = calculatePayRate(formData.compensationInfos[0],{weeklyHoursPerPay,biWeeklyHoursPerPay,semiMonthlyHoursPerPay,monthlyHoursPerPay})
      }
      setFormData({...formData, 'weeklyHoursPerPay':weeklyHoursPerPay, 'biWeeklyHoursPerPay':biWeeklyHoursPerPay, 'semiMonthlyHoursPerPay':semiMonthlyHoursPerPay, 'monthlyHoursPerPay':monthlyHoursPerPay})
    }


  },[formData.hoursPerWeek])

  const calculatePensionAmounts = ()=>{
    let pensionInfos = formData.pensionInfos;
    let compensationInfos = formData.compensationInfos;
    let placementInfos = formData.placementInfos;
    let payComponents = JSON.parse(JSON.stringify(props.payComponentList))

    let currentSections = JSON.parse(JSON.stringify(sectionformDataRef.current))

    if(!pensionInfos || pensionInfos.length === 0) return;
    if(!compensationInfos || compensationInfos.length === 0) return;
    if(!placementInfos || placementInfos.length === 0) return;
    for(let i = 0; i < pensionInfos.length; i++){

      let clientContributionStartDate = new Date(setDateForTable(pensionInfos[i].clientMatchStartDate)).setHours(0, 0, 0, 0);
    let workerContributionStartDate = new Date(setDateForTable(pensionInfos[i].myMatchStartDate)).setHours(0, 0, 0, 0);

       let workerContributionCompensations = formData.compensationInfos.filter(compensationInfo => {

        let effectiveDate = new Date(setDateForTable(compensationInfo.ciEffectiveDate)).setHours(0, 0, 0, 0);
        let selectedPayComponent = payComponents.filter(payComponent => Number(payComponent.refNum) === Number(compensationInfo.ciPayComponentId))

       // return (workerContributionStartDate >= effectiveDate && selectedPayComponent && selectedPayComponent && selectedPayComponent.length > 0 && selectedPayComponent[0].name === 'Hourly')
        return (workerContributionStartDate >= effectiveDate && selectedPayComponent && selectedPayComponent && selectedPayComponent.length > 0 && selectedPayComponent[0].name === 'Hourly')

       })

        let clientContributionCompensations = formData.compensationInfos.filter(compensationInfo => {
        let effectiveDate = new Date(setDateForTable(compensationInfo.ciEffectiveDate)).setHours(0, 0, 0, 0);
        let selectedPayComponent = payComponents.filter(payComponent => Number(payComponent.refNum) === Number(compensationInfo.ciPayComponentId))

        //return (clientContributionStartDate >= effectiveDate)
        return (clientContributionStartDate >= effectiveDate && selectedPayComponent && selectedPayComponent && selectedPayComponent.length > 0 && selectedPayComponent[0].name === 'Hourly')

       })


    let clientContribution = toNumber(pensionInfos[i].rpClientContribution, pensionInfos[i].rpClientContributionType === 'Flat' ? 4 : 2)
    let workerContribution = toNumber(pensionInfos[i].rpWorkerContribution, pensionInfos[i].rpWorkerContributionType === 'Flat' ? 4 : 2);
    let totalMarkupAmount = 0.00;
     let totalClientContributionAmount = 0.00;
     let clientContributionAmount = 1;
     let workerContributionAmount = 1
     let payrollTaxes = toNumber(pensionInfos[i].rpPayrollTaxes, 3)
     let pensionPlanMarkupAmount = pensionInfos[i].rpMarkupAmount !== '' ? Number(pensionInfos[i].rpMarkupAmount) : 0;
    let workerContributionType = pensionInfos[i].rpWorkerContributionType;
    let clientContributionType = pensionInfos[i].rpClientContributionType;

     if(compensationInfos.length !== 0){

     let frequency = 1;

     switch(pensionInfos[i].frequency){

         case 'Weekly' :
             frequency =  52;
             break;
             case 'Bi-weekly' :
                 frequency =  26;
                 break;
                 case 'Semi-monthly' :
                     frequency =  24;
                     break;
                 case 'Monthly' :
                     frequency =  12;
                      break;
     }

     if(clientContributionCompensations && clientContributionCompensations.length > 0){

        clientContributionAmount = clientContributionType === 'Percentage' ? ((Number(clientContributionCompensations[0].ciSalaryOrRate)/frequency)/100 * Number(clientContribution)) : Number(clientContribution);
     }

     //if Deal Type is flat fee
     //(Client Contribution Amount/100 * Client Contribution %)
     if(placementInfos[0].length !== 0 && placementInfos[0].hrDealType === 'Flat Fee')
     {
        totalMarkupAmount = (clientContributionAmount * Number(pensionPlanMarkupAmount/100));
     }

     //if Deal Type is uncapped
     //(Client Contribution Amount * (Payroll Taxes % + Retirement/Pension Plan Markup Amount)

     //totalMarkupAmount = - (Client Contribution Amount * (Payroll Taxes % + Retirement/Pension Plan Markup Amount);


     //((Salary or Rate/ No of Pay Cycles as per the Retirement/Pension Plan Frequency)/100 * Worker Contribution %)
     if(workerContributionCompensations && workerContributionCompensations.length > 0){
      workerContributionAmount = workerContributionType === 'Percentage' ? ((Number(workerContributionCompensations[0].ciSalaryOrRate)/frequency)/100 * workerContribution) : Number(workerContribution);
     }
     // calculate payroll tases

    if(placementInfos[0].hrDealType === 'Uncapped'){

        totalMarkupAmount = (clientContributionAmount * ((Number(payrollTaxes)/100) + (pensionPlanMarkupAmount/100)));

     }
       totalMarkupAmount = totalMarkupAmount.toFixed(2);
       clientContributionAmount = parseFloat(clientContributionAmount.toFixed(2));
       totalClientContributionAmount = (Number(totalMarkupAmount) + Number(clientContributionAmount));
       totalClientContributionAmount = parseFloat(totalClientContributionAmount.toFixed(2));

     }
      pensionInfos[i].rpTotalMarkupAmount = totalMarkupAmount;
      pensionInfos[i].rpTotalClientContribution = totalClientContributionAmount;
      pensionInfos[i].rpWorkerContributionAmount = parseFloat(workerContributionAmount.toFixed(2));
      pensionInfos[i].rpClientContributionAmount = clientContributionAmount;

  }


    setFormData({...formData, pensionInfos : [...pensionInfos]})

    sectionformDataRef.current = currentSections;

}

  useEffect(()=>{

    if(!(location.state && location.state.formData) &&  props.payComponentList &&  props.payComponentList.length > 0){
      calculatePensionAmounts();
    }


  },[formData.compensationInfos])

  const onChange = (e) => {

    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onChangeStandardInput = (name,value, isValid = true) => {
    setFormData({...formData, [name]: value, isValid:isValid});
  };

  const onDateChange = (name, value) => {

    setFormData({...formData, [name]: value});
  };


  const onMonetaryAmountChanged = (e, decimalPlaces) => {
   /* let maxFracDigits = (e.target.getAttribute("data-maxFracDigits") || 2);
    let value = e.target.value.replace(/^\D+/g, '');
    let values = value.split('.');
    if (values.length > 1)//if value contains fraction digits
      value = monetaryToNumber(values[0]).toLocaleString() + "." + values[1].replace(/\D/g, '').substr(0, maxFracDigits)
    else
      value = monetaryToNumber(value).toLocaleString()
    setFormData({
      ...formData,
      [e.target.name]: (value && value !== 'NaN') ? value : 0
    }); */


    const value = e.target.value;

    // Remove any non-digit or non-decimal point characters
    const sanitizedValue = value.replace(/[^0-9.]/g, '');

    // Split the input by the decimal point
    const parts = sanitizedValue.split('.');

    // Limit the decimal part to two digits
    if (parts.length > 1 && parts[1].length > 2) {
      parts[1] = parts[1].slice(0, decimalPlaces);
    }

    // Join the parts back together
    const formattedValue = parts.join('.');

    e.target.value = formattedValue;

    onChange(e);
  };




  useEffect(() => {

    if (formData.dateOfBirth){
      props.calculate_age(setInputDate(formData.dateOfBirth));


    }

  }, [formData.dateOfBirth]);
  useEffect(() => {
    if (props.calculateAge && location.state === undefined){

        setFormData({...formData, "age":props.calculateAge});
     }
    }, [props.calculateAge]);

    const checkSectionsEffectiveDate = ()=>{
      let placementInfos = formData.placementInfos;
    let compensationInfos = formData.compensationInfos;
    let benefitsInfos = formData.benefitsInfos;
    let pensionInfos = formData.pensionInfos;
    let HSAInfos = formData.HSAInfos;
    let EAPInfos = formData.EAPInfos;
    let retainerInfos = formData.retainerInfos;

    let oldHireDate = new Date(formDataRef.current.hireDate).setHours(0, 0, 0, 0);
    let newHireDate = new Date(formData.hireDate).setHours(0, 0, 0, 0);

    if(placementInfos && placementInfos.length > 0){
      let effectiveDate = new Date(setDateForTable(placementInfos[0].hrEffectiveDate)).setHours(0, 0, 0, 0);
      if(placementInfos.length === 1){
        placementInfos[0].hrEffectiveDate = setInputDate(formData.hireDate);
      }else
      if(effectiveDate === oldHireDate || effectiveDate <= newHireDate){
        placementInfos[0].hrEffectiveDate = setInputDate(formData.hireDate);
      }

    }
    if(compensationInfos && compensationInfos.length > 0){
      let groups = groupByElements(compensationInfos);

      Object. keys(groups).forEach(key =>{

        let compensation = groups[key];
        let selectedPayComponent = props.payComponentList && props.payComponentList.filter(payComponent => compensation.length > 0 && Number(payComponent.refNum) === Number(compensation[0].ciPayComponentId))
        let effectiveDate = new Date(setDateForTable(compensation[0].ciEffectiveDate)).setHours(0, 0, 0, 0);
      if(compensation.length === 1 && selectedPayComponent.length > 0 && selectedPayComponent[0].name === 'Hourly' && compensation[0].ciPayType === 'Salaried'){
        compensation[0].ciEffectiveDate = setInputDate(formData.hireDate);
      }
      else
      // if(effectiveDate === oldHireDate || effectiveDate <= newHireDate){
      //   compensation[0].ciEffectiveDate = setInputDate(formData.hireDate);
      // }

      if(effectiveDate <= newHireDate){
        compensation[0].ciEffectiveDate = setInputDate(formData.hireDate);
      }
      })


      // let effectiveDate = new Date(setDateForTable(compensationInfos[0].ciEffectiveDate)).setHours(0, 0, 0, 0);
      // if(compensationInfos.length === 1){
      //   compensationInfos[0].ciEffectiveDate = setInputDate(formData.hireDate);
      // }else
      // if(effectiveDate === oldHireDate || effectiveDate <= newHireDate){
      //   compensationInfos[0].ciEffectiveDate = setInputDate(formData.hireDate);
      // }

    }

    // if(benefitsInfos && benefitsInfos.length > 0){
    //   let benefitsStartDate = new Date(setDateForTable(benefitsInfos[0].benefitsStartDate)).setHours(0, 0, 0, 0);

    //   if(benefitsStartDate === oldHireDate || benefitsStartDate <= newHireDate){
    //     benefitsInfos[0].benefitsStartDate = setInputDate(formData.hireDate);
    //   }
    // }

    // if(pensionInfos && pensionInfos.length > 0){
    //   let workerContributionStartDate = new Date(setDateForTable(pensionInfos[0].myMatchStartDate)).setHours(0, 0, 0, 0);
    //   let clientContributionStartDate = new Date(setDateForTable(pensionInfos[0].clientMatchStartDate)).setHours(0, 0, 0, 0);

    //   if(workerContributionStartDate === oldHireDate || workerContributionStartDate <= newHireDate){
    //     pensionInfos[0].myMatchStartDate = setInputDate(formData.hireDate);
    //   }
    //   if(clientContributionStartDate === oldHireDate || clientContributionStartDate <= newHireDate){
    //     pensionInfos[0].clientMatchStartDate = setInputDate(formData.hireDate);
    //   }
    // }
    // if(HSAInfos && HSAInfos.length > 0){
    //   let HSAStartDate = new Date(setDateForTable(HSAInfos[0].HSAStartDate)).setHours(0, 0, 0, 0);

    //   if(HSAStartDate === oldHireDate || HSAStartDate <= newHireDate){
    //     HSAInfos[0].HSAStartDate = setInputDate(formData.hireDate);
    //   }
    // }
    // if(EAPInfos && EAPInfos.length > 0){
    //   let EAPStartDate = new Date(setDateForTable(EAPInfos[0].EAPStartDate)).setHours(0, 0, 0, 0);

    //   if(EAPStartDate === oldHireDate || EAPStartDate <= newHireDate){
    //     EAPInfos[0].EAPStartDate = setInputDate(formData.hireDate);
    //   }
    // }
    if(retainerInfos && retainerInfos.length > 0){
      let rtEffectiveDate = new Date(setDateForTable(retainerInfos[0].rtEffectiveDate)).setHours(0, 0, 0, 0);
      if(retainerInfos.length === 1){
        retainerInfos[0].rtEffectiveDate = setInputDate(formData.hireDate);
      }else
      if(rtEffectiveDate === oldHireDate || rtEffectiveDate <= newHireDate){
        retainerInfos[0].rtEffectiveDate = setInputDate(formData.hireDate);
      }
    }
    let empEffectiveDate = formData.empEffectiveDate === '' ? new Date() : formData.empEffectiveDate;
    let empEffectiveDateNumber = new Date(empEffectiveDate).setHours(0, 0, 0, 0);

    if(empEffectiveDateNumber === oldHireDate || empEffectiveDateNumber <= newHireDate || id === '0'){

      empEffectiveDate = formData.hireDate;
    }


    setFormData({...formData, empEffectiveDate: empEffectiveDate, placementInfos :  [...placementInfos], compensationInfos: [...compensationInfos], benefitsInfos: [...benefitsInfos], pensionInfos: [...pensionInfos], HSAInfos: [...HSAInfos], EAPInfos:[...EAPInfos], retainerInfos:[...retainerInfos]})
    }

  useEffect(() => {
     if (formData.hireDate){
      checkSectionsEffectiveDate();
      props.calculate_years_of_service(setInputDate(formData.hireDate));
     }
    }, [formData.hireDate]);

  useEffect(() => {
    if (props.calculateYearOfService && location.state === undefined){
      setFormData({...formData, "yearsOfService":props.calculateYearOfService});
      }
      else{
        location.state = undefined
      }

  }, [props.calculateYearOfService]);



  const onUnitFieldChange = (e, unit, decimalPlaces = 2) => {
    let inputValue = e.target.value
   let value = inputValue.replace(unit, '');
   value = toNumber(value, decimalPlaces)
   // let value = (inputValue.includes(unit)) ? fixedDecimalPlaces(inputValue.replace(unit, ''), 2) : inputValue.substring(0, inputValue.length - 1);
    if (!value)
      value = 0
    if (value < 101)
      setFormData({...formData, [e.target.name]: value});
  };


  const onToggle = (e) => {
    setFormData({ ...formData, [e.target.name]: !formData[e.target.name] });
  };

  const validateForm = (e)=>{
   let form = document.getElementById('userProfile');
    if(!form.checkValidity()){
      let invalidFields = form.querySelectorAll(':invalid');
      setFormData ({...formData, activeSection : invalidFields[0].closest('span').id});

    }
  }
  const submitForm = (e)=>{
    document.getElementById('save').click();

   }
  const onSave = (e) => {
    e.preventDefault();



    let updatedFormData = { ...formData };

    //let excludeList = ['benefitsInfos', 'pensionInfos', 'placementInfos', 'compensationInfos', 'benefitsApplicable', 'pensionApplicable', 'retainerInfos', 'retainerApplicable', 'EAPInfos', 'EAPApplicable', 'HSAInfos', 'HSAApplicable']
    let excludeList = ['benefitsInfos', 'pensionInfos', 'placementInfos', 'compensationInfos',   'retainerInfos', 'EAPInfos', 'HSAInfos']

    //filtering section's form data

    updatedFormData.placementInfos = formData.placementInfos.filter(p1 => {

      if (sectionformDataRef.current.placementInfos) {
      for (let p2 of sectionformDataRef.current.placementInfos) {

        let placement1 = JSON.stringify(p1);
        let placement2 = JSON.stringify(p2);

        if (placement1 === placement2) {
          return false;
        }
      }
    }
      return true;
    })

    updatedFormData.benefitsInfos = formData.benefitsInfos.filter(b1 => {

      if (sectionformDataRef.current.benefitsInfos) {
        for (let b2 of sectionformDataRef.current.benefitsInfos) {
          let benefits1 = JSON.stringify(b1);
          let benefits2 = JSON.stringify(b2);
          if (benefits1 === benefits2) {
            return false;
          }
        }
      }
      return true;
    })

    updatedFormData.compensationInfos = formData.compensationInfos.filter(c1 => {

      if (sectionformDataRef.current.compensationInfos) {
      for (let c2 of sectionformDataRef.current.compensationInfos) {
        let compensation1 = JSON.stringify(c1);
        let compensation2 = JSON.stringify(c2);
        if (compensation1 === compensation2) {
          return false;
        }
      }
    }
      return true;
    })

    updatedFormData.pensionInfos = formData.pensionInfos.filter(p1 => {

      if (sectionformDataRef.current.pensionInfos) {
      for (let p2 of sectionformDataRef.current.pensionInfos) {
        let pension1 = JSON.stringify(p1);
        let pension2 = JSON.stringify(p2);
        if (pension1 === pension2) {
          return false;
        }
      }
    }
      return true;
    })

    updatedFormData.retainerInfos = formData.retainerInfos.filter(r1 => {

      if (sectionformDataRef.current.retainerInfos) {
      for (let r2 of sectionformDataRef.current.retainerInfos) {
        let retainer1 = JSON.stringify(r1);
        let retainer2 = JSON.stringify(r2);
        if (retainer1 === retainer2) {
          return false;
        }
      }
    }
      return true;
    })

    updatedFormData.EAPInfos = formData.EAPInfos.filter(e1 => {

      if (sectionformDataRef.current.EAPInfos) {
      for (let e2 of sectionformDataRef.current.EAPInfos) {
        let EAP1 = JSON.stringify(e1);
        let EAP2 = JSON.stringify(e2);
        if (EAP1 === EAP2) {
          return false;
        }
      }
    }
      return true;
    })

    updatedFormData.HSAInfos = formData.HSAInfos.filter(h1 => {

      if (sectionformDataRef.current.EAPInfos) {
      for (let h2 of sectionformDataRef.current.HSAInfos) {
        let HSA1 = JSON.stringify(h1);
        let HSA2 = JSON.stringify(h2);
        if (HSA1 === HSA2) {
          return false;
        }
      }
    }
      return true;
    })
    updatedFormData.placementInfos = updatedFormData.placementInfos.length > 0 ? formData.placementInfos : null;
    updatedFormData.compensationInfos = updatedFormData.compensationInfos.length > 0 ? formData.compensationInfos : null;
    updatedFormData.benefitsInfos = updatedFormData.benefitsInfos.length > 0 ? formData.benefitsInfos : null;
    updatedFormData.pensionInfos = updatedFormData.pensionInfos.length > 0 ? formData.pensionInfos : null;
    updatedFormData.HSAInfos = updatedFormData.HSAInfos.length > 0 ? formData.HSAInfos : null;
    updatedFormData.EAPInfos = updatedFormData.EAPInfos.length > 0 ? formData.EAPInfos : null;
    updatedFormData.retainerInfos = updatedFormData.retainerInfos.length > 0 ? formData.retainerInfos : null;

    Object.keys(formData).forEach(key => {
      if (JSON.stringify(updatedFormData[key]) === JSON.stringify(formDataRef.current[key]) && !excludeList.includes(key))
        delete updatedFormData[key]
    });

    console.log(JSON.stringify(updatedFormData["allowanceTypes"]), JSON.stringify(formDataRef.current["allowanceTypes"]))
    Object.keys(formData).forEach(key => {
      if (JSON.stringify(updatedFormData[key]) === JSON.stringify(formDataRef.current[key]) && !excludeList.includes(key))
        delete updatedFormData[key]
    });
    updatedFormData.userId=formData.userId

    if ('dateOfBirth' in updatedFormData) updatedFormData.dob = setInputDate(updatedFormData.dateOfBirth);
    if ('hireDate' in updatedFormData) updatedFormData.hireDate = setInputDate(updatedFormData.hireDate);
    if ('endDate' in updatedFormData) updatedFormData.endDate = setInputDate(updatedFormData.endDate);
    if ('benefitsStartDate' in updatedFormData) updatedFormData.benefitsStartDate = setInputDate(updatedFormData.benefitsStartDate);
    if ('benefitsEndDate' in updatedFormData) updatedFormData.benefitsEndDate = setInputDate(updatedFormData.benefitsEndDate);
    if ('leaveTypes' in updatedFormData) updatedFormData.leaveTypes = setLeaveTypeString(updatedFormData.leaveTypes);
    if ('leavePolicies' in updatedFormData) updatedFormData.leavePolicies = setLeavePolicyString(updatedFormData.leavePolicies);
    if ('allowanceTypes' in updatedFormData) updatedFormData.allowanceTypes = setAllowanceTypeString(updatedFormData.allowanceTypes);
    if ('planStartDate' in updatedFormData) updatedFormData.planStartDate = setInputDate(updatedFormData.planStartDate);
    if ('myMatchStartDate' in updatedFormData) updatedFormData.myMatchStartDate = setInputDate(updatedFormData.myMatchStartDate);
    if ('clientMatchStartDate' in updatedFormData) updatedFormData.clientMatchStartDate = setInputDate(updatedFormData.clientMatchStartDate);
    if ('compensation' in updatedFormData) updatedFormData.compensation = monetaryToNumber(updatedFormData.compensation);
    if ('HSAAmount' in updatedFormData) updatedFormData.HSAAmount = monetaryToNumber(updatedFormData.HSAAmount);
    if ('HSACarryOver' in updatedFormData) updatedFormData.HSACarryOver = monetaryToNumber(updatedFormData.HSACarryOver);
    if ('hrPlacementFee' in updatedFormData) updatedFormData.hrPlacementFee = monetaryToNumber(updatedFormData.hrPlacementFee);
    if ('ciBillRate' in updatedFormData) updatedFormData.ciBillRate = monetaryToNumber(updatedFormData.ciBillRate);
    if ('ciPayRate' in updatedFormData) updatedFormData.ciPayRate = monetaryToNumber(updatedFormData.ciPayRate);
    if ('ciSalaryOrRate' in updatedFormData) updatedFormData.ciSalaryOrRate = monetaryToNumber(updatedFormData.ciSalaryOrRate);
    if ('clientContribution' in updatedFormData) updatedFormData.clientContribution = monetaryToNumber(updatedFormData.clientContribution);
    if ('contribution' in updatedFormData) updatedFormData.contribution = monetaryToNumber(updatedFormData.contribution);
    if ('clientCap' in updatedFormData) updatedFormData.clientCap = monetaryToNumber(updatedFormData.clientCap);
    if ('rtEffectiveDate' in updatedFormData) updatedFormData.rtEffectiveDate = setInputDate(updatedFormData.rtEffectiveDate);
    if ('EAPStartDate' in updatedFormData) updatedFormData.EAPStartDate = setInputDate(updatedFormData.EAPStartDate);
    if ('EAPEndDate' in updatedFormData) updatedFormData.EAPEndDate = setInputDate(updatedFormData.EAPEndDate);
    if ('ciEffectiveDay' in updatedFormData) updatedFormData.ciEffectiveDay = setInputDate(updatedFormData.ciEffectiveDay);
    if ('hrEffectiveDate' in updatedFormData) updatedFormData.hrEffectiveDate = setInputDate(updatedFormData.hrEffectiveDate);
    if ('rpWorkerContributionEndDate' in updatedFormData) updatedFormData.rpWorkerContributionEndDate = setInputDate(updatedFormData.rpWorkerContributionEndDate);
    if ('rpClientContributionEndDate' in updatedFormData) updatedFormData.rpClientContributionEndDate = setInputDate(updatedFormData.rpClientContributionEndDate);
    if ('HSAEndDate' in updatedFormData) updatedFormData.HSAEndDate = setInputDate(updatedFormData.HSAEndDate);
    if ('empEffectiveDate' in updatedFormData) updatedFormData.empEffectiveDate = setInputDate(updatedFormData.empEffectiveDate);

    if (updatedFormData.approverInfos && updatedFormData.approverInfos.length > 0) {
      if ('approverId' in updatedFormData) updatedFormData.approverList = setApproverInfoString(updatedFormData.approverInfos);

    }



      if(e.nativeEvent.submitter.id==="update")
        updatedFormData.effectiveDate= setInputDate(formData.effectiveDate)

var currentdate = new Date()
var endDate = new Date(formData.endDate)
currentdate.setHours(0,0,0,0);
endDate.setHours(0,0,0,0)
//if((formData.subStatus==='Active' && endDate <  currentdate)){
 // updatedFormData.subStatus="Inactive";
  //props.setAlert("End Date is in the past","danger")
//}


    if(!roleSelected){
      props.setAlert("Please select at least one role","danger")
    // }else if(!formData.isRequiredFlag && !(formData.phoneHome || formData.phoneWork || formData.phoneMobile)){
    //   props.setAlert("Please add at least one phone number in contact section","danger")
    // In future if needed phone no validation then remove comment
   }
  //  else if(!(formData.emailWork || formData.emailPersonal)){
  //     props.setAlert("Please add at least one email address in contact section","danger")
  //   }
    else if((formData.subStatus==='Inactive' && endDate> currentdate)){
      props.setAlert("End Date is in the future. If you wish to terminate early end date needs to be Updated","danger")
    }else if (formData.workerFlag && (!formData.approverInfos))
    {
          props.setAlert("Approval settings is not defined","danger");
    }
    else if((!formData.subscriberUserFlag && !formData.subscriberHRFlag && ! formData.subscriberBillingFlag && !formData.subscriberAdminFlag && formData.subStatus==='Internal')){
      props.setAlert(" Internal should be used only for Subscriber Roles","danger")
    }
    else if([formData.clientUserFlag,formData.clientHRFlag,formData.clientBillingFlag,formData.clientAdminFlag].filter(role=>role).length>1){
      props.setAlert("User can not have multiple client roles. Please select only one client role","danger")
    }else if([formData.subscriberUserFlag,formData.subscriberHRFlag,formData.subscriberBillingFlag,formData.subscriberAdminFlag].filter(role=>role).length>1){
      props.setAlert("User can not have multiple subscriber roles. Please select only one subscriber role","danger")
    }
    else{
      if(e.nativeEvent.submitter.id==="update"){
        props.update_user_profile(updatedFormData);
      }
      else{
        props.save_user_profile(updatedFormData);
      }


    }
  };

  let messageCategoryRole = "";
  if (queryParams.role && (queryParams.role.startsWith("Worker") || queryParams.role.startsWith("Client")))
    messageCategoryRole = queryParams.role
  else if (formData.workerFlag)
    messageCategoryRole = "Worker"
  else if (formData.clientAdminFlag || formData.clientUserFlag || formData.clientHRFlag || formData.clientBillingFlag)
    messageCategoryRole = "Client User"

  return (
    <>
    <UnloadPageConfirm confirmAlert={pageUnloadConfirm}
                    setConfirmAlert={setPageUnloadConfirm}/>
      <Container fluid className={`main-content-container px-0 ${containerColorClass}`}>
        <Form onSubmit={onSave} id="userProfile">
          <div
            className='profileNav offset-md-3 col-lg-10 offset-lg-2 row px-md-4 pt-2'>
            <Col sm={12} className='d-md-none'>
              <Row noGutters className="page-header pt-4 pb-2">
                <PageTitle title="Client Profile" subtitle="Manage" md="12"
                           className="ml-sm-auto mr-sm-auto"/>
              </Row>
            </Col>
            {editPermissions > 0 &&
            <Col sm={12} md={4} lg={3} xl={2}
                 className='d-flex justify-content-center justify-content-md-end align-items-center mb-2 mb-md-0 px-2 order-md-1'>
              <Button className="m-2" size='sm' onClick={(e)=> validateForm()}
                      theme="accent" type="submit" id={"save"} disabled={!formData.isValid || isDisabledSaveButton}>Save</Button>
              <Button className="m-2" size='sm'
                      theme="accent"
                      onClick={e => history.push('/sub-search-users')}>Cancel</Button>
            </Col>
            }
            <TabBar formData={formData} role = {queryParams.role} currentSection={queryParams.section} setAlert={props.setAlert} jumpTo={queryParams.subSection} setContainerColorClass={setContainerColorClass}/>
          </div>
          {confirmAlert.visible &&
          <Alert theme="light" style={{marginTop: "60px"}}
                 className="text-dark fixed-top offset-md-3 offset-lg-2 mb-0 alert alert-danger fade show">
            {confirmAlert.confirmMsg}
            <Button className="mx-2 py-2 btn-normal" size="sm"
                    theme="primary" onClick={confirmAlert.onConfirm}>Yes</Button>
            <Button className="mx-2 py-2" size="sm"
                    theme="white" onClick={cancelConfirmAlert}>Cancel</Button>
          </Alert>}

          {(props.alerts && props.alerts.length > 0) &&
          <Alert theme={props.alerts[0].alertType} fade show
                 className="fixed-top offset-md-3 offset-lg-2 mb-0"
                 style={{top: "60px"}}>
            {props.alerts[0].msg}
          </Alert>}
          <Row className='px-4 m-0 manage-user-profile main'>
            <span id="settings" className='show w-100'>
            <AccountSettings
              {...formData}
              employmentStatusList={props.employmentStatusList}
              hrConsultantList={props.hrConsultantList}
              salesConsultantList={props.salesConsultantList}
              subStatusList={props.subStatusList}
              user_disable_2fa={props.user_disable_2fa}
              set_user_account_status={props.set_user_account_status}
              set_user_password={props.set_user_password}
              subscriberList={props.subscriberList}
              confirmAlert={confirmAlert}
              setConfirmAlert={setConfirmAlert}
              setAlert={props.setAlert}
              messageCategoryRole={messageCategoryRole}
              onChange={onChange}
            />

            <Roles
              {...formData}
              isSystemAdmin={props.user.userRoles.filter(role=>role.role==="System Admin").length}
              onToggle={onToggle}
            />
            {formData.workerFlag && (<ApproverSettings
            formData={formData}
            setFormData={setFormData}
            moduleName = {props.moduleName}
            appModules={props.appModules}
            approverId = {props.approverId}
            approverName={props.approverName}
            contactList={props.contactList}>
            </ApproverSettings>)}
          </span>


            <span id="personal" className='w-100 hidden'>
            <PersonalInfo
              {...formData}
              pronounList={props.pronounList}
              maritalStatusList={props.maritalStatusList}
              onDateChange={onDateChange}
              onChange={onChange}
            />
            <ContactInfo
              {...formData}
              countryList={props.countryList}
              regionList={props.regionList}
              cityList={props.cityList}
              preferredEmailList={props.preferredEmailList}
              get_region_list={props.get_region_list}
              get_city_list={props.get_city_list}
              onChange={onChange}
              onChangeStandardInput={onChangeStandardInput}
            />

            {(formData.workerFlag && queryParams.role !== "Client User" && queryParams.role !== "Client Billing" && queryParams.role !== "Subscriber User") &&
            <EmergencyContactInfo
              {...formData}
              onDateChange={onDateChange}
              onChange={onChange}
              onChangeStandardInput={onChangeStandardInput}
            />}
          </span>
          <span id="placement" className='w-100 hidden'>
            <PlacementData
              formData={formData}
              workerTypes={props.workerTypes}
              currency={formData.currency}
              currencyList={props.currencyList}
              payComponentList={props.payComponentList}
              onMonetaryAmountChanged={onMonetaryAmountChanged}
              onChange={onChange}
              onDateChange={onDateChange}
              userProfile={props.userProfile}
              role={queryParams.role}
              formDataRef={formDataRef.current}
              sectionformDataRef={sectionformDataRef.current}
            />
            {(queryParams.role !== "Client User") && formData.workerFlag && formData.placementInfos.length > 0 && ['Incorporated', 'Sole Proprietor'].includes(formData.placementInfos[0].workerType) &&
            <IndependentContractorInfo
              {...formData}
              onDateChange={onDateChange}
              onChange={onChange}
              onUnitFieldChange={onUnitFieldChange}
            />}

          </span>
          <span id="employment" className=' w-100 hidden'>
            <EmploymentInfo
              {...formData}
              clientList={(formData.subscriberId ? props.clientList : [])}
              contactList={props.contactList}
              countryList={props.countryList}
              regionList={props.regionList}
              get_region_list={props.get_region_list}
              onDateChange={onDateChange}

              onChange={onChange}
              hrConsultantList={props.hrConsultantList}
              salesConsultantList={props.salesConsultantList}
              subStatusList={props.subStatusList}
              onMonetaryAmountChanged={onMonetaryAmountChanged}
              setAlert={props.setAlert}
            />
           { formData.workerFlag && <CompensationInfo
              formData={formData}
              onChange={onChange}
              payComponentList={props.payComponentList}
              compensationTypes={props.compensationTypes}
              currencyList={props.currencyList}
              onMonetaryAmountChanged={onMonetaryAmountChanged}
              onDateChange={onDateChange}
              userProfile={props.userProfile}
              role={queryParams.role}
              formDataRef={formDataRef.current}
              sectionformDataRef={sectionformDataRef.current}
            />}
            </span>

            <span id="benefits" className='w-100 hidden'>

            <BenefitsInfo
              formData={formData}
              benefitsCoverageList={props.benefitsCoverageList}
              benefitsClassList={props.benefitsClassList}
              payComponentList={props.payComponentList}
              currencyList={props.currencyList}
              onMonetaryAmountChanged={onMonetaryAmountChanged}
              onDateChange={onDateChange}
              onChange={onChange}
              userProfile={props.userProfile}
              role={queryParams.role}
              formDataRef={formDataRef.current}
              sectionformDataRef={sectionformDataRef.current}
              clientMarkups={props.clientMarkups}
            />

              <PensionPlan
              formData={formData}
              pensionPlanList={props.pensionPlanList}
              pensionPlanFrequencyList={props.pensionPlanFrequencyList}
              payComponentList={props.payComponentList}
              currencyList={props.currencyList}
              onDateChange={onDateChange}
              onUnitFieldChange={onUnitFieldChange}
              userProfile={props.userProfile}
              role={queryParams.role}
              formDataRef={formDataRef.current}
              sectionformDataRef={sectionformDataRef.current}
              clientMarkups={props.clientMarkups}
              onMonetaryAmountChanged={onMonetaryAmountChanged}
              onChange={onChange}
            />

            <PerksInfo
              formData={formData}
              perksFrequencyList={props.perksFrequencyList}
              currencyList={props.currencyList}
              payComponentList={props.payComponentList}
              userProfile={props.userProfile}
              role={queryParams.role}
              formDataRef={formDataRef.current}
              sectionformDataRef={sectionformDataRef.current}
              clientMarkups={props.clientMarkups}
            />

            <EapInfo
              formData={formData}
              onChange={onChange}
              onDateChange={onDateChange}
              currencyList={props.currencyList}
              payComponentList={props.payComponentList}
              onMonetaryAmountChanged={onMonetaryAmountChanged}
              userProfile={props.userProfile}
              role={queryParams.role}
              formDataRef={formDataRef.current}
              sectionformDataRef={sectionformDataRef.current}
              clientMarkups={props.clientMarkups}
            />

            </span>
            <span id="retainer" className='w-100 hidden'>
            <RetainerInfo
              formData={formData}
              onChange={onChange}
              onDateChange={onDateChange}
              currencyList={props.currencyList}
              payComponentList={props.payComponentList}
              onMonetaryAmountChanged={onMonetaryAmountChanged}
              userProfile={props.userProfile}
              role={queryParams.role}
              formDataRef={formDataRef.current}
              sectionformDataRef={sectionformDataRef.current}
              clientMarkups={props.clientMarkups}
            />
            </span>
            <span id='leaves' className='w-100 hidden'>
            <Leaves
              formData={formData}
              setFormData={setFormData}
            />

            </span>

            <span id='integration' className='w-100 hidden'>
            <Integration
              {...formData}
              dealTypes={props.dealTypes}
              onChange={onChange}
            />

            </span>
            <span id='notes' className='w-100 hidden' >
              <PensionNotes
              formData={formData}
              pensionNoteList={props.pensionNoteList}
              formDataRef={formDataRef.current}
              sectionformDataRef={sectionformDataRef.current}
            />
            <SubscriberNotes
              formData={formData}
              subscriberNoteList={props.subscriberNoteList}
              formDataRef={formDataRef.current}
              sectionformDataRef={sectionformDataRef.current}
            />

            <SeveranceNotes
              formData={formData}
              severanceNoteList={props.severanceNoteList}
              formDataRef={formDataRef.current}
              sectionformDataRef={sectionformDataRef.current}
            />
              </span>
              <span id='documents' className='w-100 hidden bg-white'>
                {/*<Documents/>*/}
                <ListGroup className='text-center' flush>
                                <ListGroupItem className="border-card p-3">
                                <h3 className='font-weight-bold'>Stay Tuned</h3>
                                <h5 className='font-weight-bold'>We are going to launch documents page very soon.</h5>
                                <Button onClick={()=> history.push('/sub-search-users') }>Go Back</Button>
                                </ListGroupItem>
               </ListGroup>
              </span>
            <span id='userJourney' className='w-100 hidden'>
            <UserJourney
              currency={formData.currency}
              userJourney={props.userJourney}
              userId={formData.userId}
              confirmAlert={confirmAlert}
              setConfirmAlert={setConfirmAlert}
              delete_user_journey_for_sub={props.delete_user_journey_for_sub}
            />
            </span>
            <span className='hidden'>


            <Allowances
              formData={formData}
              setFormData={setFormData}
            />


</span>

            {/*<div className='d-flex w-100 justify-content-center'>
              <div className="form-group">
                <div className='d-flex align-items-center'>
                  <label className='mb-0 text-nowrap px-2'>Effective Date</label>

                  <DatePicker
                    id='effectiveDate'
                    name='effectiveDate'
                    dateFormat='yyyy-MM-dd'
                    placeholderText="YYYY-MM-DD"
                    value={formData.effectiveDate}
                    selected={formData.effectiveDate}
                    onChange={(date) =>
                      onDateChange("effectiveDate", date)
                    }
                  />
                  <Button className="mx-2 my-2" size='sm'
                          theme="accent" type={"submit"} id="update">Update</Button>
                </div>
              </div>
                  </div>*/}


          </Row>
        </Form>
      </Container>
    </>


  );
};

ManageUserProfile.propTypes = {
  userProfile: PropTypes.object.isRequired,
  pronounList: PropTypes.array.isRequired,
  maritalStatusList: PropTypes.array.isRequired,
  countryList: PropTypes.array.isRequired,
  regionList: PropTypes.array.isRequired,
  cityList: PropTypes.array.isRequired,
  preferredEmailList: PropTypes.array.isRequired,
  perksFrequencyList: PropTypes.array.isRequired,
  benefitsCoverageList: PropTypes.array.isRequired,
  benefitsClassList: PropTypes.array.isRequired,
  employmentStatusList: PropTypes.array.isRequired,
  hrConsultantList: PropTypes.array.isRequired,
  salesConsultantList: PropTypes.array.isRequired,
  get_user_profile: PropTypes.func.isRequired,
  get_pronoun_list: PropTypes.func.isRequired,
  get_marital_status_list: PropTypes.func.isRequired,
  get_country_list: PropTypes.func.isRequired,
  get_region_list: PropTypes.func.isRequired,
  get_city_list: PropTypes.func.isRequired,
  get_preferred_email_list: PropTypes.func.isRequired,
  get_perks_frequency_list: PropTypes.func.isRequired,
  get_benefits_coverage_list: PropTypes.func.isRequired,
  get_benefits_class_list: PropTypes.func.isRequired,
  get_employment_status_list: PropTypes.func.isRequired,
  user: PropTypes.object,
  userJourney: PropTypes.array.isRequired,
  //city: PropTypes.object.isRequired,
  compensationTypes: PropTypes.array.isRequired,
  workerTypes: PropTypes.array.isRequired,
  visaStatusList: PropTypes.array.isRequired,
  dealTypes: PropTypes.array.isRequired,
  employmentSubstatusList: PropTypes.array.isRequired,
  currencyList: PropTypes.array.isRequired,
  get_client_list: PropTypes.func.isRequired,
  get_client_contact_list: PropTypes.func.isRequired,
  get_pension_plan_frequency_list: PropTypes.func.isRequired,
  get_pension_plan_list: PropTypes.func.isRequired,
  calculate_years_of_service: PropTypes.func.isRequired,
  calculate_age: PropTypes.func.isRequired,
  pensionPlanFrequencyList: PropTypes.array.isRequired,
  pensionPlanList: PropTypes.array.isRequired,
  clientList: PropTypes.array.isRequired,
  subscriberList: PropTypes.array.isRequired,
  contactList: PropTypes.array.isRequired,
  get_subscriber_list: PropTypes.func.isRequired,
  get_salesconsultant: PropTypes.func.isRequired,
  get_hr_Consultant: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  success: PropTypes.any,
  reset: PropTypes.func.isRequired,
  get_app_modules: PropTypes.func.isRequired,
  appModules: PropTypes.array.isRequired,
  approverInfos: PropTypes.array.isRequired,
  isSaveUserProfile: PropTypes.string.isRequired,
  payComponentList: PropTypes.array.isRequired,
  calculateYearOfService: PropTypes.array.isRequired,
  calculateAge: PropTypes.number.isRequired,
  defaultValues : PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  userProfile: state.user.userProfile,
  isSaveUserProfile: state.user.isSaveUserProfile,
  pronounList: state.user.pronounList,
  maritalStatusList: state.user.maritalStatusList,
  countryList: state.user.countryList,
  regionList: state.user.regionList,
  cityList: state.user.cityList,
  preferredEmailList: state.user.preferredEmailList,
  perksFrequencyList: state.user.perksFrequencyList,
  benefitsCoverageList: state.user.benefitsCoverageList,
  benefitsClassList: state.user.benefitsClassList,
  employmentStatusList: state.user.employmentStatusList,
  user: state.user.user,
  userJourney: state.user.userJourneyForSub,
  city: state.user.city,
  compensationTypes: state.user.compensationTypes,
  workerTypes: state.user.workerTypes,
  visaStatusList: state.user.visaStatusList,
  dealTypes: state.user.dealTypes,
  subStatusList: state.user.employmentSubstatusList,
  currencyList: state.user.currencyList,
  pensionPlanFrequencyList: state.user.pensionPlanFrequencyList,
  pensionPlanList: state.user.pensionPlanList,
  clientList: state.user.clientList,
  subscriberList: state.user.subscriberList,
  salesConsultantList: state.user.salesConsultantList,
  hrConsultantList: state.user.hrConsultantList,
  contactList: state.user.contactList,
  message: state.user.message,
  success: state.user.success,

  pensionNoteList: state.subscriberUsers.pensionNoteList,
  subscriberNoteList: state.subscriberUsers.subscriberNoteList,
  severanceNoteList: state.subscriberUsers.severanceNoteList,
  alerts: state.alert,
  appModules:state.user.appModules,
  approverId:state.user.approverId,
  moduleName:state.user.moduleName,
  approverName:state.user.approverName,
  payComponentList: state.user.payComponentList,
  calculateYearOfService: state.user.calculateYearOfService,
  calculateAge: state.user.calculateAge,
  defaultValues: state.user.defaultValues,
  clientMarkups:state.user.clientMarkups
})
export default connect(mapStateToProps, {
  set_user_password,
  delete_user_journey_for_sub,
  user_disable_2fa,
  get_user_profile,
  clear_user_profile,
  get_user_journey_for_sub,
  save_user_profile,
  update_user_profile,
  set_user_account_status,
  get_employment_status_list,
  get_employment_sub_status_list,
  get_subscriber_list,
  get_salesconsultant,
  get_hr_Consultant,
  sub_get_currency_list,
  get_country_list,
  get_region_list,
  get_city_list,
  get_city_details,
  get_compensation_types,
  get_worker_types,
  get_visa_status_types,
  get_deal_types_types,
  get_client_list,
  get_client_contact_list,
  get_benefits_coverage_list,
  get_perks_frequency_list,
  get_pension_plan_frequency_list,
  get_pension_plan_list,
  get_preferred_email_list,
  get_benefits_class_list,
  get_marital_status_list,
  get_pronoun_list,
  get_pension_note_list,
  get_subscriber_note_list,
  get_severance_note_list,
  clear_note_lists,
  setAlert,
  reset,
  get_app_modules,
  get_pay_component_list,
  calculate_years_of_service,
  calculate_age,
  get_user_default_values,
  get_client_markup_values,
  update_isSave_user_profile
})(ManageUserProfile);
