import * as React from 'react';
import {
  Card,
  CardHeader,
  Col, Form,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {formatPhoneNumber} from "../../../utils/general";

export const ContactInfo = (props) => {
  const {viewProfile} = props
  return (
    <Card id='contact' small className="details-card mb-5 w-100">
      {viewProfile?
      <CardHeader className="border-bottom">
        <h4 className="m-0 section-title">Contact Information</h4>
      </CardHeader>:
      <>
      <span className="iconBox"><i className="fas fa-id-badge iconStyle"></i></span>
      <h4 className="m-0 section-title headerStyle">Contact Information</h4>
      </>
      }
      <ListGroup flush>
        <ListGroupItem className={viewProfile?"p-3":"p-3 mt-3"}>
          <Row>
            <Col>
              <Form>
                <Row form>

                  <Col lg="6" className="form-group p-2 mb-0">
                    <label>Address Line 1</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{props.addressLine1}</span>
                  </Col>

                  <Col lg="6" className="form-group p-2 mb-0">
                    <label>Address Line 2</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{props.addressLine2}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Country</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{props.country}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>State/Province</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{props.state}</span>
                  </Col>


                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>City</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{props.city}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>ZIP/Postal Code</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{props.zip}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Phone - Home</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{formatPhoneNumber(props.phoneHome)}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Phone - Work</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{formatPhoneNumber(props.phoneWork)}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Phone - Work Ext</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{props.phoneWorkExt}</span>
                  </Col>


                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Phone - Mobile</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{formatPhoneNumber(props.phoneMobile)}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Email - Personal</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{props.emailPersonal}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Email - Work</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{props.emailWork}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2 mb-0">
                    <label>Linkedin</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{props.linkedIn}</span>
                  </Col>

                </Row>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>

  )
}

ContactInfo.defaultTypes={
  addressLine1:'',
  addressLine2:'',
  cityId:'',
  city:'',
  country:'',
  emailPersonal:'',
  emailWork:'',
  linkedIn:'',
  phoneHome:'',
  phoneMobile:'',
  phoneWork:'',
  regionName:'',
  website:'',
  zip:'',
}


export default ContactInfo;
