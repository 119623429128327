import React from "react";
import {Nav} from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import {Store} from "../../../flux";
import {connect} from "react-redux";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems()
    });
  }

  render() {
    const {navItems: items} = this.state;
   
    const rolesIncludes = (roles) => {
      let userRoles = this.props.roles.map(a => a.role);
     
    
     
      return roles.some(item => this.props.user.selectedrole.includes(item))
    }

    return (
      <div className="nav-wrapper">
        {items.map((nav, idx) => {
          if (rolesIncludes(nav.roles)) {
            return (
              <div key={idx}>
                <h6
                  className={`main-sidebar__nav-title ${(nav.title) ? '' : 'd-none'}`}>{nav.title}</h6>
                {typeof nav.items !== "undefined" && nav.items.length && (
                  <Nav className="nav--no-borders flex-column">
                    {nav.items.filter(item=>(!item.roles || rolesIncludes(item.roles))).filter(item=> {
                      let permissions=this.props.roles.find(role=>nav.roles.includes(role.role)).permissions
                      return !item.permission || permissions.includes(item.permission)
                    }).map((item, idx) => {
                      return <SidebarNavItem key={idx} item={item}/>
                    })}
                  </Nav>
                )}
              </div>
            )
          }

        })}
      </div>
    )
  }
}

const mapStatesToProps = (state) => ({
  roles: state.user.user.userRoles,
  user:state.user.user
})

export default connect(mapStatesToProps, null)(SidebarNavItems);
