import React, {useEffect, useState} from 'react';
import {
  Button,
  Card,
  Col, Container,
  Form, FormCheckbox, FormInput,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {setDateForTable} from "../../utils/date";
import {
  getValueInNativeDataType,
  monetaryToNumber,
  numberToMonetary
} from "../../utils/general";
import {connect} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import PropTypes from "prop-types";
import {
  clear_sub_mileage_item, get_sub_mileage_item,
  reject_sub_mileage_item, review_sub_mileage_item
} from "../../redux/actions/subscriber/subExpenses";
import InfoTooltip from "../../components/common/InfoTooltip";
import StandardFormInput from "../../components/common/StandardFormInput";
import {STANDARD_FORMATS} from "../../utils/constants";

function ApproveMileageItem(props) {

  let {refNum, sheetRefNumber} = useParams();
  let mileageItem=(props.mileageItemList)?props.mileageItemList.find(item=>item.refNum===parseInt(refNum)):props.subMileageItem
  //added Worker Name in the page title once we get response from backend
  useEffect(() => {
    if (props.workerName && props.workerName.trim()) {
      props.changePageMeta(`${props.title} - ${props.workerName}`)
    }
  }, [mileageItem]);

  useEffect(() => {
    if (
      refNum !== null &&
      refNum !== '' &&
      refNum !== '0' &&
      refNum !== 0
    ) {
      props.get_sub_mileage_item(refNum);
    }

    return () => {
      props.clear_sub_mileage_item();
    }
  }, [refNum]);

  const history = useHistory();
  const [formData, setFormData] = useState({
    distanceDriven: parseFloat(mileageItem.distanceDriven).toFixed(2),
    appliedRate: parseFloat(mileageItem.appliedRate).toFixed(2),
    amount: parseFloat(mileageItem.amount).toFixed(2),
    taxable: Boolean(mileageItem.taxable),
    distanceUnit:mileageItem.distanceUnit,
  });

  useEffect(() => {
    if (!props.mileageItemList){
      if (Object.keys(props.subMileageItem).length !== 0) {
        setFormData({
          ...formData,
          distanceDriven: parseFloat(props.subMileageItem.distanceDriven).toFixed(2),
          appliedRate: parseFloat(props.subMileageItem.appliedRate).toFixed(2),
          amount: parseFloat(props.subMileageItem.amount).toFixed(2),
          taxable: Boolean(props.subMileageItem.taxable),
          taxable:props.subMileageItem.taxable,
        });
      }
    }
  }, [props.subMileageItem]);


  useEffect(() => {
    let amount = monetaryToNumber(formData.distanceDriven) * monetaryToNumber(formData.appliedRate);
    setFormData({
      ...formData,
      amount
    })
  }, [formData.distanceDriven, formData.appliedRate]);


  let onChange = (e) => {
    let value = getValueInNativeDataType(e);
    setFormData({
      ...formData,
      [e.target.name]: value,
    })
  };

  const onChangeStandardInput = (name,value) => {
    setFormData({...formData, [name]: value});
  };


  let onToggle = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: (formData[e.target.name] === 1) ? 0 : 1,
    })
  };

  return (
    <Container fluid className="main-content-container p-4">
      <Card small className="details-card mb-4 w-100">
        <h4 className="m-0 section-title idStyle">Ref# {refNum}</h4>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>
                <Form>
                  <Row form>

                    <Col lg="3" className="form-group p-2 m-0">
                      <label>Expense Date</label>
                      <span
                        className='text-light form-control'>{setDateForTable(mileageItem.tripDate)}</span>
                    </Col>

                    <Col lg="3" className="form-group p-2 m-0">
                      <label>Trip Description</label>
                      <span
                        className='text-light form-control'>{mileageItem.tripDescription}</span>
                    </Col>

                    <Col lg="3" className="form-group p-2 m-0">
                      <label>From</label>
                      <span
                        className='text-light form-control'>{mileageItem.fromLocation}</span>
                    </Col>
                    <Col lg="3" className="form-group p-2 m-0">
                      <label>To</label>
                      <span
                        className='text-light form-control'>{mileageItem.toLocation}</span>
                    </Col>

                    {(mileageItem.status !== "Reviewed") ?
                      <StandardFormInput
                        id="distanceDriven"
                        autocomplete="off"
                        label="Distance Travelled"
                        standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                        dataMaxFracDigits={2}
                        type="text"
                        required
                        name='distanceDriven'
                        value={formData.distanceDriven}
                        onChangeStandardInput={onChangeStandardInput}
                      /> : <Col lg="3" className="form-group p-2 m-0">
                        <label>Distance Travelled</label>
                        <span className='text-light form-control'>{(mileageItem.distanceDriven) ? mileageItem.distanceDriven.toFixed(2) : ""}</span>
                      </Col>
                    }
<Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="distanceUnit">Distance Unit</label>
                      <InfoTooltip
                      msg="Default Base Distance Unit Configured" />
                      <div>
                        <FormInput
                          id="distanceUnit"
                          name='distanceUnit'
                          value={formData.distanceUnit}
                          onChange={onChange}
                          readOnly
                          placeholder="Enter Distance Unit"
                        />
                      </div>
                    </Col>
                    <Col lg="3" className="form-group p-2 m-0">
                      <label>Applied Rate</label>
                      {(mileageItem.status !== "Reviewed") ?
                        <FormInput
                          id="appliedRate"
                          autocomplete="off"
                          required
                          name='appliedRate'
                          value={formData.appliedRate}
                          onChange={onChange}
                        /> : <span
                          className='text-light form-control'>{(mileageItem.appliedRate) ? mileageItem.appliedRate.toFixed(2) : ""}</span>
                      }

                    </Col>
                    <Col lg="3" className="form-group p-2 m-0">
                      <label>Amount</label>
                      <span
                        className='text-light form-control'>{numberToMonetary(formData.amount)}</span>
                    </Col>


                    <Col lg="3" className="form-group p-2 m-0">
                      <label>Taxable</label>
                      <FormCheckbox
                        id="taxable"
                        className="d-flex mt-1"
                        autocomplete="off"
                        required
                        name='taxable'
                        disabled={mileageItem.status === "Reviewed"}
                        checked={formData.taxable}
                        onChange={onToggle}
                      >Taxable</FormCheckbox>
                    </Col>

                  </Row>
                  <Row className="px-3">
                    <Col sm="12" md="12" lg="12"
                         className=" form-group p-2 m-0 d-flex align-items-center justify-content-center justify-content-md-end">

                      {(props.reportStatus !== "Reviewed") && <>

                        <InfoTooltip
                          msg=" A notification will be sent to all the users of this expense report"/>

                        <Button className="mx-1 py-2 mx-md-2" size="sm"
                                onClick={() => {
                                  props.review_sub_mileage_item(refNum, sheetRefNumber, parseFloat(formData.distanceDriven), parseFloat(formData.appliedRate),formData.amount, formData.taxable);
                                  history.goBack();
                                }}
                                title="Approve this Mileage."
                                theme="accent">Approve</Button>

                        {(mileageItem.status !== "Rejected" && mileageItem.status !== "Reviewed") &&
                        <Button className="mx-1 py-2 mx-md-2" size="sm"
                                onClick={() => {
                                  props.reject_sub_mileage_item(refNum);
                                  history.goBack();
                                }}
                                
                              title="Reject this Mileage."
                                theme="accent">Reject</Button>}</>}

                      <Button className="mx-1 py-2 mx-md-2" size="sm"
                              onClick={() => history.goBack()}
                              theme="accent">Back</Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Container>
  );
}

ApproveMileageItem.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  subMileageItem: PropTypes.object.isRequired,
  get_sub_mileage_item: PropTypes.func.isRequired,
  approve_sub_mileage_item: PropTypes.func.isRequired,
  reject_sub_mileage_item: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  subMileageItem: state.subExpenses.subMileageItem,
  mileageItemList: state.subExpenses.expenseSheetForSub.mileageItemList,
  workerName:state.subExpenses.expenseSheetForSub.workerName,
  reportStatus: state.subExpenses.expenseSheetForSub.status
});
export default connect(mapStateToProps, {
  get_sub_mileage_item,
  review_sub_mileage_item,
  reject_sub_mileage_item,
  clear_sub_mileage_item,
})(ApproveMileageItem);
