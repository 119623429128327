import React, {useEffect, useState, useRef} from 'react';
import {setDateForTableInHrMinSec} from "../../utils/date";
import {
  Card, CardBody,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  FormSelect,
  Button,
  FormTextarea,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CardHeader,
  FormInput,
  DatePicker
} from "shards-react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
  clear_message_category,
  sub_send_message,
  sub_get_conversation_detail,
  get_message_category_role_list,
  get_user_profile,
  upload_attachment,
  remove_attachment,
  remove_all_attachments
} from "../../redux/actions/user";
import {useHistory, useParams} from "react-router-dom";
import ConfirmAlert from "../../components/common/ConfirmAlert";
import InfoTooltip from '../../components/common/InfoTooltip';
import {setAlert} from "../../redux/actions/alert";
import DisplayMessageFiles from '../../components/common/DisplayMessageFiles';
import { validateFileName, validateDuplicateFileName } from '../../utils/general';

function NewMessage({
                      conversation,
                      clear_message_category,
                      sub_send_message,
                      sub_get_conversation_detail,
                      get_message_category_role_list,
                      messageCategoryRoleList,
                      changePageMeta,
                      title,
                      get_user_profile,
                      userProfile,
                      upload_attachment,
                      remove_attachment,
                      remove_all_attachments,
                      setAlert
                    }) {
  let {id} = useParams();
  let {userId} = useParams();
  let {role} = useParams();
  const fileRef = useRef();


  const [attachmentFiles, setAttachmentFiles] = useState([])
 const [attachmentFileNames, setAttachmentFileNames]  = useState([]);
 const attachmentFileNamesRef = useRef([]);
 const [uploadingAttachment, setUploadingAttachment] = useState(false);
  const handleUpload = async (e) => {
    e.preventDefault();
    const supportedExtensions = ['.doc', '.docx', '.png','.jpeg', '.pdf']
    var file = e.target.files[0];
    let fileSize = file !== undefined ? file.size / (1024 * 1024) : 0;
    let fileExtension = file.name.substring(file.name.lastIndexOf('.'), file.name.length);
    fileRef.current.value = ""

    if(validateDuplicateFileName(file.name, attachmentFiles)) return;
    let valid = validateFileName(file.name);

    if(!valid){
      setAlert("File name should only contain letters [a-z, A-Z], numbers [0-9], space [' '], period [.], underscore [_] and hyphen [-].","danger");
      return;
    }

    if(!supportedExtensions.includes(fileExtension.toLowerCase())){
      setAlert("Please attach JPEG, PNG, PDF, DOC only.","danger");
      return;
    }
    
    // if(attachmentFiles.length === 8){
    //   setAlert("Maximum 8 Attachments Allowed.","danger");
    //   return;
    // }

    for(let file of attachmentFiles){
      fileSize = fileSize + (file.size / (1024 * 1024))
    }

    if(fileSize > 8){
      setAlert("Maximum File Size Exceeded.","danger");
      return;
    }
    //setUploadingAttachment(true);
    setAttachmentFiles([...attachmentFiles, file]);
    let fileName = await upload_attachment(file,user.userId, 'subscriber');
   
    if(fileName === null){
      let filteredAttachments = attachmentFiles.filter(file1 => file1.name !== file.name);
      setAttachmentFiles([...filteredAttachments]);
      return;
     }

    if(fileName && !fileName.includes(file.name)){
      return;
    }
    //setUploadingAttachment(false);
    //attachmentFileNames.push(fileName)
    attachmentFileNamesRef.current.push(fileName);
    setAttachmentFileNames([...attachmentFileNamesRef.current]);


  }

  const renderFiles = ()=>{
    return  (<div className=' pr-2 text-nowrap overflow-auto'>{attachmentFiles.map(file=>{
       return(
         <span className='border rounded mr-2 pl-1 pr-1' key={file.name}>
          <small className='font-weight-bold'>
          <span className='file-badge-text'>{(file.name.length > 27) ? file.name.substring(0,25) + file.name.substring(file.name.lastIndexOf('.'),file.name.length)  : file.name}</span>
           <i className="fa-solid fa-xmark text-small ml-1" style={{color:'#ec2b2b'}} role="button" onClick={()=> removeFile(file)} ></i>
           </small>
        </span>
       )
     })}
     </div> 
     )
   }

   const removeFile =  async (file)=>{

    if(uploadingAttachment) return;

    let filteredAttachments = attachmentFiles.filter(f => f.name !== file.name);
    let fileName = attachmentFileNamesRef.current.filter(fileName => fileName && fileName.includes(file.name));
    if(fileName.length === 0)
    {
      //setAttachmentFiles([...filteredAttachments]);
      return;
    }
    let response = await remove_attachment(user.userId, fileName[0], 'subscriber');
    setAttachmentFiles([...filteredAttachments]);
    attachmentFileNamesRef.current = [...attachmentFileNamesRef.current.filter(f => f !== fileName[0])];
    setAttachmentFileNames([...attachmentFileNames.filter(f => f !== fileName[0])])

  }

  const removeAllAttachmnts = (attachmentFileNames)=>{

    let attachmentFiles = attachmentFileNames.toString();
    remove_all_attachments(user.userId, attachmentFiles, 'subscriber');
    
   }

  useEffect(() => ()=> removeAllAttachmnts(attachmentFileNamesRef.current), [])

  useEffect(() => {
    if (id && id !== '0') {
      sub_get_conversation_detail(id, userId);
    }
    if(userId && userId !== '0')
    {
      get_user_profile(userId)
    }
    get_message_category_role_list(role==="Worker"?"Worker":"Client User");
    return () => clear_message_category();

  }, [id, userId]);


  const history = useHistory();
  const [formData, setFormData] = useState({
    categoryName: '',
    categoryId: 0,
    messages: [],
    msg: '',
    userName: '',
    client: ''
  });

  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null,
  });

  useEffect(() => {
    if (conversation && conversation.userName) {
      setFormData({
        ...formData,
        categoryName: conversation.categoryName,
        messages: conversation.messages,
        msg: ''
      });

    }
  }, [conversation]);

  useEffect(() => {
    if (userProfile && userProfile.personalInfo) {
      setFormData({
        ...formData,
        userName: userProfile.personalInfo.firstName + " " + userProfile.personalInfo.lastName + " " + (userProfile.personalInfo.nickName?`(${userProfile.personalInfo.nickName})`:''),
        client: userProfile.employmentInfo.clientName
      });
    }
  }, [userProfile]);

  //added Worker Name in the page title once we get response from backend
  useEffect(() => {
    if (formData.userName)
      changePageMeta(`${title} - ${formData.userName}`)
  }, [formData.userName]);

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  let user = JSON.parse(localStorage.getItem("user"));

  let messageItems = ((conversation.refNum && conversation.refNum == id) ? conversation.messages : []).map(message => {
    if (user.userId !== message.userId) {
      return (
        <div className="d-flex flex-column mb-3 mr-5 pr-5 mt-4">
          <span
            className="small">{setDateForTableInHrMinSec(message.messageTime)} by {message.userName}</span>
          <span>{message.messageBody}</span>
          {message.messageAttachments  && message.messageAttachments.map(attachment =>{
            return <DisplayMessageFiles 
            setConfirmAlert={setConfirmAlert} 
            confirmAlert={confirmAlert} 
            key={conversation.refNum + attachment.messageId + attachment.systemFileName} 
            refNumber={conversation.refNum}  
            userId={message.userId} 
            attachment={attachment} 
            sender={false}/>
          })}
        </div>)
    } else {
      return (
        <div
          className="d-flex flex-column align-items-end mb-3 ml-5 pl-5 pr-2 mt-4">
          <span
            className="small">{setDateForTableInHrMinSec(message.messageTime)} by {message.userName}</span>
          <span>{message.messageBody}</span>
          {message.messageAttachments  && message.messageAttachments.map(attachment =>{
            return <DisplayMessageFiles 
            setConfirmAlert={setConfirmAlert} 
            confirmAlert={confirmAlert} 
            key={conversation.refNum + attachment.messageId + attachment.systemFileName} 
            refNumber={conversation.refNum}  
            userId={message.userId} 
            attachment={attachment} 
            sender={true}/>
          })}
        </div>)
    }
  })

  const onSend = async (e) => {
    e.preventDefault();
    
    if (attachmentFileNames.length >  0  || (formData.msg != '' && formData.msg != null) && formData.categoryId != '' && formData.categoryId != '0') {
     
      await sub_send_message(id, userId, formData.categoryName, formData.categoryId, "Unread", formData.msg,role, JSON.stringify(attachmentFileNamesRef.current));
      attachmentFileNamesRef.current = []
      history.goBack();
    }
  };

  const category = messageCategoryRoleList.map((data) => (
    <option key={data.categoryName} value={data.categoryId}>{data.categoryName}</option>
  ));

  messageCategoryRoleList.map(data => {
    if (data.categoryName == formData.categoryName) {
      formData.categoryId = data.categoryId;
    }
  });

  function goBack(e) {
    e.preventDefault();
    setConfirmAlert({
      ...confirmAlert,
      confirmMsg: 'Are you sure you want to exit this page? You will lose unsent data.',
      visible: true,
      onConfirm: () => {
        history.goBack();
      }
    })
  }

  
  useEffect(()=> {
    setUploadingAttachment(attachmentFiles.length !== attachmentFileNames.length)
  }, [attachmentFiles, attachmentFileNames])

  return (
    <>
      <ConfirmAlert confirmAlert={confirmAlert}
                    setConfirmAlert={setConfirmAlert}/>

      <Container fluid className="main-content-container p-4 mt-2">

        <form onSubmit={onSend}>
          {/*<Card small
                className="mb-5 w-100 details-card mt-3 mb-4 d-flex personalParent">
          <span className="iconBox"><i
            className="fas fa-info-circle iconStyle"/></span>
            <h4 className="m-0 section-title headerStyle">User Information</h4>
            <h4 className="m-0 section-title idStyle">Ref# {id}</h4>

            <ListGroup flush>
              <ListGroupItem className="border-card p-3 mt-3">
                <Row>
                  <Col>
                    <Row form>
                      <Col lg="3" className="form-group p-2 mb-0">
                        <label>User Name</label>
                        <span
                          className="inputBoxClr   form-control">{formData.userName}</span>
                      </Col>
                      <Col lg="3" className="form-group p-2 mb-0">
                        <label>Role</label>
                        <span
                          className="inputBoxClr   form-control">{role}</span>
                      </Col>
                      <Col lg="3" className="form-group p-2 mb-0">
                        <label>Client</label>
                        <span
                          className="inputBoxClr   form-control">CPS</span>
                      </Col>

                      <Col sm="12" md="2"
                           lg="3"
                           className="form-group p-2 m-0">
                        <label>Category</label>
                        <FormSelect className="form-control" name='categoryId'
                                    value={formData.categoryId}
                                    onChange={onChange} disabled={id !== '0'}
                                    required>
                          <option value=''>Select Category</option>
                          {category}
                        </FormSelect>

                      </Col>
                    </Row>
                  </Col>
                </Row>

              </ListGroupItem>
            </ListGroup>

  </Card>*/}
          <Card small className="w-100">
          <span className="iconBox"><i
            className="fa-solid fa-comment-dots iconStyle"/></span>
            <h4 className="m-0 section-title headerStyle">Conversations</h4>
            <CardHeader className="p-0 py-3 border-card details-card">
                  <Container fluid
                             className="file-manager__filters border-bottom">
                  <ListGroup flush>
              <ListGroupItem className="border-card p-3 mt-3">
                <Row>
                  <Col lg="12">
                    <Row form>
                      <Col lg="3" className="form-group p-2 mb-0">
                        <label className='font-weight-bold'>User Name</label>
                        <span
                          className="inputBoxClr form-control font-weight-bold">{formData.userName}</span>
                      </Col>
                      <Col lg="3" className="form-group p-2 mb-0">
                        <label className='font-weight-bold'>Role</label>
                        <span
                          className="inputBoxClr form-control font-weight-bold">{role}</span>
                      </Col>
                      <Col lg="3" className="form-group p-2 mb-0">
                        <label className='font-weight-bold font-weight-bold'>Client</label>
                        <span
                          className="inputBoxClr form-control font-weight-bold">{formData.client}</span>
                      </Col>

                      <Col sm="12" md="2"
                           lg="3"
                           className="form-group p-2 m-0">
                        <label className='font-weight-bold'>Category</label>
                        <FormSelect className="form-control" name='categoryId'
                                    value={formData.categoryId}
                                    onChange={onChange} disabled={id !== '0'}
                                    required>
                          <option value=''>Select Category</option>
                          {category}
                        </FormSelect>

                      </Col>
                      
                    </Row>
                  </Col>
                  
                </Row>
               
              </ListGroupItem>
            </ListGroup>
                  </Container>
                </CardHeader>
            <CardBody className="border-card">
              <div className="conversations-area overflow-auto"
                   style={{maxHeight: 400, minHeight: 145}}>
                {messageItems}
              </div>
              <InputGroup className="mb-2">
              <FormTextarea id="msg" rows="2" name="msg" className="message-box"
                            required={attachmentFileNames.length === 0}
                            placeholder="Type a message here"
                            onChange={onChange}/>
                            <InputGroupAddon type="prepend">
                          
                          <InputGroupText className="rounded-right pr-0" onClick={()=> fileRef.current.click()} role="button">
                         
                            <i className="fa-solid fa-paperclip text-dark iconStyle" style={{rotate:'180deg'}}></i>
                            <InfoTooltip infoClass="mt-n4"
                            cssClassName="text-start custom-tooltip-inner"
                            msg="<div align='left'><b class='float-left'>Upload Instructions</b><br/>
                            1. Maximum upload size is 8MB. <br/>
                            2. Allowed file types are PDF, JPEG, PNG, DOC. <br/>
                            3. File name should only contain letters [a-z, A-Z], numbers [0-9], space [' '], period[.], underscore [ _ ] and hyphen[-].</div>"/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <input ref={fileRef} onChange={handleUpload} type="file" hidden  accept=".pdf, .jpeg, .png, .docx, .doc"/>

              </InputGroup>
              {renderFiles()}
              <Button size='sm' disabled={uploadingAttachment}
                      className="mx-2 py-2 my-4 float-right" type="submit">Send</Button>

            </CardBody>
          </Card>
        </form>
        <Row>
          <Col className="card-body">
            <Button size='sm'
                    onClick={goBack} className="float-right">Back</Button>
          </Col>
        </Row>
      </Container>

    </>
  );
}

NewMessage.propTypes = {
  sub_send_message: PropTypes.func.isRequired,
  sub_get_conversation_detail: PropTypes.func.isRequired,
  get_message_category_role_list: PropTypes.func.isRequired,
  conversation: PropTypes.object.isRequired,
  messageCategoryRoleList: PropTypes.array.isRequired,
  get_user_profile: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  conversation: state.user.conversation,
  messageCategoryRoleList: state.user.messageCategoryRoleList,
  userProfile: state.user.userProfile
});
export default connect(mapStateToProps, {
  clear_message_category,
  sub_get_conversation_detail,
  sub_send_message,
  get_message_category_role_list,
  get_user_profile,
  upload_attachment,
  remove_attachment,
  remove_all_attachments,
  setAlert
})(NewMessage);
