import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { b64toBlob, getBase64MimeType } from '../../utils/file'

import PropTypes from 'prop-types';
import {get_message_attachment_file} from "../../redux/actions/user";

const ViewAttachmentPopUp = ({
                                    isAuthenticated,
                                    user,
                                    viewAttachment,
                                    get_message_attachment_file,
                                  }) => {
  let { userId,refNumber,messageId, systemFileName, subPath} = useParams();
  
  useEffect(() => {
    get_message_attachment_file(userId, refNumber,messageId, systemFileName, subPath);
  }, []);


  let receiptDoc;
  if (viewAttachment[systemFileName] !== undefined) {
    let imageMimeTypes = ['jpg', 'jpeg', 'gif', 'png', 'svg']
    const mimeType = getBase64MimeType(viewAttachment[systemFileName])
    if (mimeType === 'pdf') {
      receiptDoc = <object className='popup-receipt-viewer popup-receipt-viewer-pdf' data={b64toBlob(viewAttachment[systemFileName])} />
    } else if (imageMimeTypes.includes(mimeType)) {
      receiptDoc = <img className='popup-receipt-viewer' src={b64toBlob(viewAttachment[systemFileName])} />
    } else {
      receiptDoc = <a className='themebtn'>Download File</a>
    }

  }

  if (!isAuthenticated) {
    return <Redirect to='/login' />;
  }

  return (
      <div className="popup-receipt-viewer-pdf">
        {receiptDoc}
      </div>
  );
};

ViewAttachmentPopUp.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  viewAttachment: PropTypes.any.isRequired,
  get_message_attachment_file: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  viewAttachment: state.user.viewAttachment,
});

export default connect(mapStateToProps, {
    get_message_attachment_file,
})(ViewAttachmentPopUp);
