import React, {useEffect, useState} from 'react';
import {
  Card,
  CardHeader,
  Col,
  Form, FormInput, FormSelect,
  
  
  ListGroup,
  ListGroupItem,
  Row
  
} from "shards-react";
import PropTypes from "prop-types";
import {setAPIDate, setDateForTable} from "../../utils/date";
import CustomCard from "../common/CustomCard";
import {STANDARD_FORMATS} from "../../utils/constants";
import StandardFormInput from "../common/StandardFormInput";

const EmergencyContactInfo = (
  {
   
    emergencyFirstName,
    emergencyLastName,
    relationShip,
    emergencyHomePhone,
   
    emergencyEmail,
    emergencyPhoneWork,
    onChange,
    isRequiredFlag,
    onChangeStandardInput
  }) => {


  

  return (
    <CustomCard id="emergencyContact" title=" Emergency Contact Information" noform icon={true} iconClass="fa-solid fa-address-book iconStyle">
      

      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="emergencyFirstName">Legal First Name</label>
        <FormInput
          id="emergencyFirstName"
          placeholder="Enter First Name"
          value={emergencyFirstName}
          name='emergencyFirstName'
          onChange={onChange}
        />
      </Col>


      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="emergencyLastName">Legal Last Name</label>
        <FormInput
          id="emergencyLastName"
          placeholder="Enter Last Name"
          value={emergencyLastName}
          name='emergencyLastName'
          onChange={onChange}
        />
      </Col>

      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="relationShip">Relationship</label>
        <FormInput
          id="relationShip"
          placeholder="Enter Relationship"
          value={relationShip}
          name='relationShip'
          onChange={onChange}
        />
      </Col>

      
      <StandardFormInput
        id="emergencyHomePhone"
        label="Home - Phone"
        placeholder="(xxx) xxx-xxxx"
        standardFormat={STANDARD_FORMATS.PHONE_NO}
        type="text"
        name='emergencyHomePhone'
        value={emergencyHomePhone}
        onChangeStandardInput={onChangeStandardInput}
      />
     

     <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="emergencyEmail">Email</label>
        <FormInput
          id="emergencyEmail"
          placeholder="Enter Email"
          value={emergencyEmail}
          name='emergencyEmail'
          onChange={onChange}
        />
      </Col>

      <StandardFormInput
        id="emergencyPhoneWork"
        label="Work - Phone"
        placeholder="(xxx) xxx-xxxx"
        standardFormat={STANDARD_FORMATS.PHONE_NO}
        type="text"
        name='emergencyPhoneWork'
        value={emergencyPhoneWork}
        onChangeStandardInput={onChangeStandardInput}
      />
     
    </CustomCard>
  );
};

EmergencyContactInfo.propTypes = {
    //EmergencyContactInfo: PropTypes.object.isRequired,
 
}

export default EmergencyContactInfo;
