import React, { useEffect, useState } from 'react';
import {
  Button,
  Card, CardBody,
  CardHeader,
  Col,
  Container, FormSelect,
  Row
} from "shards-react";
import ReactTable from "react-table";
import RangeDatePicker from "../../components/common/RangeDatePicker";
import { setDateForTable, setInputDate } from "../../utils/date";
import { fixedDecimalPlaces, getStatusClass } from "../../utils/general";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  get_entitled_leaves_list,
  get_leaves_list,
  get_leaves_status, get_worker_leave_policy_names_list,
  get_leaves_types,
  get_leaves_balance,
  clear_leaves_balance
} from "../../redux/actions/worker/leaves";
import {
  view_artisan_profile,
  get_hire_date
} from "../../redux/actions/worker/profile";
import Alerts from "../../components/common/Alerts";
import {default as queryString} from "qs";
import InfoTooltip from '../../components/common/InfoTooltip';
import CustomCard from '../../components/common/CustomCard';

function ManageLeaves(props) {

  let location = useLocation();
  let queryParams = queryString.parse(location.search.replace("?", ""))
  const [searchLeaveData, setSearchLeaveData] = useState({
    year: '',
    leavePolicyId : '',
    userId:''
  })
  const [yearList, setYearList] = useState([]);

  const [state, setState] = useState({
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
    from: '',
    to: '',
    status: '',
    leaveType: '',
  });

  const setSearchLeave = (e) => setSearchLeaveData({...searchLeaveData, [e.target.name]: e.target.value})

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  function createListOfYears(startYear, endYear) {
    let result = [];
    for (let i = startYear; i <= endYear; i++) {
      result.push(i);
    }
    return result.reverse();
  }

  useEffect(()=>{
    if(searchLeaveData.userId !== '')
    {props.get_leaves_balance(searchLeaveData.userId, searchLeaveData.year, searchLeaveData.leavePolicyId);}
  },[searchLeaveData])
  useEffect(() => {
    props.get_leaves_status()
    props.get_worker_leave_policy_names_list()
    props.get_entitled_leaves_list()
    props.get_hire_date()

    return props.clear_leaves_balance;
  }, []);
  useEffect(()=>{
    
    if(props.workerLeavePolicyNamesList && props.workerLeavePolicyNamesList.length > 0){
      setSearchLeaveData({...searchLeaveData, leavePolicyId: props.workerLeavePolicyNamesList[0].leavePolicyId, userId: props.user.userId, year:setInputDate(new Date()).substring(0,4)})
    }
  },[props.workerLeavePolicyNamesList])
  useEffect(() => {

      let now = setInputDate(new Date());
      let currentYear = now.substring(0, 4);
      let hireYear = currentYear;
      if (props.hireDate > 0) {
          hireYear = new String(props.hireDate).substring(0, 4);

      }

      let yearList = createListOfYears(hireYear, currentYear);
      setYearList(yearList);
    
  }, [props.hireDate])

  useEffect(() => {
    let newState = {
      ...state, from: queryParams.from || "",
      to: queryParams.to || "",
      status: queryParams.status || "",
      leaveType: queryParams.leaveType || "",
    }
    filterLeaves(newState.from, newState.to, newState.status, newState.leaveType)
    setState(newState)
  }, [queryParams.from,
  queryParams.to,
  queryParams.status,
  queryParams.leaveType]);


  const tableData = props.leavesList || [];
  var { pageSize, pageSizeOptions } = state;


  const tableColumns = [
    {
      Header: "Leave Type",
      accessor: "leavePolicy",
      className: "justify-content-start"
    },
    {
      Header: "Requested",
      accessor: "requestedDate",
      className: "text-center",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        setDateForTable(row.original.requestedDate)
    },
    {
      Header: "From",
      accessor: "fromDate",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        setDateForTable(row.original.fromDate),
      className: "text-center"
    },
    {
      Header: "To",
      accessor: "toDate",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        setDateForTable(row.original.toDate),
      className: "text-center"
    },
    {
      Header: "Time Off",
      accessor: "leaveDays",
      maxWidth: 80,
      className: "justify-content-end",
      Cell: row => row.original.leaveDays.toFixed(2)
    },
    {
      Header: "Balance (once accrued)",
      accessor: "balance",
      maxWidth: 80,
      className: "justify-content-end",
      Cell: row => row.original.balance.toFixed(2)
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 100,
      className: "text-center",
      Cell: row =>
        <span
          className={getStatusClass(row.original.status)}>{row.original.status}</span>,
    },

    {
      Header: "Ref#",
      accessor: "refNumber",
      maxWidth: 100,
      className: "text-center",
      Cell: row =>
        <Link to={`/edit-leave/${row.original.refNumber}`}>{row.original.refNumber}</Link>
    }
  ];


  var leaveTypes = props.workerLeavePolicyNamesList.map((leaveType, i) => {
      return <option key={leaveType.policyName + i} value={leaveType.policyName}>{leaveType.policyName}</option>;
  });
  var leaveTypesBalance = props.workerLeavePolicyNamesList.map((leaveType) => {
    
      return <option key={leaveType.policyName + leaveType.leavePolicyId} value={leaveType.leavePolicyId}>{leaveType.policyName}</option>;
  });
  var leaveStatus = (props.leaveStatusList || []).map((status, i) => {
    return <option key={status + i} value={status}>{status}</option>;
  });

  let onStartDateChanged = (value) => {
    setState({
      ...state,
      from: value
    })
  }

  let onEndDateChanged = (value) => {
    setState({
      ...state,
      to: value
    })
  }


  let filterLeaves = (from = state.from, to = state.to, status = state.status, leaveType = state.leaveType) => {
    props.get_leaves_list(props.user.userId, setInputDate(from), setInputDate(to), status, leaveType);
  }
  var yearListOptions = yearList.map(year => {
    return <option key={year} value={year}>{year}</option>
  })

  var leaveTypeListOptions = props.leaveTypeList && props.leaveTypeList.map(leaveType => {
    return <option key={leaveType} value={leaveType}>{leaveType}</option>;
  });

  const entitledLeavesCards = (props.entitledLeaveList || []).map(leave => {
    return <div className="pb-2 px-1 leaveCard">
      <Card className="leave-box">
        <CardBody className="p-0 leaveCardBody">
          <Row form className="m-0">
            <Col lg="12" className="form-group p-2 px-3 leaveLabel text-center">
              <label
                className="text-bold box-header text-uppercase m-0">{leave.policyName}
              </label>
            </Col>
            <Col lg="12"
              className="form-group d-flex justify-content-between px-3" >
              <label>Entitled</label>
              <span className='text-right'>{leave.entitledFlag ? leave.entitledDays + " days" : "Unlimited"}</span>
            </Col>

            <Col lg="12"
              className="form-group d-flex justify-content-between px-3">
              <label>Balance</label>
              <span className='text-right'>{leave.entitledFlag ? leave.balance + " days" : "—"} </span>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  })
  return (
    <>
      <Alerts />
      <Container fluid className="main-content-container px-4 pb-4">
        <Row>
          <Col lg="5">
            <CustomCard id="leaveBalance" title="Leaves Balance Information" className="mt-5 mb-4" icon={true} iconClass="fa-regular fa-calendar iconStyle" noform>
              <Row className="mx-0 w-100">
                <Col lg="6">
                  <label htmlFor="leaveType">Leave Type</label>
                  <InfoTooltip msg="Entitled Leave Types mapped." infoClass="info-icon-primary" />
                  <FormSelect value={searchLeaveData.leavePolicyId} name='leavePolicyId'
                    onChange={setSearchLeave} className='d-block'>
                    <option value=''>Select Leave Type</option>
                    {leaveTypesBalance}
                  </FormSelect>
                </Col>

                <Col lg="6">
                  <label htmlFor="year">Year</label>
                  <FormSelect value={searchLeaveData.year} name='year'
                    onChange={setSearchLeave} className='d-block'>
                    {yearListOptions}
                  </FormSelect>

                </Col>

              </Row>
              <Row className="mx-0 w-100 mt-2 ">
                <Col lg="12">
                  <table className='table-bordered shadow-sm p-3 mb-2 bg-white rounded w-100'>
                  <tbody>
                    <tr>
                    <td><div className="d-flex justify-content-between font-weight-normal  mr-2 ml-2"><div className='h-25'>Carry Forward ({(props.leaveBalance && props.leaveBalance.allowCarryOverFlag === undefined || props.leaveBalance.allowCarryOverFlag === 0) ? `Off` : `On`})</div><div className={`h-25 font-weight-normal ${props.leaveBalance.entitledFlag !== undefined ? props.leaveBalance.entitledFlag ? 'leave-balance' : 'mr-5' : 'leave-balance'} text-center`}>{props.leaveBalance.entitledFlag !== undefined ? props.leaveBalance.entitledFlag ? fixedDecimalPlaces(props.leaveBalance.carryForward, 2) : '-' : '0.00'}</div></div></td>
                    </tr>
                    <tr>
                      <td className='font-weight-normal'><div className="d-flex justify-content-between mr-2 ml-2"><div className='h-25 '>Entitled (once accrued)</div><div className={`h-25 font-weight-normal ${props.leaveBalance.entitledFlag !== undefined ? props.leaveBalance.entitledFlag ? 'leave-balance' : 'mr-4' : 'leave-balance'} text-center`}>{props.leaveBalance.entitledFlag  !== undefined ? props.leaveBalance.entitledFlag ? fixedDecimalPlaces(props.leaveBalance.entitledDays, 2) : 'Unlimited' : '0.00'}</div></div></td>
                    </tr>
                    <tr>
                      <td className='font-weight-normal'><div className="d-flex justify-content-between mr-2 ml-2"><div className='h-25'>Approved</div><div className='h-25 font-weight-normal leave-balance'>{fixedDecimalPlaces(props.leaveBalance.approvedLeaves, 2)}</div></div></td>
                    </tr>
                    <tr>
                      <td className='font-weight-normal'><div className="d-flex justify-content-between mr-2 ml-2"><div className='h-25'>Submitted</div><div className='h-25 font-weight-normal leave-balance'>{fixedDecimalPlaces(props.leaveBalance.submittedLeaved, 2)}</div></div></td>
                    </tr>
                    <tr>
                      <td className='font-weight-normal'><div className="d-flex justify-content-between mr-2 ml-2"><div className='h-25'>Lapse</div><div className={`h-25 font-weight-normal ${props.leaveBalance.entitledFlag !== undefined ? props.leaveBalance.entitledFlag ? 'leave-balance' : 'mr-5' : 'leave-balance'} `}>{props.leaveBalance.entitledFlag !== undefined ? props.leaveBalance.entitledFlag ? fixedDecimalPlaces(props.leaveBalance.lapse, 2) : '-' : '0.00'}</div></div></td>
                    </tr>
                    <tr>
                      <td className='font-weight-normal'><div className="d-flex justify-content-between mr-2 ml-2"><div className='h-25'>Entitled (once accrued) Balance Remaining{/*<InfoTooltip msg="Unused Worker Leave Balance." infoClass="info-icon-primary"/>*/}</div><div className={`h-25 text-leave-balance font-weight-bold ${props.leaveBalance.entitledFlag !== undefined ? !props.leaveBalance.entitledFlag ?  'mr-2' : '' : 'mr-1'}`}>{props.leaveBalance.entitledFlag !== undefined ? props.leaveBalance.entitledFlag ? fixedDecimalPlaces(props.leaveBalance.balance, 2) : '-' : '0.00'} Days</div></div></td>
                    </tr>
                  </tbody>
                  </table>
                </Col>
              </Row>
            </CustomCard>
          </Col>
        </Row>
        <div  className="page-header flex-column flex-md-row py-2 d-flex justify-content-end bg-white tableSearch">
          <div className="d-flex align-items-center p-2">
            <FormSelect name='status' value={state.status} onChange={onChange}>
              <option value=''>All Status
              </option>
              {leaveStatus}
            </FormSelect>
          </div>
          <div className="d-flex align-items-center p-2">
            <FormSelect name='leaveType' value={state.leaveType}
              onChange={onChange}>
              <option value=''>All Types
              </option>
              {leaveTypes}
            </FormSelect>
          </div>
          <div className="d-flex align-items-center p-2">
            <RangeDatePicker className="justify-content-end"
              onStartDateChanged={onStartDateChanged}
              onEndDateChanged={onEndDateChanged} name="range" />
          </div>

          <div className="d-flex align-items-center justify-content-end p-2">
            <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
              onClick={e => filterLeaves()}>Search</Button>
          </div>
        </div>


        <Card className="tableCard p-0">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters border-bottom">
              <Row>
                {/* Filters :: Page Size */}
                <Col className="file-manager__filters__rows d-flex align-items-center col-6">
                  <span>Show</span>
                  <FormSelect
                    size="sm"
                    name='pageSize'
                    value={state.pageSize}
                    onChange={onChange}
                  >
                    {pageSizeOptions.map((size, idx) => (
                      <option key={idx} value={size}>
                        {size} rows
                      </option>
                    ))}
                  </FormSelect>
                </Col>

                <Col
                  className="file-manager__filters__search d-flex justify-content-end col-6">
                  <Link to="/add-leave/0">
                    <Button className="my-2 py-2 mx-2" size='sm'
                      theme="accent">Apply for Leave</Button>
                  </Link>
                </Col>


              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <div className="">
              <ReactTable
                columns={tableColumns}
                data={tableData}
                pageSize={Number(pageSize)}
                showPageSizeOptions={false}
                resizable={false}
                noDataText="No results found"
              />
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}


const mapStateToProps = (state) => ({
  user: state.user.user,
  leavesList: state.leaves.leavesList,
  workerLeavePolicyNamesList: state.leaves.workerLeavePolicyNamesList,
  leaveStatusList: state.leaves.leaveStatusList,
  entitledLeaveList: state.leaves.entitledLeaveList,
  leaveTypeList: state.leaves.leaveTypeList,
  leaveBalance: state.leaves.leaveBalance,
  artisanProfile: state.profile.artisanProfile,
  hireDate: state.profile.hireDate,

})
export default connect(mapStateToProps, {
  get_leaves_list,
  get_leaves_status,
  clear_leaves_balance,
  get_worker_leave_policy_names_list,
  get_entitled_leaves_list,
  get_leaves_types,
  get_leaves_balance,
  view_artisan_profile,
  get_hire_date
})(ManageLeaves);
