import {
  CLEAR_LEAVE_BALANCE,
  CLEAR_LEAVE_DETAILS,
  GET_ENTITLED_LEAVE_LIST,
  GET_ENTITLED_LEAVE_LIST_ERROR,
  GET_LEAVE_BALANCE,
  GET_LEAVE_BALANCE_ERROR,
  GET_LEAVE_DETAILS,
  GET_LEAVE_DETAILS_ERROR,
  GET_LEAVE_TYPES,
  GET_LEAVE_TYPES_ERROR,
  GET_LEAVES_LIST,
  GET_LEAVES_LIST_ERROR,
  GET_LEAVES_STATUS,
  GET_LEAVES_STATUS_ERROR,
  GET_WORKER_LEAVE_POLICY_NAMES_LIST,
  GET_WORKER_LEAVE_POLICY_NAMES_LIST_ERROR,
  SAVE_LEAVE_DETAILS,
  SAVE_LEAVE_DETAILS_ERROR,
  WITHDRAW_LEAVE,
  WITHDRAW_LEAVE_ERROR
} from "../../actions/types";


const initialState = {  
  leaveStatusList: [],
  leaveTypeList: [],
  leavesList: [],
  entitledLeaveList: [],
  leaveDetails: {},
  workerLeavePolicyNamesList: [],
  leaveBalance:{}
}


export const leaves = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEAVES_STATUS:
      return {
        ...state,
        leaveStatusList: action.payload.leaveStatusList,
      };
    case GET_LEAVES_STATUS_ERROR:
      return {
        ...state,
        leaveStatusList: [],
      };

    case GET_LEAVE_TYPES:
      return {
        ...state,
        leaveTypeList: action.payload.leaveTypeList,
      };
    case GET_LEAVE_TYPES_ERROR:
      return {
        ...state,
        leaveTypeList: [],
      };
    case GET_LEAVES_LIST:
      return {
        ...state,
        leavesList: action.payload.leavesList,
      };
    case GET_LEAVES_LIST_ERROR:
      return {
        ...state,
        leavesList: [],
      };
    case GET_LEAVE_DETAILS:
      return {
        ...state,
        leaveDetails: action.payload.leaveDetails,
      };
    case GET_LEAVE_DETAILS_ERROR:
      return {
        ...state,
        leaveDetails: {},
      };
      case CLEAR_LEAVE_DETAILS:
      return {
        ...state,
        leaveDetails: {},
      };
    case SAVE_LEAVE_DETAILS:
      return {
        ...state,
        // leaveDetails: action.payload.leaveDetails,
      };
    case SAVE_LEAVE_DETAILS_ERROR:
      return {
        ...state,
        // leaveDetails: null,
      };
    case WITHDRAW_LEAVE:
      return {
        ...state,
        // leaveDetails: null,
      };
    case WITHDRAW_LEAVE_ERROR:
      return {
        ...state,
        // leaveDetails: null,
      };

    case GET_ENTITLED_LEAVE_LIST:
      return {
        ...state,
        entitledLeaveList: action.payload.leavePolicyList,
      };
    case GET_ENTITLED_LEAVE_LIST_ERROR:
      return {
        ...state,
        entitledLeaveList: [],
      };


    case GET_WORKER_LEAVE_POLICY_NAMES_LIST:
      return {
        ...state,
        workerLeavePolicyNamesList: action.payload.leavePolicyList,
      };
    case GET_WORKER_LEAVE_POLICY_NAMES_LIST_ERROR:
      return {
        ...state,
        workerLeavePolicyNamesList: [],
      };
      case GET_LEAVE_BALANCE:
      return {
        ...state,
        leaveBalance: action.payload,
      };
      case CLEAR_LEAVE_BALANCE:
      return {
        ...state,
        leaveBalance: [],
      };

      case GET_LEAVE_BALANCE_ERROR:
      return {
        ...state,
        leaveBalance: {},
      };
      

    default:
      return state;
  }
}

export default leaves;
