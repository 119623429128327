import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {
  Card, Col,
  Container,
  Row
} from "shards-react";
import InfoTooltip from "../../components/common/InfoTooltip";
import {Link} from "react-router-dom";
import MessageCenter from "./MessageCenter";
import {get_entitled_leaves_list} from "../../redux/actions/worker/leaves";
import {get_dashboard_data} from "../../redux/actions/worker/home";


function WorkerHome({
                      user,
                      title,
                      entitledLeaveList,
                      dashboardData,
                      changePageMeta,
                      get_dashboard_data,
                      get_entitled_leaves_list
                    }) {

  useEffect(() => {
    changePageMeta(`${title} - ${user.clientName?user.clientName+" - ":""}${user.displayName}`)
  }, []);

  let userPermissions = user.userRoles.find(role => role.role === "Worker").permissions

  useEffect(() => {
    get_dashboard_data()
    if(userPermissions.includes("Leaves Module"))
      get_entitled_leaves_list()
  }, []);

  let entitledLeavesCards = (entitledLeaveList || []).map(leave => {
    return <Col xs={12} md={6} lg={4} className="px-2">
      <Link to={"/manage-leaves?leaveType=" + leave.policyName} target="_blank">
        <Card className="py-2 my-2">
          <h4 className="text-center">
            <div className="h3 d-inline"><i
              className="material-icons shadow p-2 rounded-circle">today</i>
            </div> {leave.policyName} </h4>
          <div className="d-flex justify-content-around">
            <div>
              <div className="h5 text-center mb-1 mx-2">Entitled (once accrued)</div>
              <span
                className={"text-center mb-1 h5"}><Link
                to={"/manage-leaves?leaveType=" + leave.policyName}>{leave.entitledFlag ? leave.entitledDays + " days" : "Unlimited"}</Link> </span>
            </div>
            <div>
              <div className="h5 text-center mb-1 mx-2">Balance (once accrued)</div>
              <span
                className={"text-center mb-1 h5" }><Link
                to={"/manage-leaves?leaveType=" + leave.policyName}>{leave.entitledFlag ? leave.balance + " days" : "—"}</Link> </span>
            </div>
          </div>
        </Card>
      </Link>
    </Col>
  })

  return (
    <>
      <Container fluid className="px-4 pt-4 pb-2">
        <Row form className="px-2 pb-2">
          <Col lg={4} className="px-2 mb-2 mb-lg-0">
            <Card className="py-2">
              <h4 className="text-center">
                <div className="h3 d-inline"><i
                  className="material-icons shadow p-2 rounded-circle">question_answer</i>
                </div> Messages <small className="d-inline-block"><InfoTooltip
                msg={"Total number of unread messages"}/></small></h4>
              <div className="h3 text-primary text-center pb-1"><Link
                to="message-center?status=Unread" target="_blank">{dashboardData.messages}</Link>
              </div>
            </Card>
          </Col>

          {userPermissions.includes("Leaves Module") && <Col lg={4} className="px-2 mb-2 mb-lg-0">
            <Card className="py-2">
              <h4 className="text-center">
                <div className="h3 d-inline"><i
                  className="material-icons shadow p-2 rounded-circle">today</i>
                </div>
                Leaves <small className="d-inline-block"><InfoTooltip
                msg={"Total number of submitted leaves"}/></small></h4>
              <div className="h3 text-primary text-center pb-1"><Link
                to="manage-leaves?status=Submitted"
                target="_blank">{dashboardData.leaves}</Link>
              </div>
            </Card>
          </Col>}

          {userPermissions.includes("Expense Module") && <Col lg={4} className="px-2 mb-2 mb-lg-0">
            <Card className="py-2">
              <h4 className="text-center">
                <div className="h3 d-inline"><i
                  className="material-icons shadow p-2 rounded-circle">receipt_long</i>
                </div>
                Expenses <small className="d-inline-block"><InfoTooltip
                msg={"Total number of submitted expenses"}/></small></h4>
              <div className="h3 text-primary text-center pb-1"><Link
                to="view-expenses?status=Submitted"
                target="_blank">{dashboardData.expense || 0}</Link>
              </div>
            </Card>
          </Col>}
        </Row>

        {entitledLeavesCards.length > 0 && <div className="pt-2">
          <div className="mt-4 pb-2 position-relative">
            <span className="tableHeader">Leave Balances</span>
            <Card className="pt-3 px-2">
              <Row form className="flex-nowrap overflow-auto">
                {entitledLeavesCards}
              </Row>
            </Card>
          </div>
        </div>}

      </Container>

      <MessageCenter showHeading/>

    </>
  );
}

const mapStateToProps = (state) => (
  {
    user: state.user.user,
    entitledLeaveList: state.leaves.entitledLeaveList,
    dashboardData: state.home.dashboardData
  }
);

export default connect(mapStateToProps,
  {
    get_entitled_leaves_list,
    get_dashboard_data
  }
)(WorkerHome);
