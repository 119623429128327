import React, {useEffect, useRef} from 'react';
import {Button, Card, CardBody, CardHeader, Container, FormTextarea, InputGroup, InputGroupAddon, InputGroupText} from "shards-react";
import PropTypes from "prop-types";
import InfoTooltip from '../common/InfoTooltip';

function ConversationsArea({messageItems,conversation,msg,onChange, Components, handleUpload, renderFilesBadges,uploading, attachmentFileNames}) {
  //scrolls notes/messages area to the bottom cause new messages/notes are always in the bottom

  const fileRef = useRef();
  useEffect(() => {
    let area = document.getElementsByClassName('conversations-area')
    area[0].scrollTop = area[0].scrollHeight
  }, [messageItems, conversation]);
  return (
    <Card small className="w-100">
          <span className="iconBox"><i
            className="fa-solid fa-comment-dots iconStyle"/></span>
      <h4 className="m-0 section-title headerStyle">Conversations</h4>

      <CardHeader className="p-0 py-3 border-card details-card">
        
      <Container fluid
          className="file-manager__filters border-bottom">
          {Components && Components()}
      </Container>

      </CardHeader>
      <CardBody className="border-card">
        <div className="conversations-area overflow-auto"
             style={{maxHeight: 400, minHeight: 145}}>
          {messageItems}
        </div>
        <InputGroup className="mb-2">
        <FormTextarea id="msg" rows="2" name="msg" className="message-box"
                      required={attachmentFileNames.length === 0}
                      value={msg}
                      placeholder="Type a message here"
                      onChange={onChange}/>
        <InputGroupAddon type="prepend">
                          
                          <InputGroupText className="rounded-right pr-0" onClick={()=> fileRef.current.click()} role="button">
                         
                            <i className="fa-solid fa-paperclip text-dark iconStyle" style={{rotate:'180deg'}}></i>
                            <InfoTooltip infoClass="mt-n4"
                            cssClassName="text-start custom-tooltip-inner"
                            msg="<div align='left'><b class='float-left'>Upload Instructions</b><br/>
                            1. Maximum upload size is 8MB. <br/>
                            2. Allowed file types are PDF, JPEG, PNG, DOC. <br/>
                            3. File name should only contain letters [a-z, A-Z], numbers [0-9], space [' '], period[.], underscore [ _ ] and hyphen[-]. </div>"/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <input ref={fileRef} onChange={(e)=>handleUpload(e, fileRef)} type="file" hidden  accept=".pdf, .jpeg, .png, .docx, .doc"/>

        </InputGroup>
        {renderFilesBadges}
        <Button size='sm'
                className="mx-2 py-2 my-4 float-right"
                type="submit" disabled={conversation.status==="Closed" || uploading}>Send</Button>

      </CardBody>
    </Card>
  );
}

ConversationsArea.propTypes = {
  messageItems:PropTypes.array.isRequired,
  conversation:PropTypes.object.isRequired,
  msg:PropTypes.string.isRequired,
  //onSend:PropTypes.func.isRequired,
  onChange:PropTypes.func.isRequired
};

export default ConversationsArea;
