import React, {useEffect, useState} from 'react';
import {
  Button,
  Card, CardBody, CardHeader,
  Col,
  Container, Form, FormSelect, FormTextarea,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {Link, useHistory, useParams, useLocation} from "react-router-dom";
import InputBox from "../../components/common/InputBox";
import {connect} from "react-redux";
import {
  get_country_list,
  get_region_list,
} from "../../redux/actions/user";
import PropTypes from "prop-types";
import {
  delete_leave_policy_location,
  get_leave_policy_location_details,
  get_leave_policy_location_list,
  save_leave_policy_location_details,
  submit_leave_policy_location_details
} from "../../redux/actions/subscriber/leavePolicy";
import ReactTable from "react-table";
import ConfirmAlert from "../../components/common/ConfirmAlert";

function LeaveLocationConfig({
                               countryList,
                               regionList,
                               leavePolicyLocationList,
                               leavePolicyDetails,
                               leavePolicyLocationDetails,
                               get_leave_policy_location_details,
                               get_leave_policy_location_list,
                               save_leave_policy_location_details,
                               delete_leave_policy_location,
                               submit_leave_policy_location_details,
                               get_country_list,
                               get_region_list,
                               title,
                               changePageMeta
                             }) {


//added Worker Name in the page title once we get response from backend
  useEffect(() => {
    if (Object.keys(leavePolicyDetails).length > 0 && leavePolicyDetails.leaveName) {
      changePageMeta(`${title} - ${leavePolicyDetails.leaveName}`)
    }
  }, [leavePolicyDetails]);


  const [formData, setFormData] = useState({
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 5,

    notes: "",
    leavePolicyLocationId: "0",
    country: '',
    countryId: '',
    region: '',
    regionId: '',
    leavePolicyName: "",
    minimumLeaves: ""
  });

  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });

  const location = useLocation();


  useEffect(() => {
    if (leavePolicyDetails && Object.keys(leavePolicyDetails).length !== 0) {
      setFormData({
        ...formData,
        leavePolicyName: leavePolicyDetails.leaveName,
        notes: leavePolicyDetails.leavePolicyLocationNote,
      })
    }
  }, [leavePolicyDetails]);


  const history = useHistory();

  useEffect(() => {
    if (leavePolicyLocationDetails && Object.keys(leavePolicyLocationDetails).length !== 0) {
      setFormData({
        ...formData,
        "leavePolicyName": leavePolicyLocationDetails.leavePolicyName,
        "leavePolicyLocationId": leavePolicyLocationDetails.leavePolicyLocationId,
        "countryId": leavePolicyLocationDetails.countryId,
        "regionId": leavePolicyLocationDetails.regionId,
        "regionName": leavePolicyLocationDetails.regionName,
        "countryName": leavePolicyLocationDetails.countryName,
        "minimumLeaves": leavePolicyLocationDetails.minimumLeaves
      })
    }
  }, [leavePolicyLocationDetails]);


  let {leavePolicyId} = useParams();

  useEffect(() => {
    get_country_list();
  }, []);

  useEffect(() => {
    if (formData.countryId) {
      get_region_list(formData.countryId);
    }
  }, [formData.countryId]);

  if (!formData.countryId)
    regionList = [];

  useEffect(() => {
    if (leavePolicyId && leavePolicyId !== '0')
      get_leave_policy_location_list(leavePolicyId);
  }, [leavePolicyId]);


  useEffect(() => {
    if (formData.leavePolicyLocationId && formData.leavePolicyLocationId !== '0') {
      get_leave_policy_location_details(leavePolicyId, formData.leavePolicyLocationId)
    }
  }, [formData.leavePolicyLocationId]);


  useEffect(() => {
    if (location.state && location.state.leavePolicyLocationId) {
      get_leave_policy_location_details(leavePolicyId, location.state.leavePolicyLocationId)
    }
  }, [location.state]);


  const onSubmit = (e) => {
    e.preventDefault();
    submit_leave_policy_location_details(leavePolicyId, formData.notes);
    history.goBack();
  }
  const onSave = (e) => {
    e.preventDefault();
    save_leave_policy_location_details(leavePolicyId, formData.leavePolicyLocationId, formData.countryId, formData.regionId, formData.minimumLeaves)
    clearForm();

  }

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };


  var countryDropdownOptions = countryList.map((country) => {
    return <option value={country.countryId}>{country.countryName}</option>;
  });
  var regionDropdownOptions = regionList.map((region) => {
    return <option value={region.regionId}>{region.regionName}</option>;
  });

  let onEditLeavePolicyLocation = (e, leavePolicyLocationId) => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    e.preventDefault();
    setFormData({
      ...formData,
      leavePolicyLocationId: leavePolicyLocationId
    })
  };

  let onDeleteLeaveLocation = (e, leavePolicyLocationId) => {
    e.preventDefault();
    setConfirmAlert({
      ...confirmAlert,
      confirmMsg: 'Are you sure you want to delete this location?',
      visible: true,
      onConfirm: () => {
        delete_leave_policy_location(leavePolicyId, leavePolicyLocationId)
      }
    })
  };

  let clearForm = () => {
    setFormData({
      ...formData,
      leavePolicyLocationId: "0",
      country: '',
      countryId: '',
      region: '',
      regionId: '',
      minimumLeaves: ""
    })
  };

  let onAddLocation = (e) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    clearForm();
  }


  const tableColumns = [
    {
      Header: "Ref#",
      accessor: "leavePolicyLocationId",
      maxWidth: 100,
    },
    {
      Header: "Country",
      accessor: "countryName",
      className: "justify-content-start"
    },
    {
      Header: "Region",
      accessor: "regionName",
      className: "justify-content-start"
    },
    {
      Header: "Minimum Leaves",
      accessor: "minimumLeaves",
      className: "justify-content-end",
      maxWidth: 150
    },
    {
      Header: "Action",
      maxWidth: 200,
      Cell: row => <Row>
        <Col><Link
          onClick={e => onEditLeavePolicyLocation(e, row.original.leavePolicyLocationId)}>Edit</Link></Col>
        <Col><Link
          onClick={e => onDeleteLeaveLocation(e, row.original.leavePolicyLocationId)}>Delete</Link></Col>
      </Row>
    }
  ];

  return (
    <React.Fragment>
      <ConfirmAlert confirmAlert={confirmAlert}
                    setConfirmAlert={setConfirmAlert}/>
      <Container fluid className="main-content-container p-4">
        <Card small className="mb-4 w-100">
          <h4
            className="m-0 section-title idStyle">Ref# {formData.leavePolicyLocationId}</h4>
          <ListGroup flush>
            <ListGroupItem className="border-card p-3">
              <Row>
                <Col>
                  <Form onSubmit={onSave}>
                    <Row form>

                      {/* <InputBox label="Reference Number"
                                id="leavePolicyLocationId"
                                placeholder="Reference Number"
                                name='leavePolicyLocationId'
                                value={formData.leavePolicyLocationId}
                                readOnly/> */}

                      <InputBox label="Leave Policy Id" id="leavePolicyId"
                                placeholder="Leave Policy Id"
                                name="leavePolicyId"
                                value={leavePolicyId}
                                readOnly/>

                      <InputBox label="Leave Policy Name" id="leavePolicyName"
                                placeholder="Leave Policy Name"
                                name="leavePolicyName"
                                value={formData.leavePolicyName}
                                readOnly/>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="countryId">Country</label>
                        <FormSelect id="countryId" name='countryId'
                                    onChange={onChange}
                                    value={formData.countryId}
                                    required>
                          <option value=''>Select</option>
                          {countryDropdownOptions}
                        </FormSelect>
                      </Col>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="regionId">Region</label>
                        <FormSelect id="regionId" name='regionId'
                                    onChange={onChange}
                                    value={formData.regionId}
                                    required>
                          <option value=''>Select</option>
                          {regionDropdownOptions}
                        </FormSelect>
                      </Col>

                      <InputBox label="Minimum Leaves" id="minimumLeaves"
                                placeholder="Minimum Leaves"
                                name="minimumLeaves"
                                type="number"
                                value={formData.minimumLeaves}
                                onChange={onChange}/>


                      <Col sm="12" md="12" className="form-group p-2 m-0">
                        <label htmlFor="notes">Leave Policy Region
                          Notes</label>
                        <FormTextarea
                          id="notes"
                          rows="10"
                          name="notes"
                          value={formData.notes}
                          onChange={onChange}
                        />
                      </Col>

                    </Row>
                    <Row className="px-3">
                      <Col sm="12" md="12" lg="12"
                           className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">
                        <Button className="mx-1 py-2 mx-md-2" size="sm"
                                theme="accent">Save Location</Button>

                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>

        <Card className="tableCard p-0 border-card">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters border-bottom">
              <Row>
                {/*Filters :: Page Size*/}
                <Col lg="6"
                     className="file-manager__filters__rows d-flex align-items-center col-6">
                  <span>Show</span>
                  <FormSelect
                    size="sm"
                    name='pageSize'
                    value={formData.pageSize}
                    onChange={onChange}
                  >
                    {formData.pageSizeOptions.map((size, idx) => (
                      <option key={idx} value={size}>
                        {size} rows
                      </option>
                    ))}
                  </FormSelect>
                </Col>

                <Col lg="6"
                     className="file-manager__filters__search d-flex justify-content-center justify-content-lg-end col-6">
                  <Button className="mx-1 py-2 mx-md-2" size="sm" theme="accent"
                          onClick={onAddLocation}>Add Location</Button>
                </Col>


              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <div className="">
              <ReactTable
                columns={tableColumns}
                data={leavePolicyLocationList}
                pageSize={Number(formData.pageSize)}
                showPageSizeOptions={false}
                resizable={false}
                noDataText="No results found"
              />
            </div>
          </CardBody>
        </Card>

        <Row className="px-3 py-2">
          <Col sm="12" md="12" lg="12"
               className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">

            <Button className="mx-1 py-2 mx-md-2" size="sm" theme="accent"
                    onClick={onSubmit}>Submit</Button>

            <Button className="mx-1 py-2 mx-md-2" size="sm"
                    theme="accent"
                    onClick={e => history.goBack()}>Cancel</Button>
          </Col>
        </Row>

      </Container>
    </React.Fragment>
  );
}

LeaveLocationConfig.propTypes = {
  countryList: PropTypes.array.isRequired,
  regionList: PropTypes.array.isRequired,
  leavePolicyLocationList: PropTypes.array.isRequired,
  leavePolicyLocationDetails: PropTypes.array.isRequired,

  get_country_list: PropTypes.func.isRequired,
  get_region_list: PropTypes.func.isRequired,
  get_leave_policy_location_list: PropTypes.func.isRequired,
  get_leave_policy_location_details: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  countryList: state.user.countryList,
  regionList: state.user.regionList,
  leavePolicyLocationList: state.leavePolicy.leavePolicyLocationList,
  leavePolicyLocationDetails: state.leavePolicy.leavePolicyLocationDetails,
  leavePolicyDetails: state.leavePolicy.leavePolicyDetails,
});

export default connect(mapStateToProps, {
  get_country_list,
  get_region_list,
  get_leave_policy_location_list,
  get_leave_policy_location_details,
  save_leave_policy_location_details,
  delete_leave_policy_location,
  submit_leave_policy_location_details,
})(LeaveLocationConfig);


