import React, { useState} from 'react';
import {
  Alert,
  Button,
  Card, CardBody,   Col,
  Container, Form, FormGroup, FormInput,  Row
} from "shards-react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import { useHistory} from "react-router-dom";
import {disable_2fa} from "../redux/actions/settings";

function DisableTwoFA({
                        require2FA,
                        message,
                        success,
                        alerts,
                        disable_2fa,
                      }) {

  const history = useHistory();
  const [formData, setFormData] = useState({
    password: "",
    twoFA: "",
  });
  const {password, twoFA} = formData;
  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  const onSubmit = (e) => {
    e.preventDefault();
    disable_2fa(password, twoFA);
  };

  if(!require2FA){
    history.goBack();
  }
  return (
    <>
      <Container fluid
                 className="main-content-container py-4">
        <Row noGutters className="h-100">
          <Col lg="12" className="col-12">
            <Card>
              <CardBody>
                <Form onSubmit={onSubmit}>
                  <Row>
                    <Col lg="6" className="col-12">
                      <FormGroup>
                        <label htmlFor="password">Password</label>
                        <FormInput
                          type="password"
                          name="password"
                          value={password}
                          id="password"
                          required
                          placeholder="Password"
                          onChange={onChange}
                        />
                      </FormGroup>

                    </Col>
                    <Col lg="6" className="col-12">
                      <FormGroup>
                        <label htmlFor="twoFA">2FA Code</label>
                        <FormInput
                          type="password"
                          name="twoFA"
                          id="twoFA"
                          value={twoFA}
                          required
                          placeholder="2FA Code"
                          onChange={onChange}
                        />
                      </FormGroup>

                    </Col>
                  </Row>

                  <div className="d-flex justify-content-center justify-content-lg-end">
                    <Button className="py-2" size="sm"
                            theme="accent" type="submit">Disable 2FA</Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

DisableTwoFA.propTypes = {
  user: PropTypes.object.isRequired,
  disable_2fa: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user.user,
  require2FA: state.settings.require2FA,
  alerts:state.alert,
  message: state.settings.message,
  success: state.settings.success
});

export default connect(mapStateToProps, {disable_2fa})(
  DisableTwoFA
);
