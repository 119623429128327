import React, {useEffect, useState} from 'react';
import {
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import InputBox from '../../components/common/InputBox';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
  clear_allowance,
  delete_allowance_type_details, get_allowance_type_details,
  save_allowance_type_details,
} from "../../redux/actions/user";
import {useHistory, useParams} from "react-router-dom";
import ConfirmAlert from "../../components/common/ConfirmAlert";


function AddEditConfigAllowance({
                                  get_allowance_type_details,
                                  clear_allowance,
                                  save_allowance_type_details,
                                  delete_allowance_type_details,
                                  allowanceType
                                }) {

  let {id} = useParams();
  useEffect(() => {
    if (id && id !== '0') {
      get_allowance_type_details(id);
    }
    return ()=>clear_allowance();
  }, [id]);


  const history = useHistory();
  const [formData, setFormData] = useState({
    allowanceTypeId: 0,
    allowanceTypeName: '',
  });

  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });

  useEffect(() => {
    if (allowanceType.allowanceTypeId || allowanceType.allowanceType) {
      setFormData({
        ...formData,
        allowanceTypeId: allowanceType.allowanceTypeId,
        allowanceTypeName: allowanceType.allowanceType,
      });
    }
  }, [allowanceType]);

  const {allowanceTypeId, allowanceTypeName} = formData;
  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  const onSubmit = (e) => {
    e.preventDefault();
    save_allowance_type_details(allowanceTypeId, allowanceTypeName);
    history.goBack();
  };
  const onDelete = (e) => {
    e.preventDefault();
    setConfirmAlert({
      ...confirmAlert,
      confirmMsg: 'Are you sure you want to delete this allowance type?',
      visible: true,
      onConfirm: () => {
        delete_allowance_type_details(allowanceTypeId);
        history.goBack();
      }
    })

  };


  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  return (
    <Container fluid className="main-content-container p-4">
      <ConfirmAlert confirmAlert={confirmAlert}
                    setConfirmAlert={setConfirmAlert}/>
      <Card small className="mb-5 w-100">
        <h4 className="m-0 section-title idStyle">Ref# {id}</h4>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>
                <Form id="clientDetailsForm" onSubmit={onSubmit}>
                  <Row form>
                    <InputBox placeholder="Enter Allowance Type" label="Allowance Type" name='allowanceTypeName' id='allowanceTypeName'
                              value={formData.allowanceTypeName}
                              onChange={onChange}/>


                    <Col lg="3" className="form-group p-2 mb-0">
                      <div
                           className="d-flex justify-content-between mt-4 pt-2">
                        <button type="submit"  className="btn btn-primary w-100">Save</button>
                        <button onClick={onDelete} className="btn btn-primary ml-2 w-100">Delete</button>
                        <button onClick={goBack} className="btn btn-primary ml-2 w-100">Close</button>
                      </div>
                    </Col>

                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Container>
  );
}

AddEditConfigAllowance.propTypes = {
  get_allowance_type_details: PropTypes.func.isRequired,
  save_allowance_type_details: PropTypes.func.isRequired,
  delete_allowance_type_details: PropTypes.func.isRequired,
  allowanceType: PropTypes.object.isRequired,

};
const mapStateToProps = (state) => ({
  allowanceType: state.user.allowanceType,
});
export default connect(mapStateToProps, {
  get_allowance_type_details,
  clear_allowance,
  save_allowance_type_details,
  delete_allowance_type_details

})(AddEditConfigAllowance);
