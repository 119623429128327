import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  Card, Col,
  Container, DatePicker, Form, FormInput, FormTextarea,
  ListGroup, ListGroupItem,
  Row
} from "shards-react";
import {useHistory, useParams} from "react-router-dom";
import {setAPIDate, setInputDate} from "../../utils/date";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import ExpenseItemsCard
  from "../../components/worker/edit-expense-report/ExpenseItemsCard";
import MileageItemsCard
  from "../../components/worker/edit-expense-report/MileageItemsCard";
import {
  clear_expense_sheet,
  get_user_expense_sheet_details, get_user_mileage_expense_ytd,
  save_user_expense_sheet_details, saveReportNameAndDate,
  submit_user_expense_sheet_details, withdraw_user_expense_sheet_details, delete_expense_report
} from "../../redux/actions/worker/expenses";
import {formatNotesString, numberToMonetary} from "../../utils/general";
import InfoTooltip from "../../components/common/InfoTooltip";
import UnloadPageConfirm from '../../components/common/UnloadPageConfirm';

function EditExpenseReport({
                             user,
                             reportDescription,
                             reportDate,
                             expenseSheet,
                             userMileageExpenseYTD,
                             sheetRefNum,
                             saveReportNameAndDate,
                             clear_expense_sheet,
                             get_user_expense_sheet_details,
                             get_user_mileage_expense_ytd,
                             save_user_expense_sheet_details,
                             submit_user_expense_sheet_details,
                             withdraw_user_expense_sheet_details,
                             delete_expense_report,
                             reportDeleted
                           }) {

  const history = useHistory();

  const [state, setState] = useState({
    date: '',
    description: '',
    totalFileSize:0
  });
  const [currentLocation, setCurrentLocation] = useState('/add-expense-report')
  const [actionPerformed, setActionPerformed] = useState(false);
  const unblockHandle = useRef();
  const [pageUnloadConfirm, setPageUnloadConfirm] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null,
    onCancell: null
  });

  let {sheetRefNumber} = useParams();

  useEffect(() => {
    get_user_mileage_expense_ytd();
  }, []);


  useEffect(() => {
    setState({
      ...state,
      description: reportDescription,
      date: reportDate
    });
  }, [reportDescription, reportDate]);


  sheetRefNumber = sheetRefNumber || sheetRefNum;

  useEffect(() => {
    if (sheetRefNumber && sheetRefNumber !== "0") {
      get_user_expense_sheet_details(sheetRefNumber);
    }
    return () => {
      clear_expense_sheet();
    }
  }, [sheetRefNumber]);

  const deleteExpenseReport = (e)=>{
    //document.getElementById('save').click();
    delete_expense_report(user.userId, expenseSheet.refNum);
    //history.push(currentLocation)

   }
  const handlePageUnload = ()=>{
    setPageUnloadConfirm({
      confirmMsg: 'Do you want to Cancel the whole Expense Report?',
      visible: true,
      onConfirm: () => {
        deleteExpenseReport();

      },
      onCancel: ()=>{
      }
    })
   }
   useEffect(() => {
    
    unblockHandle.current = history.block((targetLocation) => {
      if((['/view-profile', '/manage-leaves', '/worker-home', '/view-expenses', '/message-center'].includes(targetLocation.pathname) || targetLocation.pathname.includes('/user-add-message')) && expenseSheet.refNum)
      {
        setCurrentLocation(targetLocation.pathname)
         handlePageUnload()

         if(!history.location.pathname.includes('/add-expense-report') || actionPerformed){
          return true;
         }
        
      }
      else{
        return true;
      }
      return false;
    });
    return function () {
      unblockHandle.current && unblockHandle.current()
      
    }
  })
  useEffect(()=>{

    if(actionPerformed){
      history.goBack();
    }

    if(reportDeleted){
      history.push(currentLocation);
    }
  },[reportDeleted, actionPerformed])

  useEffect(() => {
    if (expenseSheet && Object.keys(expenseSheet).length !== 0 && !(reportDescription || reportDate)) {
      let fileSize = expenseSheet.expenseItemList.reduce((total,currentValue) => total = total + currentValue.receiptFileSize,0);
      setState({
        ...state,
        description: expenseSheet.description,
        date: setAPIDate(expenseSheet.date),
        totalFileSize: fileSize
      });
    }
  }, [expenseSheet]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const expenseItemsListArray = [];
    const mileageItemListArray = [];
    if (expenseSheet.expenseItemList) {
      expenseSheet.expenseItemList.map((item) => expenseItemsListArray.push(item.refNum));
    }
    if (expenseSheet.mileageItemList) {
      expenseSheet.mileageItemList.map((item) => mileageItemListArray.push(item.refNumber));
    }

    if (e.nativeEvent.submitter.name === "save") {
      await save_user_expense_sheet_details(
        user.userId,
        sheetRefNumber,
        setInputDate(state.date),
        state.description,
        expenseItemsListArray,
        mileageItemListArray
      );
    } else {
      await submit_user_expense_sheet_details(
        user.userId,
        sheetRefNumber,
        setInputDate(state.date),
        state.description,
        expenseItemsListArray,
        mileageItemListArray
      );
    }

    //history.push('/view-expenses');
    setActionPerformed(true)
  };

  const onWithDraw = async () => {
    await withdraw_user_expense_sheet_details(user.userId, sheetRefNumber);
    history.push('/view-expenses');
  };

  const onAddExpense = () => {
    saveReportNameAndDate(state.description, state.date);
    history.push(`/add-expense-item/${(sheetRefNumber) ? sheetRefNumber : 0}/0`,state);
  }

  const onAddMileage = () => {
    saveReportNameAndDate(state.description, state.date);
    history.push(`/add-mileage-item/${(sheetRefNumber) ? sheetRefNumber : 0}/0`);
  }

  const onEditExpense = (refNum) => {
    saveReportNameAndDate(state.description, state.date);
  }

  const onEditMileage = (refNum) => {
    saveReportNameAndDate(state.description, state.date);
  }

  useEffect(() => {
    if (sheetRefNum)
      history.replace(`/add-expense-report/${sheetRefNum}`);
  }, [sheetRefNum]);

  return (
    <Container fluid className="main-content-container px-4 pb-4">

      <Card small className="my-4 w-100">
        <h4
          className="m-0 section-title idStyle">Ref# {(expenseSheet.refNum && expenseSheet.refNum !== '0') ? `${expenseSheet.refNum}` : 0}</h4>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>
                <Form onSubmit={onSubmit} id="expenseForm">
                  <Row form>

                    <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="reportDate">Report Date</label>
                      <DatePicker
                        autoComplete='off'
                        id="reportDate"
                        required
                        name='date'
                        dateFormat='yyyy-MM-dd'
                        selected={state.date}
                        onChange={(date) =>
                          setState({...state, date: date})
                        }
                        value={state.to}
                        placeholderText="YYYY-MM-DD"
                      />
                    </Col>

                    <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="description">Report Name <InfoTooltip
                        msg="Suggested Format - Name - Expense Report - Period"/></label>
                      <FormInput
                        id="description"
                        name='description'
                        onInvalid={e => e.target.setCustomValidity("Insert Report Name")}
                        required
                        value={state.description}
                        onChange={e => {
                          e.target.setCustomValidity("")
                          setState({...state, [e.target.name]: e.target.value});
                        }}
                        placeholder="Enter Report Name"
                      />
                    </Col>

                    <Col sm="12" md="6" lg="3"
                         className="form-group p-2 mb-0 offset-3 d-flex align-items-end justify-content-end">
                      <span className="text-bold text-nowrap py-2">Expense Report Total: {numberToMonetary(expenseSheet.status === "Submitted" ?  expenseSheet.submittedReportTotal : expenseSheet.status === 'Draft' ? expenseSheet.draftReportTotal :  expenseSheet.approvedReportTotal)}</span>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>

      <ExpenseItemsCard
        expenseItemList={expenseSheet.expenseItemList}
        submittedItemTotal={String(expenseSheet.submittedItemTotal)}
        draftItemTotal={String(expenseSheet.draftItemTotal)}
        sheetRefNumber={sheetRefNumber}
        status={expenseSheet.status}
        onAddExpense={onAddExpense}
        onEditExpense={onEditExpense}
      />

      <MileageItemsCard
        mileageItemList={expenseSheet.mileageItemList}
        submittedMileageTotal={String(expenseSheet.submittedMileageTotal)}
        approvedMileageTotal={String(expenseSheet.submittedMileageTotal)}
        draftMileageTotal={String(expenseSheet.draftMileageTotal)}
        savedYTD={userMileageExpenseYTD.savedYTD}
        submittedYTD={userMileageExpenseYTD.submittedYTD}
        approvedYTD={userMileageExpenseYTD.approvedYTD}
        reviewedYTD={userMileageExpenseYTD.reviewedYTD}
        totalYTD={userMileageExpenseYTD.totalYTD}
        sheetRefNumber={sheetRefNumber}
        status={expenseSheet.status}
        onAddMileage={onAddMileage}
        onEditMileage={onEditMileage}/>

      <Card small className="my-4 w-100">
        <span className="tableHeader"><label htmlFor="notes"
                                             className="text-bold m-0">Notes</label></span>
        <UnloadPageConfirm confirmAlert={pageUnloadConfirm}
                    setConfirmAlert={setPageUnloadConfirm}/>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>
                <Col sm="12" md="12" className="form-group p-2 m-0">
                  <FormTextarea
                    id="notes"
                    rows="5"
                    className="p-2"
                    name="notes"
                    value={(expenseSheet && expenseSheet.notes) ? formatNotesString(expenseSheet.notes) : ""}
                    readOnly
                  />
                </Col>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>

      <Row className="px-3">
        <Col sm="12" md="12" lg="12"
             className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">

          {(Object.keys(expenseSheet).length === 0 || (["Saved","Rejected", "Draft"].includes(expenseSheet.status))) &&
          <>
          <InfoTooltip msg="The draft will be available to edit until you are ready to submit the report"/>
            <Button className="mx-1 py-2 mx-md-2" size="sm" theme="accent"
                    type="submit" name="save" form="expenseForm">Save Draft</Button>
            <Button className="mx-1 py-2 mx-md-2" size="sm" theme="accent"
                    type="submit" name="submit"
                    form="expenseForm">Submit</Button>
          </>}
          {(Object.keys(expenseSheet).length && (expenseSheet.status === "Saved" || expenseSheet.status === "Draft" || expenseSheet.status === "Submitted")) ?
            <Button className="mx-1 py-2 mx-md-2" size="sm" theme="primary"
                    onClick={e => onWithDraw()}>Withdraw</Button> : null}

          <Button className="mx-1 py-2 mx-md-2" size="sm"
                  theme="accent" onClick={e => history.goBack()}>Cancel</Button>

        </Col>
      </Row>
    </Container>
  );
}

EditExpenseReport.propTypes = {
  user: PropTypes.object.isRequired,
  expenseSheet: PropTypes.object.isRequired,
  get_user_expense_sheet_details: PropTypes.func.isRequired,
  save_user_expense_sheet_details: PropTypes.func.isRequired,
  submit_user_expense_sheet_details: PropTypes.func.isRequired,
  withdraw_user_expense_sheet_details: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  reportDescription: state.expenses.reportDescription,
  reportDate: state.expenses.reportDate,
  expenseSheet: state.expenses.expenseSheet,
  userMileageExpenseYTD: state.expenses.userMileageExpenseYTD,
  sheetRefNum: state.expenses.sheetRefNum,
  reportDeleted: state.expenses.reportDeleted
});
export default connect(mapStateToProps, {
  get_user_expense_sheet_details,
  save_user_expense_sheet_details,
  submit_user_expense_sheet_details,
  withdraw_user_expense_sheet_details,
  get_user_mileage_expense_ytd,
  saveReportNameAndDate,
  clear_expense_sheet,
  delete_expense_report
})(EditExpenseReport);

