import {
  GET_CLIENT_VIEW_WORKER_LEAVE,
  GET_CLIENT_VIEW_WORKER_LEAVE_ERROR,
  SEARCH_WORKER_LEAVES_LIST,
  SEARCH_WORKER_LEAVES_LIST_ERROR,
  CLEAR_WORKER_LEAVE_DETAILS,
  GET_CLIENT_LEAVE_POLICY_NAME_LIST,
  GET_CLIENT_LEAVE_POLICY_NAME_LIST_ERROR
} from "../../actions/types";


const initialState = {
  workerLeavesList: [],
  clientWorkerLeaveDetails: {},
  clientLeavePolicyNamesList:[]
}


export const clientLeaves = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_WORKER_LEAVES_LIST:
      return {
        ...state,
        workerLeavesList: action.payload.leavesList,
      };
    case SEARCH_WORKER_LEAVES_LIST_ERROR:
      return {
        ...state,
        workerLeavesList: [],
      };

    case GET_CLIENT_VIEW_WORKER_LEAVE:
      return {
        ...state,
        clientWorkerLeaveDetails: action.payload.leaveDetails,
      };
    case GET_CLIENT_VIEW_WORKER_LEAVE_ERROR:
      return {
        ...state,
        clientWorkerLeaveDetails: {},
      };
    case CLEAR_WORKER_LEAVE_DETAILS:
      return {
        ...state,
        clientWorkerLeaveDetails: {},
      };


    case GET_CLIENT_LEAVE_POLICY_NAME_LIST:
      return {
        ...state,
        clientLeavePolicyNamesList: action.payload.leavePolicyList,
      };
    case GET_CLIENT_LEAVE_POLICY_NAME_LIST_ERROR:
      return {
        ...state,
        clientLeavePolicyNamesList: [],
      };





    default:
      return state;
  }
};
export default clientLeaves
