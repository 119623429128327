import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";
import ReactTable from "react-table";
import {
  clear_sysadmin_subscriber_profile,
  get_sysadmin_subscriber_list
} from '../../redux/actions/user';

import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container, FormInput,
  FormSelect,
  Row
} from "shards-react";


function SystemSubscriber({
                            user,
                            subscribers,
                            get_sysadmin_subscriber_list,
                            clear_sysadmin_subscriber_profile
                          }) {


  const history = useHistory()

  const [formData, setFormData] = useState({
    subscriberId: '',
    subscriberName: '',
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
  });

  useEffect(() => {
    get_sysadmin_subscriber_list(subscriberId, subscriberName);
  }, []);

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onSubmit = (e) => {
    e.preventDefault();
    get_sysadmin_subscriber_list(subscriberId, subscriberName);
  };


  const {subscriberId, subscriberName} = formData;

  const tableData = subscribers;
  var {pageSize, pageSizeOptions} = formData;

  const tableColumns = [

    {
      Header: "User ID",
      accessor: "subscriberId",
      maxWidth: 100,
      className: "text-center",
      Cell: row =>
        <Link
          to={`/admin-manage-subscriber-profile/${row.original.subscriberId}`} onClick={clear_sysadmin_subscriber_profile}>{row.original.subscriberId}</Link>
    },
    {
      Header: "User Name",
      accessor: "subscriberName",
      className: "justify-content-start"
    }
  ];

  return (
    <Container fluid className="main-content-container px-4 py-4">
      <div noGutters
           className="page-header flex-column flex-md-row py-2 d-flex justify-content-end bg-white tableSearch">
        <form className="row mx-0 justify-content-end" onSubmit={onSubmit}>
          <div className="d-flex align-items-center p-2">
            <FormInput
              name='subscriberId'
              value={formData.subscriberId}
              onChange={onChange}
              placeholder="User ID"
            />
          </div>
          <div className="d-flex align-items-center p-2">
            <FormInput
              name='subscriberName'
              value={formData.subscriberName}
              onChange={onChange}
              placeholder="User Name"
            />
          </div>
          <div className="d-flex align-items-center justify-content-end p-2">
            <Button type="submit" className="mx-2 py-2 my-2" size='sm'
                    theme="accent"
                    onClick={onChange}>Search</Button>
          </div>
        </form>
      </div>
      <Card className="tableCard p-0">
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {/* Filters :: Page Size */}
              <Col
                className="file-manager__filters__rows d-flex align-items-center col-6">
                <span>Show</span>
                <FormSelect
                  size="sm"
                  name='pageSize'
                  value={pageSize}
                  onChange={onChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size} rows
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col
                className="file-manager__filters__search d-flex justify-content-end col-6">
                <Link to="/admin-manage-subscriber-profile/0" onClick={clear_sysadmin_subscriber_profile}>
                  <Button className="my-2 py-2 mx-2" size='sm'
                          theme="accent">Add Subscriber</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
              columns={tableColumns}
              data={tableData}
              pageSize={Number(pageSize)}
              showPageSizeOptions={false}
              resizable={false}
              noDataText="No results found"
            />
          </div>
        </CardBody>
      </Card>
    </Container>
  );
}

SystemSubscriber.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  subscribers: PropTypes.array.isRequired,
  get_sysadmin_subscriber_list: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  subscribers: state.user.subscribers,
});
export default connect(mapStateToProps, {
  get_sysadmin_subscriber_list,
  clear_sysadmin_subscriber_profile
})(
  SystemSubscriber
);

