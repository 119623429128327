import React from 'react'
import {
    Alert,
    Col,
    Container,
    Row,
    FormSelect
} from "shards-react";

const change = (e) => {
}

export default function CFormSelect({ label,  onChange, name,value, id,data ,defaultOptionName, required}) {
    return (
        <Col lg="3" className="form-group p-2 mb-0">
            <label htmlFor={id === "" ? name : id}>{label}</label>
            <FormSelect id={id} name={name} value={value === null?'':value}
                onChange={onChange} required={required} className='d-block'>
                <option value="">{(defaultOptionName?defaultOptionName:"Select")}</option>
                {data}
            </FormSelect>
        </Col>
    )
}
