import React from 'react';
import {
  Card,
  CardHeader,
  Col, Form, ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {setDateForTable} from "../../../utils/date";
import {numberToMonetary} from "../../../utils/general";
import {useLocation} from "react-router-dom";

function EmploymentInfo(props) {
  const {viewProfile} = props
  return (
    <Card id='employment' small className="details-card mb-5 w-100">
      {viewProfile ?
        <CardHeader className="border-bottom">
          <h4 className="m-0 section-title">Employment Information</h4>
        </CardHeader> :
        <>
          <span className="iconBox"><i
  className="fas fa-users iconStyle"/></span>
          <h4 className="m-0 section-title headerStyle">Employment
            Information</h4>
        </>
      }
      <ListGroup flush>
        <ListGroupItem className={viewProfile ? "p-3" : "p-3 mt-3"}>
          <Row>
            <Col>
              <Form>
                <Row form>


                  {props.userPermissions.includes("Employment Information") && <>
                    <Col lg="3" className="form-group p-2 mb-0">
                      <label>Client</label>
                      <span
                        className={viewProfile ? " form-control" : "inputBoxClr  form-control"}>{props.clientName}</span>
                    </Col>

                    <Col lg="3" className="form-group p-2 mb-0">
                      <label>Job Title</label>
                      <span
                        className={viewProfile ? " form-control" : "inputBoxClr  form-control"}>{props.jobTitle}</span>
                    </Col>
                    <Col lg="3" className="form-group p-2 mb-0">
                      <label>Department</label>
                      <span
                        className={viewProfile ? " form-control" : "inputBoxClr  form-control"}>{props.department}</span>
                    </Col>


                    <Col lg="3" className="form-group p-2 mb-0">
                      <label>Direct Supervisor</label>
                      <span
                        className={viewProfile ? " form-control" : "inputBoxClr  form-control"}>{props.clientContactName}</span>
                    </Col>

                    <Col lg="3" className="form-group p-2 mb-0">
                      <label>Payroll ID</label>
                      <span
                        className={viewProfile ? " form-control" : "inputBoxClr  form-control"}>{props.payrollId}</span>
                    </Col>
                  </>}


                  {/*props.userPermissions.includes("Compensation") && <Col lg="3" className="form-group p-2 mb-0">
                    <label>Compensation</label>
                    <span
                      className={viewProfile ? " form-control" : "inputBoxClr  form-control"}>{(props.compensation ? numberToMonetary(props.compensation, 2) : "") + " " + (props.compensationType ? props.compensationType : "")}</span>
    </Col>*/}

                  {props.userPermissions.includes("Employment Information") && <Col lg="3" className="form-group p-2 mb-0">
                    <label>Start Date</label>
                    <span
                      className={viewProfile ? " form-control" : "inputBoxClr  form-control"}>{setDateForTable(props.startDate)}</span>
                  </Col>}

                </Row>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
}


export default EmploymentInfo;
