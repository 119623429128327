import api from "../../../utils/api";
import {
  GET_CLIENT_EMPLOYMENT_STATUS_LIST,
  GET_CLIENT_EMPLOYMENT_STATUS_LIST_ERROR,
  GET_CLIENT_VIEW_WORKER_PROFILE,
  CLEAR_CLIENT_VIEW_WORKER_PROFILE,
  GET_CLIENT_VIEW_WORKER_PROFILE_ERROR,
  GET_WORKER_JOURNEY_FOR_CLIENT,
  GET_WORKER_JOURNEY_FOR_CLIENT_ERROR,
  SEARCH_WORKER_FOR_CLIENT_LIST,
  SEARCH_WORKER_FOR_CLIENT_LIST_ERROR
} from "../types";
import {setAlert} from "../alert";

export const get_client_employment_status_list = () => async (dispatch) => {
  try {
    const res = await api.get(
      `api/client/getEmploymentStatusList`
    );
    dispatch({
      type: GET_CLIENT_EMPLOYMENT_STATUS_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CLIENT_EMPLOYMENT_STATUS_LIST_ERROR,
    });
  }
};
export const search_worker_for_client_list = (
  userName,
  employmentStatus,
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/client/searchWorkersList`, {
        params: {
          userName: userName,
          employmentStatus: employmentStatus,
        }
      }
    );
    dispatch({
      type: SEARCH_WORKER_FOR_CLIENT_LIST,
      payload: res.data,
    });
  } catch (error) {
    const success = error.response.data.success;
    if (!success) {
      dispatch(setAlert(error.response.data.message, "danger"));
    }
    dispatch({
      type: SEARCH_WORKER_FOR_CLIENT_LIST_ERROR,
    });
  }
};
export const get_client_view_worker_profile = (
  userId
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/client/getWorkerProfile?userId=${userId}`
    );
    dispatch({
      type: GET_CLIENT_VIEW_WORKER_PROFILE,
      payload: res.data,
    });
  } catch (error) {
    const success = error.response.data.success;
    if (!success) {
      dispatch(setAlert(error.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CLIENT_VIEW_WORKER_PROFILE_ERROR,
    });
  }
};
export const get_worker_journey_for_client = (
  userId
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/client/getWorkerJourney?userId=${userId}`
    );
    dispatch({
      type: GET_WORKER_JOURNEY_FOR_CLIENT,
      payload: res.data,
    });
  } catch (error) {
    const success = error.response.data.success;
    if (!success && error.response.status !== 401) {
      dispatch(setAlert(error.response.data.message, "danger"));
    }
    dispatch({
      type: GET_WORKER_JOURNEY_FOR_CLIENT_ERROR,
    });
  }
};

export const clear_client_view_worker_profile = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CLIENT_VIEW_WORKER_PROFILE
  });
};
