import React from 'react';
import CustomCard from "../common/CustomCard";
import InputBox from "../common/InputBox";
import {monetaryToNumber} from "../../utils/general";

function Allowances({formData,setFormData}) {
  var allowanceTypes =formData.allowanceTypes;
  var currency =formData.ciCompensationCurrency;

  var allowanceTypesFields = allowanceTypes.map((allowanceTypeVal, i) => {
    return (
      <InputBox
      key={allowanceTypeVal.allowanceTypeName + allowanceTypeVal.allowanceTypeId}
        label={allowanceTypeVal.allowanceTypeName}
        id={"allowance_"+allowanceTypeVal.allowanceTypeId}
        name='amount'
        value={currency+ ' ' + allowanceTypeVal.amount}
        onChange={(e) => {
          let value = e.target.value.replace( /^\D+/g, '');
          let values = value.split('.');
          if (values.length > 1)
            value = monetaryToNumber(values[0]).toLocaleString() + "." + values[1].replace(/\D/g, '').substr(0, 4)
          else
            value = monetaryToNumber(value).toLocaleString()


          let newAllowanceType = allowanceTypes;
          newAllowanceType[i].amount = (value && value !== 'NaN') ? value : 0;
          setFormData({ ...formData, allowanceTypes: newAllowanceType })
        }}
      />
    );
  });

  return (
    <CustomCard id="allowances" title="Allowances" icon={true} iconClass="fas fa-car iconStyle" noform>
      {allowanceTypesFields}
    </CustomCard>
  );
}

export default Allowances;
