import React, {useState} from 'react';
import {
  Button, Card, CardBody,
  CardHeader,
  Col,
  Container,
  FormSelect,
  Row
} from "shards-react";
import ReactTable from "react-table";
import {Link, useHistory} from "react-router-dom";

function Contacts({contactList,clientId,
                    clientName,setAlert}) {

  const [state, setState] = useState({
    pageSizeOptions: [10, 15, 20, 25, 30],
    pageSize: 10,
  });

  const onChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  };

  const tableData = contactList

  var {pageSize, pageSizeOptions} = state;

  if(tableData.length<10) {
    pageSize = tableData.length
    pageSizeOptions = [tableData.length]
  }
  const tableColumns = [
    {
      Header: <span className="text-wrap">ID</span>,
      accessor: "userId",
      maxWidth: 100,
      className: "text-center",
      Cell: row =>
        <Link to={`/manage-user-profile/${row.original.userId}?role=${row.original.role}`}>{row.original.userId}</Link>
    },
    {
      Header: <span className="text-wrap">Name</span>,
      accessor: "name",
      className: "justify-content-start text-left text-wrap"
    },
    {
      Header: <span className="text-wrap">Title</span>,
      accessor: "title",
      className: "justify-content-start text-left"
    },

    {
      Header: <span className="text-wrap">Role</span>,
      accessor: "role",
      maxWidth:100,
      className: "text-center",
      Cell: row =>
        <span>{row.original.role}</span>
    },
  ];
  let addContactState={
    role: 'clientUserFlag',
    clientId: clientId,
    clientName: clientName,
  };
  const history = useHistory();
  let addClientContactHandler =(e)=>{
    e.preventDefault();

    if (clientId != "0")
      history.push({pathname: "/manage-user-profile/0", state: addContactState});
    else
      setAlert("First save client profile and then try again.", "danger");
  };
  return (
    <div className="position-relative mt-4 mb-5">
      <Card className="tableCard p-0 w-100 border-card">
        <span className="iconBox iconStyle"><i className="material-icons">people</i></span>
        <h4 className="m-0 section-title headerStyle">Client Contacts</h4>
        <CardHeader className="p-0 pt-2 border-card">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              <Col className="file-manager__filters__rows d-flex justify-content-center justify-content-md-start align-items-center col-6">
                <span>Show</span>
                <FormSelect
                  size="sm"
                  name='pageSize'
                  value={pageSize}
                  onChange={onChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size} rows
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col
                className="file-manager__filters__search d-flex align-items-center justify-content-center justify-content-md-end col-6">

                <Button className="mx-2 my-2 py-2" size='sm'
                                                          theme="accent" onClick={addClientContactHandler}>Add Client Contact</Button>
              </Col>

            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
              columns={tableColumns}
              data={tableData}
              pageSize={Number(pageSize)}
              showPageSizeOptions={false}
              resizable={false}
              noDataText="No results found"
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default Contacts;
