import {
  CLEAR_EXPENSE_SHEET,
  CLEAR_SHEET_REF_NUM,
  CLEAR_USER_EXPENSE_ITEM_RECEIPT_FILE,
  GET_EXPENSE_MILEAGE_RATE,
  GET_EXPENSE_MILEAGE_RATE_ERROR,
  GET_EXPENSE_STATUS_LIST,
  GET_EXPENSE_STATUS_LIST_ERROR,
  GET_EXPENSE_TYPE_LIST,
  GET_EXPENSE_TYPE_LIST_ERROR,
  GET_USER_EXPENSE_ITEM,
  GET_USER_EXPENSE_ITEM_ERROR,
  GET_USER_EXPENSE_ITEM_RECEIPT_FILE,
  GET_USER_EXPENSE_ITEM_RECEIPT_FILE_ERROR,
  GET_USER_EXPENSE_LIST,
  GET_USER_EXPENSE_LIST_ERROR,
  GET_USER_EXPENSE_MILEAGE_ITEM,
  GET_USER_EXPENSE_MILEAGE_ITEM_ERROR,
  GET_USER_EXPENSE_SHEET_DETAILS,
  GET_USER_EXPENSE_SHEET_DETAILS_ERROR,
  GET_USER_MILEAGE_EXPENSE_YTD, GET_USER_MILEAGE_EXPENSE_YTD_ERROR,
  SAVE_REPORT_NAME_ADND_DATE,
  SAVE_USER_EXPENSE_ITEM,
  SAVE_USER_EXPENSE_ITEM_ERROR,
  SAVE_USER_EXPENSE_MILEAGE_ITEM,
  SAVE_USER_EXPENSE_MILEAGE_ITEM_ERROR,
  SAVE_USER_EXPENSE_SHEET,
  SAVE_USER_EXPENSE_SHEET_ERROR,
  SUBMIT_USER_EXPENSE_SHEET,
  SUBMIT_USER_EXPENSE_SHEET_ERROR,
  UPLOAD_RECEIPT,
  UPLOAD_RECEIPT_ERROR,
  WITHDRAW_USER_EXPENSE_SHEET,
  WITHDRAW_USER_EXPENSE_SHEET_ERROR,
  RESET_SAVE_ITEM,
  DELETE_EXPENSE_REPORT,
  DELETE_EXPENSE_REPORT_ERROR
} from "../../actions/types";


const initialState = {
  reportDescription: "",
  reportDate: "",
  expenseStatusList: [],
  expenseList: [],
  mileageRate: 0,
  sheetRefNum: 0,
  mileageItem: {},
  expenseItem: {},
  expenseCategoryList: [],
  receiptFile: null,
  receiptFileName: '',
  expenseSheet: {},
  userMileageExpenseYTD: {},
  savedItem: false,
  reportDeleted: false
}


export const expenses = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXPENSE_STATUS_LIST:
      return {
        ...state,
        expenseStatusList: action.payload.expenseStatusList,
      };
    case GET_EXPENSE_STATUS_LIST_ERROR:
      return {
        ...state,
        expenseStatusList: [],
      };

    case GET_USER_EXPENSE_LIST:
      return {
        ...state,
        expenseList: action.payload.expenseList,
      };
    case GET_USER_EXPENSE_LIST_ERROR:
      return {
        ...state,
        expenseList: [],
      };

    case GET_EXPENSE_MILEAGE_RATE:
      return {
        ...state,
        mileageRate: action.payload.mileageRate,
      };
    case GET_EXPENSE_MILEAGE_RATE_ERROR:
      return {
        ...state,
        mileageRate: 0,
      };

    case SAVE_USER_EXPENSE_MILEAGE_ITEM:
      return {
        ...state,
        mileageItem:{},
        sheetRefNum: action.payload.sheetRefNum,
        savedItem: true
      };
    case SAVE_USER_EXPENSE_MILEAGE_ITEM_ERROR:
      return {
        ...state,
        savedItem: false
      };

    case GET_USER_EXPENSE_MILEAGE_ITEM:
      return {
        ...state,
        mileageItem: action.payload.mileageItem,
      };
    case GET_USER_EXPENSE_MILEAGE_ITEM_ERROR:
      return {
        ...state,
        mileageItem: {},
      };

    case GET_USER_EXPENSE_ITEM:
      return {
        ...state,
        expenseItem: action.payload.expenseItem,
      };
    case GET_USER_EXPENSE_ITEM_ERROR:
      return {
        ...state,
        expenseItem: {},
      };

    case GET_EXPENSE_TYPE_LIST:
      return {
        ...state,
        expenseCategoryList: action.payload.expenseTypeList,
      };
    case GET_EXPENSE_TYPE_LIST_ERROR:
      return {
        ...state,
        expenseCategoryList: [],
      };


    case UPLOAD_RECEIPT:
      return {
        ...state,
        filePath: action.payload.res.filePath,
        receiptFile: action.payload.receiptFile,
        receiptFileName: action.payload.res.fileName,
      };
    case UPLOAD_RECEIPT_ERROR:
      return {
        ...state,
        filePath: null,
        receiptFileName: '',
      };
    case GET_USER_EXPENSE_ITEM_RECEIPT_FILE:
      return {
        ...state,
        receiptFile: action.payload.file,
      };
    case GET_USER_EXPENSE_ITEM_RECEIPT_FILE_ERROR:
      return {
        ...state,
        receiptFile: null,
      };

    case CLEAR_USER_EXPENSE_ITEM_RECEIPT_FILE:
      return {
        ...state,
        receiptFile: null,
        receiptFileName: "",
      };


    case SAVE_USER_EXPENSE_ITEM:
      return {
        ...state,
        expenseItem:{},
        receiptFileName:"",
        savedItem: true,
        sheetRefNum: action.payload.sheetRefNum,
      };

      case SAVE_USER_EXPENSE_ITEM:
        return {
          ...state,
          expenseItem:{},
          receiptFileName:"",
          savedItem: !state.savedItem,
          sheetRefNum: action.payload.sheetRefNum,
        };
        case RESET_SAVE_ITEM:
          return {
            ...state,
            savedItem: false,
          };

    case CLEAR_SHEET_REF_NUM:
      return {
        ...state,
        sheetRefNum: null,
        reportDescription: "",
        reportDate: "",
      };

    case CLEAR_EXPENSE_SHEET:
      return {
        ...state,
        expenseSheet: {},
        reportDeleted: false
      };
    case SAVE_USER_EXPENSE_ITEM_ERROR:
      return {
        ...state,
        sheetRefNum: null,
        savedItem: false
      };

    case GET_USER_EXPENSE_SHEET_DETAILS:
      return {
        ...state,
        expenseSheet: action.payload.expenseSheet,
      };
    case GET_USER_EXPENSE_SHEET_DETAILS_ERROR:
      return {
        ...state,
        expenseSheet: {},
      };

    case SAVE_USER_EXPENSE_SHEET:
      return {
        ...state,
        sheetRefNum: 0
      };
    case SAVE_USER_EXPENSE_SHEET_ERROR:
      return {
        ...state,
        expenseSheet: {},
      };
    case SUBMIT_USER_EXPENSE_SHEET:
      return {
        ...state,
        expenseSheet: {},
        sheetRefNum: 0,
      };
    case SUBMIT_USER_EXPENSE_SHEET_ERROR:
      return {
        ...state,
        expenseSheet: {},
      };
    case SAVE_REPORT_NAME_ADND_DATE:
      return {
        ...state,
        reportDescription: action.payload.description,
        reportDate: action.payload.date,
      };
    case WITHDRAW_USER_EXPENSE_SHEET:
      return {
        ...state,
        expenseSheet: {},
        sheetRefNum: 0,
      };
    case WITHDRAW_USER_EXPENSE_SHEET_ERROR:
      return {
        ...state,
        expenseSheet: {},
      };

      case DELETE_EXPENSE_REPORT:
      return {
        ...state,
        expenseSheet: {},
        sheetRefNum: 0,
        reportDeleted: true
      };
      case DELETE_EXPENSE_REPORT_ERROR:
      return {
        ...state,
        reportDeleted : false
      };

    case GET_USER_MILEAGE_EXPENSE_YTD:
      return {
        ...state,
        userMileageExpenseYTD: action.payload,
      };
    case GET_USER_MILEAGE_EXPENSE_YTD_ERROR:
      return {
        ...state,
        userMileageExpenseYTD: {},
      };


    default:
      return state;
  }
}

export default expenses;
