import React from 'react';
import {Container} from "shards-react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {login} from "../redux/actions/user";
import {useHistory} from "react-router-dom";

function Home({isAuthenticated}) {

  const history=useHistory()

  if(isAuthenticated){
    history.push('/login')
  }

  return (
    <Container fluid>
      <div className="home-showcase text-center">
        <p>Managing the most important part of your business</p>
        <h2>Your People!</h2>
      </div>
    </Container>
  );
}

Home.propTypes = {
  isAuthenticated: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
});

export default connect(mapStateToProps,{login})(Home);
