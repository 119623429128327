export default function () {
  return [
    {
      roles:['Worker'],
      items: [
        {
          title: 'Home',
          to: '/worker-home',
          htmlBefore: '<i class="material-icons">home</i>',
          htmlAfter: '',
        },

        {
          title: 'Profile',
          to: '/view-profile',
          htmlBefore: '<i class="material-icons">&#xE7FD;</i>',
          htmlAfter: '',
        },

        {
          title: 'Leaves',
          to: '/manage-leaves',
          htmlBefore: '<i class="material-icons">today</i>',
          htmlAfter: '',
          permission:'Leaves Module',
          activeRoutes:[
            '/edit-leave/',
            '/add-leave/',
          ]
        },

        {
          title: 'Expenses',
          to: '/view-expenses',
          htmlBefore: '<i class="material-icons">receipt_long</i>',
          htmlAfter: '',
          permission:'Expense Module',
          activeRoutes:[
            '/add-expense-report/',
            '/edit-expense-report/',
            '/add-expense-item/',
            '/edit-expense-item/',
            '/add-mileage-item/',
            '/edit-mileage-item/',
          ]
        },

        {
          title: 'Message Center',
          to: '/message-center',
          htmlBefore: '<i class="material-icons">question_answer</i>',
          htmlAfter: '',
          activeRoutes: [
            '/user-edit-message/'
          ]
        },

        {
          title: 'Product Feedback',
          to: '/user-add-message/0/Product Feedback',
          htmlBefore: '<i class="material-icons">question_answer</i>',
          htmlAfter: '',
          activeRoutes: [
            
          ]
        }
      ]
    },
    {
      roles:['Client Admin','Client Billing','Client HR', 'Client User'],
      title:'Client',
      items:[
        {
          title: 'Home',
          to: '/client-home',
          htmlBefore: '<i class="material-icons">home</i>',
          htmlAfter: '',
        },
        {
          title: 'Profile',
          to: '/client-view-profile',
          htmlBefore: '<i class="material-icons">&#xE7FD;</i>',
          htmlAfter: '',
        },
        {
          title: 'Workers',
          to: '/client-search-clientWorkers',
          htmlBefore: '<i class="material-icons">groups</i>',
          htmlAfter: '',
          activeRoutes:[
            '/client-view-worker-profile/'
          ]
        },

        {
          title: 'Leaves',
          to: '/client-search-leaves',
          htmlBefore: '<i class="material-icons">today</i>',
          htmlAfter: '',
          permission:'Leaves Module',
          activeRoutes:[
            '/client-view-worker-leave/',
          ]
        },

        {
          title: 'Expenses',
          to: '/client-search-expenses',
          htmlBefore: '<i class="material-icons">receipt_long</i>',
          htmlAfter: '',
          permission: 'Expense Module',
          activeRoutes: [
            '/client-view-expense-sheet/',
            '/client-view-expense-item/',
            '/client-view-mileage-item/',
          ]
        },

        {
          title: 'Reports',
          to: '/reports',
          htmlBefore: '<i class="material-icons">insert_chart</i>',
          htmlAfter: '',
        },

        {
          title: 'Message Center',
          to: '/client-message-center',
          htmlBefore: '<i class="material-icons">question_answer</i>',
          htmlAfter: '',
          activeRoutes: [
            '/client-edit-message/'
          ]
        },

        {
          title: 'Product Feedback',
          to: '/client-add-message/0/Product Feedback',
          htmlBefore: '<i class="material-icons">question_answer</i>',
          htmlAfter: '',
          activeRoutes: [
          ]
        }

        ]
    },

    {
      roles:['Subscriber Admin','Subscriber User','Subscriber HR','Subscriber Billing'],
      title:'Subscriber',
      items: [
        {
          title: 'Home',
          to: '/sub-home',
          htmlBefore: '<i class="material-icons">home</i>',
          htmlAfter: ''
        },

        {
          title: 'Users',
          to: '/sub-search-users',
          htmlBefore: '<i class="material-icons">groups</i>',
          htmlAfter: '',
          activeRoutes: [
            '/manage-user-profile/',
            '/sub-edit-leave/',
            '/sub-edit-expense-reports/',
            '/sub-add-expense-item/',
            '/sub-add-mileage-item/',
            '/sub-add-edit-notes',
            '/sub-manage-permissions'
          ]
        },

        {
          title: 'Leaves',
          to: '/sub-search-leaves',
          htmlBefore: '<i class="material-icons">today</i>',
          htmlAfter: '',
          activeRoutes:[
            '/sub-approve-leave/',
          ]
        },

        {
          title: 'Expenses',
          to: '/sub-search-expenses',
          htmlBefore: '<i class="material-icons">receipt_long</i>',
          htmlAfter: '',
          activeRoutes: [
            '/sub-approve-expense-report/',
            '/sub-approve-expense-item/',
            '/sub-approve-mileage-item/',
          ]
        },
        {
          title: 'Clients',
          to: '/sub-search-clients',
          htmlBefore: '<i class="material-icons">people</i>',
          htmlAfter: '',
          activeRoutes: [
            '/sub-manage-client-profile/',
            '/sub-client-customize-leave-policy/',
          ]
        },

        {
          title: 'Message Center',
          to: '/sub-message-center',
          htmlBefore: '<i class="material-icons">question_answer</i>',
          htmlAfter: '',
          activeRoutes: [
            '/sub-message-activity/'
          ]
        },
        {
          title: 'Reports',
          to: '/sub-generate-reports',
          htmlBefore: '<i class="material-icons">table_chart</i>',
          htmlAfter: '',
          activeRoutes: [
            '/sub-search-Reports/'
          ]
        },
        {
          title: 'Import Data',
            roles: ["Subscriber Admin", "Subscriber Billing"],
                open: false,
                  items: [
                    {
                      title: 'Import Data',
                      to: '/import-data',
                      htmlBefore: '<i class="material-icons"></i>',
                      htmlAfter: '',
                      activeRoutes: [
                        '/import-data/'
                      ]
                    },
                  ],
          },
        {
          title: 'Settings',
          roles:["Subscriber Admin"],
          htmlBefore: '<i class="material-icons">handyman</i>',
          open: false,
          items: [
            {
              title: 'Holiday',
              to: '/sub-search-holidays',
              htmlBefore: '<i class="material-icons">beach_access</i>',
              htmlAfter: '',
              activeRoutes:[
                '/sub-add-edit-holiday/',
              ]
            },
            {
              title: 'Leave Policy',
              to: '/sub-search-leave-policy',
              htmlBefore: '<i class="material-icons">today</i>',
              htmlAfter: '',
              activeRoutes:[
                '/sub-edit-leave-policy/',
                '/sub-leave-policy-location-config/',
              ]
            },
            {
              title: 'Configuration',
              to: '/sub-configuration',
              htmlBefore: '<i class="material-icons">settings</i>',
              htmlAfter: '',
              activeRoutes:[
                '/sub-add-edit-mileage-range/',
                '/sub-add-edit-expense-type/',
                '/sub-add-edit-allowance-type/',

              ]
            }
          ],
        }
      ]
    },


    {
      roles:['System Admin'],
      title:'System Admin',
      items:[
        // {
        //   title: 'Users',
        //   to: '/admin-search-users',
        //   htmlBefore: '<i class="material-icons">group</i>',
        //   htmlAfter: '',
        //   activeRoutes: [
        //     '/admin-manage-user-profile/',
        //     '/admin-manage-client-profile/',
        //   ]
        // },

        {
          title: 'Subscriber',
          to: '/admin-search-subscribers',
          htmlBefore: '<i class="material-icons">manage_accounts</i>',
          htmlAfter: '',
          activeRoutes: [
            '/admin-manage-subscriber-profile/',
          ]
        },

        {
          title: 'Configuration',
          to: '/admin-configuration',
          htmlBefore: '<i class="material-icons">settings</i>',
          htmlAfter: '',
          activeRoutes: [
            '/admin-add-edit-currency/',
            '/admin-add-edit-location/',
          ]
        },

        {
          title: 'Audit Log',
          to: '/admin-auditlog',
          htmlBefore: '<i class="material-icons">history</i>',
          htmlAfter: '',
          activeRoutes: []
        }
        ]
    },
  ];
}
