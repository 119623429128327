import {
  GET_CLIENT_EXPENSE_ITEM,
  GET_CLIENT_EXPENSE_ITEM_ERROR,
  GET_CLIENT_MILEAGE_ITEM,
  GET_CLIENT_MILEAGE_ITEM_ERROR,
  GET_EXPENSE_SHEET_DETAILS_FOR_CLIENT,
  CLEAR_EXPENSE_SHEET_DETAILS_FOR_CLIENT,
  GET_EXPENSE_SHEET_DETAILS_FOR_CLIENT_ERROR,
  SEARCH_EXPENSE_REPORTS_LIST_FOR_CLIENT,
  SEARCH_EXPENSE_REPORTS_LIST_FOR_CLIENT_ERROR,
  CLIENT_REJECT_EXPENSE_ITEM,
  CLIENT_REJECT_MILEAGE_ITEM,
  CLIENT_APPROVE_EXPENSE_ITEM,
  CLIENT_APPROVE_MILEAGE_ITEM,
  CLIENT_GET_MILEAGE_ITEM,
  CLIENT_CLEAR_MILEAGE_ITEM,
  CLIENT_GET_EXPENSE_ITEM,
  CLIENT_CLEAR_EXPENSE_ITEM
} from "../../actions/types";

const initialState = {
  clientExpenseReportList: [],
  clientExpenseItem: {},
  clientMileageItem: {},
  expenseSheetForClient: {},
  rejectedExpenseItems: [],
  rejectedMileageItems: [],
}
export const user = (state = initialState, action) => {
  switch (action.type) {

    case SEARCH_EXPENSE_REPORTS_LIST_FOR_CLIENT:
      return {
        ...state,
        clientExpenseReportList: action.payload.expenseList,
      };
    case SEARCH_EXPENSE_REPORTS_LIST_FOR_CLIENT_ERROR:
      return {
        ...state,
        clientExpenseReportList: [],
      };

    case GET_CLIENT_EXPENSE_ITEM:
      return {
        ...state,
        clientExpenseItem: action.payload.expenseItem,
      };
    case GET_CLIENT_EXPENSE_ITEM_ERROR:
      return {
        ...state,
        clientExpenseItem: {},
      };

    case GET_EXPENSE_SHEET_DETAILS_FOR_CLIENT:
      return {
        ...state,
        expenseSheetForClient: action.payload.expenseSheet,
      };
    case GET_EXPENSE_SHEET_DETAILS_FOR_CLIENT_ERROR:
      return {
        ...state,
        expenseSheetForClient: {},
      };

    case GET_CLIENT_MILEAGE_ITEM:
      return {
        ...state,
        clientMileageItem: action.payload.mileageItem,
      };
    case GET_CLIENT_MILEAGE_ITEM_ERROR:
      return {
        ...state,
        clientMileageItem: {},
      };
    case CLEAR_EXPENSE_SHEET_DETAILS_FOR_CLIENT:
      return {
        ...state,
        expenseSheetForClient: {},
        rejectedMileageItems: [],
        rejectedExpenseItems: []
      };
    case CLIENT_REJECT_MILEAGE_ITEM: {

      let mileageItemList = state.expenseSheetForClient.mileageItemList;
      let submittedMileageTotal = state.expenseSheetForClient.submittedMileageTotal;
      let submittedReportTotal = state.expenseSheetForClient.submittedReportTotal;
      mileageItemList = mileageItemList.map(item => {
        if (item.refNum === action.payload) {
          if(item.status!=="Saved"){
            submittedMileageTotal = submittedMileageTotal - item.amount
            submittedReportTotal = submittedReportTotal - item.amount
          }
          item.status = "Rejected";

        }
        return item;
      })

      return {
        ...state,
        rejectedMileageItems: [...state.rejectedMileageItems, action.payload],
        expenseSheetForClient: {
          ...state.expenseSheetForClient,
          mileageItemList: mileageItemList,
          submittedMileageTotal,
          submittedReportTotal
        }
      };
    }
    case CLIENT_REJECT_EXPENSE_ITEM: {
      let expenseItemList = state.expenseSheetForClient.expenseItemList;
      let submittedItemTotal=state.expenseSheetForClient.submittedItemTotal;
      let submittedReportTotal=state.expenseSheetForClient.submittedReportTotal;
      expenseItemList = expenseItemList.map(item => {
        if (item.refNum === action.payload) {
          if(item.status!=="Saved"){
            submittedItemTotal = submittedItemTotal - item.netAmount;
            submittedReportTotal = submittedReportTotal - item.netAmount;
          }
          item.status = "Rejected";

        }
        return item;
      })

      return {
        ...state,
        rejectedExpenseItems: [...state.rejectedExpenseItems, action.payload],
        expenseSheetForClient: {
          ...state.expenseSheetForClient,
          expenseItemList: expenseItemList,
          submittedItemTotal,
          submittedReportTotal
        }
      };
    }

    case CLIENT_APPROVE_MILEAGE_ITEM: {
      let mileageItemList = state.expenseSheetForClient.mileageItemList || [];
      let submittedMileageTotal=state.expenseSheetForClient.submittedMileageTotal;
      let submittedReportTotal=state.expenseSheetForClient.submittedReportTotal;
      mileageItemList = mileageItemList.map(item => {
        if (item.refNum === action.payload) {
          if(item.status!=="Submitted"){
            submittedMileageTotal = submittedMileageTotal + item.amount
            submittedReportTotal = submittedReportTotal + item.amount
          }
          item.status = "Approved";
        }
        return item;
      })

      return {
        ...state,
        rejectedMileageItems: state.rejectedMileageItems.filter(item => item !== action.payload),
        expenseSheetForClient: {
          ...state.expenseSheetForClient,
          mileageItemList: mileageItemList,
          submittedMileageTotal,
          submittedReportTotal
        }
      };
    }
    case CLIENT_APPROVE_EXPENSE_ITEM: {

      let expenseItemList = state.expenseSheetForClient.expenseItemList;
      let submittedItemTotal=state.expenseSheetForClient.submittedItemTotal;
      let submittedReportTotal=state.expenseSheetForClient.submittedReportTotal;
      expenseItemList = expenseItemList.map(item => {
        if (item.refNum === action.payload) {
          if(item.status!=="Submitted")
          {
            submittedItemTotal = submittedItemTotal + item.netAmount;
            submittedReportTotal = submittedReportTotal + item.netAmount;
          }
          item.status = "Approved";

        }
        return item;
      })

      return {
        ...state,
        rejectedExpenseItems: state.rejectedExpenseItems.filter(item => item !== action.payload),
        expenseSheetForClient: {
          ...state.expenseSheetForClient,
          expenseItemList: expenseItemList,
          submittedItemTotal,
          submittedReportTotal
        }
      };
    }

    case CLIENT_GET_MILEAGE_ITEM: {
      let mileageItem = action.payload.mileageItem;
      if (state.rejectedMileageItems.includes(parseInt(mileageItem.refNum)))
        mileageItem.status = "Rejected"

      if (state.expenseSheetForClient.mileageItemList.filter(item=>item.refNum===mileageItem.refNum && item.status==="Approved").length)
        mileageItem.status = "Approved"

      return {
        ...state,
        clientMileageItem: mileageItem,
      };
    }

    case CLIENT_CLEAR_MILEAGE_ITEM:
      return {
        ...state,
        clientMileageItem: {},
      };

    case CLIENT_GET_EXPENSE_ITEM: {
      let expenseItem = action.payload.expenseItem;
      if (state.rejectedExpenseItems.includes(parseInt(expenseItem.refNum)))
        expenseItem.status = "Rejected"

      if (state.expenseSheetForClient.expenseItemList.filter(item=>item.refNum===expenseItem.refNum && item.status==="Approved").length)
        expenseItem.status = "Approved"
      return {
        ...state,
        clientExpenseItem: expenseItem,
      };
    }

    case CLIENT_CLEAR_EXPENSE_ITEM:
      return {
        ...state,
        clientExpenseItem: {}
      };
    default:
      return state;
  }

}

export default user;
