import React, {useState} from 'react';
import {
  Card, CardBody,
  CardHeader,
  Col,
  Container,
  FormSelect,
  Row
} from "shards-react";
import {getStatusClass, numberToMonetary} from "../../../utils/general"
import {Link} from "react-router-dom";
import ReactTable from "react-table";
import {setDateForTable} from "../../../utils/date";
import PropTypes from "prop-types";

function ExpenseItemsCard(props) {

  const [state, setState] = useState({
    pageSizeOptions: [10, 15, 20, 25, 30],
    pageSize: 10,
  });

  const onChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  };

  const openReceipt = (userId, refNum) => {
    window.open(`/expense-item-receipt-popup/${userId}/${refNum}`, "customWindow", "width=500, height=1040");
  }

  const tableData = (props.expenseItemList) ? props.expenseItemList : []

  var {pageSize, pageSizeOptions} = state;

  if (tableData.length < 10) {
    pageSize = tableData.length
    pageSizeOptions = [tableData.length]
  }
  const tableColumns = [
    {
      Header: <span className="text-wrap">Date</span>,
      accessor: "expenseDate",
      maxWidth: 90,
      Cell: row =>
        setDateForTable(row.original.expenseDate),
    },
    {
      Header: <span className="text-wrap">Expense Category</span>,
      accessor: "type",
      maxWidth: 90,
      className: "justify-content-start text-left text-wrap text-break"
    },
    {
      Header: <span className="text-wrap">Description</span>,
      accessor: "description",
      minWidth: 160,
      className: "justify-content-start text-left text-wrap text-break"
    },
    {
      Header: <span className="text-wrap">Gross</span>,
      accessor: "grossAmount",
      maxWidth: 120,
      className: "justify-content-end text-wrap text-break",
      Cell: row => <span
        className="w-100 text-wrap text-right">{numberToMonetary(row.original.grossAmount)}</span>
    },

    {
      Header: <span className="text-wrap">Tax Amount</span>,
      accessor: "taxAmount",
      maxWidth: 70,
      className: "justify-content-end text-wrap text-break",
      Cell: row => <span
        className="w-100 text-wrap text-right">{numberToMonetary(row.original.taxAmount)}</span>
    },

    {
      Header: <span className="text-wrap">Receipt Total</span>,
      accessor: "receiptAmount",
      minWidth: 100,
      className: "justify-content-end text-wrap text-break",
      Cell: row => <span
        className="w-100 text-wrap text-right">{numberToMonetary(row.original.receiptAmount)}</span>
    },

    {
      Header: <span className="text-wrap">Exchange Rate</span>,
      accessor: "exchangeRate",
      maxWidth: 90,
      className: "justify-content-end text-wrap text-break",
      Cell: row => parseFloat(row.original.exchangeRate).toFixed(5)

    },
    {
      Header: <span className="text-wrap">Net Amount</span>,
      accessor: "netAmount",
      minWidth: 110,
      className: "justify-content-end text-wrap text-break overflow-visible",
      Cell: row => <span
        className="w-100 text-wrap text-right">{numberToMonetary(row.original.netAmount)}</span>,
      Footer: <div className="d-flex w-100 justify-content-end text-nowrap">
        <span>Sub Total: {props.status === undefined ? '0.00' : numberToMonetary(props.status === 'Draft' ? props.draftItemTotal :props.submittedItemTotal)}</span>
      </div>

    },

    {
      Header: <span className="text-wrap">Receipt</span>,
      maxWidth: 60,
      Cell: row => (row.original.receiptFileName) ? <Link
        onClick={e => openReceipt(row.original.userId, row.original.refNum)}>Receipt</Link> : ''

    },

    {
      Header: <span className="text-wrap">Status</span>,
      accessor: "status",
      maxWidth: 80,
      className: "text-center",
      Cell: row =>
        <span
          className={getStatusClass(row.original.status)}>{row.original.status}</span>
    },

    {
      Header: <span className="text-wrap">Ref#</span>,
      accessor: "refNum",
      maxWidth: 60,
      className: "text-center",
      Cell: row =>
        <Link
          to={`/${props.approveItemRoute}/${props.sheetRefNumber}/${row.original.refNum}`}>{row.original.refNum}</Link>
    }
  ];

  if (props.status !== "Reviewed" && props.showActions) {
    tableColumns.splice(10, 0, {
      Header: <span className="text-wrap">Action</span>,
      maxWidth: 60,
      Cell: row =>
        (row.original.status === "Rejected") ?
          <Link title="Approve this expense item."
            onClick={e => approveExpenseItem(e, row.original.refNum)}>Approve</Link> :
          <Link  title="Reject this expense item."
            onClick={e => rejectExpenseItem(e, row.original.refNum)}>Reject</Link>
    });
  }

  let approveExpenseItem = (e, refNum) => {
    e.preventDefault();
    props.approveExpenseItem(refNum);
  };

  let rejectExpenseItem = (e, refNum) => {
    e.preventDefault();
    props.rejectExpenseItem(refNum);
  };

  return (
    <Card className="p-0 mb-4">
      <span className="tableHeader">Review Expense</span>
      <CardHeader className="p-0 pt-2 mt-1 border-card">
        <Container fluid className="file-manager__filters border-bottom">
          <Row>
            <Col xl="4" lg="3"
                 className="file-manager__filters__rows d-flex justify-content-center justify-content-md-start align-items-center col-6 order-lg-0">
              <span>Show</span>
              <FormSelect
                size="sm"
                name='pageSize'
                value={pageSize}
                onChange={onChange}
              >
                {pageSizeOptions.map((size, idx) => (
                  <option key={idx} value={size}>
                    {size} rows
                  </option>
                ))}
              </FormSelect>
            </Col>


          </Row>
        </Container>
      </CardHeader>
      <CardBody className="p-0">
        <div className="">
          <ReactTable
            className="expenseItemsTable"
            columns={tableColumns}
            data={tableData}
            pageSize={Number(pageSize)}
            showPageSizeOptions={false}
            resizable={false}
            noDataText="No results found"
          />
        </div>
      </CardBody>
    </Card>
  );
}

ExpenseItemsCard.propTypes = {
  expenseItemList: PropTypes.array.isRequired,
  submittedItemTotal: PropTypes.string.isRequired,
  approvedItemTotal: PropTypes.string.isRequired,
  sheetRefNumber: PropTypes.string.isRequired,
}

ExpenseItemsCard.defaultProps = {
  expenseItemList: [],
  submittedItemTotal: "",
  approvedItemTotal: "",
  sheetRefNumber: '',
}

export default ExpenseItemsCard;

