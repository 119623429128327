export const getBase64MimeType = (base64) => {
    return base64.split(';')[0].split(':')[1].toLowerCase().split('/')[1]
};

export const b64toBlob = (base64) => {
    let mimeType = base64.split(';')[0].split(':')[1].toLowerCase()
    var byteString = atob(base64.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    let blob = new Blob([ab], { type: mimeType });

    return URL.createObjectURL(blob)
}