import api from "../../../utils/api";
import { setAlert } from "../alert";
import {
  GET_PAYROLL_TAX_IMPORT_TYPE,
  GET_PAYROLL_TAX_IMPORT_TYPE_ERROR,

 } from "../types";
export const save_import_data = ({file, importDuplicates, payPeriod, startDate, importType})=> async(dispatch)=>{
  
    try{
      let fd = new FormData();
    fd.append('file', file);
     const res = await api({
        method: "post",
        url: `api/sub-data/save-import-data?importDuplicates=${importDuplicates}&payPeriod=${payPeriod}&startDate=${startDate}&importType=${importType}`,
        data: fd,
        headers: {"Content-Type": "multipart/form-data"},
      });
      dispatch(setAlert(res.data.message,"success"));
      return true;
    }catch(err){
      const success = err.response.data.success;
      if (!success) {
        dispatch(setAlert(err.response.data.message, "danger"));
      }
      return false;
    }
}
export const get_payroll_taximort_type = () => async (dispatch)=>{
  try{
    const res = await api.get('api/sub-data/payrolltax-import-type-list');
    dispatch({type:GET_PAYROLL_TAX_IMPORT_TYPE,
      payload:res.data
    });
  }catch(err){
    const success = err.response.data.success;
    dispatch({type:GET_PAYROLL_TAX_IMPORT_TYPE_ERROR
    });
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
} 
