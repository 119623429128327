import CBoxLayout from "../layouts/CBoxLayout";
import SystemAdminUsers from "../views/system-admin/SystemAdminUsers";
import SystemAdminUserEdit from "../views/system-admin/SystemAdminUserEdit";
import SystemSubscriber from "../views/system-admin/SystemSubscriber";
import ManageSubscriber from "../views/system-admin/ManageSubscriber";
import AdminConfiguration from "../views/system-admin/Configuration";
import SystemAuditLog from "../views/system-admin/SystemAuditLog"
import AddCurrency from "../views/system-admin/AddCurrency";
import AddLocation from "../views/system-admin/AddLocation";
import ManageUserProfile from "../views/subscriber/ManageUserProfile";
import SubscriberManageClientProfile
  from "../views/subscriber/SubscriberManageClientProfile";

export default [
 /* {
    path: "/admin-search-users",
    layout: CBoxLayout,
    component: SystemAdminUsers,
    title:"Users",
    subtitle:"Search",
    roles:[ "System Admin"]
  },*/
  {
    path: "/system-admin/user/:id",
    layout: CBoxLayout,
    component: SystemAdminUserEdit,
    title:"Edit",
    subtitle:"User",
    roles:[ "System Admin"]
  },
  {
    path: "/system-admin/user/:id",
    layout: CBoxLayout,
    component: SystemAdminUserEdit,
    title:"Edit",
    subtitle:"Search",
    roles:[ "System Admin"]
  },

  {
    path: "/admin-search-subscribers",
    layout: CBoxLayout,
    component: SystemSubscriber,
    title:"Subscriber",
    subtitle:"System",
    roles:[ "System Admin"]
  },
  {
    path: "/admin-manage-subscriber-profile/:id",
    layout: CBoxLayout,
    component: ManageSubscriber,
    title:"Subscriber Profile",
    subtitle:"Manage",
    roles:[ "System Admin"]
  },

  {
    path: "/admin-configuration",
    layout: CBoxLayout,
    component: AdminConfiguration,
    title:"Configuration",
    subtitle:"System",
    roles:[ "System Admin"]
  },

  {
    path: "/admin-auditlog",
    layout: CBoxLayout,
    component: SystemAuditLog,
    title:"Audit Log",
    subtitle:"System",
    roles:[ "System Admin"]
  },

  {
    path: "/admin-add-currency/:id",
    layout: CBoxLayout,
    component: AddCurrency,
    title:"Currency",
    subtitle:"Add",
    roles:[ "System Admin"]
  },

  {
    path: "/admin-edit-currency/:id",
    layout: CBoxLayout,
    component: AddCurrency,
    title:"Currency",
    subtitle:"Edit",
    roles:[ "System Admin"]
  },

  {
    path: "/admin-add-location/:id",
    layout: CBoxLayout,
    component: AddLocation,
    title:"Location",
    subtitle:"Add",
    roles:[ "System Admin"]
  },

  {
    path: "/admin-edit-location/:id",
    layout: CBoxLayout,
    component: AddLocation,
    title:"Location",
    subtitle:"Edit",
    roles:[ "System Admin"]
  },
  {
    path: "/admin-manage-user-profile/:id",
    layout: CBoxLayout,
    component: ManageUserProfile,
    title:"User Profile",
    subtitle:"Manage",
    roles:[ "System Admin"]
  },

  {
    path: "/admin-manage-client-profile/:subscriberId/:id",
    layout: CBoxLayout,
    component: SubscriberManageClientProfile,
    title:"Client Profile",
    subtitle:"Manage",
    roles:[ "System Admin"]
  },
]
