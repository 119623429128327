
// Route Views
import Login from "./views/Login";
import ForgotPassword from "./views/ForgotPassword";
import ManageUserProfile from "./views/subscriber/ManageUserProfile";
import CBoxLayout from "./layouts/CBoxLayout";
import Home from "./views/Home";
import GuestLayout from "./layouts/GuestLayout";
import Account from "./views/Account";
import ChangePassword from "./views/ChangePassword";
import EnableTwoFA from "./views/EnableTwoFA";
import DisableTwoFA from "./views/DisableTwoFA";

import workerRoutes from "./routes/worker.routes"
import clientRoutes from "./routes/client.routes"
import subscriberRoutes from "./routes/subscriber.routes"
import adminRoutes from "./routes/admin.routes"
import Error403 from "./views/Error403";

export default [
  {
    path: "/",
    exact: true,
    layout: GuestLayout,
    component: Home
  },

  // {
  //   path: "/login",
  //   layout: GuestLayout,
  //   component: Login
  // },
  {
    path: "/login/(msg)?/:msg?",
    layout: GuestLayout,
    component: Login
  },
  {
    path: "/forgotpassword",
    layout: GuestLayout,
    component: ForgotPassword
  },
  {
    path: "/account",
    layout: CBoxLayout,
    component: Account,
    title:"Settings",
    subtitle:"Account"
  },

  {
    path: "/change-password",
    layout: CBoxLayout,
    component: ChangePassword,
    title:"Password",
    subtitle:"Change"
  },

  {
    path: "/enable-2fa",
    layout: CBoxLayout,
    component: EnableTwoFA,
    title:"2FA Authentication",
    subtitle:"Enable"
  },

  {
    path: "/disable-2fa",
    layout: CBoxLayout,
    component: DisableTwoFA,
    title:"2FA Authentication",
    subtitle:"Disable"
  },


  {
    path: "/manage-user-profile/:id",
    layout: CBoxLayout,
    component: ManageUserProfile,
    title:"User Profile",
    subtitle:"Manage",
    roles:["Subscriber User","Subscriber HR", "Subscriber Billing", "Subscriber Admin"]
  },

  {
    path: "/error403",
    exact: true,
    layout: CBoxLayout,
    component: Error403
  },
  ...workerRoutes,
  ...clientRoutes,
  ...subscriberRoutes,
  ...adminRoutes
];
