import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container, DatePicker,
  FormSelect, InputGroup, InputGroupAddon, InputGroupText,
  Row
} from "shards-react";
import ReactTable from "react-table";
import {Link, useLocation} from "react-router-dom";
import {setDateForTable, setInputDate} from "../../utils/date";
import {getStatusClass} from "../../utils/general";
import {
  worker_get_message_category_list,
  get_message_status_list,
  worker_search_conversations,
} from "../../redux/actions/user";
import classNames from "classnames";
import {default as queryString} from "qs";

function MessageCenter({
                         worker_get_message_category_list,
                         get_message_status_list,
                         worker_search_conversations,

                         messageCategoryList,
                         messageStatusList,
                         conversationList,
                         showHeading
                       }) {

  let location = useLocation();
  let queryParams = queryString.parse(location.search.replace("?", ""))

  let initialState = {
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
    category: "",
    status: "",
    from: "",
    to: ""
  };

  const [formData, setFormData] = useState(initialState);


  useEffect(() => {
    let newState = {
      ...formData, from: queryParams.from || "",
      to: queryParams.to || "",
      status: queryParams.status || "",
      category: queryParams.category || ""
    }
    searchConversations(newState.category, newState.status, newState.from, newState.to)
    setFormData(newState)
  }, [queryParams.from,
    queryParams.to,
    queryParams.status,
    queryParams.category]);

  useEffect(() => {
    worker_get_message_category_list()
    get_message_status_list()
  }, []);


  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  let resetState = () => {
    setFormData(initialState)
    worker_search_conversations()
  }

  let onSubmit = (e) => {
    e.preventDefault();
    searchConversations();
  }

  let searchConversations = (category = formData.category, status = formData.status, from = formData.from, to = formData.to) => {
    worker_search_conversations(category, status, setInputDate(from), setInputDate(to))
  }

  let messageCategoryOptions = messageCategoryList.map(category => <option
    value={category.categoryName}>{category.categoryName}</option>)
  let messageStatusOptions = messageStatusList.map(status => <option
    value={status}>{status}</option>)

  const tableColumns = [
    {
      Header: "Date",
      accessor: "createdDate",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        setDateForTable(row.original.createdDate),
    },

    {
      Header: "Category",
      accessor: "categoryName",
      className: "justify-content-start text-left text-wrap text-break"
    },

    {
      Header: "Status",
      accessor: "status",
      maxWidth: 120,
      className: "text-center",
      Cell: row =>
        <span
          className={getStatusClass(row.original.status)}>{row.original.status}</span>,
    },

    {
      Header: "Ref#",
      accessor: "refNum",
      maxWidth: 100,
      className: "text-center",
      Cell: row =>
        <Link
          to={"/user-edit-message/" + row.original.refNum}>{row.original.refNum}</Link>
    }
  ];
  return (
    <Container fluid
               className={"main-content-container px-4 pb-4" + (showHeading ? " position-relative" : "")}>
      {showHeading && <span className="tableHeader b-2">Message Center</span>}
      <form id="filterForm" onSubmit={onSubmit}
            className={"message-center-filter page-header tableSearch flex-column flex-lg-row py-2 d-flex justify-content-end bg-white mt-4" + (showHeading ? " pt-4 pt-xl-2 position-relative" : "")}>

        <div className="d-flex align-items-center p-2">
          <FormSelect name='category' className="filter-control-md"
                      value={formData.category}
                      onChange={onChange}>
            <option value=''>All Categories</option>
            {messageCategoryOptions}
          </FormSelect>
        </div>
        <div className="d-flex align-items-center p-2">
          <FormSelect name='status' className="filter-control-md"
                      value={formData.status}
                      onChange={onChange}>
            <option value=''>All Status</option>
            {messageStatusOptions}
          </FormSelect>
        </div>


        <div
          className="d-flex flex-column flex-md-row align-items-center justify-content-end">
          <div className="d-flex align-items-center p-2">
            <InputGroup
              className={classNames("d-flex", "my-auto", "date-range")}>
              <DatePicker
                name="from"
                size="sm"
                autoComplete='off'
                selected={formData.from}
                onChange={(date) => {
                  setFormData({...formData, from: date})
                }}
                dateFormat='yyyy-MM-dd'
                placeholderText="Start Date"
                dropdownMode="select"
                className="text-center"
              />
              <DatePicker
                name="to"
                size="sm"
                autoComplete='off'
                selected={formData.to}
                onChange={(date) => {
                  setFormData({...formData, to: date})
                }}
                dateFormat='yyyy-MM-dd'
                placeholderText="End Date"
                dropdownMode="select"
                className="text-center"
              />
              <InputGroupAddon type="append">
                <InputGroupText>
                  <i className="material-icons">&#xE916;</i>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </div>
          <div className="d-flex">
            <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                    type="submit">Search</Button>
            <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                    onClick={resetState}>Clear</Button>
          </div>
        </div>

      </form>
      <Card className="tableCard p-0">
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {/* Filters :: Page Size */}
              <Col
                className="file-manager__filters__rows d-flex justify-content-center justify-content-md-start align-items-center col-2">
                <span>Show</span>
                <FormSelect
                  size="sm"
                  name='pageSize'
                  value={formData.pageSize}
                  onChange={onChange}
                >
                  {formData.pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size} rows
                    </option>
                  ))}
                </FormSelect>
              </Col>
              <Col lg="6"
                   className="file-manager__filters__search d-flex justify-content-center justify-content-lg-start col-12 mt-3 mb-3">
               <p
                  className="m-0 ">Note - This section shows Unread and In-Process status items.</p>
              </Col>
              <Col
                className="file-manager__filters__search d-flex justify-content-center justify-content-md-end col-4">
                <Link to="/user-add-message/0/0">
                  <Button className="mx-2 py-2 my-2" size='sm'
                          theme="accent">New Message</Button>
                </Link>
              </Col>

            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
              columns={tableColumns}
              data={conversationList}
              pageSize={Number(formData.pageSize)}
              showPageSizeOptions={false}
              resizable={false}
            />
          </div>
        </CardBody>
      </Card>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  messageCategoryList: state.user.messageCategoryList,
  messageStatusList: state.user.messageStatusList,
  conversationList: state.user.conversationList
});

export default connect(mapStateToProps, {
  worker_get_message_category_list,
  get_message_status_list,
  worker_search_conversations
})(MessageCenter);
