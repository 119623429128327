import React, { useEffect, useState } from 'react';
import {
  Button,
  Card, CardBody, CardFooter, CardHeader, Col,
  Container, FormCheckbox, ListGroup,
  ListGroupItem, Row
} from "shards-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getSettings, user_disable_2fa, updateSettings } from "../redux/actions/settings";
import { Link } from "react-router-dom";

function Account({
  user,
  require2FA,
  message,
  success,
  leavesNotification,
  expenseNotification,
  messageNotification,
  notificationSettings,
  getSettings,
  updateSettings
}) {

  const [formData, setFormData] = useState({
    leavesFlag: false,
    expenseFlag: false,
    messageFlag: false,
  });

  useEffect(() => {
    getSettings(user.userId);  
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      leavesFlag: notificationSettings.leavesNotification,
      expenseFlag: notificationSettings.expenseNotification,
      messageFlag: notificationSettings.messageNotification
    });
    console.log("notificationSettings:",notificationSettings);
  }, [notificationSettings]);

  let onToggle = (e) => {
    let toggleValue = !formData[e.target.name];
    setFormData({ ...formData, [e.target.name]: toggleValue });
  }

  let updateNotificationSettings = (e) => {
    updateSettings(user.userId, formData.leavesFlag, formData.expenseFlag, formData.messageFlag)
  };

  let clientRole = user.userRoles.filter(userRole => userRole.role.startsWith("Client"));
  let workerRole = user.userRoles.filter(userRole => userRole.role === "Worker");

  return (
    <Container fluid className="main-content-container p-4">
      <Row>
        <Col lg="6" className="col-12">
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h4 className="m-0 section-title">Account</h4>
            </CardHeader>
            <CardBody className="p-0 overflow-hidden">
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <span>{user.login}</span>
                    <Link to="/change-password">
                      <Button className="mx-1 py-2 mx-md-2" size="sm"
                        theme="accent">Change Password</Button>
                    </Link>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
            <CardFooter></CardFooter>
          </Card>
        </Col>
        <Col lg="6" className="col-12">
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h4 className="m-0 section-title">Security</h4>
            </CardHeader>
            <CardBody className="p-0 overflow-hidden">
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Two-factor Authentication</span>
                   
                    {require2FA != null &&
                      <Link to={(require2FA) ? "/disable-2fa" : "/enable-2fa"}>

                      {require2FA != 1 &&
                        <Button className="mx-1 py-2 mx-md-2" size="sm"
                          theme="accent">{(require2FA) ? "Disable 2FA" : "Enable 2FA"}</Button>
                      }  </Link>}
                      
                      {require2FA != null && require2FA == 1 &&
                        <label className="text-success font-weight-bold"> <i class="fa fa-comment-alt"></i> Enabled</label>
                        
                      }
                    
                  </div>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
            <CardFooter></CardFooter>
          </Card>
        </Col>
      </Row>
      {clientRole.length > 0 || workerRole.length > 0 ?
        <Row>
          <Col lg="6" className="col-12">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h4 className="m-0 section-title">Email Notification Settings</h4>
              </CardHeader>
              <CardBody className="p-0 overflow-hidden">
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Row form>
                      <Col lg="3" className="form-group mb-0 p-2">

                        <FormCheckbox id="leavesChkFlag" name="leavesFlag"
                          checked={formData.leavesFlag} onChange={onToggle}
                          toggle small>Leaves</FormCheckbox>
                      </Col>
                      <Col lg="3" className="form-group mb-0 p-2">
                        <FormCheckbox id="expenseChkFlag" name="expenseFlag"
                          checked={formData.expenseFlag} onChange={onToggle}
                          toggle small>Expense</FormCheckbox>
                      </Col>
                      <Col lg="4" className="form-group mb-0 p-2">
                        <FormCheckbox id="messageChkFlag" name="messageFlag"
                          checked={formData.messageFlag} onChange={onToggle}
                          toggle small>Messages</FormCheckbox>
                      </Col>
                      <Col lg="2" className="form-group mb-0 p-1">
                        <Button className="btn btn-primary w-100" onClick={updateNotificationSettings}>Save</Button>
                      </Col>
                    </Row>
                    {/* <div
                      className="d-flex justify-content-between align-items-center mt-4 pt-2">
                      
                    </div> */}
                  </ListGroupItem>
                </ListGroup>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>

        </Row>
        : ''
      }
    </Container>
  );
}

Account.propTypes = {
  user: PropTypes.object.isRequired,
  require2FA: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired,
  leavesNotification: PropTypes.bool.isRequired,
  expenseNotification: PropTypes.bool.isRequired,
  messageNotification: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  require2FA: state.settings.require2FA,
  message: state.settings.message,
  success: state.settings.success,
  leavesNotification: state.settings.leavesNotification,
  expenseNotification: state.settings.expenseNotification,
  messageNotification: state.settings.messageNotification,
  notificationSettings: state.settings.notificationSettings
});

export default connect(mapStateToProps, {
  getSettings,
  user_disable_2fa,
  updateSettings
})(Account);
