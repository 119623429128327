import React,  { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Row,
  Button,
  Container,
  FormCheckbox
} from "shards-react";
import Table from './Table';
import { useLocation, useHistory } from "react-router-dom";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { setDateForTable } from '../../utils/date';
import {default as queryString} from "qs";
import { formatNumberAmount } from '../../utils/general';
function PerksInfo({
  formData,
  userProfile,
  formDataRef,
  sectionformDataRef,
  clientMarkups
}) {

  const [viewHistory, setViewHistory] = useState(false);
  const [isDisabledHSA, disableHSA] = useState(false);
  const location = useLocation();
  const history = useHistory();
  let queryParams = queryString.parse(location.search.replace("?", ""))

  const tableColumns = [
    {
      Header: <span className="text-wrap">HSA Provider</span>,
      accessor: "HSAProvider",
      maxWidth: 110,
      minWidth: 110,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",

    },
    {
      Header: <span className="text-wrap">HSA Start Date</span>,
      accessor: "HSAStartDate",
      maxWidth: 110,
      minWidth: 110,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => setDateForTable(row.HSAStartDate)
    },
    {
      Header: <span className="text-wrap">HSA End Date</span>,
      accessor: "HSAEndDate",
      maxWidth: 110,
      minWidth: 110,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => setDateForTable(row.HSAEndDate)
    },
    {
      Header: <span className="text-wrap">HSA Contribution Frequency</span>,
      accessor: "frequency",
      maxWidth: 203,
      minWidth: 203,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
    },

    {
      Header: <span className="text-wrap">HSA Contribution Currency</span>,
      accessor: "HSAContributionCurrency",
      maxWidth: 192,
      minWidth: 192,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",

    },

    {
      Header: <span className="text-wrap">HSA Contribution Amount</span>,
      accessor: "HSAAmount",
      maxWidth: 187,
      minWidth: 187,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-right",
      cell : row=>formatNumberAmount(row.HSAAmount, "en-US", 2, 2)
    },
    {
      Header: <span className="text-wrap">Ref#</span>,
      accessor: "HSAId",
      maxWidth: 50,
      minWidth: 50,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => (<Link to={{ pathname: `/HSA-info-form`, search:`?role=${queryParams.role}`, state: { userProfile, role: queryParams.role, row, formData, formDataRef, sectionformDataRef, clientMarkups } }}>{row.HSAId == 0 ? 'New' : row.HSAId}</Link>)
    },


  ];

  const togleHistory = () => {
    setViewHistory(!viewHistory);

  }

  useEffect(()=>{

    if(formData.HSAApplicable === 'Yes'){
      disableHSA(true); 
    }

  },[formData])
  return (
    <Card id="perks" small

      className="mb-5 w-100 d-flex personalParent">
      <span className="iconBox"><i className="fa-solid fa-gift iconStyle" /></span>
      <h4 className="m-0 section-title headerStyle pr-0">Health Spending Account Information <spen className="d-inline" ><FormCheckbox id="history" name="history"
        className="d-inline"
        checked={isDisabledHSA} onChange={() => {
          formData.HSAApplicable = formData.HSAApplicable == 'Yes' ? 'No' :'Yes';
          disableHSA(!isDisabledHSA)
        }}
        toggle small></FormCheckbox></spen></h4>
      
      <Container fluid className="file-manager__filters border-bottom">
        <Row className="mb-4">

          <Col lg="10"
            className="d-flex flex-row-reverse">

            <div className='d-flex flex-column ml-3 section-card-header'>
              <spen className="header-checkbox">
                <FormCheckbox id="history" name="history"
                  checked={viewHistory} onChange={togleHistory}
                  toggle small><spen className="font-weight-bold text-center">View HSA History</spen></FormCheckbox>
              </spen>
              <spen className="font-weight-bold text-center">


              </spen>
              <spen className="font-weight-bold font-italic">Note - Highlighted in blue row is the active HSA information.</spen>
            </div>
            <div></div>

          </Col>
          
          <Col lg="2"
            className=" pt-0 pl-0 pb-0 pr-2 file-manager__filters__search d-flex align-items-center justify-content-center justify-content-md-end col-6" >

<Button disabled={!isDisabledHSA} className="my-2 py-2 pr-0 pl-0 mx-2 w-100" size='sm'
                theme="accent"
                onClick={() => { 

                  history.push({
                    pathname: `/HSA-info-form`,
                    search:`?role=${queryParams.role}`,
                    state: {
                      userProfile,
                      refNum: 0,
                      role:queryParams.role,
                      formData,
                      formDataRef,
                      sectionformDataRef,
                      clientMarkups
                    },
                  });
                }}
                ><spen>Add HSA</spen></Button>

          </Col>

        </Row>
       

        <div className='scrollabe-table-container'>
          {

            (formData && formData.HSAInfos) ? 
              (<Table headers={tableColumns} rows={formData.HSAInfos} viewHistory={viewHistory} />)
             :
              (<Table headers={tableColumns} rows={[]} viewHistory={viewHistory} />)
          }
        </div>
      </Container>




    </Card>
  );
}

export default PerksInfo;
