import React, {useEffect, useState} from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Container, DatePicker, Form, FormInput, FormSelect, FormTextarea,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {Link, useHistory, useParams} from 'react-router-dom';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {setAPIDate, setDateForTable, setInputDate} from "../../utils/date";
import {
  clear_leave_details,
  get_leave_details,
  get_leaves_types,
  get_worker_leave_policy_names_list,
  save_leave_details,
  withdraw_leave
} from "../../redux/actions/worker/leaves";
import Alerts from "../../components/common/Alerts";
import {
  clear_calculated_leave_info,
  get_calculated_leave_info
} from "../../redux/actions/user";
import ConfirmAlert from "../../components/common/ConfirmAlert";

let typingTimer = null;

function EditLeave({
                     user,
                     workerLeavePolicyNamesList,
                     leaveDetails,
                     calculatedLeaveInfo,
                     clear_calculated_leave_info,
                     get_calculated_leave_info,
                     get_worker_leave_policy_names_list,
                     save_leave_details,
                     get_leave_details,
                     clear_leave_details,
                     withdraw_leave,
                     enableLeaveSubmit,
                   }) {

  const [state, setState] = useState({
    widthdraw: false
  });

  const [formData, setFormData] = useState({
    userId: null,
    ref: null,
    requestedDate: new Date().toISOString().slice(0, 10),
    leavePolicyId: '',
    from: '',
    to: '',
    leaveDays: '',
    leaveHours: '',
    notes: '',
    status: '',
    hoursInputDisabled:false,
    submitDisabled:true
  });

  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });

  const history = useHistory();
  let {ref} = useParams();

  useEffect(() => {
    get_worker_leave_policy_names_list();
  }, []);


  useEffect(() => {
    if (ref && ref !== '0') {
      get_leave_details(user.userId, ref);
    }
    return () => clear_leave_details();
  }, [ref]);

  if (leaveDetails.leavePolicy && workerLeavePolicyNamesList.length > 0 && !formData.leavePolicyId) {
    const selectedLeavePolicy = workerLeavePolicyNamesList.find(policy => policy.policyName === leaveDetails.leavePolicy);
    if (selectedLeavePolicy) {
      setFormData({
        ...formData,
        leavePolicyId: selectedLeavePolicy.leavePolicyId
      });
    }

  }


  useEffect(() => {
    if (leaveDetails && Object.keys(leaveDetails).length !== 0) {
      setFormData({
        ...formData,
        userId: user.userId,
        ref: ref,
        from: setAPIDate(leaveDetails.fromDate),
        to: setAPIDate(leaveDetails.toDate),
        leaveDays: leaveDetails.leaveDays,
        leaveHours: leaveDetails.leaveHours,
        notes: leaveDetails.notes,
        status: leaveDetails.status,
        requestedDate: setDateForTable(leaveDetails.requestedDate),
        hoursInputDisabled:false,
        submitDisabled:false
      });
    }
  }, [leaveDetails]);

  useEffect(() => {
   
    if (calculatedLeaveInfo && Object.keys(calculatedLeaveInfo).length !== 0) {
      setFormData({
        ...formData,
        leaveDays: calculatedLeaveInfo.leaveDays,
        leaveHours: calculatedLeaveInfo.leaveHours,
        hoursInputDisabled:false,
        submitDisabled:false
      });
    }
    
  }, [calculatedLeaveInfo]);
  useEffect(() => {
    debugger;
    if (enableLeaveSubmit) {
      setFormData({
        ...formData,       
        submitDisabled:true
      });
    }
    
  }, [enableLeaveSubmit]);
  useEffect(() => {
    if (formData.from && formData.to && formData.leavePolicyId) {
      
      get_calculated_leave_info(setInputDate(formData.from), setInputDate(formData.to), formData.leaveDays, formData.leaveHours,"",formData.leavePolicyId);
    }
    return () => {
      clear_calculated_leave_info();
      
    };
  }, [formData.from, formData.to,formData.leavePolicyId]);

  useEffect(() => {
   
    clearTimeout(typingTimer);
    if (formData.from && formData.to && formData.leaveHours && formData.leaveHours!=calculatedLeaveInfo.leaveHours && formData.leaveHours!=leaveDetails.leaveHours && formData.leavePolicyId) {
      typingTimer = setTimeout(() => {
        setFormData({...formData,hoursInputDisabled:true})
        setFormData({...formData,submitDisabled:true})
        
        get_calculated_leave_info(setInputDate(formData.from), setInputDate(formData.to), formData.leaveDays, formData.leaveHours,formData.leavePolicyId);
      }, 1000);
    }
   
    return () => {
      clearTimeout(typingTimer);
      
    };
  }, [formData.leaveHours]);


  const onChange = (e) => {
    e.target.setCustomValidity("")
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onSubmit = async (e) => {
    e.preventDefault();
   const fromDate = setInputDate(formData.from);
   const toDate = setInputDate(formData.to);  
   

   if (calculatedLeaveInfo && Object.keys(calculatedLeaveInfo).length !== 0 ) {
    save_leave_details(
      user.userId,
      ref,
      formData.leavePolicyId,
      fromDate,
      toDate,
      formData.leaveDays,
      formData.leaveHours,
      formData.notes
    );
    history.goBack();

  }else{
    save_leave_details(
      user.userId,
      ref,
      formData.leavePolicyId,
      fromDate,
      toDate,
      formData.leaveDays,
      formData.leaveHours,
      formData.notes
    );
    history.goBack();
  }
   
  };

  var leavePolicyOptions = workerLeavePolicyNamesList.map((leavePolicy) => {
    return <option
      value={leavePolicy.leavePolicyId}>{leavePolicy.policyName}</option>;
  });

  let widthDrawClickHandler = (e) => {
    e.preventDefault();
    if (state.widthdraw) {
      withdraw_leave(user.userId, ref);
      history.push('/manage-leaves');
    } else {
      setState({
        ...state,
        widthdraw: true
      })
    }
  };

  let cancelWithdraw = (e) => {
    e.preventDefault();
    setState({
      ...state,
      widthdraw: false
    })
  };


  let onCancel=()=>{
    if((ref == 0 || (formData.status && formData.status !== "Approved") ))
    {
    setConfirmAlert({
      confirmMsg: "Are you sure you want to exit this screen without completing your leave application?",
      visible: true,
      onConfirm: () => {
        history.goBack();
      }
    })
  }
  else
    history.goBack();
  };

  return (
    <React.Fragment>
      <ConfirmAlert confirmAlert={confirmAlert}
                    setConfirmAlert={setConfirmAlert}/>
      <Alerts/>
      {state.widthdraw &&
      <Container fluid className="p-0 sticky-alert-wrapper sticky-top">
        <Alert theme="light" className="text-dark show px-4 mb-0">
          Are you sure you want to Withdraw this leave?
          <Button className="mx-2 py-2" size="sm"
                  theme="primary"
                  onClick={widthDrawClickHandler}>Withdraw</Button>
          <Button className="mx-2 py-2" size="sm"
                  theme="white" onClick={cancelWithdraw}>Cancel</Button>
        </Alert>
      </Container>}
      <Container fluid className="main-content-container p-4">
        <Card small className="mb-4 w-100">
          <h4 className="m-0 section-title idStyle">Ref# {ref}</h4>
          <ListGroup flush>
            <ListGroupItem className="border-card p-3">
              <Row>
                <Col>
                  <Form onSubmit={onSubmit}>
                    <Row form>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="requestedDate">Requested Date</label>
                        <FormInput
                          id="requestedDate"
                          placeholder="Requested Date"
                          name='requestedDate'
                          readOnly
                          value={formData.requestedDate}
                          onChange={onChange}
                        />
                      </Col>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="leavePolicyId">Leave Type</label>
                        <FormSelect id="leavePolicyId" name='leavePolicyId'
                                    value={formData.leavePolicyId}
                                    onInvalid={e => e.target.setCustomValidity("Choose Leave Type")}
                                   
                                    onChange={onChange}
                                    required>
                          <option value=''>Select</option>
                          {leavePolicyOptions}
                        </FormSelect>
                      </Col>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="from">From</label>
                        <div>
                          <DatePicker
                            id='from'
                            autoComplete='off'
                            name='from'
                            dateFormat='yyyy-MM-dd'
                            selected={formData.from}
                            onChange={(fromDate) =>
                              setFormData({...formData, from: fromDate})
                            }
                            value={formData.from}
                            required
                          />
                        </div>
                      </Col>


                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="to">To</label>
                        <div>
                          <DatePicker
                            id='to'
                            autoComplete='off'
                            name='to'
                            required
                            dateFormat='yyyy-MM-dd'
                            selected={formData.to}
                            onChange={(toDate) =>
                              setFormData({...formData, to: toDate})
                            }
                            value={formData.to}
                          />
                        </div>
                      </Col>


                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="leaveDays">Leave Days</label>
                        <FormInput
                          id="leaveDays"
                          name='leaveDays'
                          value={(formData.leaveDays)?parseFloat(formData.leaveDays).toFixed(2):""}
                          readOnly
                          onChange={onChange}
                        />
                      </Col>


                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="leaveHours">Leave Hours</label>
                        <FormInput
                          id="leaveHours"
                          name='leaveHours'
                          value={formData.leaveHours}
                          readOnly={formData.hoursInputDisabled}
                          onChange={onChange}
                        />
                      </Col>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        {ref != 0 && <React.Fragment>
                          <label htmlFor="status">Status</label>
                          <FormInput
                            id="status"
                            name='status'
                            readOnly
                            value={formData.status}
                          />
                        </React.Fragment>}
                      </Col>

                      <Col sm="12" md="12" className="form-group p-2 m-0">
                        <label htmlFor="notes">My Notes</label>
                        <FormTextarea
                          id="notes"
                          rows="10"
                          name="notes"
                          value={formData.notes}
                          onChange={onChange}
                        />
                      </Col>

                    </Row>
                    <Row className="px-3">
                      <Col sm="12" md="12" lg="12"
                           className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">
                        {ref != 0 && formData.status && formData.status !== "Withdrawn" &&
                        <Button className="mx-1 py-2 mx-md-2" size="sm"
                                theme="primary"
                                onClick={widthDrawClickHandler}>Withdraw</Button>}
                        {(ref == 0 || (formData.status && formData.status !== "Withdrawn" && formData.status !== "Approved") ) &&
                        <Button className="mx-1 py-2 mx-md-2" size="sm" disabled={formData.submitDisabled }
                                theme="accent">Submit</Button>}

                          <Button className="mx-1 py-2 mx-md-2" size="sm"
                                  theme="accent" onClick={onCancel}>Cancel</Button>

                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>
    </React.Fragment>
  );
}

EditLeave.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  get_leaves_list: PropTypes.func.isRequired,
  save_leave_details: PropTypes.func.isRequired,
  get_leave_details: PropTypes.func.isRequired,
  withdraw_leave: PropTypes.func.isRequired,
  leaveDetails: PropTypes.object.isRequired,
  clear_calculated_leave_info: PropTypes.func.isRequired,
  get_calculated_leave_info: PropTypes.func.isRequired,
  approve_leave: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  workerLeavePolicyNamesList: state.leaves.workerLeavePolicyNamesList,
  leaveDetails: state.leaves.leaveDetails,
  calculatedLeaveInfo: state.user.calculatedLeaveInfo,
  enableLeaveSubmit: state.user.enableLeaveSubmit
});
export default connect(mapStateToProps, {
  get_worker_leave_policy_names_list,
  get_leave_details,
  clear_leave_details,
  save_leave_details,
  withdraw_leave,
  clear_calculated_leave_info,
  get_calculated_leave_info,
})(EditLeave)
