import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams,useLocation} from "react-router-dom";
import {default as queryString} from "qs";
import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container,
  FormInput,
  FormSelect, Row
} from "shards-react";
import {getStatusClass} from "../../utils/general";
import ReactTable from "react-table";
import {
  get_role_list,
  get_sub_status_list,
  search_user_list,
  delete_user,
  userHasExistingData
} from "../../redux/actions/user";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {clear_sheet_ref_num} from "../../redux/actions/worker/expenses";
import Alerts from "../../components/common/Alerts";
import {clear_sub_expense_sheet} from "../../redux/actions/subscriber/subExpenses";
import ConfirmAlert from "../../components/common/ConfirmAlert";
import user from '../../redux/reducers/user';
function SubscriberUsers({user,
                           subStatusList,
                           roleList,
                           get_sub_status_list,
                           get_role_list,
                           delete_user,
                           userHasExistingData,
                           IsUserDeleted,
                           IsUserHasData,
                           userId,
                           subscriberUserSearchList,
                           clear_sheet_ref_num,
                           search_user_list,
                           clear_sub_expense_sheet
                         }) {
  const [formData, setFormData] = useState({
    clientName: '',
    userName: '',
    userId: null,
    email: '',
    substatus: '',
    role: '',
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
  });
  const location = useLocation();
  let queryParams = queryString.parse(location.search.replace("?", ""))
  
  const [state, setState] = useState({
    userId: ""
  });
  const history = useHistory();

  useEffect(() => {
    get_sub_status_list();
    get_role_list();
  }, []);

  function searchUsers() {
    search_user_list(formData.userId, formData.clientName, formData.userName, formData.email, formData.substatus, formData.role);
  }

  useEffect(() => {
   
   if(queryParams && queryParams.status){
  
    if(queryParams.status==="all"){

      setFormData({...formData,"substatus": '', "role": 'Worker'});
      formData.substatus="";
    }
    else
    {
      setFormData({...formData, "substatus": queryParams.status, "role": 'Worker'});
      formData.substatus=queryParams.status;
    }
    
    search_user_list(formData.userId, formData.clientName, formData.userName, formData.email, formData.substatus, 'Worker');
   }else{
    searchUsers();
   }
    
    return () => clear_sheet_ref_num();
  }, []);

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  useEffect(() => {
    if(IsUserDeleted && IsUserDeleted!=false){
      search_user_list(formData.userId, formData.clientName, formData.userName, formData.email, formData.substatus, formData.role);
                           }
                          }, [IsUserDeleted]);
  useEffect(() => {
    if(IsUserHasData && IsUserHasData.hasdata && state.userId){
      
      setConfirmAlert({
        confirmMsg: " Deleting the User will delete all associated records (eg. Leave, Expense and Message Center). Do you wish to proceed, Yes or No?",
        visible: true,
        onConfirm: () => {
          IsUserDeleted=false;
          delete_user(state.userId);
        }
      })
    
     }
     else  if(IsUserHasData && IsUserHasData.hasdata==false && state.userId){
     debugger;
      setConfirmAlert({
        confirmMsg: "Are you sure you want to delete User?",
        visible: true,
        onConfirm: () => {
          IsUserDeleted=false;
          delete_user(state.userId);
        }
      })
     }
   
  }, [IsUserHasData]);
  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });
  const onActionChange = (e, userId, userName, role,clientName, hireDate) => {

    let state = {
      userId: userId,
      workerName: userName,
      role: role,
     hireDate :hireDate
     
    }
    
    setState({
      userId: userId
    });
   
    
    switch (e.target.value) {
      case "editProfile":
        history.push(`/manage-user-profile/${userId}?role=${role}&workerName=${userName}`);
        break;
      case "addLeave":
        history.push({pathname: '/sub-edit-leave/0', state: state});
        break;
      case "deleteUser": {
        userHasExistingData(userId);
      }
        break;
        case "addExpense": {
          clear_sub_expense_sheet();
          history.push({
            pathname: '/sub-edit-expense-reports/' + userId + '/0',
            state: state
          });
        }
          break;
      case "newMessage": {
        history.push('/sub-new-message/0/' + userId + '/' + role);
      }
        break;
      case "manageLeaves":
        history.push({pathname: '/sub-search-leaves/' + userId, state: state});
        break;
      case "manageExpenses":
        history.push({
          pathname: '/sub-search-expenses/' + userId,
          state: state
        });
        break;
      case "manageMessages":
        history.push('/sub-message-activity/' + userId + '?role=' + role);
        break;
      case "managePermissions":
        history.push(`/sub-manage-permissions/${userId}?role=${role}&userName=${encodeURIComponent(userName)}&clientName=${encodeURIComponent(clientName)}`);
        break;
      default:
        break;
    }
  }

  const tableData = subscriberUserSearchList;
  var {pageSize, pageSizeOptions} = formData;

  const tableColumns = [
    {
      Header: "Client Name",
      accessor: "clientName",
      className: "justify-content-start text-left text-wrap",
      Cell: row =>
        <Link
          to={`/sub-manage-client-profile/${row.original.clientId}`}>{row.original.clientName}</Link>
    },
    {
      Header: "User ID",
      accessor: "userId",
      maxWidth: 80,
      className: "text-center",
      Cell: row =>
        <Link
          to={`/manage-user-profile/${row.original.userId}?role=${row.original.role}`}>{row.original.userId}</Link>
    },
    {
      Header: "User Name",
      accessor: "userName",
      className: "justify-content-start text-left text-wrap text-break"
    },
    {
      Header: "Login Email",
      accessor: "loginEmail",
      maxWidth: 180,
      className: "justify-content-start text-left text-wrap text-break"
    },

    {
      Header: "Employment Status",
      accessor: "subStatus",
      maxWidth: 100,
      minWidth: 100,
      className: "text-center",
      Cell: row =>
        <span
          className={getStatusClass(row.original.subStatus)}>{row.original.subStatus}</span>,
    },

    {
      Header: "Role",
      accessor: "role",
      maxWidth: 150,
      className: "text-center",
    },

    {
      Header: "Action",
      maxWidth: 140,
      Cell: row =>
        <FormSelect className="row-action-select py-0" value=''
                    onChange={e => onActionChange(e, row.original.userId, row.original.userName, row.original.role,row.original.clientName, row.original.hireDate)}>
          <option value=''>Select</option>
          <option value='editProfile'>Edit Profile</option>
         
          {(row.original.subStatus != 'Inactive') &&
             <option value='addLeave'>Apply for Leave</option>             
          }
           {(row.original.subStatus != 'Inactive') &&
              <option value='addExpense'>Add Expense</option>           
          }
        {(row.original.subStatus != 'Inactive') &&
              <option value='newMessage'>New Message</option>         
          }
             
           
          <option value='manageLeaves'>Manage Leaves</option>
          <option value='manageExpenses'>Manage Expenses</option>
          <option value='manageMessages'>Manage Messages</option>
          {(["Worker", "Client User", "Client HR", "Client Billing", "Client Admin"].includes(row.original.role)) &&
          <option value='managePermissions'>Manage Permissions</option>}
          {(["Worker", "Client User", "Client HR", "Client Billing", "Client Admin"].includes(row.original.role)) && user.userRoles.filter((role)=>role.role.includes("Subscriber Admin")).length> 0 &&
          <option value='deleteUser' className='text-danger'>Delete User</option>}
          

        </FormSelect>
    },
  ];

  var subStatusOptions = subStatusList.map((status, i) => {
    return <option key={status + i} value={status}>{status}</option>;
  });
  var roleOptions = roleList !== null && roleList.map((role, i) => {
    return <option key={role + i} value={role}>{role}</option>;
  });

  return (
    <>
      <ConfirmAlert confirmAlert={confirmAlert}
                    setConfirmAlert={setConfirmAlert}/>
      <Alerts/>
      <Container fluid className="main-content-container p-4">

        <div noGutters
             className="page-header flex-column flex-md-row py-2 d-flex justify-content-end bg-white tableSearch">

          <div className="d-flex align-items-center p-2 ml-2">
            <FormInput
              name='clientName'
              value={formData.clientName}
              onChange={onChange}
              placeholder="Client Name"
            />
          </div>

          <div className="d-flex align-items-center p-2">
            <FormInput
              name='userName'
              value={formData.userName}
              onChange={onChange}
              placeholder="User Name"
            />
          </div>

          <div className="d-flex align-items-center p-2">
            <FormInput
              name='userId'
              value={formData.userId}
              onChange={onChange}
              placeholder="User Id"
            />
          </div>

          <div className="d-flex align-items-center p-2">
            <FormInput
              name='email'
              value={formData.email}
              onChange={onChange}
              placeholder="Email"
            />
          </div>

          <div className="d-flex align-items-center p-2">
            <FormSelect name='substatus' value={formData.substatus}
                        onChange={onChange}>
              <option value=''>All Employment Status
              </option>
              {subStatusOptions}
            </FormSelect>
          </div>

          <div className="d-flex align-items-center p-2">
            <FormSelect name='role' value={formData.role}
                        onChange={onChange}>
              <option value=''>All Roles
              </option>
              {roleOptions}
            </FormSelect>
          </div>

          <div className="d-flex align-items-center justify-content-end p-2">
            <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                    onClick={searchUsers}>Search</Button>
          </div>
        </div>


        <Card className="tableCard p-0">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {/* Filters :: Page Size */}
              <Col
                className="file-manager__filters__rows d-flex justify-content-center justify-content-md-start align-items-center col-6 col-lg-2 order-lg-0">
                <span>Show</span>
                <FormSelect
                    size="sm"
                    name='pageSize'
                    value={pageSize}
                    onChange={onChange}
                  >
                    {pageSizeOptions.map((size, idx) => (
                      <option key={idx} value={size}>
                        {size} rows
                      </option>
                    ))}
                  </FormSelect>
              </Col>

              <Col
                className="file-manager__filters__search d-flex justify-content-center justify-content-md-end col-6 col-lg-3 order-lg-2">
                 <Link to={`/manage-user-profile/0`}>
                    <Button className="my-2 py-2 mx-2" size='sm'
                            theme="accent">Add User</Button>
                  </Link>
              </Col>

              <Col
                className="col-12 col-lg-7 d-flex align-items-center order-lg-1">
                <p
                  className={"m-0 "}>{`Note - This section shows Active and On Leave Users`}</p>
              </Col>


            </Row>
              
             
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <div className="">
              <ReactTable
                columns={tableColumns}
                data={tableData}
                pageSize={Number(pageSize)}
                showPageSizeOptions={false}
                resizable={false}
                noDataText="No results found"
              />
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

SubscriberUsers.propTypes = {
  user: PropTypes.object.isRequired,
  subStatusList: PropTypes.array.isRequired,
  roleList: PropTypes.array.isRequired,
  get_sub_status_list: PropTypes.func.isRequired,
  get_role_list: PropTypes.func.isRequired,
  subscriberUserSearchList: PropTypes.array.isRequired,
  search_user_list: PropTypes.func.isRequired,
  delete_user: PropTypes.func.isRequired,
  userHasExistingData: PropTypes.func.isRequired,
  IsUserHasData:PropTypes.object.isRequired,
  IsUserDeleted:PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user.user,
  subStatusList: state.user.subStatusList,
  roleList: state.user.roleList,
  subscriberUserSearchList: state.user.subscriberUserSearchList,
  delete_user: state.user.delete_user,
  userHasExistingData: state.user.userHasExistingData,
  IsUserHasData:state.user.IsUserHasData,
  IsUserDeleted:state.user.IsUserDeleted,
});
export default connect(mapStateToProps, {
  get_sub_status_list,
  get_role_list,
  search_user_list,
  clear_sub_expense_sheet,
  clear_sheet_ref_num,
  delete_user,
  userHasExistingData
})(SubscriberUsers);
