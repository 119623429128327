import React, {useEffect, useState} from 'react';
import {
  Card,
  Col,
  Container, Form, FormCheckbox, ListGroup, ListGroupItem,
  Row
} from "shards-react";
import CustomCard from '../../components/common/CustomCard';
import InputBox from '../../components/common/InputBox';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
  clear_leave_type_detail,
  get_leave_type_detail,
  save_leave_type_detail
} from "../../redux/actions/user";
import {useHistory, useParams} from "react-router-dom";


function AddEditConfigLeaves({
                               get_leave_type_detail,
                               clear_leave_type_detail,
                               save_leave_type_detail,
                               leaveType,
                             }) {

  let {id} = useParams();
  useEffect(() => {
    if (id !== null && id !== '' && id !== '0' && id !== 0) {
      get_leave_type_detail(id);
    }
    return ()=>clear_leave_type_detail();
  }, [id]);


  const history = useHistory();
  const [formData, setFormData] = useState({
    leaveTypeName: '',
    policyRequired: false,
    entitled: false,
    days: null,
  });
  useEffect(() => {
    if (Object.keys(leaveType).length !== 0) {
      setFormData({
        ...formData,
        leaveTypeName: leaveType.leaveTypeName,
        policyRequired: leaveType.policyRequired,
        entitled: leaveType.entitled,
        days: leaveType.days,
      });
    }
  }, [leaveType]);

  const {leaveTypeName, policyRequired, entitled, days} = formData;
  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  const onSubmit = (e) => {
    e.preventDefault();
    save_leave_type_detail(id, leaveTypeName, policyRequired, entitled, days);
    history.goBack();
  };

  function goBack(e) {
    e.preventDefault();
    history.goBack();
  }

  function onToggle(e) {
    setFormData({
      ...formData,
      [e.target.name]: !formData[e.target.name]
    })
  }

  return (
    <Container fluid className="main-content-container p-4">
      <Card small className="mb-5 w-100">
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <Form id="clientDetailsForm" onSubmit={onSubmit}>
                  <Row form>
                    <InputBox label="Leave Type Name" name='leaveTypeName'
                              value={formData.leaveTypeName}
                              onChange={onChange}/>

                    <Col lg="3" className="form-group p-2 mb-0 d-flex">
                      <Col className="form-group mb-0">
                        <label htmlFor="policyRequired">Policy Type</label>
                        <FormCheckbox id="policyRequired" name="policyRequired"
                                      checked={formData.policyRequired}
                                      onChange={onToggle}
                                      toggle small/>
                      </Col>

                      <Col className="form-group mb-0">
                        <label htmlFor="entitled">Entitled</label>
                        <FormCheckbox id="entitled" name="entitled"
                                      checked={formData.entitled}
                                      onClick={onToggle}
                                      toggle small/>
                      </Col>
                    </Col>


                    <InputBox label="Entitled Days" name='days'
                              value={formData.days}
                              readOnly={!formData.entitled}
                              onChange={onChange}/>

                    <Col lg="3" className="form-group p-2 mb-0">
                      <div
                           className="d-flex justify-content-between  mt-4 pt-2">
                        <button type="submit"
                                className="btn btn-primary w-100">Save
                        </button>
                        <button onClick={goBack}
                                className="btn btn-primary ml-2 w-100">Close
                        </button>
                      </div>
                    </Col>

                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Container>
  );
}


AddEditConfigLeaves.propTypes = {
  get_leave_type_detail: PropTypes.func.isRequired,
  leaveType: PropTypes.object.isRequired,
  save_leave_type_detail: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  leaveType: state.user.leaveType,
});
export default connect(mapStateToProps, {
  get_leave_type_detail,
  clear_leave_type_detail,
  save_leave_type_detail,
})(AddEditConfigLeaves);
