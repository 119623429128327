import {isAuthenticated} from '../../utils/general';
import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_SUCCESS,
  VERIFICATIONCODE_CLEAR,
  VERIFICATIONCODE_SUCCESS,
  VERIFICATIONCODE_FAILED,
  VERIFYCODE_SUCCESS,
  VERIFYCODE_FAILED,
  RESETPASSWORD_SUCCESS,
  RESETPASSWORD_FAILED,
  RESET,
  GET_SUB_STATUS_LIST,
  GET_SUB_STATUS_LIST_ERROR,
  GET_ROLE_LIST,
  GET_ROLE_LIST_ERROR,
  SEARCH_USER_LIST,
  SEARCH_USER_LIST_ERROR,
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_ERROR,
  GET_REGION_LIST,
  GET_REGION_LIST_ERROR,
  GET_CITY_LIST,
  GET_CITY_LIST_ERROR,
  GET_CITY_DETAILS,
  CLEAR_CITY_DETAILS,
  GET_CITY_DETAILS_ERROR,
  GET_CURRENCY_LIST,
  CLEAR_CURRENCY_DETAILS,
  GET_CURRENCY_LIST_ERROR,
  GET_CURRENCY_DETAILS,
  GET_CURRENCY_DETAILS_ERROR,
  GET_COMPENSATION_TYPES,
  GET_COMPENSATION_TYPES_ERROR,
  GET_WORKER_TYPES,
  GET_WORKER_TYPES_ERROR,
  GET_VISA_STATUS_LIST,
  GET_VISA_STATUS_LIST_ERROR,
  GET_DEAL_TYPES,
  GET_DEAL_TYPES_ERROR,
  GET_USER_PROFILE,
  GET_USER_PROFILE_ERROR,
  SAVE_USER_PROFILE,
  SAVE_USER_PROFILE_ERROR,
  GET_AVAILABLE_FIELD_NAME,
  GET_AVAILABLE_FIELD_NAME_ERROR,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_ERROR,
  USER_DISABLE_2FA,
  USER_DISABLE_2FA_ERROR,
  SET_USER_ACCOUNT_STATUS,
  SET_USER_ACCOUNT_STATUS_ERROR,
  GET_EMPLOYMENT_STATUS_LIST,
  GET_EMPLOYMENT_STATUS_LIST_ERROR,
  GET_EMPLOYMENT_SUB_STATUS_LIST,
  GET_EMPLOYMENT_SUB_STATUS_LIST_ERROR,
  GET_CLIENT_VIEW_WORKER_PROFILE_ERROR,
  GET_CLIENT_VIEW_WORKER_PROFILE,
  CLEAR_CLIENT_VIEW_WORKER_PROFILE,
  GET_USER_JOURNEY_LIST_FOR_SUB,
  GET_USER_JOURNEY_LIST_FOR_SUB_ERROR,
  DELETE_USER_JOURNEY_FOR_SUB,
  DELETE_USER_JOURNEY_FOR_SUB_ERROR,
  GET_SUBSCRIBER_LIST,
  GET_SUBSCRIBER_LIST_ERROR,
  GET_SALESCONSULTANT_LIST,
  GET_SALESCONSULTANT_LIST_ERROR,
  GET_CLIENT_LIST,
  GET_CLIENT_LIST_ERROR,
  GET_CLIENT_CONTACT_LIST,
  GET_CLIENT_CONTACT_LIST_ERROR,
  GET_BENEFITS_COVERAGE_LIST,
  GET_BENEFITS_COVERAGE_LIST_ERROR,
  GET_PERKS_FREQUENCY_LIST,
  GET_PERKS_FREQUENCY_LIST_ERROR,
  GET_PENSION_PLAN_FREQUENCY_LIST,
  GET_PENSION_PLAN_FREQUENCY_LIST_ERROR,
  GET_PENSION_PLAN_LIST,
  GET_PENSION_PLAN_LIST_ERROR,
  GET_PREFERRED_EMAIL_LIST,
  GET_PREFERRED_EMAIL_LIST_ERROR,
  GET_BENEFITS_CLASS_LIST,
  GET_BENEFITS_CLASS_LIST_ERROR,
  GET_PRONOUN_LIST,
  GET_PRONOUN_LIST_ERROR,
  GET_MARITAL_STATUS_LIST,
  GET_MARITAL_STATUS_LIST_ERROR,
  SEARCH_LEAVES,
  SEARCH_LEAVES_ERROR,
  GET_SUB_LEAVE_DETAILS,
  GET_SUB_LEAVE_DETAILS_ERROR,
  GET_SUB_EXPENSE_REPORTS,
  GET_SUB_EXPENSE_REPORTS_ERROR,
  SUB_WITHDRAW_LEAVE,
  SUB_WITHDRAW_LEAVE_ERROR,
  SUB_SUBMIT_LEAVE_DETAILS,
  SUB_SUBMIT_LEAVE_DETAILS_ERROR,
  GET_SUB_LEAVE_TYPES,
  GET_SUB_LEAVE_TYPES_ERROR,
  GET_SUB_CLIENT_LIST,
  GET_SUB_CLIENT_LIST_ERROR,

  GET_CLIENT_PROFILE,
  GET_CLIENT_PROFILE_ERROR,
  DELETE_CLIENT,
  DELETE_CLIENT_ERROR,
  DELETE_USER,
  DELETE_USER_ERROR,
  USER_HAS_EXISTING_DATA,
  //USER_HAS_EXISTING_DATA_ERROR, it was not being used causing console error
  GET_WORKER_FOR_CLIENT_LIST,
  GET_WORKER_FOR_CLIENT_LIST_ERROR,
  GET_MILEAGE_RANGE_LIST,
  GET_MILEAGE_RANGE_LIST_ERROR,
  GET_LEAVE_TYPE_DETAILS,
  GET_LEAVE_TYPE_DETAILS_ERROR,
  SAVE_LEAVE_TYPE_DETAILS,
  SAVE_LEAVE_TYPE_DETAILS_ERROR,
  GET_MILEAGE_RANGE_DETAILS,
  GET_MILEAGE_RANGE_DETAILS_ERROR,
  SAVE_MILEAGE_RANGE_DETAILS,
  SAVE_MILEAGE_RANGE_DETAILS_ERROR,
  GET_SYSADMIN_SUBSCRIBER_LIST,
  GET_SYSADMIN_SUBSCRIBER_LIST_ERROR,
  GET_SYSADMIN_SUBSCRIBER_USER_LIST,
  GET_SYSADMIN_SUBSCRIBER_USER_LIST_ERROR,
  GET_SYSADMIN_SUBSCRIBER_PROFILE,
  GET_SYSADMIN_SUBSCRIBER_PROFILE_ERROR,
  SAVE_SYSADMIN_SUBSCRIBER_PROFILE,
  SAVE_SYSADMIN_SUBSCRIBER_PROFILE_ERROR,
  SEARCH_SYSADMIN_USER_LIST,
  SEARCH_SYSADMIN_USER_LIST_ERROR,
  GET_CITY_DETAILED_LIST,
  GET_CITY_DETAILED_LIST_ERROR,
  SAVE_CITY_DETAILS,
  SAVE_CITY_DETAILS_ERROR,
  APPROVE_LEAVE,
  APPROVE_LEAVE_ERROR,
  SET_USER_PASSWORD,
  SET_USER_PASSWORD_ERROR,
  SEARCH_SUB_HOLIDAYS_ERROR,
  SEARCH_SUB_HOLIDAYS,
  GET_HOLIDAY_DETAILS_ERROR,
  GET_HOLIDAY_DETAILS,
  GET_ALLOWANCE_TYPE_LIST,
  GET_ALLOWANCE_TYPE_LIST_ERROR,
  GET_EXPENSE_CONFIG_TYPE_LIST,
  GET_EXPENSE_CONFIG_TYPE_LIST_ERROR,
  DELETE_ALLOWANCE_TYPE_DETAILS_ERROR,
  DELETE_ALLOWANCE_TYPE_DETAILS,
  SAVE_ALLOWANCE_TYPE_DETAILS_ERROR,
  SAVE_ALLOWANCE_TYPE_DETAILS,
  GET_ALLOWANCE_TYPE_DETAILS_ERROR,
  GET_ALLOWANCE_TYPE_DETAILS,
  DELETE_EXPENSE_TYPE_DETAILS_ERROR,
  DELETE_EXPENSE_TYPE_DETAILS,
  SAVE_EXPENSE_TYPE_DETAILS_ERROR,
  SAVE_EXPENSE_TYPE_DETAILS,
  GET_EXPENSE_TYPE_DETAILS_ERROR,
  GET_EXPENSE_TYPE_DETAILS,
  GET_SEARCH_LEAVE_POLICY_SUCCESS,
  GET_SEARCH_LEAVE_POLICY_ERROR,
  GET_LEAVE_QUOTA_RESET_TYPE_LIST_ERROR,
  GET_LEAVE_QUOTA_RESET_TYPE_LIST,
  GET_LEAVE_PERIOD_STRING_LIST_ERROR,
  GET_LEAVE_PERIOD_STRING_LIST,
  GET_LEAVE_ACCRUAL_DATE_TYPE_LIST_ERROR,
  GET_LEAVE_ACCRUAL_DATE_TYPE_LIST,
  GET_LEAVE_ACCRUAL_EVERY_STRING_LIST_ERROR,
  GET_LEAVE_ACCRUAL_EVERY_STRING_LIST,
  GET_LEAVE_PAY_OUT_TIME_TYPE_LIST_ERROR,
  GET_LEAVE_PAY_OUT_TIME_TYPE_LIST,
  GET_LEAVE_PAY_OUT_LIMIT_TYPE_LIST_ERROR,
  GET_LEAVE_PAY_OUT_LIMIT_TYPE_LIST,
  GET_CLIENT_LEAVE_POLICY_LIST_SUCCESS,
  GET_CLIENT_LEAVE_POLICY_LIST_ERROR,
  DELETE_CLIENT_LEAVE_POLICY_LIST_SUCCESS,
  DELETE_CLIENT_LEAVE_POLICY_LIST_ERROR,
  GET_CLIENT_LEAVE_POLICY_DETAIL_SUCCESS,
  GET_CLIENT_LEAVE_POLICY_DETAIL_ERROR,
  REJECT_LEAVE_ERROR,
  REJECT_LEAVE,
  CLEAR__HOLIDAYS_DETAILS,
  DELETE_HOLIDAY,
  CLEAR__CLIENT_PROFILE,
  SAVE_CLIENT_PROFILE,
  CLEAR_CLIENT_LEAVES_LIST,
  GET_CALCULATED_LEAVE_INFO,
  GET_CALCULATED_LEAVE_INFO_ERROR,
  CLEAR_CALCULATED_LEAVE_INFO,
  CLEAR_SYSADMIN_SUBSCRIBER_PROFILE,
  GET_BASE_CURRENCY,
  GET_MESSAGE_CATEGORY_LIST,
  GET_MESSAGE_CATEGORY_LIST_ERROR,
  SAVE_MESSAGE_CATEGORY_DETAILS,
  SAVE_MESSAGE_CATEGORY_DETAILS_ERROR,
  DELETE_MESSAGE_CATEGORY_DETAILS,
  DELETE_MESSAGE_CATEGORY_DETAILS_ERROR,
  GET_MESSAGE_CATEGORY_DETAILS,
  GET_MESSAGE_CATEGORY_DETAILS_ERROR,
  GET_MESSAGE_CATEGORY_ROLE_LIST,
  GET_MESSAGE_CATEGORY_ROLE_LIST_ERROR,
  GET_MESSAGE_STATUS_LIST,
  GET_HR_CONSULTANT_LIST,
  GET_HR_CONSULTANT,
  GET_HR_CONSULTANT_ERROR,
  SEARCH_CONVERSATIONS,
  GET_CONVERSATION_DETAIL,
  UPLOAD_CONVERSATION_ATTACHMENT,
  SEARCH_USER_MESSAGES,
  CLEAR_CONVERSATION_DETAILS,
  SEARCH_CONVERSATIONS_PAGINATED,
  REDIRECT,
  UNREDIRECT,
  GET_NOTIFICATION_CONFIG,
  GET_NOTIFICATION_CONFIG_ERROR,
  SAVE_NOTIFICATION_CONFIG,
  SAVE_NOTIFICATION_CONFIG_ERROR,

  GET_ROLE_USER_LIST,
  GET_ROLE_USER_LIST_ERROR,
  GET_ASSIGNED_MSG_LIST,
  GET_ASSIGNED_MSG_USER_LIST,
  GET_ASSIGNED_MSG_LIST_ERROR,
  SAVE_MSG_ASSIGNED_INFO,
  SAVE_MSG_ASSIGNED_INFO_ERROR,
  DELETE_MSG_ASSIGNED_INFO,
  DELETE_MSG_ASSIGNED_INFO_ERROR,
  GET_SYSADMIN_EVENTNAME_LIST,
  GET_SYSADMIN_EVENTNAME_LIST_ERROR,
  GET_SYSADMIN_EVENTDATA_LIST,
  GET_SYSADMIN_EVENTDATA_LIST_ERROR,
  ENABLE_LEAVE_SUBMIT,
  GET_APP_MODULE,
  GET_APP_MODULE_CLEAR,
  GET_APP_MODULE_ERROR,
  GET_CLIENT_LIST_REPORT,
  GET_CLIENT_LIST_REPORT_ERROR,
  GET_COUNTRY_LIST_REPORT,
  GET_COUNTRY_LIST_REPORT_ERROR,
  GET_EMPLOYMENT_STATUS_LIST_REPORT,
  GET_EMPLOYMENT_STATUS_LIST_REPORT_ERROR,
  GET_SALES_CONSULTANT_LIST_REPORT,
  GET_SALES_CONSULTANT_LIST_REPORT_ERROR,
  GET_HR_CONSULTANT_LIST_REPORT,
  GET_HR_CONSULTANT_LIST_REPORT_ERROR,
  GET_REGION_LIST_REPORT,
  GET_REGION_LIST_REPORT_ERROR,
  GET_LEAVE_TYPE_LIST_REPORT,
  GET_LEAVE_TYPE_LIST_REPORT_ERROR,
  GET_REPORTS_NAME_LIST,
  GET_REPORTS_NAME_LIST_ERROR,
  GET_REPORTS_PERIOD_LIST,
  GET_REPORTS_PERIOD_LIST_ERROR,
  GET_REPORTS_STATUS_LIST,
  GET_REPORTS_STATUS_LIST_ERROR,
  GENERATE_REPORT,
  GENERATE_REPORT_ERROR,
  DOWNLOAD_REPORT,
  DOWNLOAD_REPORT_ERROR,
  GET_WORKER_LIST_REPORT,
  GET_WORKER_LIST_REPORT_ERROR,
  REPORT_ADVANCE_FILTERS,
  REPORT_ADVANCE_FILTERS_ERROR,

  GET_BASE_DISTANCE_UNIT,
  GET_BASE_DISTANCE_UNIT_ERROR,
  GET_BASE_DISTANCE_UNIT_NAME,
  GET_BASE_DISTANCE_UNIT_NAME_ERROR,
  GET_DISTANCE_UNIT_DETAILS,
  GET_DISTANCE_UNIT_DETAILS_ERROR,
  CLEAR_DISTANCE_UNIT_DETAILS,
  GET_BASE_DISTANCE_UNIT_LIST,
  GET_BASE_DISTANCE_UNIT_LIST_ERROR,
  DELETE_DISTANCE_DETAILS,
  DELETE_DISTANCE_DETAILS_ERROR,
  SAVE_DISTANCE_DETAILS,
  SAVE_DISTANCE_DETAILS_ERROR,
  GET_COUNTRY_NAME,
  GET_COUNTRY_NAME_ERROR,
  GET_REGION_MILEAGE_LIST,
  GET_REGION_MILEAGE_LIST_ERROR,
  SAVE_PAY_COMPONENT_DETAILS,
  SAVE_PAY_COMPONENT_DETAILS_ERROR,
  GET_PAY_COMPONENT_DETAILS,
  GET_PAY_COMPONENT_DETAILS_ERROR,
  GET_PAY_COMPONENT_LIST,
  GET_PAY_COMPONENT_LIST_ERROR,
  CALCULATE_YEAR_OF_SERVICES,
  CALCULATE_YEAR_OF_SERVICES_ERROR,
  CALCULATE_AGES,
  CALCULATE_AGES_ERROR,
  GET_PAY_ELEMENT_LIST,
  GET_PAY_ELEMENT_LIST_ERROR,
  SAVE_PAY_ELEMENT_DETAILS,
  SAVE_PAY_ELEMENT_DETAILS_ERROR,
  GET_PAY_ELEMENT_DETAILS,
  GET_PAY_ELEMENT_DETAILS_ERROR,
  DELETE_PAY_ELEMENT_DETAILS_ERROR,
  DELETE_PAY_ELEMENT_DETAILS,
  LOGOUT,
  SAVE_USER_PLACEMENT,
  SAVE_USER_PLACEMENT_ERROR,
  GET_WORKER_DETAILS_REPORT,
  GET_WORKER_DETAILS_REPORT_ERROR,
  GET_DEFAULT_VALUES,
  GET_DEFAULT_VALUES_ERROR,
  GET_MESSAGE_ATTACHMENT_FILE,
  GET_MESSAGE_ATTACHMENT_FILE_ERROR,
  GET_CLIENT_MARKUP_VALUES,
  GET_CLIENT_MARKUP_VALUES_ERROR,
  UPDATE_IS_SAVE_USER_PROFILE,
  GENERATE_BENEFITS_REPORT,
  GENERATE_BENEFITS_REPORT_ERROR,
} from "../actions/types";

const initialState = {
  isAuthenticated: isAuthenticated(),
  loading: true,
  user: JSON.parse(localStorage.getItem("user")),
  message: null,
  success: null,
  expenseStatusList: [],
  subStatusList: [],
  roleList: [],
  expenseList: [],
  subscriberUserSearchList: [],
  systemUsers: [],
  currencyList: [],
  filePath: null,
  userJourneyForSub: [],
  countryList: [],
  regionList: [],
  cityList: [],
  currency: {},
  city: {},
  compensationTypes: [],
  workerTypes: [],
  visaStatusList: [],
  dealTypes: [],
  userProfile: {},
  employmentStatusList: [],
  employmentSubstatusList: [],
  benefitsCoverageList: [],
  benefitsClassList: [],
  clientList: [],
  perksFrequencyList: [],
  pensionPlanFrequencyList: [],
  pensionPlanList: [],
  preferredEmailList: [],
  subscriberList: [],
  contactList: [],
  maritalStatusList: [],
  pronounList: [],
  subLeavesList: [],
  subLeaveDetails: {},
  subExpenseList: [],
  subLeaveTypeList: [],
  clientInfo: {},
  workerList: [],
  mileageRangeList: [],
  leaveType: {},
  mileageRange: {},
  subscribers: [],
  subscriberUsers: [],
  subscriberProfile: {},
  subExpenseSheet: {},
  subExpenseItem: {},
  subMileageItem: {},
  cityDetailedList: [],
  subHolidayList: [],
  allowanceTypeList: [],
  expenseTypeList: [],
  allowanceType: {},
  expenseType: {},
  leavePolicyList: [],
  leaveQuotaResetTypeList: [],
  leavePeriodStringList: [],
  leaveAccrualDateTypeList: [],
  leaveAccrualEveryStringList: [],
  leavePayOutTimeTypeList: [],
  leavePayOutLimitTypeList: [],
  calculatedLeaveInfo:{},
  holidayDetails: {},
  baseCurrency:{},
  messageCategoryList:[],
  messageStatusList:[],
  hrConsultantList:[],
  salesConsultantList:[],
  MessageCenterhrConsultantList:[],
  conversationList:[],
  numPages:0,
  messageCategory: {},
  messageCategoryRoleList: [],
  conversation:{categoryName:''},
  userMessages:[],
  redirectTo:"",
  notificationConfig:{},
  roleUserList:[],
  assignedMsgList:[],
  assignedUserList:[],
  assignedMsgInfo: {},
  eventNames: [],
  eventData: [],
  nextstep:"",
  IsUserHasData:{},
  appModules: [],
  availableFields:[],
  clientListReport: [],
  countryListReport: [],
  salesConsultantListReport: [],
  hrConsultantListReport:[],
  employmentStatusListReport:[],
  regionListReport:[],
  leaveTypeListReport:[],
  reportsNameList:[],
  reportsPeriodList:[],
  reportsStatusList:[],
  reportList:[],
  downloadReportList:null,
  workerListReport:[],
  reportListFilter:[],
  isSaveUserProfile:false,
  baseDistanceUnitList:[],
  DistanceUnitDetails:{},
  DistanceUnitId:"",
  DistanceUnitName:"",
  IsDistanceDeleted:0,
  IsDistanceSaved:0,
  IsMileageRangeSaved:0,
  countryNameList:[],
  regionNameList:[],
  IsPayComponentSaved:0,
  PayComponentDetails:{},
  payComponentList:[],
  calculateYearOfService:0.00,
  calculateAge:0.00,
  payElementList:[],
  IsPayElementSaved:0,
  IsPayElementDeleted:0,
  PayElementDetails: {},
  workerDetailsReport:[],
  defaultValues :{},
  clientMarkups:{},
  messageAttachment:null,
  viewAttachment:{},
  workerBenefitsReport: [],
};
export const user = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem("isAuthenticated", true);
      var user=action.payload.user;

      if(user.userRoles.filter(userRole => userRole.role.startsWith("Subscriber")).length)
      {
        user["selectedrole"]=user.userRoles.filter(userRole => userRole.role.startsWith("Subscriber"))[0].role;
        localStorage.setItem("user", JSON.stringify(user));
      }else
      {

        user["selectedrole"]=user.userRoles[0].role;
        localStorage.setItem("user", JSON.stringify(user));
      }

      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: true,
        loading: false,
      };
    case LOGOUT_SUCCESS:
    case LOGIN_FAILED:
      case LOGOUT :
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("user");
      document.cookie = 'Authentication=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        user: null,
      };
      case VERIFICATIONCODE_SUCCESS:
        return {
          ...state,
          nextstep:"code"
        };
        case VERIFICATIONCODE_CLEAR:
          return {
            ...state,
            nextstep:""
          };
      case VERIFICATIONCODE_FAILED:

        return {
          ...state,
          nextstep:"email"
        };
      case VERIFYCODE_SUCCESS:
          return {
            ...state,
            message: action.payload.message,
            nextstep:"password"
          };
      case VERIFYCODE_FAILED:
            return {
              ...state,
              nextstep:"code"
            };
      case RESETPASSWORD_SUCCESS:
              return {
                ...state,
                nextstep:"login"
              };
      case RESETPASSWORD_FAILED:
                return {
                  ...state,
                  nextstep:"password"
                };
    case DELETE_USER_JOURNEY_FOR_SUB:
      return {
        ...state,
        message: action.payload.message,
      };
    case DELETE_USER_JOURNEY_FOR_SUB_ERROR:
      return {
        ...state,
        message: null,
      };

    case GET_SUB_STATUS_LIST:
      return {
        ...state,
        subStatusList: action.payload.subStatusList,
      };
    case GET_SUB_STATUS_LIST_ERROR:
      return {
        ...state,
        subStatusList: [],
      };
    case GET_ROLE_LIST:
      return {
        ...state,
        roleList: action.payload.roleList,
      };
    case GET_ROLE_LIST_ERROR:
      return {
        ...state,
        roleList: [],
      };

    case SEARCH_USER_LIST:
      return {
        ...state,
        subscriberUserSearchList: action.payload.subscriberUserSearchList,
      };
    case SEARCH_USER_LIST_ERROR:
      return {
        ...state,
        subscriberUserSearchList: [],
      };

    case GET_CURRENCY_LIST:
      return {
        ...state,
        currencyList: action.payload.currencyList,
      };
    case CLEAR_CURRENCY_DETAILS:
      return {
        ...state,
        currency: {},
      };
    case GET_CURRENCY_LIST_ERROR:
      return {
        ...state,
        currencyList: [],
      };

    case GET_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload.countryList,
      };
    case GET_COUNTRY_LIST_ERROR:
      return {
        ...state,
        countryList: [],
      };
    case GET_REGION_LIST:
      return {
        ...state,
        regionList: action.payload.regionList,
      };
    case GET_REGION_LIST_ERROR:
      return {
        ...state,
        regionList: [],
      };
    case GET_CITY_LIST:
      return {
        ...state,
        cityList: action.payload.cityList,
      };
    case GET_CITY_LIST_ERROR:
      return {
        ...state,
        cityList: [],
      };
    case GET_CITY_DETAILS:
      return {
        ...state,
        city: action.payload.city,
      };
    case GET_CITY_DETAILS_ERROR:
      return {
        ...state,
        city: {},
      };
    case CLEAR_CITY_DETAILS:
      return {
        ...state,
        city: {},
      };
    case GET_CURRENCY_DETAILS:
      return {
        ...state,
        currency: action.payload.currency,
      };
    case GET_CURRENCY_DETAILS_ERROR:
      return {
        ...state,
        currency: {},
      };
    case GET_COMPENSATION_TYPES:
      return {
        ...state,
        compensationTypes: action.payload.compensationTypes,
      };
    case GET_COMPENSATION_TYPES_ERROR:
      return {
        ...state,
        compensationTypes: [],
      };
    case GET_WORKER_TYPES:
      return {
        ...state,
        workerTypes: action.payload.workerTypes,
      };
    case GET_WORKER_TYPES_ERROR:
      return {
        ...state,
        workerTypes: [],
      };
    case GET_VISA_STATUS_LIST:
      return {
        ...state,
        visaStatusList: action.payload.visaStatusList,
      };
    case GET_VISA_STATUS_LIST_ERROR:
      return {
        ...state,
        visaStatusList: [],
      };
    case GET_DEAL_TYPES:
      return {
        ...state,
        dealTypes: action.payload.dealTypes,
      };
    case GET_DEAL_TYPES_ERROR:
      return {
        ...state,
        dealTypes: [],
      };
    case GET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload.userProfile,
      };
    case GET_USER_PROFILE_ERROR:
      return {
        ...state,
        userProfile: {},
        userJourneyForSub: []
      };

    case SAVE_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload.userProfile,
        isSaveUserProfile:Math.random(),
      };
    case SAVE_USER_PROFILE_ERROR:
      return {
        ...state,
        userProfile: {},
        isSaveUserProfile:false,
      };
      case UPDATE_IS_SAVE_USER_PROFILE:
      return {
        ...state,
        isSaveUserProfile:action.payload,
      };
      case GET_DEFAULT_VALUES :
        return{
          ...state,
          defaultValues: action.payload
        };
        case GET_DEFAULT_VALUES_ERROR :
          return{
            ...state,
            defaultValues : {}
          }
          case GET_CLIENT_MARKUP_VALUES :
        return{
          ...state,
          clientMarkups: action.payload
        };
        case GET_CLIENT_MARKUP_VALUES_ERROR :
          return{
            ...state,
            clientMarkups : {}
          }
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload.userProfile,
        isSaveUserProfile:Math.random(),
      };
    case UPDATE_USER_PROFILE_ERROR:
      return {
        ...state,
        userProfile: {},
        isSaveUserProfile:false,
      };
      case SAVE_USER_PLACEMENT : 
      return {
        ...state,
        isSaveUserPlacement:Math.random(),
      };
      case SAVE_USER_PLACEMENT_ERROR :
        return {
          ...state,
          isSaveUserPlacement : false
        }
    case USER_DISABLE_2FA:
      return {
        ...state,
        message: action.payload.message,
        success: action.payload.success,
        userProfile: {...state.userProfile, requiredTwoFA: 0},
      };
    case USER_DISABLE_2FA_ERROR:
      return {
        ...state,
      };
    case SET_USER_ACCOUNT_STATUS:
      return {
        ...state,
        message: action.payload.message,
        success: action.payload.success,
        userProfile: {
          ...state.userProfile,
          accountStatus: action.payload.accountStatus
        }
      };
    case SET_USER_ACCOUNT_STATUS_ERROR:
      return {
        ...state,
      };
    case GET_EMPLOYMENT_STATUS_LIST:
      return {
        ...state,
        employmentStatusList: action.payload.employmentStatusList,
      };
    case GET_EMPLOYMENT_STATUS_LIST_ERROR:
      return {
        ...state,
        employmentStatusList: [],
      };
    case GET_EMPLOYMENT_SUB_STATUS_LIST:
      return {
        ...state,
        employmentSubstatusList: action.payload.employmentSubstatusList,
      };
    case GET_EMPLOYMENT_SUB_STATUS_LIST_ERROR:
      return {
        ...state,
        employmentSubstatusList: [],
      };

    case GET_CLIENT_VIEW_WORKER_PROFILE:
      return {
        ...state,
        workerForClientProfile: action.payload.workerProfile,
      }
    case GET_CLIENT_VIEW_WORKER_PROFILE_ERROR:
      return {
        ...state,
        workerForClientProfile: {},
      };
    case GET_USER_JOURNEY_LIST_FOR_SUB:
      return {
        ...state,
        userJourneyForSub: action.payload.userJourneyList,
      };
    case GET_USER_JOURNEY_LIST_FOR_SUB_ERROR:
      return {
        ...state,
        userJourneyForSub: [],
      };

    case GET_CLIENT_LIST:
      return {
        ...state,
        clientList: action.payload.clientList,
      };
    case GET_CLIENT_LIST_ERROR:
      return {
        ...state,
        clientList: [],
      };

    case GET_CLIENT_CONTACT_LIST:
      return {
        ...state,
        contactList: action.payload.contactList,
      };
    case GET_CLIENT_CONTACT_LIST_ERROR:
      return {
        ...state,
        contactList: [],
      };
    case GET_BENEFITS_COVERAGE_LIST:
      return {
        ...state,
        benefitsCoverageList: action.payload.benefitsCoverageList,
      };
    case GET_BENEFITS_COVERAGE_LIST_ERROR:
      return {
        ...state,
        benefitsCoverageList: [],
      };

    case GET_PERKS_FREQUENCY_LIST:
      return {
        ...state,
        perksFrequencyList: action.payload.perksFrequencyList,
      };
    case GET_PERKS_FREQUENCY_LIST_ERROR:
      return {
        ...state,
        perksFrequencyList: [],
      };

    case GET_PENSION_PLAN_FREQUENCY_LIST:
      return {
        ...state,
        pensionPlanFrequencyList: action.payload.pensionPlanFrequencyList,
      };
    case GET_PENSION_PLAN_FREQUENCY_LIST_ERROR:
      return {
        ...state,
        pensionPlanFrequencyList: [],
      };


    case GET_PENSION_PLAN_LIST:
      return {
        ...state,
        pensionPlanList: action.payload.retirementPensionPlanList,
      };
    case GET_PENSION_PLAN_LIST_ERROR:
      return {
        ...state,
        pensionPlanList: [],
      };


    case GET_PREFERRED_EMAIL_LIST:
      return {
        ...state,
        preferredEmailList: action.payload.preferredEmailList,
      };
    case GET_PREFERRED_EMAIL_LIST_ERROR:
      return {
        ...state,
        preferredEmailList: [],
      };

    case GET_SUBSCRIBER_LIST:
      return {
        ...state,
        subscriberList: action.payload.subscribers,
      };
    case GET_SUBSCRIBER_LIST_ERROR:
      return {
        ...state,
        subscriberList: [],
      };
      case GET_SALESCONSULTANT_LIST:
      return {
        ...state,
        salesConsultantList: action.payload.data,
      };
    case GET_SALESCONSULTANT_LIST_ERROR:
      return {
        ...state,
        salesConsultantList: [],
      };
      case  GET_HR_CONSULTANT:
      return {
        ...state,
        hrConsultantList: action.payload.data,
      };
    case  GET_HR_CONSULTANT_ERROR:
      return {
        ...state,
        hrConsultantList: [],
      };
    case GET_BENEFITS_CLASS_LIST:
      return {
        ...state,
        benefitsClassList: action.payload.benefitsClassList,
      };
    case GET_BENEFITS_CLASS_LIST_ERROR:
      return {
        ...state,
        benefitsClassList: [],
      };
    case GET_PRONOUN_LIST:
      return {
        ...state,
        pronounList: action.payload.pronounList,
      };
    case GET_PRONOUN_LIST_ERROR:
      return {
        ...state,
        pronounList: [],
      };
    case GET_MARITAL_STATUS_LIST:
      return {
        ...state,
        maritalStatusList: action.payload.maritalStatusList,
      };
    case GET_MARITAL_STATUS_LIST_ERROR:
      return {
        ...state,
        maritalStatusList: [],
      };
    case SEARCH_LEAVES:
      return {
        ...state,
        subLeavesList: action.payload.leavesList,
      };
    case SEARCH_LEAVES_ERROR:
      return {
        ...state,
        subLeavesList: [],
      };
    case GET_SUB_LEAVE_DETAILS:
      return {
        ...state,
        subLeaveDetails: action.payload.leaveDetails,
      };
    case GET_SUB_LEAVE_DETAILS_ERROR:
      return {
        ...state,
        subLeaveDetails: {},
      };
    case GET_SUB_EXPENSE_REPORTS:
      return {
        ...state,
        subExpenseList: action.payload.expenseList,
      };
    case GET_SUB_EXPENSE_REPORTS_ERROR:
      return {
        ...state,
        subExpenseList: [],
      };
    case SUB_WITHDRAW_LEAVE:
      return {
        ...state,
        // subExpenseList: action.payload.leaveDetails,
      };
    case SUB_WITHDRAW_LEAVE_ERROR:
      return {
        ...state,
        // subExpenseList: null,
      };
    case SUB_SUBMIT_LEAVE_DETAILS:
      return {
        ...state,
        // leaveDetails: action.payload.leaveDetails,
      };
    case SUB_SUBMIT_LEAVE_DETAILS_ERROR:
      return {
        ...state,
        // leaveDetails: null,
      };
    case GET_SUB_LEAVE_TYPES:
      return {
        ...state,
        subLeaveTypeList: action.payload.leaveTypeList,
      };
    case GET_SUB_LEAVE_TYPES_ERROR:
      return {
        ...state,
        subLeaveTypeList: [],
      };
    case GET_SUB_CLIENT_LIST:
      return {
        ...state,
        clientList: action.payload.clientList,
      };
    case GET_SUB_CLIENT_LIST_ERROR:
      return {
        ...state,
        clientList: [],
      };
    case GET_CLIENT_PROFILE:
      return {
        ...state,
        clientInfo: action.payload.clientInfo,
      };
    case GET_CLIENT_PROFILE_ERROR:
      return {
        ...state,
        clientInfo: {},
      };
    case GET_WORKER_FOR_CLIENT_LIST:
      return {
        ...state,
        workerList: action.payload.workerList,
      };
    case GET_WORKER_FOR_CLIENT_LIST_ERROR:
      return {
        ...state,
        workerList: [],
      };
    case GET_MILEAGE_RANGE_LIST:
      return {
        ...state,
        mileageRangeList: action.payload.data,
      };
    case GET_MILEAGE_RANGE_LIST_ERROR:
      return {
        ...state,
        mileageRangeList: [],
      };
    case GET_LEAVE_TYPE_DETAILS:
      return {
        ...state,
        leaveType: action.payload.leaveType,
      };
    case GET_LEAVE_TYPE_DETAILS_ERROR:
      return {
        ...state,
        leaveType: {},
      };
    case SAVE_LEAVE_TYPE_DETAILS:
      return {
        ...state,
        leaveType: action.payload.leaveType,
      };
    case SAVE_LEAVE_TYPE_DETAILS_ERROR:
      return {
        ...state,
        leaveType: {},
      };
    case GET_MILEAGE_RANGE_DETAILS:
      return {
        ...state,
        mileageRange: action.payload.data,
      };
    case GET_MILEAGE_RANGE_DETAILS_ERROR:
      return {
        ...state,
        mileageRange: {},
      };
    case SAVE_MILEAGE_RANGE_DETAILS:
      return {
        ...state,
        IsMileageRangeSaved: Math.random(),
      };
    case SAVE_MILEAGE_RANGE_DETAILS_ERROR:
      return {
        ...state,
        IsMileageRangeSaved: 0
      };
    case GET_SYSADMIN_SUBSCRIBER_LIST:
      return {
        ...state,
        subscribers: action.payload.subscribers,
      };
    case GET_SYSADMIN_SUBSCRIBER_LIST_ERROR:
      return {
        ...state,
        subscribers: {},
      };

    case GET_SYSADMIN_SUBSCRIBER_USER_LIST:
      return {
        ...state,
        subscriberUsers: action.payload.subscriberUsers,
      };
    case GET_SYSADMIN_SUBSCRIBER_USER_LIST_ERROR:
      return {
        ...state,
        subscriberUsers: [],
      };

    case GET_SYSADMIN_SUBSCRIBER_PROFILE:
      return {
        ...state,
        subscriberProfile: action.payload.subscriberProfile,
      };
    case CLEAR_SYSADMIN_SUBSCRIBER_PROFILE:
      return {
        ...state,
        subscriberProfile: {},
        subscriberUsers: []
      };
    case GET_SYSADMIN_SUBSCRIBER_PROFILE_ERROR:
      return {
        ...state,
        subscriberProfile: {},
      };
    case SAVE_SYSADMIN_SUBSCRIBER_PROFILE:
      return {
        ...state,
        subscriberProfile: action.payload.subscriberProfile,
      };
    case SAVE_SYSADMIN_SUBSCRIBER_PROFILE_ERROR:
      return {
        ...state,
        subscriberProfile: {},
      };
    case SEARCH_SYSADMIN_USER_LIST:
      return {
        ...state,
        systemUsers: action.payload.systemUsers,
      };
    case SEARCH_SYSADMIN_USER_LIST_ERROR:
      return {
        ...state,
        systemUsers: null,
      };

    case GET_CITY_DETAILED_LIST:
      return {
        ...state,
        cityDetailedList: action.payload.cityList,
      };
    case GET_CITY_DETAILED_LIST_ERROR:
      return {
        ...state,
        cityDetailedList: [],
      };
    case SAVE_CITY_DETAILS: {
      let newCityDetailedList = state.cityDetailedList.filter((city) => {
        return city.cityId !== action.payload.city.cityId
      })
      newCityDetailedList = [...newCityDetailedList,
        {
          country: action.payload.city.region.country.countryName,
          region: action.payload.city.region.regionName,
          cityName: action.payload.city.cityName,
          cityId: action.payload.city.cityId
        }
      ]
      newCityDetailedList = newCityDetailedList.sort(function (a, b) {
        return a.cityName.localeCompare(b.cityName);
      });
      return {
        ...state,
        cityDetailedList: newCityDetailedList,
        city: {}
      };
    }
    case SAVE_CITY_DETAILS_ERROR:
      return {
        ...state,
        city: {},
      };

    case APPROVE_LEAVE:
      return {
        ...state,
      };
    case APPROVE_LEAVE_ERROR:
      return {
        ...state,
      };
    case REJECT_LEAVE:
      return {
        ...state,
      };
    case REJECT_LEAVE_ERROR:
      return {
        ...state,
      };

    case SET_USER_PASSWORD:
      return {
        ...state,
        message: action.payload.message,
        success: action.payload.success,
      };
    case SET_USER_PASSWORD_ERROR:
      return {
        ...state,
      };

    case RESET:
      return {
        ...state,
        message: null,
        success: null,
      };
    case SEARCH_SUB_HOLIDAYS:
      return {
        ...state,
        subHolidayList: action.payload.holidays,
      };
    case SEARCH_SUB_HOLIDAYS_ERROR:
      return {
        ...state,
        subHolidayList: [],
      };
    case GET_HOLIDAY_DETAILS:
      return {
        ...state,
        holidayDetails: action.payload.holiday,
      };
    case DELETE_HOLIDAY:
      return {
        ...state,
        subHolidayList: state.subHolidayList.filter(holiday => holiday.holidayId !== action.payload)
      }
    case GET_HOLIDAY_DETAILS_ERROR:
      return {
        ...state,
        holidayDetails: {}
      };
    case GET_ALLOWANCE_TYPE_LIST:
      return {
        ...state,
        allowanceTypeList: action.payload.allowanceTypeList
      };
    case GET_ALLOWANCE_TYPE_LIST_ERROR:
      return {
        ...state,
        allowanceTypeList: []
      };

    case GET_EXPENSE_CONFIG_TYPE_LIST:
      return {
        ...state,
        expenseTypeList: action.payload.expenseTypeList
      };
    case GET_EXPENSE_CONFIG_TYPE_LIST_ERROR:
      return {
        ...state,
        expenseTypeList: []
      };

    case GET_ALLOWANCE_TYPE_DETAILS:
      return {
        ...state,
        allowanceType: action.payload.allowanceType
      };
    case GET_ALLOWANCE_TYPE_DETAILS_ERROR:
      return {
        ...state,
        allowanceType: {}
      };

    case SAVE_ALLOWANCE_TYPE_DETAILS:
      return {
        ...state,
        allowanceType: action.payload.allowanceType,
      };
    case SAVE_ALLOWANCE_TYPE_DETAILS_ERROR:
      return {
        ...state,
        allowanceType: {}
      };
    case DELETE_ALLOWANCE_TYPE_DETAILS:
      return {
        ...state,
        allowanceType: action.payload.allowanceType,
      };
    case DELETE_ALLOWANCE_TYPE_DETAILS_ERROR:
      return {
        ...state,
        allowanceType: {}
      };
      case DELETE_CLIENT:
        return {
          ...state,
          IsClientdelted: Math.random(),
        };
      case DELETE_CLIENT_ERROR:
        return {
          ...state,
          IsClientdelted: false
        };
        case DELETE_USER:
          return {
            ...state,
            IsUserDeleted: Math.random(),
          };
        case DELETE_USER_ERROR:
          return {
            ...state,
            IsUserDeleted: false
          };
        case USER_HAS_EXISTING_DATA:
          return {
            ...state,
            IsUserHasData:{"hasdata":action.payload.data,"userrandomid":Math.random()},
          };
        // case USER_HAS_EXISTING_DATA_ERROR:
        //   return {
        //     ...state,
        //     IsUserHasData: false
        //   };
    case GET_EXPENSE_TYPE_DETAILS:
      return {
        ...state,
        expenseType: action.payload.expenseType
      };
    case GET_EXPENSE_TYPE_DETAILS_ERROR:
      return {
        ...state,
        expenseType: {}
      };

    case SAVE_EXPENSE_TYPE_DETAILS:
      return {
        ...state,
        expenseType: action.payload.expenseType
      };
    case SAVE_EXPENSE_TYPE_DETAILS_ERROR:
      return {
        ...state,
        expenseType: {}
      };

    case DELETE_EXPENSE_TYPE_DETAILS:
      return {
        ...state,
        expenseType: {}
      };
    case DELETE_EXPENSE_TYPE_DETAILS_ERROR:
      return {
        ...state,
        expenseType: {}
      };
    case GET_SEARCH_LEAVE_POLICY_SUCCESS:
      return {
        ...state,
        leavePolicyList: action.payload.leavePolicyList
      };
    case GET_SEARCH_LEAVE_POLICY_ERROR:
      return {
        ...state,
        leavePolicyList: []
      };
    case GET_CLIENT_LEAVE_POLICY_LIST_SUCCESS:
      return {
        ...state,
        leavePolicyList: action.payload.leavePolicyList
      };
    case GET_CLIENT_LEAVE_POLICY_LIST_ERROR:
      return {
        ...state,
        leavePolicyList: []
      };
    case DELETE_CLIENT_LEAVE_POLICY_LIST_SUCCESS:
      return {
        ...state,
        leavePolicyList: state.leavePolicyList.filter(leavePolicy => {
          return leavePolicy.leavePolicyId !== action.payload.leavePolicyId
        }),
        message: action.payload.message,
      };
    case DELETE_CLIENT_LEAVE_POLICY_LIST_ERROR:
      return {
        ...state,
        message: null,
      };
    case GET_CLIENT_LEAVE_POLICY_DETAIL_SUCCESS:
      return {
        ...state,
        leavePolicyList: action.payload.expenseType
      };
    case GET_CLIENT_LEAVE_POLICY_DETAIL_ERROR:
      return {
        ...state,
        leavePolicyList: []
      };

    case GET_LEAVE_QUOTA_RESET_TYPE_LIST:
      return {
        ...state,
        leaveQuotaResetTypeList: action.payload.leaveQuotaResetTypeList
      };
    case GET_LEAVE_QUOTA_RESET_TYPE_LIST_ERROR:
      return {
        ...state,
        leaveQuotaResetTypeList: []
      };

    case GET_LEAVE_PERIOD_STRING_LIST:
      return {
        ...state,
        leavePeriodStringList: action.payload.leavePeriodStringList
      };
    case GET_LEAVE_PERIOD_STRING_LIST_ERROR:
      return {
        ...state,
        leavePeriodStringList: []
      };

    case GET_LEAVE_ACCRUAL_DATE_TYPE_LIST:
      return {
        ...state,
        leaveAccrualDateTypeList: action.payload.leaveAccrualDateTypeList
      };
    case GET_LEAVE_ACCRUAL_DATE_TYPE_LIST_ERROR:
      return {
        ...state,
        leaveAccrualDateTypeList: []
      };

    case GET_LEAVE_ACCRUAL_EVERY_STRING_LIST:
      return {
        ...state,
        leaveAccrualEveryStringList: action.payload.leaveAccrualEveryStringList
      };
    case GET_LEAVE_ACCRUAL_EVERY_STRING_LIST_ERROR:
      return {
        ...state,
        leaveAccrualEveryStringList: []
      };

    case GET_LEAVE_PAY_OUT_TIME_TYPE_LIST:
      return {
        ...state,
        leavePayOutTimeTypeList: action.payload.leavePayOutTimeTypeList
      };
    case GET_LEAVE_PAY_OUT_TIME_TYPE_LIST_ERROR:
      return {
        ...state,
        leavePayOutTimeTypeList: []
      };

    case GET_LEAVE_PAY_OUT_LIMIT_TYPE_LIST:
      return {
        ...state,
        leavePayOutLimitTypeList: action.payload.leavePayOutLimitTypeList
      };
    case GET_LEAVE_PAY_OUT_LIMIT_TYPE_LIST_ERROR:
      return {
        ...state,
        leavePayOutLimitTypeList: []
      };
    case CLEAR__HOLIDAYS_DETAILS:
      return {
        ...state,
        holidayDetails: {}
      };
    case CLEAR__CLIENT_PROFILE:
      return {
        ...state,
        clientInfo: {},
        contactList: [],
        workerList: [],
        leavePolicyList: []
      }

    case CLEAR_CLIENT_VIEW_WORKER_PROFILE:
      return {
        ...state,
        workerForClientProfile: {},
      };

    case GET_CALCULATED_LEAVE_INFO:
      return {
        ...state,
        calculatedLeaveInfo: action.payload.calculatedLeaveInfo
      };
      case ENABLE_LEAVE_SUBMIT:
      return {
        ...state,
        enableLeaveSubmit: action.payload
      };
    case GET_CALCULATED_LEAVE_INFO_ERROR:
      return {
        ...state,
        calculatedLeaveInfo: {}
      };

    case CLEAR_CALCULATED_LEAVE_INFO:
      return {
        ...state,
        calculatedLeaveInfo: {}
      };


    case SAVE_CLIENT_PROFILE:
      return {
        ...state,
        clientInfo: action.payload.clientInfo
      }

    case CLEAR_CLIENT_LEAVES_LIST:
      return {
        ...state,
        leavePolicyList: []
      };

    case GET_BASE_CURRENCY:
      return {
      ...state,
      baseCurrency: action.payload.currency
    }


    case GET_MESSAGE_CATEGORY_LIST:
      return {
        ...state,
        messageCategoryList: action.payload.categoryList
      };

    case GET_MESSAGE_CATEGORY_LIST_ERROR:
      return {
        ...state,
        messageCategoryList: []
      };

    case GET_MESSAGE_STATUS_LIST:
      return {
        ...state,
        messageStatusList: action.payload.statusList
      };

    case GET_HR_CONSULTANT_LIST:
      return {
        ...state,
        MessageCenterhrConsultantList: action.payload.hrConsultantList
      };

      case SEARCH_CONVERSATIONS:
      return {
        ...state,
        conversationList: action.payload.conversationList
      };

    case SEARCH_CONVERSATIONS_PAGINATED:
      return {
        ...state,
        conversationList: action.payload.conversationList,
        numPages: action.payload.numPages
      };

    case GET_MESSAGE_CATEGORY_ROLE_LIST:
        return {
          ...state,
          messageCategoryRoleList: action.payload.categoryList
        };

    case GET_MESSAGE_CATEGORY_ROLE_LIST_ERROR:
        return {
          ...state,
          messageCategoryRoleList: []
        };

    case GET_MESSAGE_CATEGORY_DETAILS:
        return {
          ...state,
          messageCategory: action.payload.category
        };
    case GET_MESSAGE_CATEGORY_DETAILS_ERROR:
        return {
          ...state,
          messageCategory: {}
        };

    case SAVE_MESSAGE_CATEGORY_DETAILS:
        return {
          ...state,
          messageCategory: action.payload.messageCategory,
        };
    case SAVE_MESSAGE_CATEGORY_DETAILS_ERROR:
        return {
          ...state,
          messageCategory: {}
        };
    case DELETE_MESSAGE_CATEGORY_DETAILS:
        return {
          ...state,
          messageCategory: action.payload.messageCategory,
        };
    case DELETE_MESSAGE_CATEGORY_DETAILS_ERROR:
        return {
          ...state,
          messageCategory: {}
        };

    case GET_CONVERSATION_DETAIL:
      return {
        ...state,
        conversation: action.payload.conversation
      };
      case UPLOAD_CONVERSATION_ATTACHMENT:
      return {
        ...state,
        messageAttachment: action.payload
      };
      case GET_MESSAGE_ATTACHMENT_FILE:
      return {
        ...state,
        viewAttachment: {...state.viewAttachment, ...action.payload}
      };

    case CLEAR_CONVERSATION_DETAILS:
      return {
        ...state,
        conversation: {},
        userMessages: []
      };

      case SEARCH_USER_MESSAGES:
      return {
        ...state,
        userMessages: action.payload.messageList
      };

      case REDIRECT:
      return {
        ...state,
        redirectTo: action.payload
      };

      case UNREDIRECT:
      return {
        ...state,
        redirectTo: ""
      };

      case GET_NOTIFICATION_CONFIG:
      return {
      ...state,
      notificationConfig: action.payload.data
      };

      case GET_NOTIFICATION_CONFIG_ERROR:
      return {
      ...state,
      notificationConfig: {}
      };

      case SAVE_NOTIFICATION_CONFIG:
      return {
      ...state,
      notificationConfig: action.payload.data
      };

      case SAVE_NOTIFICATION_CONFIG_ERROR:
      return {
      ...state,
      notificationConfig: {}
      };

      case GET_ROLE_USER_LIST:
        return {
          ...state,
          roleUserList: action.payload.data
        };

      case GET_ROLE_USER_LIST_ERROR:
        return {
          ...state,
          roleUserList: []
        };

      case GET_ASSIGNED_MSG_LIST:
          return {
            ...state,
            assignedMsgList: action.payload.data
          };
      case GET_ASSIGNED_MSG_USER_LIST:
            return {
              ...state,
              assignedUserList: action.payload.data
           };

      case GET_ASSIGNED_MSG_LIST_ERROR:
          return {
            ...state,
            assignedMsgList: []
          };
          case DELETE_MSG_ASSIGNED_INFO:
            return {
              ...state,
              IsAssigneeDeleted: Math.random(),
            };

      case DELETE_MSG_ASSIGNED_INFO_ERROR:
            return {
              ...state,
              IsAssigneeDeleted: false
            };
      case SAVE_MSG_ASSIGNED_INFO:
            return {
              ...state,
              IsSaveAssignee: Math.random(),
              assignedMsgInfo: action.payload.data
            };

      case SAVE_MSG_ASSIGNED_INFO_ERROR:
            return {
              ...state,
              IsSaveAssignee: false,
              assignedMsgInfo: {}
            };
          case GET_SYSADMIN_EVENTNAME_LIST:
              return {
                ...state,
                eventNames: action.payload.data,
              };
          case GET_SYSADMIN_EVENTNAME_LIST_ERROR:
              return {
                ...state,
                eventNames: {},
              };
          case GET_SYSADMIN_EVENTDATA_LIST:
                return {
                  ...state,
                  eventData: action.payload.data,
                };
          case GET_SYSADMIN_EVENTDATA_LIST_ERROR:
                return {
                  ...state,
                  eventData: {},
                };
          case GET_AVAILABLE_FIELD_NAME:

                return {
                  ...state,
                  availableFields: action.payload.data,
                  reportList:[]
                  };
          case GET_AVAILABLE_FIELD_NAME_ERROR:
                return {
                  ...state,
                  availableFields: [],
                  };
          case GET_APP_MODULE:
                return {
                    ...state,
                    appModules: action.payload.data,
                    reportList:[],
                    availableFields:[]
                  };
                  case GET_APP_MODULE_CLEAR:
                return {
                    ...state,
                    reportList:[],
                    availableFields:[]
                  };
                case GET_APP_MODULE_ERROR:
                  return {
                    ...state,
                    appModules: [],
                    reportList:[],
                    availableFields:[]
                  };
                  case GET_CLIENT_LIST_REPORT:
                    return {
                     ...state,
                    clientListReport: action.payload.clientList,
                  };
                  case GET_CLIENT_LIST_REPORT_ERROR:
                    return {
                      ...state,
                       clientListReport: [],
                  };
                  case GET_COUNTRY_LIST_REPORT:
                   return {
                    ...state,
                    countryListReport: action.payload.countryList,
                  };
                  case GET_COUNTRY_LIST_REPORT_ERROR:
                    return {
                      ...state,
                      countryListReport: [],
                    };
                    case GET_REGION_LIST_REPORT:
                      return {
                        ...state,
                        regionListReport: action.payload.regionList,
                      };
                    case GET_REGION_LIST_REPORT_ERROR:
                      return {
                        ...state,
                        regionListReport: [],
                      };
                      case GET_EMPLOYMENT_STATUS_LIST_REPORT:
                        return {
                          ...state,
                          employmentStatusListReport: action.payload.subStatusList,
                        };
                      case GET_EMPLOYMENT_STATUS_LIST_REPORT_ERROR:
                        return {
                          ...state,
                          employmentStatusListReport: [],
                        };
                        case GET_HR_CONSULTANT_LIST_REPORT:
                            return {
                              ...state,
                              hrConsultantListReport: action.payload.hrConsultantList
                        };
                        case GET_HR_CONSULTANT_LIST_REPORT_ERROR:
                        return {
                          ...state,
                          hrConsultantListReport: [],
                        };
                        case GET_SALES_CONSULTANT_LIST_REPORT:
                          return {
                           ...state,
                           salesConsultantListReport: action.payload.data,
                        };
                        case GET_SALES_CONSULTANT_LIST_REPORT_ERROR:
                           return {
                            ...state,
                            salesConsultantListReport: [],
                        };
                         case GET_LEAVE_TYPE_LIST_REPORT:
                          return {
                           ...state,
                           leaveTypeListReport: action.payload.leavePolicyList,
                        };
                        case GET_LEAVE_TYPE_LIST_REPORT_ERROR:
                           return {
                            ...state,
                            leaveTypeListReport: [],
                        };
                        case GET_REPORTS_NAME_LIST:
                          return {
                           ...state,
                           reportsNameList: action.payload.data,
                        };
                        case GET_REPORTS_NAME_LIST_ERROR:
                           return {
                            ...state,
                            reportsNameList: [],
                        };
                        case GET_REPORTS_PERIOD_LIST:
                          return {
                           ...state,
                           reportsPeriodList: action.payload.data,
                        };
                        case GET_REPORTS_PERIOD_LIST_ERROR:
                           return {
                            ...state,
                            reportsPeriodList: [],
                        };
                        case GET_REPORTS_STATUS_LIST:
                          return {
                           ...state,
                           reportsStatusList: action.payload.data,
                        };
                        case GET_REPORTS_STATUS_LIST_ERROR:
                           return {
                            ...state,
                            reportsStatusList: [],
                        };
                        case GENERATE_REPORT:
                          return {
                           ...state,
                           reportList: action.payload.data,
                        };
                        case GENERATE_REPORT_ERROR:
                           return {
                            ...state,
                            reportList: [],
                        };
                        case DOWNLOAD_REPORT:
                          return {
                           ...state,
                           downloadReportList: action.payload,
                        };
                        case DOWNLOAD_REPORT_ERROR:
                           return {
                            ...state,
                            downloadReportList: [],
                        };
                  case GET_WORKER_LIST_REPORT:
                    return {
                     ...state,
                     workerListReport: action.payload.workerList,
                  };
                  case GET_WORKER_LIST_REPORT_ERROR:
                    return {
                      ...state,
                      workerListReport: [],
                  };
                  case GET_WORKER_DETAILS_REPORT:
                    return {
                     ...state,
                     workerDetailsReport: action.payload.data,
                  };
                  case GET_WORKER_DETAILS_REPORT_ERROR:
                    return {
                      ...state,
                      workerDetailsReport: [],
                  };
                  case REPORT_ADVANCE_FILTERS:
                          return {
                           ...state,
                           reportList: action.payload.data,
                    };
                    case REPORT_ADVANCE_FILTERS_ERROR:
                           return {
                            ...state,
                            reportList: [],
                  };
                  case GET_BASE_DISTANCE_UNIT:
                     return {
                   ...state,
                   DistanceUnitId:action.payload.data? action.payload.data.distanceUnitId:'',
                   };
                   case GET_BASE_DISTANCE_UNIT_ERROR:
                   return {
                   ...state,
                   DistanceUnitId: '',
                   };
                   case GET_BASE_DISTANCE_UNIT_NAME:
                     return {
                   ...state,
                   DistanceUnitName:action.payload.data? action.payload.data.unit:'',
                   };
                   case GET_BASE_DISTANCE_UNIT_NAME_ERROR:
                   return {
                   ...state,
                   DistanceUnitName: null,
                   };
                   case GET_BASE_DISTANCE_UNIT_LIST:
                     return {
                   ...state,
                   baseDistanceUnitList: action.payload.data,
                   };
                   case GET_BASE_DISTANCE_UNIT_LIST_ERROR:
                   return {
                   ...state,
                   baseDistanceUnitList: [],
                   };

                   case GET_DISTANCE_UNIT_DETAILS:
                    return {
                      ...state,
                      DistanceUnitDetails: action.payload.data,
                    };
                  case GET_DISTANCE_UNIT_DETAILS_ERROR:
                    return {
                      ...state,
                      DistanceUnitDetails: {},
                    };
                    case CLEAR_DISTANCE_UNIT_DETAILS:
                      return {
                        ...state,
                        DistanceUnitDetails: {},
                      };
                      case DELETE_DISTANCE_DETAILS:
            return {
              ...state,
              IsDistanceDeleted: Math.random(),
            };

      case DELETE_DISTANCE_DETAILS_ERROR:
            return {
              ...state,
              IsDistanceDeleted: 0
            };
            case SAVE_DISTANCE_DETAILS:
              return {
                ...state,
                IsDistanceSaved: Math.random(),
              };

        case SAVE_DISTANCE_DETAILS_ERROR:
              return {
                ...state,
                IsDistanceSaved: 0
              };
              case GET_COUNTRY_NAME:
      return {
        ...state,
        countryNameList: action.payload.countryList,
      };
    case GET_COUNTRY_NAME_ERROR:
      return {
        ...state,
        countryNameList: [],
      };
      case GET_REGION_MILEAGE_LIST:
      return {
        ...state,
        regionNameList: action.payload.regionList,
      };
    case GET_REGION_MILEAGE_LIST_ERROR:
      return {
        ...state,
        regionNameList: [],
      };
     case SAVE_PAY_COMPONENT_DETAILS:
        return {
          ...state,
          IsPayComponentSaved: Math.random(),
        };

  case SAVE_PAY_COMPONENT_DETAILS_ERROR:
        return {
          ...state,
          IsPayComponentSaved: 0
        };
        case GET_PAY_COMPONENT_DETAILS:
                    return {
                      ...state,
                      PayComponentDetails: action.payload.data,
                    };
                  case GET_PAY_COMPONENT_DETAILS_ERROR:
                    return {
                      ...state,
                      PayComponentDetails: {},
                    };
                    case GET_PAY_COMPONENT_LIST:
                      return {
                        ...state,
                        payComponentList: action.payload.data,
                      };
                case DELETE_PAY_ELEMENT_DETAILS_ERROR:
                  return {
                    ...state,
                    IsPayElementDeleted: 0
                  };
                case DELETE_PAY_ELEMENT_DETAILS:
                  return {
                    ...state,
                    IsPayElementDeleted: Math.random(),
                  };


    case GET_PAY_COMPONENT_LIST_ERROR:
                      return {
                        ...state,
                        payComponentList: []
                      };
                   case GET_PAY_ELEMENT_LIST_ERROR:
                      return {
                        ...state,
                        payElementList: []
                      };
                      case CALCULATE_YEAR_OF_SERVICES:
                      return {
                        ...state,
                        calculateYearOfService: action.payload.data,
                      };

                    case CALCULATE_YEAR_OF_SERVICES_ERROR:
                      return {
                        ...state,
                        calculateYearOfService: 0.00
                      };
                      case CALCULATE_AGES:
                        return {
                          ...state,
                          calculateAge: action.payload.data,
                        };

                      case CALCULATE_AGES_ERROR:
                        return {
                          ...state,
                          calculateAge: 0.00
                        };
    case SAVE_PAY_ELEMENT_DETAILS:
      return {
        ...state,
        IsPayElementSaved: Math.random(),
      };
    case SAVE_PAY_ELEMENT_DETAILS_ERROR:
      return {
        ...state,
        IsPayElementSaved: 0,
      };
    case GET_PAY_ELEMENT_DETAILS:
      return {
        ...state,
        PayElementDetails: action.payload.data,
      };
    case GET_PAY_ELEMENT_DETAILS_ERROR:
      return {
        ...state,
        PayElementDetails: {},
      };
    case GET_PAY_ELEMENT_LIST:
      return {
        ...state,
        payElementList: action.payload.data,
      };

    case GENERATE_BENEFITS_REPORT: {
      return {
        ...state,
        workerBenefitsReport: action.payload.data
      }
    }
    case GENERATE_BENEFITS_REPORT_ERROR: {
      return {
        ...state,
        workerBenefitsReport: []
      }
    }

    default:
      return state;
  }
};
export default user;
