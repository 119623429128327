import { 
    GET_PAYROLL_TAX_IMPORT_TYPE_ERROR,
    GET_PAYROLL_TAX_IMPORT_TYPE, 
 } from "../../actions/types"

const initialState = {
    recordFrequencyList:[],
    payrollTaxImportType:[]

}
export const payroll = (state = initialState, action) => {


    switch (action.type) {
        case GET_PAYROLL_TAX_IMPORT_TYPE:{
            return{
                ...state,
                payrollTaxImportType:action.payload.data
            }
        }
        case GET_PAYROLL_TAX_IMPORT_TYPE_ERROR:{
            return{
                ...state,
                payrollTaxImportType:initialState.payrollTaxImportType
            }
        }

        default:
            return state;
    }

}