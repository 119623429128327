import api from "../../../utils/api";
import {
  GET_CLIENT_EXPENSE_ITEM,
  GET_CLIENT_EXPENSE_ITEM_ERROR,
  GET_CLIENT_MILEAGE_ITEM,
  GET_CLIENT_MILEAGE_ITEM_ERROR,
  GET_EXPENSE_SHEET_DETAILS_FOR_CLIENT,
  CLEAR_EXPENSE_SHEET_DETAILS_FOR_CLIENT,
  GET_EXPENSE_SHEET_DETAILS_FOR_CLIENT_ERROR,
  SEARCH_EXPENSE_REPORTS_LIST_FOR_CLIENT,
  SEARCH_EXPENSE_REPORTS_LIST_FOR_CLIENT_ERROR,
  CLIENT_REJECT_EXPENSE_ITEM,
  CLIENT_REJECT_MILEAGE_ITEM,
  CLIENT_APPROVE_EXPENSE_ITEM,
  CLIENT_APPROVE_MILEAGE_ITEM,
  CLIENT_GET_MILEAGE_ITEM,
  CLIENT_CLEAR_MILEAGE_ITEM,
  CLIENT_GET_EXPENSE_ITEM, CLIENT_CLEAR_EXPENSE_ITEM
} from "../types";
import {setAlert} from "../alert";
import {redirect} from "../user";

export const search_expense_reports_for_client = (
  workerName,
  status,
  fromDate,
  toDate,
  empStatus
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/client/searchExpenseReportsList`,
      {
        params: {
          workerName: workerName,
          status: status,
          fromDate: fromDate,
          toDate: toDate,
          empStatus: empStatus
        }
      }
    );
    dispatch({
      type: SEARCH_EXPENSE_REPORTS_LIST_FOR_CLIENT,
      payload: res.data,
    });
  } catch (error) {
    const success = error.response.data.success;
    if (!success) {
      if (error.response.status === 401)
        dispatch(redirect('/error403'))
      else
        dispatch(setAlert(error.response.data.message, "danger"));
    }
    dispatch({
      type: SEARCH_EXPENSE_REPORTS_LIST_FOR_CLIENT_ERROR,
    });
  }
};
export const get_client_expense_item = (
  refNum
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/client/getExpenseItem?refNum=${refNum}`
    );
    dispatch({
      type: GET_CLIENT_EXPENSE_ITEM,
      payload: res.data,
    });
  } catch (error) {
    const success = error.response.data.success;
    if (!success) {
      dispatch(setAlert(error.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CLIENT_EXPENSE_ITEM_ERROR,
    });
  }
};
export const get_expense_sheet_details_for_client = (
  refNum
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/client/getExpenseSheetDetails?refNum=${refNum}`
    );
    dispatch({
      type: GET_EXPENSE_SHEET_DETAILS_FOR_CLIENT,
      payload: res.data,
    });
  } catch (error) {
    const success = error.response.data.success;
    if (!success) {
      dispatch(setAlert(error.response.data.message, "danger"));
    }
    dispatch({
      type: GET_EXPENSE_SHEET_DETAILS_FOR_CLIENT_ERROR,
    });
  }
};
export const get_client_mileage_item = (
  refNum
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/client/getMileageItem?refNum=${refNum}`
    );
    dispatch({
      type: GET_CLIENT_MILEAGE_ITEM,
      payload: res.data,
    });
  } catch (error) {
    const success = error.response.data.success;
    if (!success) {
      dispatch(setAlert(error.response.data.message, "danger"));
    }
    dispatch({
      type: GET_CLIENT_MILEAGE_ITEM_ERROR,
    });
  }
};

export const clear_expenses_sheet_details_for_client = () => async (dispatch) => {
  dispatch({
    type: CLEAR_EXPENSE_SHEET_DETAILS_FOR_CLIENT,
  });
};


export const client_approve_expense_report = (refNum, notes, rejectedExpenseItems, rejectedMileageItems) => async (dispatch) => {
  try {
    let params = {
      refNum,
      rejectedExpenseList: rejectedExpenseItems.toString(),
      rejectedMileageList: rejectedMileageItems.toString()
    };
    if (notes)
      params.notes = notes;
    const res = await api.get(
      `api/client/approveExpenseReport`,
      {
        params: params
      }
    );
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};
export const client_reject_expense_report = (refNum, notes) => async (dispatch) => {
  try {
    let params = {
      refNum
    };
    if (notes)
      params.notes = notes;
    const res = await api.get(
      `api/client/rejectExpenseReport`,
      {
        params: params
      }
    );
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};


export const client_reject_mileage_item = (refNumber) => async (dispatch) => {
  try {
    dispatch({
      type: CLIENT_REJECT_MILEAGE_ITEM,
      payload: parseInt(refNumber),
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const client_reject_expense_item = (refNumber) => async (dispatch) => {
  try {
    dispatch({
      type: CLIENT_REJECT_EXPENSE_ITEM,
      payload: parseInt(refNumber),
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};


export const client_approve_mileage_item = (refNumber) => async (dispatch) => {
  try {
    dispatch({
      type: CLIENT_APPROVE_MILEAGE_ITEM,
      payload: parseInt(refNumber),
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const client_approve_expense_item = (refNumber) => async (dispatch) => {
  try {
    dispatch({
      type: CLIENT_APPROVE_EXPENSE_ITEM,
      payload: parseInt(refNumber),
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};


export const client_get_mileage_item = (refNumber) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/client/getMileageItem?refNum=${refNumber}`
    );
    dispatch({
      type: CLIENT_GET_MILEAGE_ITEM,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const client_clear_mileage_item = (refNumber) => async (dispatch) => {
  dispatch({
    type: CLIENT_CLEAR_MILEAGE_ITEM,
  });
};

export const client_get_expense_item = (refNumber) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/client/getExpenseItem?refNum=${refNumber}`
    );
    dispatch({
      type: CLIENT_GET_EXPENSE_ITEM,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const client_clear_expense_item = (refNumber) => async (dispatch) => {
  dispatch({
    type: CLIENT_CLEAR_EXPENSE_ITEM,
  });
};
