import React, {useEffect, useState} from 'react';
import {
  Card,
  CardHeader,
  Col,  Form,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import PropTypes from "prop-types";
import {numberToMonetary} from "../../../utils/general";

const PerksInfo = (props) => {

  const {viewProfile} = props
  return (
    <Card id='perks' small className="details-card mb-5 w-100">
    {viewProfile?
      <CardHeader className="border-bottom">
        <h4 className="m-0 section-title">Perks</h4>
      </CardHeader>:
      <>
      <span className="iconBox"><i className="fas fa-gift iconStyle"></i></span>
      <h4 className="m-0 section-title headerStyle">Health Spending Account</h4>
      </>
      }
      <ListGroup flush>
        <ListGroupItem className={viewProfile?"p-3":"p-3 mt-3"}>
          <Row>
            <Col>
              <Form>
                <Row form>

                  <Col lg="3" className="form-group p-2">
                    <label>HSA Provider</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{props.HSAProvider}</span>
                  </Col>


                  <Col lg="3" className="form-group p-2">
                    <label>HSA Amount</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{numberToMonetary(props.HSAAmount)}</span>
                  </Col>


                  <Col lg="3" className="form-group p-2">
                    <label>HSA Carry Over</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{props.HSACarryOver}</span>
                  </Col>

                  <Col lg="3" className="form-group p-2">
                    <label>HSA Waiting Period</label>
                    <span className={viewProfile?" form-control":"inputBoxClr  form-control"}>{props.HSAWaitingPeriod} months</span>
                  </Col>

                </Row>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

PerksInfo.propTypes={
  perksInfo:PropTypes.object.isRequired
}

export default PerksInfo;
