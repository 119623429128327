import React from "react";
import { Container, Button } from "shards-react";
import {useHistory} from "react-router-dom";

const Error403 = () =>{
  const history=useHistory()
  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <div className="error">
        <div className="error__content">
          <h2>Oops!</h2>
          <p className={"my-2"}>You do not have permissions to look behind this curtain</p>
          <Button className={"py-2"} size={"sm"} theme={"accent"} onClick={history.goBack}>Go Back</Button>
        </div>
      </div>
    </Container>
  );
}

export default Error403;
