import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container, FormInput,
  FormSelect,
  Row
} from "shards-react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {setDateForTable} from "../../utils/date";
import {getStatusClass} from "../../utils/general";
import {
  get_client_employment_status_list,
  search_worker_for_client_list,
} from "../../redux/actions/client/clientWorkers";
import {get_client_sub_status_list} from "../../redux/actions/user";

import {default as queryString} from "qs";

function ClientWorkers({
                         clientEmploymentStatusList,
                         clientWorkerList,
                         subStatusList,
                         get_client_employment_status_list,
                         search_worker_for_client_list,
                         get_client_sub_status_list
                       }) {

  let location = useLocation();
  let queryParams = queryString.parse(location.search.replace("?", ""))

  const [formData, setFormData] = useState({
    workerName: '',
    employmentStatus: '',
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
  });

  useEffect(() => {
    let newState = {
      ...formData,
      workerName: queryParams.workerName || "",
      employmentStatus: queryParams.employmentStatus || ""
    }
    searchWorkers(newState.workerName, newState.employmentStatus)
    setFormData(newState)
  }, [queryParams.workerName, queryParams.employmentStatus]);

  useEffect(() => {
    get_client_sub_status_list();
  }, []);

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const searchWorkers = (workerName=formData.workerName, employmentStatus=formData.employmentStatus) => {
    search_worker_for_client_list(workerName, employmentStatus);
  }

  const tableData = clientWorkerList;
  var {pageSize, pageSizeOptions} = formData;

  const tableColumns = [
    {
      Header: "User ID",
      accessor: "userId",
      maxWidth: 100,
      className: "text-center",
      Cell: row =>
        <Link
          to={`/client-view-worker-profile/${row.original.userId}`}>{row.original.userId}</Link>
    },
    {
      Header: "Worker Name",
      accessor: "workerName",
      className: "justify-content-start"
    },
    {
      Header: "Hire Date",
      accessor: "hireDate",
      className: "text-center",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        setDateForTable(row.original.hireDate)
    },
    {
      Header: "Job Title",
      accessor: "jobTitle",
      maxWidth: 200,
      className: "justify-content-start"
    },

    {
      Header: "Status",
      accessor: "employmentStatus",
      maxWidth: 100,
      minWidth: 100,
      className: "text-center",
      Cell: row =>
        <span
          className={getStatusClass(row.original.employmentStatus)}>{row.original.employmentStatus}</span>,
    },
    {
      Header: "End Date",
      accessor: "endDate",
      className: "text-center",
      maxWidth: 100,
      minWidth: 100,
      Cell: row =>
        setDateForTable(row.original.endDate)
    },
  ];


  var employmentStatusOptions = subStatusList && subStatusList.filter(subStatus => subStatus !== 'Internal Staff').map((employmentStatus, i) => {
    return <option key={employmentStatus + i} value={employmentStatus}>{employmentStatus}</option>
  });

 

  return (
    <Container fluid className="main-content-container p-4">

      <div 
           className="page-header flex-column flex-md-row py-2 d-flex justify-content-end bg-white tableSearch">
        <div className="d-flex align-items-center p-2">
          <FormInput
            name='workerName'
            value={formData.workerName}
            onChange={onChange}
            placeholder="Worker Name"
          />
        </div>

        <div className="d-flex align-items-center p-2">
          <FormSelect name='employmentStatus' value={formData.employmentStatus}
                      onChange={onChange}>
            <option value=''>All Employment Status
            </option>
            {employmentStatusOptions}
          </FormSelect>
        </div>

        <div className="d-flex align-items-center justify-content-end p-2">
          <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                  onClick={e=>searchWorkers()}>Search</Button>
        </div>
      </div>


      <Card className="tableCard p-0">
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {/*Filters :: Page Size*/}
              <Col lg="2"
                   className="file-manager__filters__rows d-flex align-items-center col-12">
                <span>Show</span>
                <FormSelect
                  size="sm"
                  name='pageSize'
                  value={pageSize}
                  onChange={onChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size} rows
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col lg="6" className="d-flex align-items-center">
                    <p className='m-0'>Note - This section shows Active and On Leave Users</p>
              </Col>
              
              <Col lg="4"
                   className="file-manager__filters__search d-flex justify-content-center justify-content-lg-end col-12">
                <a
                  href={`/api/client/exportWorkersList?userName=${formData.workerName}&employmentStatus=${formData.employmentStatus}&type=pdf`}>
                  <Button className="my-2 py-2 mx-2" size='sm'
                          theme="accent">Export PDF</Button>
                </a>
                <a
                  href={`/api/client/exportWorkersList?userName=${formData.workerName}&employmentStatus=${formData.employmentStatus}&type=csv`}>
                  <Button className="my-2 py-2 mx-2" size='sm'
                          theme="accent">Export CSV</Button>
                </a>
              </Col>


            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
              columns={tableColumns}
              data={tableData}
              pageSize={Number(pageSize)}
              showPageSizeOptions={false}
              resizable={false}
              noDataText="No results found"
            />
          </div>
        </CardBody>
      </Card>
    </Container>
  );
}

Worker.propTypes = {
  clientEmploymentStatusList: PropTypes.array.isRequired,
  clientWorkerList: PropTypes.array.isRequired,
  get_client_employment_status_list: PropTypes.func.isRequired,
  search_worker_for_client_list: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  clientEmploymentStatusList: state.clientWorkers.clientEmploymentStatusList,
  clientWorkerList: state.clientWorkers.clientWorkerList,
  subStatusList: state.user.subStatusList,
});


export default connect(mapStateToProps, {
  get_client_employment_status_list,
  search_worker_for_client_list,
  get_client_sub_status_list
})(ClientWorkers);
