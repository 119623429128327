import React, { useEffect, useState } from 'react';
import {
  Button,
  Card, Col,
  Form, FormInput,
  ListGroup, ListGroupItem,
  Row
} from "shards-react";
import InfoTooltip from "../../common/InfoTooltip";

export default function Notification({
  notificationConfig,
  subscriberId,
  save_notification_config
}) {


  const [formData, setFormData] = useState({
    apiURL: "",
    apiKey: "",
    email: ""
  });

  useEffect(() => {
    setFormData({
      ...formData,
      apiURL: notificationConfig.apiUrl,
      apiKey: notificationConfig.apiKey,
      email: notificationConfig.email
    })
  }, [notificationConfig]);

  let saveNotificationConfig = (e) => {
    save_notification_config(formData.apiURL, formData.apiKey, formData.email);
  };
  return (
    <div className="position-relative mt-5">
      <span className="tableHeader">Notification Email ID</span>
      <Card className="tableCard p-0 border-card">
        <ListGroup flush>

          <ListGroupItem className="p-3 mt-3">
            <Row>
              <Col>
                <Form>
                  <Row form>
                    <Col lg="4" className="form-group p-2 mb-0">
                      <label htmlFor="email">Email ID <InfoTooltip
                        msg="The email ID for Notifications to be sent from" /></label>
                      <FormInput
                        id="email"
                        name='email'
                        value={formData.email}
                        onChange={e => {
                          e.target.setCustomValidity("")
                          setFormData({ ...formData, [e.target.name]: e.target.value });
                        }}
                        placeholder="Enter Email ID"
                      />
                    </Col>
                    <Col lg="2" className="p-2 mb-0">
                      <div
                        className="d-flex justify-content-end mt-4 pt-2">
                        <Button className="mx-5 py-2 btn btn-primary w-100" onClick={saveNotificationConfig}>Save</Button>
                      </div>
                    </Col>
                    <Col lg="4" className="form-group p-2 mb-0">
                      <label htmlFor="apiKey">API Key <InfoTooltip
                        msg="This is the unique identifier used by the chosen email application being integrated with (ex. Sendgrid)" /></label>
                      <FormInput
                        id="apiKey"
                        name='apiKey'
                        value={formData.apiKey}
                        onChange={e => {
                          e.target.setCustomValidity("")
                          setFormData({ ...formData, [e.target.name]: e.target.value });
                        }}
                        placeholder="Enter API Key"
                      />
                    </Col>
                    <Col  lg="2" className="p-2 mb-0">
                      <div
                        className="d-flex justify-content-end mt-4 pt-2">
                        <Button className="mx-5 py-2 btn btn-primary w-100" onClick={saveNotificationConfig}>Save</Button>
                      </div>
                    </Col>
                    <Col lg="4" className="form-group p-2 mb-0">
                      <label htmlFor="apiURL">API URL <InfoTooltip
                        msg="The URL address provided by the email integration" /></label>
                      <FormInput
                        id="apiURL"
                        name='apiURL'
                        value={formData.apiURL}
                        onChange={e => {
                          e.target.setCustomValidity("")
                          setFormData({ ...formData, [e.target.name]: e.target.value });
                        }}
                        placeholder="Enter API URL"
                      />
                    </Col>
                    <Col lg="2" className="p-2 mb-0">
                      <div
                        className="d-flex justify-content-end mt-4 pt-2">
                        <Button className="mx-5 py-2 btn btn-primary w-100" onClick={saveNotificationConfig}>Save</Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>


        </ListGroup>
      </Card>
    </div>
  )
}




