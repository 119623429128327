//Change User Password
import api from "../../utils/api";
import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  DISABLE_2FA,
  DISABLE_2FA_ERROR,
  ENABLE_2FA,
  ENABLE_2FA_ERROR,
  GENERATED_2FA,
  GENERATED_2FA_ERROR,
  GET_SETTINGS,
  GET_SETTINGS_ERROR,
  RESET, USER_DISABLE_2FA,
  USER_DISABLE_2FA_ERROR
} from "./types";
import {setAlert} from "./alert";

export const change_password = (
  userId,
  userLogin,
  currentPassword,
  newPassword,
  verifyPassword,
  tFA
) => async (dispatch) => {
  try {
    const res = await api.get(`api/user/updatePassword?userId=${userId}&login=${encodeURIComponent(userLogin)}&currentPassword=${encodeURIComponent(currentPassword)}&newPassword=${encodeURIComponent(newPassword)}&verifyPassword=${encodeURIComponent(verifyPassword)}&2fa=${tFA}`);
    dispatch({
      type: CHANGE_PASSWORD,
      payload:res.data
    });
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: CHANGE_PASSWORD_ERROR,
      payload:err.response.data
    });
  }
};

export  const reset=()=> async (dispatch)=>{
  dispatch({
    type: RESET,
  });
}

//generate 2fa
export const generate_2fa = (userId) => async (dispatch) => {
  const body = {userId};
  try {
    const res = await api.get(`api/user/generate2fa?userId=${userId}`);
    dispatch({
      type: GENERATED_2FA,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GENERATED_2FA_ERROR,
    });
  }
};
//get user profile
export const getSettings = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`api/user/settingsProfile?userId=${userId}`);

    dispatch({
      type: GET_SETTINGS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SETTINGS_ERROR,
    });
  }
};
//enable 2fa
export const enable_2fa = (_2faCode) => async (dispatch) => {
  try {
    const res = await api.get(`api/user/enable2fa?&2fa=${_2faCode}`);
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: ENABLE_2FA,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: ENABLE_2FA_ERROR,
      payload:err.response.data
    });
  }
};
export const disable_2fa = (password, twoFA) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/user/disable2FA?password=${encodeURIComponent(password)}&2fa=${twoFA}`
    );
    dispatch({
      type: DISABLE_2FA,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: DISABLE_2FA_ERROR,
      payload:err.response.data
    });
  }
};
export const user_disable_2fa = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`api/subscriber/userDisable2FA?userId=${userId}`);
    dispatch({
      type: USER_DISABLE_2FA,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: USER_DISABLE_2FA_ERROR,
    });
  }
};
export const updateSettings = (userId, leavesFlag, expenseFlag, messageFlag) => async (dispatch) => {
  try {
    const res = await api.get(`api/user/updateNotificationSettings?userId=${userId}&leavesFlag=${leavesFlag}&expenseFlag=${expenseFlag}&messageFlag=${messageFlag}`);

    dispatch({
      type: GET_SETTINGS,
      payload: res.data,
    });
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_SETTINGS_ERROR,
    });
  }
};