import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom';
import classNames from "classnames";
import {
  Alert,
  Card,
  Col,
  Container, Form, FormInput, ListGroup, ListGroupItem,
  Row,FormSelect
} from "shards-react";
import { connect } from 'react-redux';
import {
  save_pay_component,
  get_pay_component
} from '../../redux/actions/user';
import PropTypes from 'prop-types';
import ConfirmAlert from "../../components/common/ConfirmAlert";
import InfoTooltip from "../../components/common/InfoTooltip";
import { setAlert } from '../../redux/actions/alert';

function AddEditPayComponent({

  save_pay_component,
  get_pay_component,
  payElementList,
  PayComponentDetails,

  IsPayComponentSaved,
  setAlert

}) {
  let { id } = useParams();
  const history = useHistory();

  const [Saved, setSaved] = useState(IsPayComponentSaved);
  const [IsReturn, setIsReturn] = useState("");
  const { className } = "";
  const classes = classNames(className, "d-flex", "my-auto", "date-range");


  const [formData, setFormData] = useState({
    refNum: '',
    name: '',
    payElementId: '',
    payElement:'',
    financialAppPayTypeId:''
  });


  let payElementOptionsList = payElementList.map(payElement => {
    return <option value={payElement.refNum}>{payElement.payElement}</option>
  });



  useEffect(() => {
    if (id !== null && id !== '' && id !== '0' && id !== 0) {

      get_pay_component(id);
    }
   else{
    setFormData({
      ...formData,
      payComponentName: '',
      payElementId: '',
      payElement:'',
      financialAppPayTypeID:''

    });
   }
  }, [id]);



  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });




  useEffect(() => {
    if (PayComponentDetails && Object.keys(PayComponentDetails).length !== 0 && id!=0) {
      setFormData({
        ...formData,
        Id: PayComponentDetails.refNum,
        payComponentName: PayComponentDetails.name,
        payElementId: PayComponentDetails.payElementId,
        payElement: PayComponentDetails.payElement,
        financialAppPayTypeID: PayComponentDetails.financialAppPayTypeId

      });
    }
  }, [PayComponentDetails]);
  const { financialAppPayTypeID, payComponentName,payElement} = formData;
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (IsPayComponentSaved && IsPayComponentSaved != 0) {
      if (Saved != IsPayComponentSaved) {

        setSaved(IsPayComponentSaved)
        if (IsReturn && IsReturn === "yes") {
          history.goBack();
        } else {

          history.replace(`/sub-add-Pay-Component/0`);
          setFormData({
            ...formData,

            payComponentName: '',
            payElementId:'',
            payElement:'',
            financialAppPayTypeID:''
          })
        }

      }

    }
  }, [IsPayComponentSaved]);

  const onSubmit = (e) => {

    if(formData.payComponentName == ""){
      setAlert("Please enter Pay Component Name", "danger");
    }else
    if(formData.payElementId == ""){
      setAlert("Please select Pay Element", "danger");
    }else
    if(formData.financialAppPayTypeID == ""){
      setAlert("Please enter Financial App Pay Type ID", "danger");
    }
    else{

    e.preventDefault();
    var data = {
      refNum: id ,
      name: formData.payComponentName,
      payElement: formData.payElement,
      payElementId: formData.payElementId,
      financialAppPayTypeId: formData.financialAppPayTypeID
    }
    if (formData.payComponentName && formData.payComponentName !='' && formData.payElementId != '' && formData.financialAppPayTypeID !='') {
      save_pay_component(data);
      if (e.target.name === "saveReturn") {
        setIsReturn("yes");

      }
      else {
        setIsReturn("no");
      }
    }
  }

  };
  return (
    <>


      <Container fluid className="main-content-container p-4">
        <ConfirmAlert confirmAlert={confirmAlert}
          setConfirmAlert={setConfirmAlert} />
        <Card small className="mb-5 w-100">
          <h4 className="m-0 section-title idStyle">Ref# {id > 0 ? id : '0'}</h4>
          <ListGroup flush>
            <ListGroupItem className="border-card p-3">
              <Row>
                <Col sm="12" md="12" lg="12">
                  <Form id="clientDetailsForm" onSubmit={onSubmit}>
                    <Row>

                     <Col lg="8" sm="12" md="12">
                      <Row>
                     <Col lg="4" className="form-group p-2 mb-0">
                        <label htmlFor="Pay Component Name">Pay Component Name</label>
                        <span aria-hidden="true" className="required">*</span>
                        <InfoTooltip
                          msg="An earning (such as base salary or bonus) or deduction (such as federal withholding taxes or medical) that applies to a worker" />
                        <FormInput placeholder="Enter Pay Component Name" name='payComponentName' id='payComponentName'
                          value={formData.payComponentName}
                          onChange={onChange} />
                      </Col>
                      <Col lg="4" className="form-group p-2 mb-0">
                        <label htmlFor="Pay Element">Pay Element</label>
                        <span aria-hidden="true" className="required">*</span>
                        <InfoTooltip
                          msg="Element that represents the compensation and benefit types that you give to your employees" />
                        <FormSelect id="payElementId"
                            name='payElementId'
                            onChange={onChange}
                            required
                            value={formData.payElementId}
                            >
                            <option value=''>Select Pay Element</option>
                          {payElementOptionsList}
                          </FormSelect>
                      </Col>
                      <Col lg="4" className="form-group p-2 mb-0">
                        <label htmlFor="Financial App Pay Type ID">Financial App Pay Type ID</label>
                        <span aria-hidden="true" className="required">*</span>
                        <InfoTooltip
                          msg="Integration point between the Pay Component and finance modules" />
                        <FormInput placeholder="Enter Financial App Pay Type ID" name='financialAppPayTypeID' id='financialAppPayTypeID'
                          value={formData.financialAppPayTypeID}
                          onChange={onChange} />
                        </Col>
                        </Row>
                     </Col>

                      <div  className="mt-4 pt-3">

                        {id == 0 &&
                          <button type="button" onClick={onSubmit} className="btn btn-primary" name="saveAddAnother">Save and Add Another</button>
                        }
                        <button type="button" onClick={onSubmit} className="btn btn-primary ml-2 " name="saveReturn">Save and Return</button>

                        <button onClick={(e) => {
                          e.preventDefault();
                          history.goBack();
                        }} className="btn btn-primary ml-2 ">Cancel</button>

                      </div>


                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>



    </>
  )
}

AddEditPayComponent.propTypes = {

  get_pay_component: PropTypes.func.isRequired,
  save_pay_component: PropTypes.func.isRequired,

  setAlert: PropTypes.func.isRequired,
  IsPayComponentSaved: PropTypes.number.isRequired,

};
const mapStateToProps = (state) => ({
  payElementList: state.user.payElementList,
  PayComponentDetails: state.user.PayComponentDetails,
  subscriberId: state.user.user.userRoles.filter(item => item.role === "Subscriber Admin")[0].domainId,

  IsPayComponentSaved: state.user.IsPayComponentSaved,
});
export default connect(mapStateToProps, {
  save_pay_component,
  get_pay_component,
  setAlert
})(AddEditPayComponent);
