import React, {useState} from 'react';
import {
  Card, CardBody,
  CardHeader,
  Col,
  Container, FormCheckbox,
  FormSelect,
  Row
} from "shards-react";
import {Link} from "react-router-dom";
import ReactTable from "react-table";
import {setDateForTable} from "../../../utils/date";
import PropTypes from "prop-types";
import {
  getStatusClass, numberToMonetary
} from "../../../utils/general";
import {connect} from "react-redux";
import {get_user_expense_sheet_details} from "../../../redux/actions/worker/expenses";
import {
  reject_sub_mileage_item
} from "../../../redux/actions/subscriber/subExpenses";

function MileageItemsCard(props) {

  const [state, setState] = useState({
    pageSizeOptions: [10, 15, 20, 25, 30],
    pageSize: 10,
  });

  const onChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  };

  const tableData = (props.mileageItemList)?props.mileageItemList:[]

  var {pageSize, pageSizeOptions} = state;

  if(tableData.length<10) {
    pageSize = tableData.length
    pageSizeOptions = [tableData.length]
  }

  const tableColumns = [
    {
      Header: "Date",
      accessor: "tripDate",
      maxWidth: 90,
      Cell: row =>
        setDateForTable(row.original.tripDate),
    },
    {
      Header: "From",
      accessor: "fromLocation",
      maxWidth: 120,
      className: "justify-content-start text-left text-wrap text-break"
    },
    {
      Header: "To",
      accessor: "toLocation",
      maxWidth: 120,
      className: "justify-content-start text-left text-wrap text-break"
    },
    {
      Header: "Trip Description",
      accessor: "tripDescription",
      className: "justify-content-start text-left text-wrap text-break",
    },

    {
      Header: "Distance Unit",
      accessor: "distanceUnit",
      className: "justify-content-start text-left text-wrap text-break",
    },

    {
      Header: "Distance Travelled",
      accessor: "distanceDriven",
      maxWidth: 100,
      className: "justify-content-end text-wrap text-break",
      Cell:row => numberToMonetary(row.original.distanceDriven,2)
    },

    {
      Header: "Applied Rate",
      accessor: "appliedRate",
      maxWidth: 100,
      className: "justify-content-end text-wrap text-break",
      Cell:row => row.original.appliedRate.toFixed(2)
    },
    {
      Header: "Amount",
      accessor: "amount",
      maxWidth: 120,
      className: "justify-content-end text-wrap text-break overflow-visible",
      Cell: row => row.original.amount.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2}),
      Footer:<div className="d-flex w-100 justify-content-end text-nowrap"><span>Sub Total: {props.status === undefined ? '0.00' : numberToMonetary(props.submittedMileageTotal)}</span></div>

    },


    {
      Header: "Status",
      accessor: "status",
      maxWidth: 80,
      className: "text-center",
      Cell: row =>
        <span
          className={getStatusClass(row.original.status)}>{row.original.status}</span>
    },

        {
      Header: "Ref#",
      accessor: "refNum",
      maxWidth: 80,
      className: "text-center",
      Cell: row =>
        <Link to={`/${props.approveItemRoute}/${props.sheetRefNumber}/${row.original.refNum}`}>{row.original.refNum}</Link>
    }
  ];

  if(props.status==="Approved" || props.status==="Reviewed"){
    tableColumns.splice(8,0,{
      Header: "Taxable",
      accessor: "taxable",
      maxWidth: 55,
      className: "align-items-center text-wrap text-break",
      Cell:row => <FormCheckbox checked={row.original.taxable} name="taxable" className="ml-2" disabled/>
    });
  }

  if(props.status!=="Reviewed"){
    tableColumns.splice(8,0,{
      Header: <span className="text-wrap">Action</span>,
      maxWidth: 80,
      Cell: row =>(row.original.status==="Rejected" )?<Link title="Approve this Mileage." onClick={e=>reviewMileageItem(e,row.original.refNum)}>Approve</Link>:<Link title="Reject this Mileage." onClick={e=>rejectMileageItem(e,row.original.refNum)}>Reject</Link>
    });
  }


  let rejectMileageItem=(e,refNum)=>{
    e.preventDefault();
    props.rejectMileageItem(refNum);
  };

  let reviewMileageItem=(e,refNum)=>{
    e.preventDefault();
    props.reviewMileageItem(refNum);
  };


  return (
    <Card className="p-0 mb-4">
      <span className="tableHeader">Review Mileage</span>
      <CardHeader className="p-0 pt-2 mt-1 border-card">
        <Container fluid className="file-manager__filters border-bottom">
          <Row>
            <Col className="file-manager__filters__rows d-flex align-items-center">
              <span>Show</span>
              <FormSelect
                size="sm"
                name='pageSize'
                value={pageSize}
                onChange={onChange}
              >
                {pageSizeOptions.map((size, idx) => (
                  <option key={idx} value={size}>
                    {size} rows
                  </option>
                ))}
              </FormSelect>
            </Col>

            <Col className="d-flex align-items-center text-nowrap justify-content-end">
              <label className="m-0">Submitted YTD</label>
              <span className='px-2'>{numberToMonetary(props.submittedYTD)}</span>
            </Col>

            <Col className="d-flex align-items-center text-nowrap justify-content-end">
              <label className="m-0">Approved YTD</label>
              <span className='px-2'>{numberToMonetary(props.approvedYTD)}</span>
            </Col>

            <Col className="d-flex align-items-center text-nowrap justify-content-end">
              <label className="m-0">Reviewed YTD</label>
              <span className='px-2'>{numberToMonetary(props.reviewedYTD)}</span>
            </Col>

            <Col className="d-flex align-items-center text-nowrap justify-content-end">
              <label className="m-0">Total YTD</label>
              <span className='px-2'>{numberToMonetary(props.totalYTD)}</span>
            </Col>
          </Row>
        </Container>
      </CardHeader>
      <CardBody className="p-0">
        <div className="">
          <ReactTable
            className="mileageItemsTable"
            columns={tableColumns}
            data={tableData}
            pageSize={Number(pageSize)}
            showPageSizeOptions={false}
            resizable={false}
            noDataText="No results found"
          />
        </div>
      </CardBody>
    </Card>
  );
}

MileageItemsCard.propTypes={
  mileageItemList:PropTypes.array.isRequired,
  submittedMileageTotal:PropTypes.string.isRequired,
  approvedMileageTotal:PropTypes.string.isRequired,
  sheetRefNumber:PropTypes.string.isRequired
}

MileageItemsCard.defaultProps={
  mileageMileageList:[],
  submittedMileageTotal:'',
  approvedMileageTotal:'',
  sheetRefNumber:''
}

export default connect(null, {
  reject_sub_mileage_item,
  get_user_expense_sheet_details
})(MileageItemsCard);
