import React from "react";
import  './forms/manage-user-profile-style.css';

export default function Table({ headers, rows, viewHistory}) {

    const Row = ({row, index}) => {

        return (
            <tr key={`t${index}`}>
                {
                    headers && headers.map(header =>{

                        if(header.cell){
                          return  <td key={header.accessor} className={index === 0 ? `table-first-row-bg  ${header.bodyClassName && header.bodyClassName}` : `table-all-row-bg ${header.bodyClassName && header.bodyClassName}`} >{header.cell(row)}</td>
                        }
                        return (<td key={header.accessor} className={index === 0 ? `table-first-row-bg ${header.bodyClassName && header.bodyClassName}` : `table-all-row-bg ${header.bodyClassName && header.bodyClassName}`} >{ row[header.accessor]}</td>)
                    })

                }
            </tr>
        )

    }
    return (
        <>
            <table className="table table-bordered shadow-sm mb-5 bg-white custom-table" >
                <thead>
                    <tr key="header">
                        {
                            headers && headers.map((header, i) => (
                                <th className={header.className} key={header+i} style={{minWidth:header.minWidth}}>{header.Header}</th>
                                
                            ))
                        }

                    </tr>
                </thead>
                <tbody>
                    
                        {
                            (!viewHistory && rows && rows.length > 0) ? (<Row row={rows[0]} index={0}/>) :

                            rows && rows.map((row, i) => {

                                return (<Row row={row} index={i}/>)
                            })

                        }
                   
                </tbody>

            </table>
        </>
    );
}
