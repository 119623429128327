import React from 'react'
import ReactTable from "react-table";
import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container,
  FormSelect,
  Row
} from "shards-react";
import {Link} from "react-router-dom";


export default function CustomTable({
                                      pageSize,
                                      onChange,
                                      pageSizeOptions = [],
                                      tableColumns = [],
                                      tableData = [],
                                      AddButton,
                                      addPath,
                                      addButtonOnClick,
                                      showHeader,
                                      headerLabel,
                                      headerIconClass,
                                      roundedTop
                                    }) {
  return (
    <Card className={"tableCard p-0" + (roundedTop ? " border-card" : "")}>
      {headerLabel && <>{headerIconClass &&
      <span className="iconBox"><i className={headerIconClass}/></span>}
        <h4 className="m-0 section-title headerStyle">{headerLabel}</h4></>}
      {showHeader ?
        <CardHeader
          className={`border-card p-0  ${(headerLabel ? "pt-2" : "")}`}>
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              <Col
                className="file-manager__filters__rows d-flex align-items-center col-6">
                <span>Show</span>
                <FormSelect size="sm" name='pageSize' value={pageSize}
                            onChange={onChange}>
                  {pageSizeOptions.map((size, idx) => <option key={idx}
                                                              value={size}>
                    {size} rows
                  </option>)}
                </FormSelect>
              </Col>
              {AddButton ?
                <Col
                  className="file-manager__filters__search d-flex justify-content-end col-6">
                  <Link to={addPath}>
                    <Button className="my-2 py-2 mx-2" size='sm'
                            onClick={addButtonOnClick}
                            theme="accent">{AddButton}</Button>
                  </Link>
                </Col> : null}
            </Row>
          </Container>
        </CardHeader> : null}

      <CardBody className="p-0">
        <div className="">
          <ReactTable columns={tableColumns} data={tableData}
                      pageSize={Number(pageSize)} showPageSizeOptions={false}
                      resizable={false}
                      noDataText="No results found"/>
        </div>
      </CardBody>
    </Card>);
}
