import React, {useEffect, useState} from 'react';
import {
  Button,
  Card,
  Col,
  Container, Form, FormCheckbox, FormInput,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {setDateForTable} from "../../utils/date";
import {
  getValueInNativeDataType,
  monetaryToNumber,
  numberToMonetary
} from "../../utils/general";
import {connect} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import loadingGif from "../../images/loading-gif.gif";
import {b64toBlob} from "../../utils/file";
import {get_user_expense_item_receipt_file} from "../../redux/actions/worker/expenses";
import {
  clear_sub_expense_item, get_sub_expense_item,
  reject_sub_expense_item, review_sub_expense_item
} from "../../redux/actions/subscriber/subExpenses";
import InfoTooltip from "../../components/common/InfoTooltip";

function ApproveExpenseItem(props) {

  let {refNumber} = useParams();
  let expenseItem=(props.expenseItemList)?props.expenseItemList.find(item=>item.refNum===parseInt(refNumber)):props.subExpenseItem
  const [state, setState] = useState({
    grossAmount: expenseItem.grossAmount,
    taxAmount: expenseItem.taxAmount,
    exchangeRate: expenseItem.exchangeRate,
    taxable: expenseItem.taxable,
    receiptAmount: expenseItem.receiptAmount,
    netAmount: expenseItem.netAmount,
  });

  useEffect(() => {
    if(!props.expenseItemList){
      setState({
        ...state,
        grossAmount: numberToMonetary(props.subExpenseItem.grossAmount),
        taxAmount: numberToMonetary(props.subExpenseItem.taxAmount),
        exchangeRate: numberToMonetary(props.subExpenseItem.exchangeRate,5),
        receiptAmount: numberToMonetary(props.subExpenseItem.receiptAmount),
        netAmount: numberToMonetary(props.subExpenseItem.netAmount),
        taxable: props.subExpenseItem.taxable
      })
    }
  }, [props.subExpenseItem]);



  useEffect(() => {
    let receiptAmount = numberToMonetary(monetaryToNumber(state.grossAmount) + monetaryToNumber(state.taxAmount));
    let netAmount = numberToMonetary(monetaryToNumber(receiptAmount) * monetaryToNumber(state.exchangeRate));
    setState({
      ...state,
      receiptAmount,
      netAmount
    })
  }, [state.grossAmount, state.taxAmount, state.exchangeRate]);


  //added Worker Name in the page title once we get response from backend
  useEffect(() => {
    if (props.workerName && props.workerName.trim()) {
      props.changePageMeta(`${props.title} - ${props.workerName}`)
    }
  }, [expenseItem]);

  useEffect(() => {
    if (expenseItem && expenseItem.receiptFileName) {
      props.get_user_expense_item_receipt_file(expenseItem.userId, refNumber);
    }
  }, [expenseItem])

  useEffect(() => {
    if (
      refNumber !== null &&
      refNumber !== '' &&
      refNumber !== '0' &&
      refNumber !== 0
    ) {
      props.get_sub_expense_item(refNumber);
    }

    return () => {
      props.clear_sub_expense_item();
    }
  }, [refNumber]);

  const history = useHistory();


  let receiptDoc = <img src={loadingGif}
                        style={{width: '50px'}}
                        alt="loader"/>;

  if (Object.keys(expenseItem).length && !expenseItem.receiptFileName)
    receiptDoc = <label className="text-center">No receipt attached</label>

    if (expenseItem.receiptFileName && expenseItem.receiptFileName.toLowerCase().endsWith(".pdf") && props.receiptFile)
    {
      receiptDoc = <object
        className='receipt-viewer w-100 h-100 border receipt-viewer-pdf'
        type="application/pdf"
        data={b64toBlob(props.receiptFile)}
        title="Receipt Preview"/>
    }
    if (expenseItem.receiptFileName && (expenseItem.receiptFileName.toLowerCase().endsWith(".png") || expenseItem.receiptFileName.toLowerCase().endsWith(".jpg") || expenseItem.receiptFileName.toLowerCase().endsWith(".jpeg")) && props.receiptFile)
    {
      receiptDoc = <img
        className='receipt-viewer mh-75 mw-100'
        src={b64toBlob(props.receiptFile)}
        alt="receipt"/>
    }


  let onChange = (e) => {
    let value = getValueInNativeDataType(e);
    setState({
      ...state,
      [e.target.name]: value,
    })
  };

  let onToggle = (e) => {
    setState({
      ...state,
      [e.target.name]: (state[e.target.name] === 1) ? 0 : 1,
    })
  };
  return (
    <Container fluid className="main-content-container p-4">
      <Card small className="details-card mb-4 w-100">
        <h4 className="m-0 section-title idStyle">Ref# {refNumber}</h4>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>
                <Form>
                  <Row>
                    <Col className={"col-12 col-lg-6"}>
                      <Row form>

                        <Col lg="6" className="form-group p-2 m-0">
                          <label>Date</label>
                          <span
                            className='text-light form-control'>{setDateForTable(expenseItem.expenseDate)}</span>
                        </Col>

                        <Col lg="6" className="form-group p-2 m-0">
                          <label>Expense Category</label>
                          <span
                            className='text-light form-control'>{expenseItem.type}</span>
                        </Col>


                        <Col lg="12" className="form-group p-2 m-0">
                          <label>Description</label>
                          <span
                            className='text-light form-control'>{expenseItem.description}</span>
                        </Col>

                        <Col lg="12" className="form-group p-2 pr-4 m-0">
                          <label>Gross</label>
                          <InfoTooltip msg="For meals: add up meal sub-total and Tips here."/>
                          {(expenseItem.status !== "Reviewed") ?
                            <FormInput
                              id="grossAmount"
                              className="w-50"
                              autocomplete="off"
                              pattern="^[1-9][0-9.,]*$"
                              required
                              onInvalid={e => e.target.setCustomValidity("Gross amount should be greater than zero")}
                              name='grossAmount'
                              value={state.grossAmount}
                              data-type="monetary"
                              onChange={onChange}
                            /> : <span
                              className='text-light form-control w-50'>{expenseItem.grossAmount}</span>}

                        </Col>

                        <Col lg="12" className="form-group p-2 pr-4 m-0">
                          <label>Tax Amount</label>
                          {(expenseItem.status !== "Reviewed") ?
                            <FormInput
                              id="taxAmount"
                              className="w-50"
                              autocomplete="off"
                              required
                              name='taxAmount'
                              value={state.taxAmount}
                              data-type="monetary"
                              onChange={onChange}
                            /> : <span
                              className='text-light form-control w-50'>{expenseItem.taxAmount}</span>}

                        </Col>

                        <Col lg="12" className="form-group p-2 pr-4 m-0">
                          <label>Receipt Total</label>
                          <span
                            className='text-light form-control w-50'>{state.receiptAmount}</span>
                        </Col>

                        <Col lg="6" className="form-group p-2 m-0">
                          <label>Currency</label>
                          <span
                            className='text-light form-control'>{expenseItem.currency}</span>
                        </Col>

                        <Col lg="6" className="form-group p-2 m-0">
                          <label>Exchange Rate</label>
                          {(expenseItem.status !== "Reviewed") ?
                            <FormInput
                              id="exchangeRate"
                              autocomplete="off"
                              data-maxFracDigits="5"
                              required
                              name='exchangeRate'
                              value={state.exchangeRate}
                              data-type="monetary"
                              onChange={onChange}
                            /> : <span
                              className='text-light form-control'>{(expenseItem.exchangeRate) ? parseFloat(expenseItem.exchangeRate).toFixed(5) : ""}</span>}
                        </Col>


                        <Col lg="12" className="form-group p-2 m-0">
                          <label>Net Amount</label>
                          <span
                            className='text-light form-control w-50'>{state.netAmount}</span>
                        </Col>


                        <Col lg="12" className="form-group p-2 m-0">
                          <label>Taxable</label>
                          <FormCheckbox
                            id="taxable"
                            className="d-flex"
                            autocomplete="off"
                            required
                            name='taxable'
                            disabled={expenseItem.status === "Reviewed"}
                            checked={state.taxable}
                            onChange={onToggle}
                          >Taxable</FormCheckbox>
                        </Col>


                      </Row>
                    </Col>
                    <Col
                      className="form-group col-12 col-lg-6 p-2 px-3 m-0 d-flex flex-column">
                      <div>
                        <label>Receipt</label>
                        {(Boolean(Object.keys(expenseItem).length && !expenseItem.receiptFileName)) &&
                        <InfoTooltip
                          msg="No receipt attached.This expense item has been submitted as taxable"/>}
                      </div>
                      <div
                        className="d-flex align-items-center justify-content-center w-100 h-100 border">
                        {receiptDoc}
                      </div>

                    </Col>
                  </Row>
                  <Row className="px-3">
                    <Col sm="12" md="12" lg="12"
                         className=" form-group p-2 m-0 d-flex align-items-center justify-content-center justify-content-md-end">

                      {(props.reportStatus !== "Reviewed") && <>

                        <InfoTooltip
                          msg=" A notification will be sent to all the users of this expense report"/>

                        <Button className="mx-1 py-2 mx-md-2" size="sm"
                                onClick={() => {
                                  props.review_sub_expense_item(refNumber, state.grossAmount, state.taxAmount, state.exchangeRate, state.receiptAmount, state.netAmount, state.taxable);
                                  history.goBack();
                                }}
                                title="Approve this expense item."
                                theme="accent">Approve</Button>


                        {(expenseItem.status !== "Rejected") &&
                        <Button className="mx-1 py-2 mx-md-2" size="sm"
                                onClick={() => {
                                  props.reject_sub_expense_item(refNumber);
                                  history.goBack();
                                }}
                                title="Reject this expense item."
                                theme="accent">Reject</Button>}</>}

                      <Button className="mx-1 py-2 mx-md-2" size="sm"
                              onClick={e => history.goBack()}
                              theme="accent">Back</Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  subExpenseItem: state.subExpenses.subExpenseItem,
  expenseItemList: state.subExpenses.expenseSheetForSub.expenseItemList,
  workerName:state.subExpenses.expenseSheetForSub.workerName,
  receiptFile: state.expenses.receiptFile,
  reportStatus: state.subExpenses.expenseSheetForSub.status
});
export default connect(mapStateToProps, {
  get_user_expense_item_receipt_file,
  get_sub_expense_item,
  clear_sub_expense_item,
  review_sub_expense_item,
  reject_sub_expense_item,
})(ApproveExpenseItem);

