import React,  { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Row,
  Button,
  Container,
  FormCheckbox
} from "shards-react";
import Table from './Table';
import { useLocation, useHistory } from "react-router-dom";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { setDateForTable } from '../../utils/date';
import {default as queryString} from "qs";
import { formatNumberAmount, getFrequencyLabel } from '../../utils/general';
function PensionPlan({
  formData,
  userProfile,
  formDataRef,
  sectionformDataRef,
  clientMarkups
}) {

  const [viewHistory, setViewHistory] = useState(false);
  const [isDisabledPension, disablePension] = useState(false);
  const location = useLocation();
  const history = useHistory();
  let queryParams = queryString.parse(location.search.replace("?", ""))

  const tableColumns = [
    {
      Header: <span className="text-wrap">Retirement Plan</span>,
      accessor: "pensionPlan",
      maxWidth: 100,
      minWidth: 100,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",

    },
    {
      Header: <span className="text-wrap">Retirement Plan Frequency</span>,
      accessor: "frequency",
      maxWidth: 110,
      minWidth: 110,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: row=> getFrequencyLabel(row.frequency)
     
    },
    {
      Header: <span className="text-wrap">Retirement Plan Currency</span>,
      accessor: "rpCurrency",
      maxWidth: 110,
      minWidth: 110,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      
    },
    {
      Header: <span className="text-wrap">Worker Contribution Start Date</span>,
      accessor: "myMatchStartDate",
      maxWidth: 150,
      minWidth: 150,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => setDateForTable(row.myMatchStartDate)
    },

    {
      Header: <span className="text-wrap">Worker Contribution End Date</span>,
      accessor: "rpWorkerContributionEndDate",
      maxWidth: 150,
      minWidth: 150,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => setDateForTable(row.rpWorkerContributionEndDate)

    },

    {
      Header: <span className="text-wrap">Worker Contribution Amount</span>,
      accessor: "rpWorkerContributionAmount",
      maxWidth: 148,
      minWidth: 148,
      bodyClassName: "text-right",
      className: "justify-content-center text-center text-wrap text-break",
      cell : row=> formatNumberAmount(row.rpWorkerContributionAmount, "en-US", 2, 2)
    },

    {
      Header: <span className="text-wrap">Client Contribution Start Date</span>,
      accessor: "clientMatchStartDate",
      maxWidth: 148,
      minWidth: 148,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => setDateForTable(row.clientMatchStartDate)
    },

    {
      Header: <span className="text-wrap">Client Contribution End Date</span>,
      accessor: "rpClientContributionEndDate",
      maxWidth: 148,
      minWidth: 148,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => setDateForTable(row.rpClientContributionEndDate)

    },

    {
      Header: <span className="text-wrap">Client Contribution Amount</span>,
      accessor: "rpClientContributionAmount",
      maxWidth: 148,
      minWidth: 148,
      bodyClassName: "text-right",
      className: "justify-content-center text-center text-wrap text-break",
      cell : row=>formatNumberAmount(row.rpClientContributionAmount, "en-US", 2, 2)
    },
    {
      Header: <span className="text-wrap">Ref#</span>,
      accessor: "refNumber",
      maxWidth: 50,
      minWidth: 50,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => (<Link to={{ pathname: `/pension-info-form`, search:`?role=${queryParams.role}`, state: { userProfile, role: queryParams.role, row, formData, formDataRef, sectionformDataRef, clientMarkups } }}>{row.pensionPlanId == 0 ? 'New' : row.pensionPlanId}</Link>)
    },


  ];

  const togleHistory = () => {
    setViewHistory(!viewHistory);

  }

  useEffect(()=>{

    if(formData.pensionApplicable === 'Yes'){
      disablePension(true); 
    }

  },[formData])
  return (
    <Card id="pension" small

      className="mb-5 w-100 d-flex personalParent">
      <span className="iconBox mr-0"><i className="fa-solid fa-umbrella iconStyle" /></span>
      <h4 className="m-0 section-title headerStyle pr-0">Retirement Plan Information <spen className="d-inline" ><FormCheckbox id="history" name="history"
        className="d-inline"
        checked={isDisabledPension} onChange={() => {
          formData.pensionApplicable = formData.pensionApplicable == 'Yes' ? 'No' :'Yes';
          disablePension(!isDisabledPension)
        }}
        toggle small></FormCheckbox></spen></h4>
      
      <Container fluid className="file-manager__filters border-bottom">
        <Row className="mb-4">

          <Col lg="10"
            className="d-flex flex-row-reverse">

            <div className='d-flex flex-column ml-3 section-card-header'>
              <spen className="header-checkbox">
                <FormCheckbox id="history" name="history"
                  checked={viewHistory} onChange={togleHistory}
                  toggle small><spen className="font-weight-bold text-center">View Retirement Plan History</spen></FormCheckbox>
              </spen>
              <spen className="font-weight-bold text-center">


              </spen>
              <spen className="font-weight-bold font-italic">Note - Highlighted in blue row is the active Retirement Plan Information.</spen>
            </div>
            <div></div>

          </Col>
          
          <Col lg="2"
            className=" pt-0 pl-0 pb-0 pr-2 file-manager__filters__search d-flex align-items-center justify-content-center justify-content-md-end col-6" >

<Button disabled={!isDisabledPension} className="my-2 py-2 pr-0 pl-0 mx-2 w-100" size='sm'
                theme="accent"
                onClick={() => { 

                  history.push({
                    pathname: `/pension-info-form`,
                    search:`?role=${queryParams.role}`,
                    state: {
                      userProfile,
                      refNum: 0,
                      role:queryParams.role,
                      formData,
                      formDataRef,
                      sectionformDataRef,
                      clientMarkups
                    },
                  });
                }}
                ><spen>Add Retirement Plan</spen></Button>


          {/*<Link className="btn-primary" to={{ pathname: `/placement-info-form`, state: { userProfile, refNum: 0 } }} disabled={userProfile.personalInfo === undefined} theme="accent" state>
            Add Compensation
              </Link>*/}
          </Col>

        </Row>
       

        <div className='scrollabe-table-container'>
          {

            (formData && formData.pensionInfos) ? 
              (<Table headers={tableColumns} rows={formData.pensionInfos} viewHistory={viewHistory} />)
             :
              (<Table headers={tableColumns} rows={[]} viewHistory={viewHistory} />)
          }
        </div>
      </Container>




    </Card>
  );
}

export default PensionPlan;
