import React, {useEffect, useState} from 'react';
import ConfigMileageRange from './ConfigMileageRange';
import ConfigLeavesTypes from './ConfigLeavesTypes';
import ConfigExpenseTypes from './ConfigExpenseTypes';
import ConfigAllowanceTypes from './ConfigAllowanceTypes';
import ConfigMsgCategories from './ConfigMsgCategories';
import ConfigPayComponent from './ConfigPayComponent';
import './Config.scss';
import {
  Container
} from "shards-react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {
  get_allowance_type_list,
  get_base_currency,
  get_expense_config_type_list,
  get_mileage_range_list,
  get_search_leave_policy,
  save_base_currency,
  save_base_distance_unit,

  get_distance_unit_list,
  get_base_distance_unit,
  sub_get_currency_list,
  get_pay_component_list,
  get_message_category_list,
  get_notification_config,
  save_notification_config,
  get_pay_element_list
} from "../../redux/actions/user";
import {clear_leave_policy_caches} from "../../redux/actions/subscriber/leavePolicy";
import Currency from "../../components/subscriber/Configuration/Currency";
import DistanceUnit from "../../components/subscriber/Configuration/DistanceUnit";
import Notification from "../../components/subscriber/Configuration/Notification";
import ConfirmAlert from "../../components/common/ConfirmAlert";
import { setAlert } from '../../redux/actions/alert';
import ConfigPayElement from "./ConfigPayElement";

function Configuration({
                         get_search_leave_policy,
                         get_mileage_range_list,
                         get_allowance_type_list,
                         get_expense_config_type_list,
                         sub_get_currency_list,
                         get_base_currency,

                         save_base_currency,
                         save_base_distance_unit,

                         get_base_distance_unit,
                         get_distance_unit_list,

                         expenseTypeList,
                         allowanceTypeList,
                         currencyList,

                         leavePolicyList,
                         mileageRangeList,
                         baseCurrency,
                         baseDistanceUnitList,

                         DistanceUnitId,
                         subscriberId,
                         clear_leave_policy_caches,
                         get_message_category_list,
                         messageCategoryList,
                         get_pay_component_list,
                         payComponentList,
                         get_notification_config,
                        save_notification_config,
                        notificationConfig,
                        setAlert,
                        get_pay_element_list,
                        payElementList,

                       }) {

  useEffect(() => {
    get_base_distance_unit();
    get_search_leave_policy();
    get_mileage_range_list();
    get_allowance_type_list();
    get_expense_config_type_list();
    sub_get_currency_list(subscriberId);
   get_distance_unit_list();

    get_base_currency();

    get_message_category_list();
    get_pay_component_list();
    get_pay_element_list();
    get_notification_config();
  }, []);

  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });

  return (
    <>
      <ConfirmAlert confirmAlert={confirmAlert}
                    setConfirmAlert={setConfirmAlert}/>
      <Container fluid className="main-content-container px-4 py-4">
        <ConfigLeavesTypes
          leavePolicyList={leavePolicyList}
          clear_leave_policy_caches={clear_leave_policy_caches}
        />
        <ConfigMileageRange
          mileageRangeList={mileageRangeList}/>
        <ConfigExpenseTypes
          expenseTypeList={expenseTypeList}/>
        {/*<ConfigAllowanceTypes allowanceTypeList={allowanceTypeList}/>*/}
          <ConfigMsgCategories
        messageCategoryList={messageCategoryList}/>

        <Currency
          confirmAlert={confirmAlert}
          setConfirmAlert={setConfirmAlert}
          save_base_currency={save_base_currency}
          subscriberId={subscriberId}
          baseCurrency={baseCurrency}
          currencyList={currencyList}/>
        <Notification
          save_notification_config={save_notification_config}
          subscriberId={subscriberId}
          notificationConfig={notificationConfig} />
           <DistanceUnit
          confirmAlert={confirmAlert}
          setConfirmAlert={setConfirmAlert}
          save_base_distance_unit={save_base_distance_unit}
          DistanceUnitId={DistanceUnitId}

          baseDistanceUnitList={baseDistanceUnitList}
          setAlert={setAlert}/>
          <ConfigPayComponent
          payComponentList={payComponentList}/>
        <ConfigPayElement
          payElementList={payElementList} />
      </Container>
    </>
  );
}

Configuration.propTypes = {
  leavePolicyList: PropTypes.array.isRequired,
  get_search_leave_policy: PropTypes.func.isRequired,
  get_mileage_range_list: PropTypes.func.isRequired,
  get_allowance_type_list: PropTypes.func.isRequired,
  get_expense_config_type_list: PropTypes.func.isRequired,
  get_distance_unit_list:PropTypes.func.isRequired,
  get_base_distance_unit:PropTypes.func.isRequired,

  expenseTypeList: PropTypes.array.isRequired,
  allowanceTypeList: PropTypes.array.isRequired,
  mileageRangeList: PropTypes.array.isRequired,
  get_message_category_list: PropTypes.func.isRequired,
  messageCategoryList: PropTypes.array.isRequired,
  payComponentList: PropTypes.array.isRequired,
  baseDistanceUnitList: PropTypes.array.isRequired,
  DistanceUnitId:PropTypes.string,
  notificationConfig: PropTypes.object.isRequired,
  payElementList: PropTypes.array.isRequired,

};
const mapStateToProps = (state) => ({
  leavePolicyList: state.user.leavePolicyList,
  mileageRangeList: state.user.mileageRangeList,
  allowanceTypeList: state.user.allowanceTypeList,
  expenseTypeList: state.user.expenseTypeList,
  currencyList: state.user.currencyList,
  baseCurrency: state.user.baseCurrency,
  subscriberId: state.user.user.userRoles.filter(item => item.role === "Subscriber Admin")[0].domainId,
  messageCategoryList: state.user.messageCategoryList,
  payComponentList: state.user.payComponentList,
  notificationConfig: state.user.notificationConfig,
  baseDistanceUnitList: state.user.baseDistanceUnitList,

  DistanceUnitId: state.user.DistanceUnitId,
  payElementList: state.user.payElementList,

});
export default connect(mapStateToProps, {
  get_search_leave_policy,
  get_mileage_range_list,
  get_allowance_type_list,
  get_expense_config_type_list,
  sub_get_currency_list,
  get_base_currency,

  get_distance_unit_list,
  get_base_distance_unit,
  save_base_currency,
  save_base_distance_unit,
  clear_leave_policy_caches,
  get_message_category_list,
  get_pay_component_list,
  get_pay_element_list,
  get_notification_config,
  save_notification_config,
  setAlert
})(Configuration);
