import React, {useEffect, useState} from 'react';
import {
  Button,
  Card, Col,
  Container, DatePicker, Form, FormInput,
  ListGroup, ListGroupItem,
  Row
} from "shards-react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {setAPIDate, setInputDate} from "../../utils/date";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {
  clear_expense_sheet, get_user_mileage_expense_ytd,
  saveReportNameAndDate, submit_user_expense_sheet_details
} from "../../redux/actions/worker/expenses";

import InfoTooltip from "../../components/common/InfoTooltip";
import {numberToMonetary} from "../../utils/general";
import ExpenseItemsCard
  from "../../components/worker/edit-expense-report/ExpenseItemsCard";
import MileageItemsCard
  from "../../components/worker/edit-expense-report/MileageItemsCard";
import {
  get_sub_expense_sheet_details,
  save_sub_expense_sheet_details
} from "../../redux/actions/subscriber/subExpenses";

function SubEditExpenseReport({
                                reportDescription,
                                reportDate,
                                subExpenseSheet,
                                userMileageExpenseYTD,
                                sheetRefNum,
                                saveReportNameAndDate,
                                get_sub_expense_sheet_details,
                                save_sub_expense_sheet_details,
                                submit_user_expense_sheet_details,
                                get_user_mileage_expense_ytd,
                                changePageMeta,
                                title
                              }) {

  var {userId, sheetRefNumber} = useParams();
  const history = useHistory();
  const location = useLocation();

  //added Worker Name in the page title once we get response from backend
  useEffect(() => {
    if(location.state)
      changePageMeta(`${title} - ${location.state.workerName}`)
  }, []);

  const [state, setState] = useState({
    date: '',
    description: '',
    totalFileSize: 0
  });

  useEffect(() => {
    get_user_mileage_expense_ytd(userId);
  }, []);

  useEffect(() => {
    if (sheetRefNum)
      history.replace(`/sub-edit-expense-reports/${userId}/${sheetRefNum}`, location.state);
  }, [sheetRefNum]);

  useEffect(() => {
    setState({
      ...state,
      description: reportDescription,
      date: reportDate
    });
  }, [reportDescription, reportDate]);

  sheetRefNumber = (sheetRefNumber && sheetRefNumber !== "0") ? sheetRefNumber : sheetRefNum

  useEffect(() => {
    if (sheetRefNumber && sheetRefNumber !== "0") {
      get_sub_expense_sheet_details(sheetRefNumber);
    }
    return () => {
      clear_expense_sheet();
    }
  }, [sheetRefNumber]);


  useEffect(() => {
    if (subExpenseSheet && Object.keys(subExpenseSheet).length !== 0 && !(reportDescription || reportDate)) {
      setState({
        ...state,
        description: subExpenseSheet.description,
        date: setAPIDate(subExpenseSheet.date),
      });
      if (subExpenseSheet.expenseItemList) {
        let fileSize = subExpenseSheet.expenseItemList.reduce((total,currentValue) => total = total + currentValue.receiptFileSize,0);
        location.state.totalFileSize = fileSize;
      }
    }
  }, [subExpenseSheet]);

  const onSubmit = (e) => {
    e.preventDefault();
    const expenseItemsListArray = [];
    const mileageItemListArray = [];
    if (subExpenseSheet.expenseItemList) {
      subExpenseSheet.expenseItemList.map((item) => expenseItemsListArray.push(item.refNum));
    }
    if (subExpenseSheet.mileageItemList) {
      subExpenseSheet.mileageItemList.map((item) => mileageItemListArray.push(item.refNumber));
    }

    if (e.nativeEvent.submitter.name === "save") {
      save_sub_expense_sheet_details(
        userId,
        sheetRefNumber,
        setInputDate(state.date),
        state.description,
        expenseItemsListArray,
        mileageItemListArray
      );
    } else {
      submit_user_expense_sheet_details(
        userId,
        sheetRefNumber,
        setInputDate(state.date),
        state.description,
        expenseItemsListArray,
        mileageItemListArray
      );
    }

    history.goBack();
  };

  const onAddExpense = () => {
    saveReportNameAndDate(state.description, state.date);
    history.push({
      pathname: `/sub-add-expense-item/${userId}/${(sheetRefNumber) ? sheetRefNumber : 0}/0`,
      state: location.state
    });
  }

  const onAddMileage = () => {
    saveReportNameAndDate(state.description, state.date);
    history.push({
      pathname: `/sub-add-mileage-item/${userId}/${(sheetRefNumber) ? sheetRefNumber : 0}/0`,
      state: location.state
    });
  }

  const onEditExpense = () => {
    saveReportNameAndDate(state.description, state.date);
  }

  const onEditMileage = () => {
    saveReportNameAndDate(state.description, state.date);
  }

  return (
    <Container fluid className="main-content-container px-4 pb-4">

      <Card small className="my-4 w-100">
        <h4
          className="m-0 section-title idStyle">Ref# {(subExpenseSheet.refNum && subExpenseSheet.refNum !== '0') ? `${subExpenseSheet.refNum}` : 0}</h4>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>
                <Form onSubmit={onSubmit} id="expenseForm">
                  <Row form>

                    <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="reportDate">Report Date</label>
                      <DatePicker
                        autoComplete='off'
                        id="reportDate"
                        required
                        name='date'
                        dateFormat='yyyy-MM-dd'
                        selected={state.date}
                        onChange={(date) =>
                          setState({...state, date: date})
                        }
                        value={state.to}
                        placeholderText="YYYY-MM-DD"
                      />
                    </Col>

                    <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="description">Report Name <InfoTooltip
                        msg="Suggested Format - Name - Expense Report - Period"/></label>
                      <FormInput
                        id="description"
                        name='description'
                        required
                        onInvalid={e => e.target.setCustomValidity("Insert Report Name")}
                        value={state.description}
                        onChange={e => {
                          e.target.setCustomValidity("")
                          setState({...state, [e.target.name]: e.target.value});
                        }}
                        placeholder="Enter Report Name"
                      />
                    </Col>

                    <Col sm="12" md="6" lg="3"
                         className="form-group p-2 mb-0 offset-3 d-flex align-items-end justify-content-end">
                      <span className="text-bold text-nowrap py-2">Expense Report Total: {numberToMonetary(subExpenseSheet.status === "Submitted" ?  subExpenseSheet.submittedReportTotal : subExpenseSheet.status === 'Draft' ? subExpenseSheet.draftReportTotal :  subExpenseSheet.approvedReportTotal)}</span>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>

      <ExpenseItemsCard
        expenseItemList={subExpenseSheet.expenseItemList}
        submittedItemTotal={subExpenseSheet.submittedItemTotal}
        draftItemTotal={subExpenseSheet.draftItemTotal}
        status={subExpenseSheet.status}
        sheetRefNumber={sheetRefNumber}
        onAddExpense={onAddExpense}
        onEditExpense={onEditExpense}
        editItemRoute={"sub-edit-expense-item/" + userId}
      />

      <MileageItemsCard
        mileageItemList={subExpenseSheet.mileageItemList}
        submittedMileageTotal={subExpenseSheet.submittedMileageTotal}
        savedYTD={userMileageExpenseYTD.savedYTD}
        submittedYTD={userMileageExpenseYTD.submittedYTD}
        approvedYTD={userMileageExpenseYTD.approvedYTD}
        reviewedYTD={userMileageExpenseYTD.reviewedYTD}
        totalYTD={userMileageExpenseYTD.totalYTD}
        sheetRefNumber={sheetRefNumber}
        status={subExpenseSheet.status}
        draftMileageTotal={subExpenseSheet.draftMileageTotal}
        onAddMileage={onAddMileage}
        onEditMileage={onEditMileage}
        editItemRoute={"sub-edit-mileage-item/" + userId}
      />

      <Row className="px-3">
        <Col sm="12" md="12" lg="12"
             className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">
          <InfoTooltip msg="The draft will be available to edit until you are ready to submit the report"/>
          <Button className="mx-1 py-2 mx-md-2" name="save" size="sm"
                  theme="accent" type="submit" form="expenseForm">Save Draft</Button>
          <Button className="mx-1 py-2 mx-md-2" name="submit" size="sm"
                  theme="accent" type="submit"
                  form="expenseForm">Submit</Button>

          <Button className="mx-1 py-2 mx-md-2" size="sm"
                  theme="accent" onClick={() => history.goBack()}>Cancel</Button>

        </Col>
      </Row>
    </Container>
  );
}

SubEditExpenseReport.propTypes = {
  user: PropTypes.object.isRequired,
  subExpenseSheet: PropTypes.object.isRequired,
  get_user_expense_sheet_details: PropTypes.func.isRequired,
  save_user_expense_sheet_details: PropTypes.func.isRequired,
  submit_user_expense_sheet_details: PropTypes.func.isRequired,
  withdraw_user_expense_sheet_details: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  reportDescription: state.expenses.reportDescription,
  reportDate: state.expenses.reportDate,
  subExpenseSheet: state.subExpenses.subExpenseSheet,
  sheetRefNum: state.subExpenses.sheetRefNum,
  userMileageExpenseYTD: state.expenses.userMileageExpenseYTD,
});
export default connect(mapStateToProps, {
  get_sub_expense_sheet_details,
  save_sub_expense_sheet_details,
  get_user_mileage_expense_ytd,
  submit_user_expense_sheet_details,
  saveReportNameAndDate,
})(SubEditExpenseReport);

