import React, {useEffect, useState, setState } from 'react';
import classNames from "classnames";
import {
  InputGroup,
  DatePicker,
  InputGroupAddon,
  InputGroupText
} from "shards-react";


import Select from 'react-select'
import {
  Button, ListGroupItem, Dropdown,
 
  FormCheckbox,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  NavLink,
  NavItem,
  ListGroup,
  Form,
  Card, CardBody,
  CardHeader, Col,
  Container,
  FormInput,
  FormSelect, Row
} from "shards-react";

import {

  get_region_list_report,
  get_client_list_report,
  get_country_list_report,
  get_employment_status_list_report,
  get_sales_consultant_list_report,
  get_hr_Consultant_list_report,
  get_leave_type_list_report,
  get_reports_name_list,
  get_reports_period_list,
  get_reports_status_list,
  generate_report,
  get_app_modules,
  get_app_modules_clear,
  download_report,
  get_fields_report,
  get_worker_list_report,
  get_worker_details,
  report_advance_filters,
  generate_benefits_report,



} from "../../redux/actions/user";
import {setDateForTable, setDateForTableInHrMinSec, setInputDate} from "../../utils/date";
import { getStatusClass,numberToMonetary,getLeaveBalanceClass } from "../../utils/general";
import ReactTable from "react-table";
import { render } from 'react-dom';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {setAlert} from "../../redux/actions/alert";



import InfoTooltip from "../../components/common/InfoTooltip";
import "react-table/react-table.css";
import {Link, useLocation} from "react-router-dom";
function SubscriberReports({
  
  get_country_list_report,
  get_fields_report,
  get_client_list_report,
  get_region_list_report,
  get_employment_status_list_report,
  get_sales_consultant_list_report,
  get_hr_Consultant_list_report,
  get_leave_type_list_report,
  get_reports_name_list,
  get_reports_period_list,
  get_reports_status_list,
  generate_report,
  report_advance_filters,
  download_report,
  get_app_modules,
  get_app_modules_clear,
  get_worker_list_report,
  clientListReport,
  workerListReport,
  countryListReport,
  salesConsultantListReport,
  hrConsultantListReport,
  employmentStatusListReport,
  regionListReport,
  leaveTypeListReport,
  reportsNameList,
  availableFields,
  reportsPeriodList,
  reportsStatusList,
  reportList,
  appModules,
  setAlert,
  get_worker_details,
  workerDetailsReport,
  workerBenefitsReport,
  generate_benefits_report
}) {



  const { className } = "";
  const classes = classNames(className, "d-flex", "my-auto", "date-range");

  const [checked, setChecked] = useState([]);
  const location = useLocation();
  const [formData, setFormData] = useState({
    showAdvanceFilter: false,
    clientId: [],
    employmentStatus: [{ value: "Active", label: "Active" }, { value: "On Leave", label: "On Leave" }],
    hrConsultantId: [],
    salesConsultantId: [],
    moduleName: '',
    reportName: '',
    regionId: [],
    search: '',
    countryId: 1,
    workerId: [],
    period: '',
    from: '',
    to: '',
    leavePolicyId: [],
    status: [],
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
  });
  const [state, setState] = useState({
    show2FA: false,
    visible: false
  });
  var { pageSize, pageSizeOptions } = formData;
  const [search, setSearch] = React.useState('');

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  let onStartDateChanged = (value) => {
    setFormData({
      ...formData,
      from: value
    })
  }

  let onEndDateChanged = (value) => {
    setFormData({
      ...formData,
      to: value
    })
  }
  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });

  // const getWorkerReportFieldNames = () => {
  //   return [
  //     {
  //       accessor: "legalFirstName",
  //       "isDefault": false,
  //       "order": 1,
  //       Header: "First Name",
  //       className: "justify-content-start text-left text-wrap",
  //     },
  //     {
  //       accessor: "legalMiddleName",
  //       "isDefault": false,
  //       "order": 2,
  //       Header: "Middle Name",
  //       className: "justify-content-start text-left text-wrap",
  //     },
  //     {
  //       accessor: "legalLastName",
  //       "isDefault": false,
  //       "order": 3,
  //       Header: "Last Name",
  //       className: "justify-content-start text-left text-wrap",
  //     },
  //     {
  //       accessor: "clientName",
  //       "isDefault": false,
  //       "order": 4,
  //       Header: "Client Name",
  //       className: "justify-content-start text-left text-wrap",
  //     },
  //     {
  //       accessor: "employmentStatus",
  //       "isDefault": false,
  //       "order": 5,
  //       Header: "Employment Status",
  //       className: "justify-content-start text-left text-wrap",
  //     },
  //     {
  //       accessor: "hireDate",
  //       "isDefault": false,
  //       "order": 6,
  //       Header: "Hire Date",
  //       className: "justify-content-start text-left text-wrap",
  //       Cell: row => setDateForTable(row.original.hireDate),
  //     },
  //     {
  //       accessor: "jobTitle",
  //       "isDefault": false,
  //       "order": 7,
  //       Header: "Job Title",
  //       className: "justify-content-start text-left text-wrap",
  //     },
  //     {
  //       accessor: "payrollId",
  //       "isDefault": false,
  //       "order": 8,
  //       Header: "Payroll Id",
  //       className: "justify-content-start text-left text-wrap",
  //     },
  //     {
  //       accessor: "hrConsultant",
  //       "isDefault": false,
  //       "order": 9,
  //       Header: "HR Consultant",
  //       className: "justify-content-start text-left text-wrap",
  //     }


  //   ]
  // }
  useEffect(() => {
    if (formData.countryId) {
      get_region_list_report(formData.countryId)
    }
  }, [formData.countryId])

  useEffect(() => {
    get_app_modules();
    get_app_modules_clear();
    get_client_list_report();
    get_country_list_report();

    get_employment_status_list_report();
    get_sales_consultant_list_report();
    get_hr_Consultant_list_report();

    
    get_reports_period_list();
    get_reports_status_list();



  }, []);

  const cancelConfirmAlert = () => {
    setConfirmAlert({
      ...confirmAlert,
      visible: false
    })
  }

  useEffect(() => {
    // if (formData.moduleName && formData.moduleName !== 'Worker Data') {

    //   get_reports_name_list(formData.moduleName);
    // }
    get_reports_name_list(formData.moduleName);
  }, [formData.moduleName]);

  useEffect(() => {

    if (formData.period != '') {
      if (formData.period === "This Month") {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setFormData({
          ...formData,
          from: firstDay,
          to: lastDay
        })
      } else if (formData.period === "Last Month") {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        setFormData({
          ...formData,
          from: firstDay,
          to: lastDay
        })
      }
      else if (formData.period === "This Year") {
        const currentYear = new Date().getFullYear();

const january = 0;
const firstDay = new Date(currentYear, january, 1);


const december = 11;
const lastDay = new Date(currentYear, december, 31);

setFormData({
  ...formData,
  from: firstDay,
  to:lastDay
})

}else if(formData.period=="Last Year")
{
  const currentYear = new Date().getFullYear()-1;

const january = 0;
const firstDay = new Date(currentYear, january, 1);


const december = 11;
const lastDay = new Date(currentYear, december, 31);

setFormData({
  ...formData,
  from: firstDay,
  to:lastDay
})
}else{


}
    }
  }, [formData.period]);


  useEffect(() => {
    

      if (formData.reportName === "Leave Requests" || formData.reportName == "Leave Balances") {
        setFormData({
          ...formData,
          period: "This Year"
        })
        

      } else {
        setFormData({
          ...formData,
          period: "",
          from: "",
          to: ""
        })
      }

      availableFields.forEach(element => {
       
          element.isDefault = false;
      
      });
      setState({
        checked: []
      });
    
    var updatedList = [...checked];
      setChecked(updatedList);
      if(formData.reportName && formData.reportName !== ''){
        get_fields_report(formData.reportName);

      }
    
    if (formData.reportName && (formData.reportName === 'Worker details' || formData.reportName === 'Benefits Data')) {
      setFormData({
        ...formData,
        showAdvanceFilter: false
      })
    }

  }, [formData.reportName]);

  useEffect(() => {
    if (formData.clientId && formData.clientId.length > 0) {
      var newArr = [];
      formData.clientId.forEach(element => {
        newArr.push(element.value);
      });
      get_leave_type_list_report(String(newArr));
      get_worker_list_report(String(newArr));
    }

  }, [formData.clientId]);
  const availableFieldsColumns = availableFields.sort((a, b) => a.order - b.order).filter(i => i.isDefault === true).map((option) => {

    if (option.name === "status") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "text-center",
        Cell: row =>
        <span
          className={getStatusClass(row.original.status)}>{row.original.status}</span>
      };

    } else if (option.name === "from") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-start text-left text-wrap",
        Cell: row => setDateForTable(row.original.from),
      };

    } else if (option.name === "to") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-start text-left text-wrap",
        Cell: row => setDateForTable(row.original.to),
      };

    } else if (option.name === "resetDate") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-start text-left text-wrap",
        Cell: row => setDateForTable(row.original.resetDate),
      };

    }
    else if (option.name === "refNum") {
      if (formData.reportName === "Leave Requests") {

        return {
          Header: option.header,
          accessor: option.name,
          maxWidth: 80,
          className: "justify-content-start text-left text-wrap text-break",
          Cell: row => <a href={"/sub-approve-leave/" + row.original.refNum}>{row.original.refNum}</a>,
        };

      } else {
        return {
          Header: option.header,
          accessor: option.name,
          className: "justify-content-start text-left text-wrap",

        };
      }


    } else if (option.name === "submittedOn") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-start text-left text-wrap",
        maxWidth: 155,
        minWidth: 155,
        Cell: row => setDateForTableInHrMinSec(row.original.submittedOn),

      };

    } else if (option.name === "dateOfJoining") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-start text-left text-wrap",
        Cell: row => setDateForTable(row.original.dateOfJoining),
        
      };

    } else if (option.name === "withdrawnOn") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-start text-left text-wrap",
        maxWidth: 155,
        minWidth: 155,
        Cell: row => setDateForTableInHrMinSec(row.original.withdrawnOn),
        
      };

    } else if (option.name === "rejectOn") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-start text-left text-wrap",
        maxWidth: 155,
        minWidth: 155,
        Cell: row => setDateForTableInHrMinSec(row.original.rejectOn),
        
      };

    } else if (option.name === "approvedOn") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-start text-left text-wrap",
        maxWidth: 155,
        minWidth: 155,
        Cell: row => setDateForTableInHrMinSec(row.original.approvedOn),
        
      };

    } else if (option.name === "timeOffDays") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-end text-wrap text-break",
        Cell: row => numberToMonetary(row.original.timeOffDays)
      };

    } else if (option.name === "approved") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-end",
        maxWidth: 80,
        Cell: row => numberToMonetary(row.original.approved)
      };

    } else if (option.name === "balance") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-end",
        maxWidth: 80,
        Cell: row =>
        <span
          className={getLeaveBalanceClass(row.original.balance)}>{numberToMonetary(row.original.balance)}</span>
      
      };

    } else if (option.name === "carryForward") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-end",
        maxWidth: 80,
        Cell: row => numberToMonetary(row.original.carryForward)
      };

    } else if (option.name === "entitled") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-end",
        maxWidth: 80,
       Cell: row => numberToMonetary(row.original.entitled)
      };

    } else if (option.name === "lapsed") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-end",
        maxWidth: 80,
       Cell: row => numberToMonetary(row.original.lapsed)
      };

    } else if (option.name === "submitted") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-end",
        maxWidth: 80,
       Cell: row => numberToMonetary(row.original.submitted)
      };

    } else if (option.name === "yearsOfService") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-end",
        maxWidth: 80,
       Cell: row => numberToMonetary(row.original.yearsOfService)
      };

    } else if (option.name === "unitOfLeave") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-end",
        maxWidth: 80,
       Cell: row => numberToMonetary(row.original.unitOfLeave)
      };

    }
    else if (option.name === "userId") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-center text-center text-wrap",
        Cell: row =>
        <Link
          to={{pathname: `/manage-user-profile/${row.original.userId}`, search: `?role=Worker`, state:{goBackPath:location.pathname}}} >{row.original.userId}</Link>
      };

    }
    else if (option.name === "legalFirstName") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-start text-left text-wrap",
        
      };

    }
    else if (option.name === "legalMiddleName") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-start text-left text-wrap",
        
      };

    }
    else if (option.name === "legalLastName") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-start text-left text-wrap",
        
      };

    }
    else if (option.name === "clientName" && formData.reportName != 'Benefits Data') {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-start text-left text-wrap",
        
      };

    }
    else if (option.name === "employmentStatus") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-center text-center",
        
        Cell: row =>
        <span
          className={getStatusClass(row.original.employmentStatus)}>{row.original.employmentStatus}</span>
      };

    }
    else if (option.name === "hireDate") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-start text-left text-wrap",
        
        Cell: row => setDateForTable(row.original.hireDate),
      };

    }
    else if (option.name === "jobTitle") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-start text-left text-wrap",
        
      };

    }
    else if (option.name === "payrollId") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-start text-left text-wrap",
        
      };

    }
    else if (option.name === "hrConsultant") {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-start text-left text-wrap",
        
      };

    }

    // Added by nipul --> For setting up the columns in table and formatting them
    else if (option.name === "userName" || option.name === "subscriberId" || option.name === "clientId"
      || option.name === "clientName" || option.name === "ceridianId" || option.name === "benefitsClass"
      || option.name === "benefitsType"
    ) {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-center text-center",
        Cell: row => <div title={row.original[option.name]}>{row.original[option.name]}</div>
      };
    }
    else if (option.name === "clientPremiumSplit" || option.name === "workerPremiumSplit"
        || option.name === "clientAmount" || option.name === "workerAmount" || option.name === "health"
        || option.name === "ltd" || option.name === "lifeDepAd"
    ) {
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-end",
        maxWidth: 100,
        Cell: row => numberToMonetary(row.original[option.name])
      };
    }
    else if (option.name === "benefitsCertificateNo"){
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-end",
        maxWidth: 100,
        Cell: row => <div title={row.original[option.name]}>{row.original[option.name]}</div>
      };
    }
    /// -------------------------------------------------------------------------

    else{
      
      return {
        Header: option.header,
        accessor: option.name,
        className: "justify-content-start text-left text-wrap",
        
      };
    }
    

  });
  

  var clientListReportOptions = clientListReport.map((option) => {
    return { value: option.clientId, label: option.clientName, key: option.clientName };

  });
  var countryListReportOption = countryListReport.map((option) => {
    return <option key={option.countryId}
      value={option.countryId}>{option.countryName}</option>;
  })
  var salesConsultantListReportOption = salesConsultantListReport && salesConsultantListReport.map((option) => {
    return { value: option.userId, label: option.userName, ket: option.userName };

  });

  var hrConsultantListReportOption = hrConsultantListReport && hrConsultantListReport.map((option) => {
    return { value: option, label: option, key: option };

  });

  var employmentStatusListReportOption = employmentStatusListReport && employmentStatusListReport.filter(i => i != "Internal Staff").map((status) => {
    return { value: status, label: status, key: status };

  });
  var regionListReportOption = regionListReport.map((option) => {
    return { value: option.regionId, label: option.regionName, key: option.regionName };

  })
  var leaveTypeListReportOptions = leaveTypeListReport.map((option) => {
    return { value: option.leavePolicyId, label: option.policyName, key: option.policyName };

  });
  var appModulesOptions = appModules.map((option) => {
    return <option key={option} value={option}>{option}</option>;
  });

  // if (!reportsNameList.includes('Worker details')) {
  //   reportsNameList.push('Worker details');
  // }

  var reportsNameListOptions = reportsNameList && reportsNameList.map((option) => {
    return <option key={option} value={option}>{option}</option>;
  });
  var workerListReportOptions = workerListReport.map((option) => {
    return { value: option.userId, label: option.name, key: option };

  });
  var reportsPeriodListOptions = reportsPeriodList.map((option) => {
    return <option key={option} value={option}>{option}</option>;
  });
  var reportsStatusListOptions = reportsStatusList.map((option) => {
    return { value: option, label: option, key: option };

  });
  const toggleUserActions = (e) => {
    setState({
      visible: !state.visible
    });
  };
  const GenerateReports = (e) => {

    if (!formData.moduleName || formData.moduleName == "") {
      setAlert("Please select Module", "danger");
    } else
      if (!formData.reportName || formData.reportName == "") {
        setAlert("Please select Report Name", "danger");
      }
      else {
        e.preventDefault();
        //generate_report(formData.moduleName, formData.reportName);
        //if(formData.moduleName = "Worker details"){
        if (formData.moduleName === 'Worker Data' && formData.reportName === 'Worker details') {
          //availableFields = getWorkerReportFieldNames();
          get_worker_details();
          return;
        }

        if (formData.moduleName === 'Worker Data' && formData.reportName === 'Benefits Data') {
          generate_benefits_report();
          return;
        }
        var clientIds = [];
        formData.clientId.forEach(element => {
          clientIds.push(element.value);
        });
        var regionIds = [];
        formData.regionId.forEach(element => {
          regionIds.push(element.value);
        });
        var workerIds = [];
        formData.workerId.forEach(element => {
          workerIds.push(element.value);
        });
        var employmentStatuss = [];
        formData.employmentStatus.forEach(element => {
          employmentStatuss.push(element.value);
        });
        var hrConsultantIds = [];
        formData.hrConsultantId.forEach(element => {
          hrConsultantIds.push(element.value);
        });
        var salesConsultantIds = [];
        formData.salesConsultantId.forEach(element => {
          salesConsultantIds.push(element.value);
        });
        var leavePolicyIds = [];
        formData.leavePolicyId.forEach(element => {
          leavePolicyIds.push(element.value);
        });
        var statuss = [];
        formData.status.forEach(element => {
          statuss.push(element.value);
        });

        report_advance_filters(formData.moduleName, formData.reportName, String(clientIds), formData.countryId, String(regionIds), String(workerIds), String(employmentStatuss), formData.period, setInputDate(formData.from), setInputDate(formData.to), String(hrConsultantIds), String(salesConsultantIds), String(leavePolicyIds), String(statuss));

      }
  };
  const downloadReports = (e) => {
   
    if(!formData.moduleName || formData.moduleName == "")
    {
       setAlert("Please select Module","danger");
    }else
    if(!formData.reportName || formData.reportName == "")
    {
       setAlert("Please select Report Name","danger");
    }
    else{
    e.preventDefault();
    var newArr = [];
    availableFields.forEach(element => {
      if (element.isDefault) {

        newArr.push(element.name);
      }



    });
    var clientIds = [];
    formData.clientId.forEach(element => {
      clientIds.push(element.value);
    });
    var regionIds = [];
    formData.regionId.forEach(element => {
      regionIds.push(element.value);
    });
    var workerIds = [];
    formData.workerId.forEach(element => {
      workerIds.push(element.value);
    });
    var employmentStatuss = [];
    formData.employmentStatus.forEach(element => {
      employmentStatuss.push(element.value);
    });
    var hrConsultantIds = [];
    formData.hrConsultantId.forEach(element => {
      hrConsultantIds.push(element.value);
    });
    var salesConsultantIds = [];
    formData.salesConsultantId.forEach(element => {
      salesConsultantIds.push(element.value);
    });
    var leavePolicyIds = [];
    formData.leavePolicyId.forEach(element => {
      leavePolicyIds.push(element.value);
    });
    var statuss = [];
    formData.status.forEach(element => {
      statuss.push(element.value);
    });
    var data = {
      "reportName": formData.reportName,
      "clientIdList": String(clientIds),
      "countryId": formData.countryId,
      "regionIdList": String(regionIds),
      "workerIdList": String(workerIds),
      "employmentStatusList": String(employmentStatuss),
      "period": formData.period,
      "from": setInputDate(formData.from),
      "to": setInputDate(formData.to),
      "hrConsultantIdList": String(hrConsultantIds),
      "salesConsultantIdList": String(salesConsultantIds),
      "leavePolicyIdList": String(leavePolicyIds),
      "statusList": String(statuss),
      "fields": newArr
    };
    download_report(data);
  }
  };

  const data = reportList.filter((item) => item.subscriberName.toLowerCase().includes(search) || 
    item.workerName.toLowerCase().includes(search)||
    item.hrConsultant.toLowerCase().includes(search)||
    item.leaveType.toLowerCase().includes(search)||
    item.clientName.toLowerCase().includes(search)||
    item.employmentStatus.toLowerCase().includes(search)||
    item.userId==search
  );

  const workerData = workerDetailsReport.filter((item)=>{
    return String(item.userId) === search  ||
      item.legalFirstName.toLowerCase().includes(search) || 
      item.legalMiddleName.toLowerCase().includes(search)||
      item.legalLastName.toLowerCase().includes(search)||
      item.clientName.toLowerCase().includes(search)||
      item.employmentStatus.toLowerCase().includes(search)||
      setDateForTable(item.hireDate) === search ||
      item.jobTitle.toLowerCase().includes(search)||
      item.hrConsultant.toLowerCase().includes(search)||
      item.payrollId==search
  })

  const workerBenefitData = workerBenefitsReport.filter((x) => {
    return (String(x.subscriberId)).includes(search) || (String(x.userId)).includes(search) 
      || (String(x.userName)).toLowerCase().includes((search)) 
      || (String(x.clientId)).includes(search) 
      || (String(x.clientName)).toLowerCase().includes(search) 
      || String(x.ceridianId).toLowerCase().includes(search) 
      || x.benefitsClass.toLowerCase().includes(search) 
      || x.benefitsType.toLowerCase().includes(search) 
      || (String(x.benefitsCertificateNo)).toLowerCase().includes(search) 
      || (String(x.clientPremiumSplit)).includes(search) 
      || (String(x.workerPremiumSplit)).includes(search) 
      || (String(x.clientAmount)).includes(search) 
      || (String(x.workerAmount)).includes(search) 
      || (String(x.health)).includes(search) || x.ltd.includes(search)
      || (String(x.lifeDepAd)).includes(search) 
  })

  // const AdvanceFilter = (e) => {
  //   e.preventDefault();
  //   var clientIds = [];
  //   formData.clientId.forEach(element => {
  //     clientIds.push(element.value);
  //   });
  //   var regionIds = [];
  //   formData.regionId.forEach(element => {
  //     regionIds.push(element.value);
  //   });
  //   var workerIds = [];
  //   formData.workerId.forEach(element => {
  //     workerIds.push(element.value);
  //   });
  //   var employmentStatuss = [];
  //   formData.employmentStatus.forEach(element => {
  //     employmentStatuss.push(element.value);
  //   });
  //   var hrConsultantIds = [];
  //   formData.hrConsultantId.forEach(element => {
  //     hrConsultantIds.push(element.value);
  //   });
  //   var salesConsultantIds = [];
  //   formData.salesConsultantId.forEach(element => {
  //     salesConsultantIds.push(element.value);
  //   });
  //   var leavePolicyIds = [];
  //   formData.leavePolicyId.forEach(element => {
  //     leavePolicyIds.push(element.value);
  //   });
  //   var statuss = [];
  //   formData.status.forEach(element => {
  //     statuss.push(element.value);
  //   });
    
  //   report_advance_filters(formData.moduleName, formData.reportName, String(clientIds), formData.countryId, String(regionIds),String(workerIds), String(employmentStatuss), formData.period, setInputDate(formData.from), setInputDate(formData.to), String(hrConsultantIds), String(salesConsultantIds), String(leavePolicyIds), String(statuss));
  // };
  const Search = (e) => {
    setSearch((formData.search).toLowerCase());
  //   if(!formData.moduleName || formData.moduleName == "")
  //   {
  //      setAlert("Please select Module","danger");
  //   }else
  //   if(!formData.reportName || formData.reportName == "")
  //   {
  //      setAlert("Please select Report Name","danger");
  //   }
  //   else{
  //   e.preventDefault();
  //   var clientIds = [];
  //   formData.clientId.forEach(element => {
  //     clientIds.push(element.value);
  //   });
  //   var regionIds = [];
  //   formData.regionId.forEach(element => {
  //     regionIds.push(element.value);
  //   });
  //   var workerIds = [];
  //   formData.workerId.forEach(element => {
  //     workerIds.push(element.value);
  //   });
  //   var employmentStatuss = [];
  //   formData.employmentStatus.forEach(element => {
  //     employmentStatuss.push(element.value);
  //   });
  //   var hrConsultantIds = [];
  //   formData.hrConsultantId.forEach(element => {
  //     hrConsultantIds.push(element.value);
  //   });
  //   var salesConsultantIds = [];
  //   formData.salesConsultantId.forEach(element => {
  //     salesConsultantIds.push(element.value);
  //   });
  //   var leavePolicyIds = [];
  //   formData.leavePolicyId.forEach(element => {
  //     leavePolicyIds.push(element.value);
  //   });
  //   var statuss = [];
  //   formData.status.forEach(element => {
  //     statuss.push(element.value);
  //   });
  //   report_advance_filters(formData.moduleName, formData.reportName, String(clientIds), formData.countryId, String(regionIds),String(workerIds), String(employmentStatuss), formData.period, setInputDate(formData.from), setInputDate(formData.to), String(hrConsultantIds), String(salesConsultantIds), String(leavePolicyIds), String(statuss), formData.search);
  //   //report_advance_filters(formData.moduleName, formData.reportName, String(formData.clientId), formData.countryId, String(formData.regionId), formData.workerId, String(formData.employmentStatus), formData.period, formData.from, formData.to, String(formData.hrConsultantId), String(formData.salesConsultantId), String(formData.leavePolicyId), String(formData.status), formData.search);
  // }

};
  const clear_search = (e) => {
    setSearch('');
    setFormData({
           ...formData,
           search: ""
        })
  //   if(!formData.moduleName || formData.moduleName == "")
  //   {
  //      setAlert("Please select Module","danger");
  //   }else
  //   if(!formData.reportName || formData.reportName == "")
  //   {
  //      setAlert("Please select Report Name","danger");
  //   }
  //   else{
  //   setFormData({
  //     ...formData,
  //     search: ""
  //   })
  //   e.preventDefault();
  //   var clientIds = [];
  //   formData.clientId.forEach(element => {
  //     clientIds.push(element.value);
  //   });
  //   var regionIds = [];
  //   formData.regionId.forEach(element => {
  //     regionIds.push(element.value);
  //   });
  //   var workerIds = [];
  //   formData.workerId.forEach(element => {
  //     workerIds.push(element.value);
  //   });
  //   var employmentStatuss = [];
  //   formData.employmentStatus.forEach(element => {
  //     employmentStatuss.push(element.value);
  //   });
  //   var hrConsultantIds = [];
  //   formData.hrConsultantId.forEach(element => {
  //     hrConsultantIds.push(element.value);
  //   });
  //   var salesConsultantIds = [];
  //   formData.salesConsultantId.forEach(element => {
  //     salesConsultantIds.push(element.value);
  //   });
  //   var leavePolicyIds = [];
  //   formData.leavePolicyId.forEach(element => {
  //     leavePolicyIds.push(element.value);
  //   });
  //   var statuss = [];
  //   formData.status.forEach(element => {
  //     statuss.push(element.value);
  //   });
  //   report_advance_filters(formData.moduleName, formData.reportName, String(clientIds), formData.countryId, String(regionIds),String(workerIds), String(employmentStatuss), formData.period, setInputDate(formData.from), setInputDate(formData.to), String(hrConsultantIds), String(salesConsultantIds), String(leavePolicyIds), String(statuss));
  //  // report_advance_filters(formData.moduleName, formData.reportName, String(formData.clientId), formData.countryId, String(formData.regionId), formData.workerId, String(formData.employmentStatus), formData.period, formData.from, formData.to, String(formData.hrConsultantId), String(formData.salesConsultantId), String(formData.leavePolicyId), String(formData.status), formData.search);
  // }
};
  
  const Reset_AdvanceFilters = (e) => {
    if(!formData.moduleName || formData.moduleName == "")
    {
       setAlert("Please select Module","danger");
    }else
    if(!formData.reportName || formData.reportName == "")
    {
       setAlert("Please select Report Name","danger");
    }
    else{
      const currentYear = new Date().getFullYear();

        const january = 0;
        var firstDay = new Date(currentYear, january, 1);


        const december = 11;
        var lastDay = new Date(currentYear, december, 31);
        var periodvalue = "";
        if (formData.reportName === "Leave Requests" || formData.reportName === "Leave Balances") {
          periodvalue = "This Year";

}else{
  firstDay="";
  lastDay="";
}
    setFormData({
      ...formData,
      clientId: [],
      regionId:[],
      employmentStatus: [{ value: "Active", label: "Active" }, { value: "On Leave", label: "On Leave" }],
      hrConsultantId: [],
      salesConsultantId: [],      
      countryId: 1,
      workerId: [],
      period: periodvalue,      
      leavePolicyId: [],
      status: [],
    });
    var employmentStatuss = [];
    var statuses=[{ value: "Active", label: "Active" }, { value: "On Leave", label: "On Leave" }];
    statuses.forEach(element => {
      employmentStatuss.push(element.value);
    });
    e.preventDefault();
    report_advance_filters(formData.moduleName, formData.reportName, String([]), 1, String([]), String([]), String(employmentStatuss), periodvalue, setInputDate(firstDay), setInputDate(lastDay), String([]), String([]), String([]), String([]));
   } };
  const onClientChange = (e) => {    
    setFormData({
      ...formData,
      clientId: e
    })
  };

  const onRegionChange = (e) => {

    var newArr = [];
    e.forEach(element => {
      newArr.push(element.value);
    });
    setFormData({
      ...formData,
      regionId: e
    })
  };
  const onWorkerChange = (e) => {

    var newArr = [];
    e.forEach(element => {
      newArr.push(element.value);
    });
    setFormData({
      ...formData,
      workerId: e
    })
  };
  const onHrConsultantChange = (e) => {

    var newArr = [];
    e.forEach(element => {
      newArr.push(element.value);
    });
    setFormData({
      ...formData,
      hrConsultantId: e
    })
  };
  const onSalesConsultantChange = (e) => {

    var newArr = [];
    e.forEach(element => {
      newArr.push(element.value);
    });
    setFormData({
      ...formData,
      salesConsultantId: e
    })
  };
  const onEmploymentStatusChange = (e) => {

    var newArr = [];
    e.forEach(element => {
      newArr.push(element.value);
    });
    setFormData({
      ...formData,
      employmentStatus: e
    })
  };
  const onLeaveTypeChange = (e) => {

    var newArr = [];
    e.forEach(element => {
      newArr.push(element.value);
    });
    setFormData({
      ...formData,
      leavePolicyId: e
    })
  };
  const onLeaveStatusChange = (e) => {

    var newArr = [];
    e.forEach(element => {
      newArr.push(element.value);
    });
    setFormData({
      ...formData,
      status: e
    })
  };

  
  const handleCheck = (event) => {
   
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
      availableFields.forEach(element => {
        if (element.name === event.target.value) {
          element.isDefault = true;
        }
      });
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
      availableFields.forEach(element => {
        if (element.name === event.target.value) {

          element.isDefault = false;
        }
      });
    }
    setChecked(updatedList);
  };
  return (
    <>

      <Container fluid className="main-content-container p-4">
        <Form>
          <div className="position-relative">
            <div className="tableHeader d-flex">
              <label className="text-bold m-0 mx-1" htmlFor="allowLeaveEntitlementRules">Generate Reports
              </label>
            </div>
            <div className="position-relative">
              <Card small className="mb-5 w-100 p-1">
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Row>
                      <Card small className="mb-2 w-100">
                        <ListGroup flush>
                          <ListGroupItem className="p-3">
                           
                                <Row form className={"report"}>
                                  <Col sm="12" md="6" lg="3"
                                    className="form-group pl-2 pt-2 pb-2 pr-0 m-0">
                                    <label htmlFor="moduleName">Module <span aria-hidden="true" className="required">*</span>
                                    </label>

                                <FormSelect id="moduleName"
                                  name='moduleName'


                                  onChange={onChange}>
                                  <option value=''>Select Module</option>
                                  {appModulesOptions}
                                </FormSelect>
                              </Col>

                                  <Col sm="12" md="6" lg="3"
                                    className="form-group p-2 m-0">
                                    <label htmlFor="ReportName">Report Name <span aria-hidden="true" className="required">*</span></label>

                                    <FormSelect
                                      id="reportName"
                                      name='reportName'
                                      
                                      onChange={onChange}>
                                      <option value=''>Select Report Name</option>
                                      {reportsNameListOptions}

                                    </FormSelect>

                                  </Col>
                                  <Col sm="12" md="6" lg="3"></Col>

                                  <Col sm="12" md="6" lg="3"
                                    className="form-group p-2 m-0 mt-2 file-manager__filters__search d-flex justify-content-center justify-content-md-end">

                                <Button className="mt-4" size="sm"
                                  theme="accent" type="button" onClick={GenerateReports}
                                >Generate Reports</Button>
                                <Button disabled={formData.reportName === 'Worker details' || formData.reportName === 'Benefits Data'} className="mt-4 ml-2 pl-1 pr-1" size="sm"
                                  theme="accent" onClick={e => { setFormData({ ...formData, showAdvanceFilter: !formData.showAdvanceFilter }) }}
                                ><i className={`twofa float-right ${formData.showAdvanceFilter ? "fas fa-angle-double-up" : "fas fa-angle-double-down"} btn-accent`} aria-hidden="true" />
                                </Button>

                                  </Col>



                                </Row>
                             
                          </ListGroupItem>
                        </ListGroup>
                      </Card>


                      {formData.showAdvanceFilter &&
                        <Card small className="mb-2 w-100">
                          <ListGroup flush>
                            <ListGroupItem className="p-3">
                              <Row>
                                <Col>
                                  <Row>
                                    <label className="text-bold m-0 mx-3" htmlFor="advanceFilters" style={{ color: "#0071bc" }}>Advance Filters
                                    </label>
                                  </Row>
                                  <Row form>

                                    <Col sm="12" md="6" lg="3"
                                      className="form-group p-2 m-0">
                                      <label htmlFor="Client">Client
                                      </label>
                                      <Select placeholder="All Clients"
                                       isClearable={false}
                                        id="clientId"
                                        name='clientId'
                                        onChange={onClientChange}
                                        value={formData.clientId}                                       
                                        isMulti
                                        options={clientListReportOptions}
                                      />


                                    </Col>

                                    <Col sm="12" md="6" lg="3"
                                      className="form-group p-2 m-0">
                                      <label htmlFor="Country">Country </label>

                                      <FormSelect
                                        id="countryId"
                                        name='countryId'
                                        defaultValue="Canada"
                                        onChange={onChange}
                                        value={formData.countryId}> 
                                        <option value="0">All Countries</option>
                                        {countryListReportOption}

                                      </FormSelect>
                                    </Col>
                                    <Col sm="12" md="6" lg="3"
                                      className="form-group p-2 m-0">
                                      <label htmlFor="Province">State/Province </label>
                                      <Select
                                        placeholder="All State/Provinces"
                                        isClearable={false}
                                        id="regionId"
                                        name='regionId'
                                        value={formData.regionId}   
                                        onChange={onRegionChange}
                                        isMulti
                                        options={regionListReportOption}
                                      />

                                    </Col>
                                    <Col sm="12" md="6" lg="3"
                                      className="form-group p-2 m-0">
                                      <label htmlFor="Worker">Worker </label>
                                      <Select
                                        placeholder="All Workers"
                                        isClearable={false}
                                        id="workerId"
                                        name='workerId'
                                        value={formData.workerId} 
                                        onChange={onWorkerChange}
                                        isMulti
                                        options={workerListReportOptions}
                                      />

                                    </Col>
                                    <Col sm="12" md="6" lg="3"
                                      className="form-group p-2 m-0">
                                      <label htmlFor="EmploymentStatus">Employment Status </label>
                                      <InfoTooltip
                                        msg="Reports are filtered for Active and On Leave workers by default" />
                                      <Select placeholder="All Employment Status"
                                        id="employmentStatus"
                                        name='employmentStatus'
                                        isClearable={false}
                                        value={formData.employmentStatus} 
                                        defaultValue={formData.employmentStatus}
                                        onChange={onEmploymentStatusChange}
                                        isMulti
                                        options={employmentStatusListReportOption} />

                                    </Col>
                                    <Col sm="12" md="6" lg="3"
                                      className="form-group p-2 m-0">
                                      <label htmlFor="reportPeriod">Report Period </label>
                                      <InfoTooltip
                                        msg="Select Custom to create a custom Date Range for your report" />
                                      <FormSelect id="period"
                                        name='period'
                                        value={formData.period}
                                        disabled={formData.reportName == "Employee Tenure"}
                                        onChange={onChange}>
                                        <option value=''>Select Report Period</option>
                                        {reportsPeriodListOptions}
                                      </FormSelect>
                                    </Col>
                                    <Col sm="12" md="6" lg="3"
                                      className="form-group p-2 m-0">
                                      <label htmlFor="dateRange">Date Range </label>
                                     
                                      <InputGroup className={classes}>
        <DatePicker
          size="sm"
          selected={formData.from}
          onChange={onStartDateChanged}
          dateFormat='yyyy-MM-dd'
          placeholderText="From"
          dropdownMode="select"
          className="text-center"
          disabled={formData.period != "Custom"}
        />
        <DatePicker
          size="sm"
          selected={formData.to}
          onChange={onEndDateChanged}
          dateFormat='yyyy-MM-dd'
          placeholderText="To"
          dropdownMode="select"
          className="text-center"
          disabled={formData.period != "Custom"}
        />
        <InputGroupAddon type="append" disabled={formData.period != "Custom"}>
          <InputGroupText>
            <i className="material-icons">&#xE916;</i>
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
                                      {/* <RangeDatePicker className="justify-content-end"
                                        onStartDateChanged={onStartDateChanged}
                                        onEndDateChanged={onEndDateChanged} name="range"
                                        startDate={formData.from}
                                        endDate={formData.to}
                                      /> */}

                                    </Col>
                                    <Col sm="12" md="6" lg="3"
                                      className="form-group p-2 m-0">
                                      <label htmlFor="hrConsultant">HR Consultant</label>
                                      <Select
                                        placeholder="All HR Consultants"
                                        isClearable={false}
                                        id="hrConsultantId"
                                        name='hrConsultantId'
                                        value={formData.hrConsultantId} 
                                        onChange={onHrConsultantChange}
                                        isMulti
                                        options={hrConsultantListReportOption} />

                                    </Col>
                                    <Col sm="12" md="6" lg="3"
                                      className="form-group p-2 m-0">
                                      <label htmlFor="salesConsultant">Sales Consultant</label>
                                      <Select
                                        placeholder="All Sales Consultants"
                                        isClearable={false}
                                        id="salesConsultantId"
                                        name='salesConsultantId'
                                        value={formData.salesConsultantId} 
                                        onChange={onSalesConsultantChange}
                                        isMulti
                                        options={salesConsultantListReportOption} />

                                    </Col>
                                    <Col sm="12" md="6" lg="3"
                                      className="form-group p-2 m-0">
                                      <label htmlFor="leaveType">Leave Type</label>
                                      <Select
                                        placeholder="All Leave Types"
                                        isClearable={false}
                                        id="leavePolicyId"
                                        name='leavePolicyId'
                                        value={formData.leavePolicyId} 
                                        onChange={onLeaveTypeChange}
                                        isMulti
                                        options={leaveTypeListReportOptions}
                                      />

                                    </Col>
                                    <Col sm="12" md="6" lg="3"
                                      className="form-group p-2 m-0">
                                      <label htmlFor="leaveStatus">Leave Status</label>
                                      <Select
                                        placeholder={formData.reportName === "Leave Requests" ? "All Leave Status" : "Select Leave Status"}
                                        isClearable={false}
                                        id="status"
                                        name='status'
                                        isDisabled={formData.reportName === "Leave Balances" || formData.reportName == "Employee Tenure"}
                                        onChange={onLeaveStatusChange}
                                        value={formData.status} 
                                        isMulti
                                        options={reportsStatusListOptions} />

                                    </Col>
                                    <Col sm="12" md="6" lg="3"
                                      className="form-group p-2 m-0 mt-2 file-manager__filters__search d-flex justify-content-center justify-content-md-end">
                                      <Button className="mt-4 mx-2 py-2 my-3 pl-5 pr-5 btn btn-secondary btn-lg" size="sm"
                                        type="button"
                                        disabled={(formData.reportName === "Employee Tenure" ? (formData.period === '') : (formData.period === 'This Year')) && formData.clientId.length == 0 && formData.countryId == 1 && formData.regionId.length == 0 && formData.workerId.length == 0 && (formData.employmentStatus.filter(i => i.value == "On Leave" || i.value == "Active").length == 2 && formData.employmentStatus.length == 2) && formData.hrConsultantId.length == 0 && formData.salesConsultantId.length == 0 && formData.leavePolicyId.length == 0 && formData.status.length == 0}
                                        onClick={GenerateReports}
                                      >Submit</Button>

                                      <Button className="mt-4  btn btn-secondary btn-lg my-3" size="sm"
                                        type="button"
                                        onClick={Reset_AdvanceFilters}
                                        disabled={(formData.reportName === "Employee Tenure" ? (formData.period === '') : (formData.period === 'This Year')) && formData.clientId.length == 0 && formData.countryId == 1 && formData.regionId.length == 0 && formData.workerId.length == 0 && (formData.employmentStatus.filter(i => i.value == "On Leave" || i.value == "Active").length == 2 && formData.employmentStatus.length == 2) && formData.hrConsultantId.length == 0 && formData.salesConsultantId.length == 0 && formData.leavePolicyId.length == 0 && formData.status.length == 0}
                                      >Reset</Button>
                                    </Col>
                                    <Col sm="12" md="6" lg="1"
                                      className="form-group p-2 m-0 mt-2">
                                      


                                    </Col>
                                  </Row>
                                </Col>
                              </Row>

                            </ListGroupItem>
                          </ListGroup>
                        </Card>}
                      {((availableFieldsColumns && availableFieldsColumns.length > 0) || (workerDetailsReport.length > 0)) && <Card className="tableCard p-0 w-100">
                        <CardHeader className="p-0">
                          <Container fluid className="file-manager__filters border-bottom">
                            <Row>

                              <Col sm="12" md="4" lg="4"
                                className="file-manager__filters__rows d-flex justify-content-center justify-content-md-start align-items-center ">
                                <span>Show</span>
                                <FormSelect
                                  size="sm"
                                  name='pageSize'
                                  value={pageSize}
                                  onChange={onChange}
                                >
                                  {pageSizeOptions.map((size, idx) => (
                                    <option key={idx} value={size}>
                                      {size} rows
                                    </option>
                                  ))}
                                </FormSelect>
                              </Col>
                              <Col sm="12" md="8" lg="8">
                                <Row className={"justify-content-md-end"}>
                                  <Col sm="12" md="6" lg="auto" className="form-group p-1 mt-3">

                                    <div className={"input-group input-group-seamless search"}>
                                      <div className="input-group-append">
                                        <span className='input-group-text'><i className="fa fa-search"></i></span></div>
                                      <FormInput
                                        id="search"
                                        name="search"
                                        value={formData.search}
                                        onChange={onChange}
                                        placeholder="Search"
                                      />
                                    </div>

                                  </Col>
                                    <Col sm="12" md="6" lg="auto" className={"file-manager__filters__search d-flex justify-content-center justify-content-md-end"} >

                                      <Button size="sm" className="mx-2 py-2 my-3" type="button"
                                        theme="accent" onClick={Search}
                                      >Search</Button>
                                      <Button className="py-2 my-3  mr-2" size='sm' type="button"
                                        theme="accent" onClick={clear_search}>Clear</Button>
                                      <Button className="my-3 mr-0" size='sm' type="button"
                                        theme="accent" onClick={downloadReports}>Download</Button>
                                      <NavItem tag={Dropdown} caret toggle={toggleUserActions} className="py-2 my-2">


                                        < DropdownToggle caret tag={NavLink} className="text-nowrap px-3 field invisible">
                                          <i className="fa fa-ellipsis-v iconStyle field visible" ></i>
                                        </DropdownToggle>
                                        <Collapse tag={DropdownMenu} className="pl-2" style={{ maxHeight: "480px", overflowX: "auto" }} right small open={state.visible}>
                                          <fieldset>
                                            {/* {availableFieldsOption} */}

                                            {availableFields.sort((a, b) => a.order - b.order).map((option) => (
                                              <FormCheckbox value={option.name} id={option.name} key={option.name} defaultChecked={option.isDefault} onChange={handleCheck}>{option.header}</FormCheckbox>

                                            ))}

                                          </fieldset>
                                        </Collapse>
                                      </NavItem>

                                    </Col>
                                </Row>




                              </Col>

                            </Row>


                          </Container>
                        </CardHeader>
                        <CardBody className="p-0">
                          <div>


                            <ReactTable
                              columns={availableFieldsColumns}
                              //data={(formData.reportName === 'Worker details') ? workerData : data}
                              data={(formData.reportName === 'Worker details') ? workerData 
                                : (formData.reportName === 'Benefits Data') ? workerBenefitData : data}
                              pageSize={Number(pageSize)}
                              showPageSizeOptions={false}
                              resizable={false}
                              noDataText="No results found"
                            />


                          </div>
                        </CardBody>

                      </Card>
                      }
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Card>

            </div>
          </div>



        </Form>
      </Container>
    </>
  );
}

SubscriberReports.propTypes = {  
  clientListReport: PropTypes.array.isRequired,
  countryListReport: PropTypes.array.isRequired,
  regionListReport: PropTypes.array.isRequired,
  employmentStatusListReport: PropTypes.array.isRequired,
  hrConsultantListReport: PropTypes.array.isRequired,
  salesConsultantListReport: PropTypes.array.isRequired,
  leaveTypeListReport: PropTypes.array.isRequired,
  reportsNameList: PropTypes.array.isRequired,
  reportsPeriodList: PropTypes.array.isRequired,
  reportsStatusList: PropTypes.array.isRequired,
  reportList: PropTypes.array.isRequired,
  appModules: PropTypes.array.isRequired,
  availableFields: PropTypes.array.isRequired,
  workerListReport: PropTypes.array.isRequired,
  setAlert: PropTypes.func.isRequired,
  workerDetailsReport: PropTypes.array.isRequired,
  //Added by nipul -> benefit worker report
  workerBenefitsReport: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  
  clientListReport: state.user.clientListReport,
  countryListReport: state.user.countryListReport,
  regionListReport: state.user.regionListReport,
  employmentStatusListReport: state.user.employmentStatusListReport,
  hrConsultantListReport: state.user.hrConsultantListReport,
  salesConsultantListReport: state.user.salesConsultantListReport,
  leaveTypeListReport: state.user.leaveTypeListReport,
  reportsNameList: state.user.reportsNameList,
  reportsPeriodList: state.user.reportsPeriodList,
  reportsStatusList: state.user.reportsStatusList,
  reportList: state.user.reportList,
  workerDetailsReport: state.user.workerDetailsReport,
  appModules: state.user.appModules,
  availableFields: state.user.availableFields,

  workerListReport: state.user.workerListReport,
  get_client_list_report: PropTypes.func.isRequired,
  get_country_list_report: PropTypes.func.isRequired,
  get_region_list_report: PropTypes.func.isRequired,
  get_employment_status_list_report: PropTypes.func.isRequired,
  get_sales_consultant_list_report: PropTypes.func.isRequired,
  get_hr_Consultant_list_report: PropTypes.func.isRequired,
  get_leave_type_list_report: PropTypes.func.isRequired,
  get_reports_name_list: PropTypes.func.isRequired,
  get_reports_period_list: PropTypes.func.isRequired,
  get_reports_status_list: PropTypes.func.isRequired,
  generate_report: PropTypes.func.isRequired,
  report_advance_filters: PropTypes.func.isRequired,
  get_app_modules: PropTypes.func.isRequired,
  get_app_modules_clear: PropTypes.func.isRequired,
  get_fields_report: PropTypes.func.isRequired,
  download_report: PropTypes.func.isRequired,
  get_worker_list_report: PropTypes.func.isRequired,
  get_worker_details: PropTypes.func.isRequired,
  
  //Added by nipul -> benefit worker report
  workerBenefitsReport: state.user.workerBenefitsReport,
});
export default connect(mapStateToProps, {

  get_client_list_report,
  get_country_list_report,
  get_region_list_report,
  get_employment_status_list_report,
  get_sales_consultant_list_report,
  get_hr_Consultant_list_report,
  get_leave_type_list_report,
  get_reports_name_list,
  get_reports_period_list,
  get_reports_status_list,
  generate_report,
  report_advance_filters,
  get_app_modules,
  get_app_modules_clear,
  get_fields_report,
  download_report,
  get_worker_list_report,
  get_worker_details,
  setAlert,
  generate_benefits_report,

})(SubscriberReports);