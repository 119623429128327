import React, {useState} from 'react';
import {Link} from "react-router-dom";
import ReactTable from "react-table";

import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container,
  FormSelect,
  Row
} from "shards-react";

export default function ConfigMsgCategories({messageCategoryList}) {
  const [formData, setFormData] = useState({
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 5,
  });

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const {pageSizeOptions, pageSize} = formData;
  const tableData = messageCategoryList;
  const tableColumns = [
    {
      Header: "Message Category",
      accessor: "categoryName",
      className: "justify-content-start"
    },
    {
      Header: "Role",
      accessor: "role",
      className: "justify-content-start"
    },
    {
      Header: "Ref #",
      accessor: "categoryId",
      className: "text-center",
      maxWidth: 100,
      Cell: row => <Link to={'sub-edit-message-catagory/'+row.original.categoryId}>{row.original.categoryId}</Link>
    }
  ];
  return (
    <div className="mt-5 position-relative">
      <span className="tableHeader">Message Categories</span>
      <Card className="tableCard p-0 border-card">
        <CardHeader className="p-0 pt-2">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {/* Filters :: Page Size */}
              <Col
                className="file-manager__filters__rows d-flex align-items-center col-6">
                <span>Show</span>
                <FormSelect
                  size="sm"
                  name='pageSize'
                  value={pageSize}
                  onChange={onChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size} rows
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col
                className="file-manager__filters__search d-flex justify-content-end col-6">
                <Link to="/sub-add-message-catagory/0">
                  <Button className="my-2 py-2 mx-2" size='sm'
                          theme="accent">Add Message Category</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
              columns={tableColumns}
              data={tableData}
              pageSize={Number(pageSize)}
              showPageSizeOptions={false}
              resizable={false}
              noDataText="No results found"
            />
          </div>
        </CardBody>
      </Card>
    </div>
  )
}
