import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";
import {setDateForTableInHrMinSec,setInputDate} from "../../utils/date";
import ReactTable from "react-table";
import {
  clear_sysadmin_subscriber_profile,
  get_sysadmin_eventname_list,
  get_sysadmin_eventdata_list
} from '../../redux/actions/user';

import {
  Button,
  Card, CardBody,
  CardHeader, Col,
  Container, FormInput,
  FormSelect,
  Row,InputGroup,DatePicker,InputGroupAddon,InputGroupText
} from "shards-react";
import classNames from "classnames";


function SystemAuditLog({
                            user,
                            eventNames,
                            get_sysadmin_eventname_list,
                            eventData,
                            get_sysadmin_eventdata_list
                          }) {

                           
                           
  const history = useHistory()

  const [formData, setFormData] = useState({
    eventName: '',
    actionBy: '',
    actionFor: '',
    from:'',
    to:'',
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
  });

  const onChange = (e) => {
   
    setFormData({...formData, [e.target.name]: e.target.value});
    
  };

 

  useEffect(() => {
    
    get_sysadmin_eventname_list();
    get_sysadmin_eventdata_list(formData.eventName,formData.actionBy,formData.actionFor,setInputDate(formData.from),setInputDate(formData.to));
    
  }, []);

  
  const onSubmit = (e) => {
    e.preventDefault();
    var from="";
    from=setInputDate(formData.from);
    var to="";
    to=setInputDate(formData.to);

    if(from!="")
    {
      from=from+"000000000";
    }
    if(to!="")
    {
      to=to+"000000000";
    }
    get_sysadmin_eventdata_list(formData.eventName,formData.actionBy,formData.actionFor,from,to);
  };
  const resetState = (e) => {
    e.preventDefault();
    formData.eventName="";  
    formData.actionBy="";
    formData.actionFor="";
    formData.from="";
    formData.to="";
      get_sysadmin_eventdata_list(formData.eventName,formData.actionBy,formData.actionFor,setInputDate(formData.from),setInputDate(formData.to));
  };

  const {eventName, actionBy, actionFor,from,to} = formData;

  const tableData = eventData;
  var {pageSize, pageSizeOptions} = formData;

  const tableColumns = [

    {
      Header: "Action Name",
      accessor: "actionName",
      className: "justify-content-start text-left text-wrap text-break"
    },

    {
      Header: "Action Detail",
      accessor: "actionDetail",
     
      className: "justify-content-start text-left text-wrap text-break"
      
    },
    
    {
      Header: "Action By",
      accessor: "actionByName",
      maxWidth: 130,
      className: "justify-content-start"
    }
    ,
   
    {
      Header: "Action For",
      accessor: "actionForName",
      maxWidth: 130,
      className: "justify-content-start"
    },
    {
      Header: "Action Time",
      accessor: "actionTime",
     
   
      minWidth: 100,
      Cell: row =>
      setDateForTableInHrMinSec(row.original.actionTime),
    }
  ];

  var names = eventNames.map((eventName) =>{
    return <option value={eventName}>{eventName}</option>;
  });

  return (
    <Container fluid className="main-content-container px-4 py-4">
      <div noGutters
           className="page-header flex-column flex-md-row py-2 d-flex justify-content-end bg-white tableSearch">
        <form id="filterForm" onSubmit={onSubmit}
            className="message-center-filter page-header tableSearch flex-column flex-md-row py-2 d-flex justify-content-end bg-white mt-4">


        <div className="d-flex align-items-center p-2">
        <FormSelect name='eventName' className="filter-control-md" value={formData.eventName} onChange={onChange}>
            <option value=''>All Events
            </option>
            {names}
          </FormSelect>
         
        </div>
        <div className="d-flex align-items-center p-2">
        <FormInput className="filter-control-md"
              name='actionBy'
              value={formData.actionBy}
              onChange={onChange}
              placeholder="Action By"
            />
         
        </div>
        <div className="d-flex align-items-center p-2">
        <FormInput className="filter-control-md"
              name='actionFor'
              value={formData.actionFor}
              onChange={onChange}
              placeholder="Action For"
            />
       
         
        </div>

        <div
          className="d-flex flex-column flex-md-row align-items-center justify-content-end">
          <div className="d-flex align-items-center p-2">
            <InputGroup
              className={classNames("d-flex", "my-auto", "date-range")}>
              <DatePicker
                name="from"
                size="sm"
                autoComplete='off'
                selected={formData.from}
                onChange={(date) => {
                  setFormData({...formData, from: date})
                }}
                dateFormat='yyyy-MM-dd'
                placeholderText="Start Date"
                dropdownMode="select"
                className="text-center"
              />
              <DatePicker
                name="to"
                size="sm"
                autoComplete='off'
                selected={formData.to}
                onChange={(date) => {
                  setFormData({...formData, to: date})
                }}
                dateFormat='yyyy-MM-dd'
                placeholderText="End Date"
                dropdownMode="select"
                className="text-center"
              />
              <InputGroupAddon type="append">
                <InputGroupText>
                  <i className="material-icons">&#xE916;</i>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </div>
          <div className="d-flex">
            <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                    type="submit">Search</Button>
            <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                    onClick={resetState}>Clear</Button>
          </div>
        </div>

      </form>
       
     
      </div>
      <Card className="tableCard p-0">
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              {/* Filters :: Page Size */}
              <Col
                className="file-manager__filters__rows d-flex align-items-center col-6">
                <span>Show</span>
                <FormSelect
                  size="sm"
                  name='pageSize'
                  value={pageSize}
                  onChange={onChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size} rows
                    </option>
                  ))}
                </FormSelect>
              </Col>

              
            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
              columns={tableColumns}
              data={tableData}
              pageSize={Number(pageSize)}
              showPageSizeOptions={false}
              resizable={false}
              noDataText="No results found"
            />
          </div>
        </CardBody>
      </Card>
    </Container>
  );
}

SystemAuditLog.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  eventNames: PropTypes.array.isRequired,
  get_sysadmin_eventname_list: PropTypes.func.isRequired,
  eventData: PropTypes.array.isRequired,
  get_sysadmin_eventdata_list: PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  eventNames: state.user.eventNames,
  eventData: state.user.eventData,
});
export default connect(mapStateToProps, {
  get_sysadmin_eventname_list,
  clear_sysadmin_subscriber_profile,
  get_sysadmin_eventdata_list
})(
  SystemAuditLog
);

