import api from "../../../utils/api";
import {setAlert} from "../alert";
import {GET_CLIENT_DASHBOARD_DATA} from "../types";


    export const get_client_dashboard_data = () => async (dispatch) => {
    try {
        const res = await api.get(
            `api/client/getDashboardData`
          );
       dispatch({
            type: GET_CLIENT_DASHBOARD_DATA,
            payload: res.data,
          });
      } catch (error) {
        const success = error.response.data.success;
        if (!success) {
            dispatch(setAlert(error.response.data.message, "danger"));
          }
      }
  };
