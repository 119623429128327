import React, { useEffect, useRef, useState } from 'react';
import {
    Card,
    Col, Form, FormSelect, DatePicker,
    ListGroup,
    ListGroupItem,
    Row,
    Container,
    Button,
} from "shards-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InputBox from '../../common/InputBox';
import { useLocation } from 'react-router-dom';
import { setAlert } from "../../../redux/actions/alert";
import {
    clear_user_profile,
    get_client_list,
    get_compensation_types,
    get_country_list,
    sub_get_currency_list,
    get_deal_types_types,
    get_region_list,
    get_subscriber_list,
    get_hr_Consultant,
    get_user_profile,
    get_worker_types, reset,
    get_pay_component_list,
    get_pension_plan_frequency_list,
    get_pension_plan_list,
    save_user_profile,
    update_isSave_user_profile
} from "../../../redux/actions/user";
import { calculateEndDate, setAPIDate, setDateForTable, setInputDate, plusDays } from '../../../utils/date';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {compareObjects, fixedDecimalPlaces } from '../../../utils/general';
import StandardFormInput from '../../common/StandardFormInput';
import { STANDARD_FORMATS } from '../../../utils/constants';

function EAPForm(props) {
    const location = useLocation();
    const [notesCharacters, seNotesCharacters] = useState(100);
    const history = useHistory();
    const [hasError, setHasError] = useState(false);
    const formDataVal = useRef({})

    const [disabledSave, setDisabledSave] = useState(location.state.row !== undefined)
    const [disabledEndDate, setDisableEndDate] = useState(location.state.row !== undefined && location.state.formData.EAPInfos[0] !== undefined && location.state.row.EAPId !== location.state.formData.EAPInfos[0].EAPId );

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const onChangeStandardInput = (name,value) => {
        setFormData({...formData, [name]: value});
      
    };

    const onMonetaryAmountChanged = (e, decimalPlaces) => {

        const value = e.target.value;

        const sanitizedValue = value.replace(/[^0-9.]/g, '');

        const parts = sanitizedValue.split('.');

        if (parts.length > 1 && parts[1].length > 2) {
            parts[1] = parts[1].slice(0, decimalPlaces);
        }

        const formattedValue = parts.join('.');

        e.target.value = formattedValue;

        onChange(e);
    };
    const onSave = async (e) => {

        e.preventDefault();

        let updatedFormData = { ...formData };
        if ('EAPStartDate' in updatedFormData) updatedFormData.EAPStartDate = setInputDate(updatedFormData.EAPStartDate);
        if ('EAPEndDate' in updatedFormData) updatedFormData.EAPEndDate = updatedFormData.EAPEndDate === '' ? '0' : setInputDate(updatedFormData.EAPEndDate);
        if ('EAPContributionAmount' in updatedFormData) updatedFormData.EAPContributionAmount = fixedDecimalPlaces(updatedFormData.EAPContributionAmount, 2);
        if ('EAPMarkupAmount' in updatedFormData) updatedFormData.EAPMarkupAmount = fixedDecimalPlaces(updatedFormData.EAPMarkupAmount, 2);

        formDataVal.current = {...updatedFormData}

        let isSaved = false;
        //let EAPEndDate = calculateEndDate(updatedFormData.EAPStartDate, 1);
        if(location.state && location.state.formData){

        for (const EAPInfo of location.state.formData.EAPInfos) {
            let currentDate = new Date(setDateForTable(EAPInfo.EAPStartDate)).setHours(0, 0, 0, 0); //name will be either client contribution start date of worker contribution start date
            let selectedDate = new Date(formData.EAPStartDate).setHours(0, 0, 0, 0);
            if (EAPInfo.EAPId !== formData.EAPId && selectedDate === currentDate) {
                props.setAlert("Details with the same Worker EAP Start Date exists.", "danger");
                return 0;
            }
        }
    }
        if (location.state.row) {

            let currentEAPInfos = location.state.formData.EAPInfos;
            let updatedEAPs = [];
            if (currentEAPInfos) {
                updatedEAPs = currentEAPInfos.map(eap => {
                    if (eap.EAPId === updatedFormData.EAPId) {
                        return {
                            userId: updatedFormData.userId,
                            EAPId: updatedFormData.EAPId,
                            EAPApplicable: updatedFormData.EAPApplicable,
                            EAPProvider: updatedFormData.EAPProvider,
                            EAPStartDate: updatedFormData.EAPStartDate,
                            EAPEndDate: updatedFormData.EAPEndDate === "" ? "0" : updatedFormData.EAPEndDate,
                            EAPContributionCurrency: updatedFormData.EAPContributionCurrency,
                            EAPContributionAmount: updatedFormData.EAPContributionAmount,
                            EAPDeductionFrequency: updatedFormData.EAPDeductionFrequency,
                            EAPPayComponentId: updatedFormData.EAPPayComponentId,
                            EAPMarkup: updatedFormData.EAPMarkup,
                            EAPMarkupAmount: updatedFormData.EAPMarkupAmount,
                            notes: updatedFormData.notes,
                        };
                    }
                    else {
                        return eap;
                    }


                })
            }

            location.state.formData.EAPInfos = updatedEAPs;
            isSaved = true;
        } else {
            // if(formData.userId && formData.userId !== '0'){
            //     let updatedData = {userId:formData.userId, subscriberId: location.state.formData.subscriberId, EAPInfos: [{...updatedFormData}],
            //      EAPApplicable: 'Yes',
            //      HSAApplicable: location.state.formData.HSAApplicable,
            //      pensionApplicable:location.state.formData.pensionApplicable,
            //      benefitsApplicable: location.state.formData.benefitsApplicable,
            //      retainerApplicable: location.state.formData.retainerApplicable}
            //     props.save_user_profile(updatedData);
            // }
            // else
            // {
            //     location.state.formData = { ...location.state.formData, EAPInfos: [{ ...updatedFormData }, ...location.state.formData.EAPInfos], EAPApplicable: 'Yes'}
            //     isSaved = true
            // }
            location.state.formData = { ...location.state.formData, EAPInfos: [{ ...updatedFormData }, ...location.state.formData.EAPInfos], EAPApplicable: 'Yes'}
            isSaved = true
        }

        if (isSaved) {

            if(location.state.formData.EAPInfos.length > 1){
                
                location.state.formData.EAPInfos.sort(function(eap1, eap2){return eap2.EAPStartDate - eap1.EAPStartDate});
                for(let i = 0; i < location.state.formData.EAPInfos.length; i++){
                   if(location.state.formData.EAPInfos[i].EAPEndDate !== '0'){
                        if((i+1) < location.state.formData.EAPInfos.length ){
                            
                            location.state.formData.EAPInfos[i+1].EAPEndDate = calculateEndDate(location.state.formData.EAPInfos[i].EAPStartDate, 1);
                        }
                   }
                   else{
                    if(i === 0 && (i+1) < location.state.formData.EAPInfos.length && location.state.formData.EAPInfos[i+1].EAPEndDate !== '0') continue;
                    location.state.formData.EAPInfos[i+1].EAPEndDate = location.state.formData.EAPInfos[i] ? calculateEndDate(location.state.formData.EAPInfos[i].EAPStartDate, 1) : '0';

                    //location.state.formData.EAPInfos[1].EAPEndDate = calculateEndDate(location.state.formData.EAPInfos[i].EAPStartDate, 1);;

                   }
                   
                }
                //location.state.formData.EAPInfos[0].EAPEndDate = '0'


            }
            let userId = formData.userId === undefined ? 0 : formData.userId;
            let roleStr = (formData.userId !== undefined && location.state.role !== undefined) ? `?role=${location.state.role}` : '?role=';
            history.push({
                pathname: `/manage-user-profile/${userId}`,
                search: `${roleStr}&section=benefits&subSection=EapInfo`,
                state: { formData, formData: location.state.formData, formDataRef: location.state.formDataRef, sectionformDataRef: location.state.sectionformDataRef },
            });

        }


    }

    // useEffect(()=>{

    //     if(props.isSaveUserProfile){
    //         formDataVal.current.EAPId = props.userProfile.EAPInfos[0].EAPId;

    //         if(location.state.sectionformDataRef.EAPInfos && location.state.sectionformDataRef.EAPInfos.length > 0 && props.userProfile.EAPInfos[1]){
    //             location.state.sectionformDataRef.EAPInfos[0].EAPEndDate = props.userProfile.EAPInfos[1].EAPEndDate;
    //             location.state.formData.EAPInfos[0].EAPEndDate = props.userProfile.EAPInfos[1].EAPEndDate;

    //         }
    //         location.state.sectionformDataRef.EAPInfos = [{...formDataVal.current}, ...location.state.sectionformDataRef.EAPInfos]
    //         location.state.formData =  { ...location.state.formData, EAPInfos: [{ ...formDataVal.current}, ...location.state.formData.EAPInfos]}
    //         onCancel()
    //     }
        
    // },[props.isSaveUserProfile])
    const onCancel = ()=>{
        let userId = formData.userId === undefined ? 0 : formData.userId;
            let roleStr = (formData.userId !== undefined && location.state.role !== undefined) ? `?role=${location.state.role}` : '?role=';
            props.update_isSave_user_profile(false);
            history.push({
                pathname: `/manage-user-profile/${userId}`,
                search: `${roleStr}&section=benefits&subSection=EapInfo`,
                state: { formData, formData: location.state.formData, formDataRef: location.state.formDataRef, sectionformDataRef: location.state.sectionformDataRef },
            });
    }
    const onDateChange = (name, value) => {

        let EAPInfos = location.state.formData.EAPInfos;

        let hireDate = new Date(location.state.formData.hireDate).setHours(0, 0, 0, 0);
       let selectedDate = new Date(value).setHours(0, 0, 0, 0);

       let checkBackDate = false;
        let EAPStartDate = new Date(name === 'EAPEndDate' ? formData.EAPStartDate : value ).setHours(0, 0, 0, 0);
        let EAPEndDate = new Date(name === 'EAPStartDate' ? formData.EAPEndDate : value).setHours(0, 0, 0, 0);
        let currentDate = new Date().setHours(0, 0, 0, 0);    
        let selectedPreviousEndDate = '';

       if(name === 'EAPEndDate'){
        if(selectedDate > 0  && selectedDate < EAPStartDate){
            props.setAlert("EAP End Date must not be less than EAP Start Date.", "danger");
            return;
        }

        setFormData({ ...formData, [name]: value});
        return;

    }else
       {

        if(formData.EAPEndDate !== '' && EAPStartDate > EAPEndDate){
            props.setAlert("EAP Start Date must be greater than EAP End Date.", "danger");
            return;
            }
            checkBackDate = selectedDate < currentDate

        if(selectedDate < hireDate){
           props.setAlert("Effective Date must not be less than the Hire Date.", "danger");
               return 0;
       }
        for (let i = 0; i < EAPInfos.length ; i++ ) {
            let currentEAP = EAPInfos[i];
            let previousEAP = EAPInfos[i+1];
            let currentDate = new Date(setDateForTable(currentEAP[name])).setHours(0, 0, 0, 0); //name will be either client contribution start date of worker contribution start date
            
            let prevEndDate = (currentEAP && currentEAP.EAPEndDate !== '0') ? new Date(setDateForTable(currentEAP.EAPEndDate)).setHours(0, 0, 0, 0) : '';
            let prevStartDate = (currentEAP && currentEAP.EAPStartDate !== '0') ? new Date(setDateForTable(currentEAP.EAPStartDate)).setHours(0, 0, 0, 0) : '';

            if(selectedDate < prevEndDate && selectedDate > prevStartDate && currentEAP){
                selectedPreviousEndDate = setAPIDate(currentEAP.EAPEndDate);

                checkBackDate = true;
                //currentEAP.EAPEndDate = calculateEndDate(setInputDate(value), 1);
            }

            if(prevEndDate !== ''  && selectedDate === prevEndDate){
                props.setAlert("EAP can not start from previous EAP End Date.", "danger");
                return 0;
            }

            if (currentEAP.EAPId !== formData.EAPId && selectedDate === currentDate) {
                props.setAlert("Details with the same Worker EAP Start Date exists.", "danger");
                return 0;
            }
        }
        setDisableEndDate(checkBackDate)

    }
        //setHasError(!hasError)
        setFormData({ ...formData, [name]: value, EAPEndDate : checkBackDate ? selectedPreviousEndDate : formData.EAPEndDate });

    };


    const onUnitFieldChange = (e, unit) => {
        let inputValue = e.target.value
        let value = (inputValue.includes(unit)) ? parseInt(inputValue.replace(unit, '')) : inputValue.substring(0, inputValue.length - 1);
        if (!value)
            value = 0
        if (value < 101)
            setFormData({ ...formData, [e.target.name]: value });
    };

    const [formData, setFormData] = useState({

        userId: location.state.formData.userId,
        EAPApplicable: '',
        EAPProvider: '',
        EAPStartDate: location.state.formData.EAPInfos.length > 0 ? new Date() : location.state.formData.hireDate,//location.state.userProfile.userId === undefined ? location.state.formData.hireDate : new Date(),
        EAPEndDate: '',
        EAPContributionCurrency: location.state.formData.baseCurrency,
        EAPContributionAmount: '',
        EAPDeductionFrequency: '',
        EAPPayComponentId: '0',
        EAPMarkup: location.state.clientMarkups && location.state.clientMarkups.eapType,
        EAPMarkupAmount: location.state.clientMarkups && fixedDecimalPlaces(location.state.clientMarkups.eapAmount, 2),
        notes: '',
        EAPId: 1,

    });

    var payComponentListOption = props.payComponentList && props.payComponentList.map((cmp) => {
        return <option key={cmp.name + cmp.refNum} value={cmp.refNum}>{cmp.name}</option>;
    });

    var currencyListOptions = props.currencyList && props.currencyList.map((option) => {
        return <option key={option.currencyCode + option.currencyId}
            value={option.currencyCode}>{option.currencySymbol + ' ' + option.currencyCode}</option>;
    });


    useEffect(() => {


        if (location.state.userProfile.firstName) {
            props.changePageMeta(`Employee Assistance Program - ${location.state.userProfile.personalInfo.firstName} ${location.state.userProfile.personalInfo.lastName}`, location.state.row === undefined ? `ADD` : `EDIT`)
        }
        else {
            props.changePageMeta(`Employee Assistance Program - ${location.state.formData.firstName} ${location.state.formData.lastName}`, location.state.row === undefined ? `ADD` : `EDIT`)

        }


        props.get_worker_types();
        props.sub_get_currency_list();
        props.get_deal_types_types();
        props.get_compensation_types();
        props.get_pay_component_list();
        props.get_pension_plan_list();
        props.get_pension_plan_frequency_list();

        if (location.state && location.state.row) {

            let currentEAP = { ...location.state.row }

            setFormData({
                ...formData,
                userId: currentEAP.userId,
                EAPApplicable: currentEAP.EAPApplicable,
                EAPProvider: currentEAP.EAPProvider,
                EAPStartDate: setAPIDate(currentEAP.EAPStartDate),
                EAPEndDate: setAPIDate(currentEAP.EAPEndDate),
                EAPContributionCurrency: currentEAP.EAPContributionCurrency,
                EAPContributionAmount: currentEAP.EAPContributionAmount,
                EAPDeductionFrequency: currentEAP.EAPDeductionFrequency,
                EAPPayComponentId: currentEAP.EAPPayComponentId,
                EAPMarkup: currentEAP.EAPMarkup,
                EAPMarkupAmount: currentEAP.EAPMarkupAmount,
                notes: currentEAP.notes,
                EAPId: currentEAP.EAPId,



            })
        }else{
            if(location.state.formData.EAPInfos[0]){
                //setFormData({...formData, EAPId : Number(location.state.formData.EAPInfos[0].EAPId) + 1})
                let EAPIds = location.state.formData.EAPInfos.map(eap => Number(eap.EAPId));
                setFormData({...formData,
                     EAPId: Math.max(...EAPIds) + 1,
                     EAPApplicable: location.state.formData.EAPInfos[0].EAPApplicable,
                     EAPProvider: location.state.formData.EAPInfos[0].EAPProvider,
                     EAPContributionCurrency: location.state.formData.EAPInfos[0].EAPContributionCurrency,
                     EAPContributionAmount: location.state.formData.EAPInfos[0].EAPContributionAmount,
                     EAPDeductionFrequency: location.state.formData.EAPInfos[0].EAPDeductionFrequency,
                     EAPPayComponentId: location.state.formData.EAPInfos[0].EAPPayComponentId,
                     EAPMarkup: location.state.formData.EAPInfos[0].EAPMarkup,
                     EAPMarkupAmount: location.state.formData.EAPInfos[0].EAPMarkupAmount,
                     EAPStartDate: location.state.formData.EAPInfos[0].EAPEndDate !== '0' ? setAPIDate(plusDays(location.state.formData.EAPInfos[0].EAPEndDate, 1)) : formData.EAPStartDate,
                     notes: location.state.formData.EAPInfos[0].notes,
                    })
            }
        }

    }, [])

    useEffect(()=>{
        if(location.state.row){     
            setDisabledSave(compareObjects(location.state.row, formData))
        }
    },[formData])

    return (
        <>
            <Container fluid className="main-content-container p-0">
                <Form onSubmit={onSave}>
                    <Card small className=" w-100" style={{ borderRadius: 0 }}>
                        <Row className="w-100" style={{ marginLeft: '-4px' }}>
                            <Col lg="12" className="form-group m-1 p-1">
                                <Card small className="mb-0 w-100" style={{ borderRadius: 0 }}>
                                    <Row className="m-1">
                                        <Col lg="8" className="d-flex flex-row">
                                            {/*<h4 className="m-0">Placement - First Name Last Name</h4>*/}
                                        </Col>
                                        <Col lg="4" className="d-flex flex-row-reverse">
                                            <Button onClick={onCancel} className="my-2 py-2 mx-2" size='sm'
                                                theme="accent">Cancel</Button>

                                            <Button className="my-2 py-2 mx-2" size='sm'  disabled={disabledSave}
                                                theme="accent">Save</Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card small className="mb-4 w-100 p-3" style={{ borderRadius: 0 }}>


                        <Card small className="mb-4 mt-5 w-100">
                            <h4 className="m-0 section-title idStyle">Ref# {formData.EAPId}</h4>
                            <ListGroup flush>
                                <ListGroupItem className="border-card p-3">

                                    <Row>

                                        {/*<Col lg="3" className="form-group m-0 p-2">
                                            <label>EAP Applicable</label>
                                            <FormSelect name='EAPApplicable' value={formData.EAPApplicable}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select EAP Applicable</option>
                                                <option value='Yes'>Yes</option>
                                                <option value='No'>No</option>
                                            </FormSelect>
    </Col>*/}

                                        <InputBox required={true} placeholder="Enter EAP Provider" label="EAP Provider" name='EAPProvider' id='EAPProvider'
                                            value={formData.EAPProvider}
                                            onChange={onChange} />



                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="EAPStartDate">EAP Start Date</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <DatePicker
                                                required={true}
                                                id='EAPStartDate'
                                                name='EAPStartDate'
                                                dateFormat='yyyy-MM-dd'
                                                value={formData.EAPStartDate}
                                                selected={formData.EAPStartDate}
                                                autoComplete='off'
                                                onChange={(date) =>
                                                    onDateChange("EAPStartDate", date)
                                                }
                                                placeholderText="YYYY-MM-DD"
                                            />
                                        </Col>
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="EAPEndDate">EAP End Date</label>
                                            <DatePicker
                                                disabled={disabledEndDate}
                                                id='EAPEndDate'
                                                name='EAPEndDate'
                                                dateFormat='yyyy-MM-dd'
                                                value={formData.EAPEndDate}
                                                selected={formData.EAPEndDate}
                                                autoComplete='off'
                                                onChange={(date) =>
                                                    onDateChange("EAPEndDate", date)
                                                }
                                                placeholderText="YYYY-MM-DD"
                                            />
                                        </Col>
                                        <Col lg="3" className="form-group m-0 p-2"></Col>
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>EAP Deduction Frequency</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='EAPDeductionFrequency' value={formData.EAPDeductionFrequency}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select EAP Deduction Frequency</option>
                                                <option value='Weekly'>Weekly (52 Cycles)</option>
                                                <option value='Bi-weekly'>Bi-weekly (26 Cycles)</option>
                                                <option value='Semi-monthly'>Semi-monthly (24 Cycles)</option>
                                                <option value='Monthly'>Monthly (12 Cycles)</option>
                                            </FormSelect>
                                        </Col>
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="EAPContributionCurrency">EAP Contribution Currency</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} id='EAPContributionCurrency' name='EAPContributionCurrency'
                                                value={formData.EAPContributionCurrency} onChange={onChange}
                                                className='d-block'>
                                                <option value=''>Select EAP Contribution Currency</option>
                                                {currencyListOptions}
                                            </FormSelect>
                                        </Col>
                                        <Col lg="3" className="form-group m-0 p-2"></Col>
                                        <Col lg="3" className="form-group m-0 p-2"></Col>
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Pay Component</label>
                                            <FormSelect name='EAPPayComponentId' value={formData.EAPPayComponentId}
                                                onChange={onChange} className='d-block'>
                                                <option value='0'>Select Pay Component</option>

                                                {payComponentListOption}
                                            </FormSelect>
                                        </Col>
                                        {/*<InputBox required={true} placeholder="0.00" label="EAP Contribution Amount" id='EAPContributionAmount' name='EAPContributionAmount'
                                            value={formData.EAPContributionAmount}
                                            align='right'
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)} />*/}

                                        <StandardFormInput
                                        id="EAPContributionAmount"
                                        name='EAPContributionAmount'
                                        label="EAP Contribution Amount"
                                        standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                        dataMaxFracDigits={2}
                                        type="text"
                                        value={formData.EAPContributionAmount}
                                        onChangeStandardInput={onChangeStandardInput}
                                        required
                                        placeholder="0.00"
                                        align='right'   
                                        />
                                        <Col lg="3" className="form-group m-0 p-2"></Col>
                                        <Col lg="3" className="form-group m-0 p-2"></Col>
                                        {/* <InputBox placeholder="Enter EAP Markup Type" id="EAPMarkup" label="EAP Markup Type" name='EAPMarkup'
                value={EAPMarkup} onChange={onChange}
                />
        */}

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="EAPMarkup">EAP Markup Type</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='EAPMarkup' value={formData.EAPMarkup}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select EAP Markup Type</option>
                                                <option value='%'>%</option>
                                                <option value='Flat'>Flat</option>
                                            </FormSelect>
                                        </Col>
                                        <InputBox required={true} placeholder="0.00" id="EAPMarkupAmount" label="EAP Markup Amount" name='EAPMarkupAmount'
                                            value={formData.EAPMarkupAmount} onChange={(e) => onMonetaryAmountChanged(e, 2)}
                                            align={formData.EAPMarkup === '%' ? 'left' : 'right'}

                                        />

                                    </Row>

                                    <Row>
                                        <Col>
                                            <label htmlFor="notes">Notes</label>
                                            <textarea placeholder='Enter notes' maxLength="100" className="form-control" value={formData.notes} rows="3" id="notes" name="notes" onChange={(e) => {

                                                if (formData.notes.length >= 99) {

                                                    props.setAlert("Notes can not exceed more than 100 charaters.", "danger")
                                                }
                                                onChange(e)
                                            }}>
                                                {formData.notes}
                                            </textarea>
                                            <span>Maximum {notesCharacters} characters.</span>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </Card>
                </Form>
            </Container>
        </>
    );
}

EAPForm.propTypes = {
    userProfile: PropTypes.object.isRequired,
    get_user_profile: PropTypes.func.isRequired,
    user: PropTypes.object,
    workerTypes: PropTypes.array.isRequired,
    dealTypes: PropTypes.array.isRequired,
    currencyList: PropTypes.array.isRequired,
    setAlert: PropTypes.func.isRequired,
    success: PropTypes.any,
    reset: PropTypes.func.isRequired,
    payComponentList: PropTypes.array.isRequired,

}

const mapStateToProps = (state) => {

    return {
        userProfile: state.user.userProfile,
        isSaveUserProfile: state.user.isSaveUserProfile,
        user: state.user.user,
        workerTypes: state.user.workerTypes,
        dealTypes: state.user.dealTypes,
        currencyList: state.user.currencyList,
        message: state.user.message,
        success: state.user.success,
        alerts: state.alert,
        moduleName: state.user.moduleName,
        payComponentList: state.user.payComponentList,
        
    }
}
export default connect(mapStateToProps, {

    get_worker_types,
    get_user_profile,
    clear_user_profile,
    get_subscriber_list,
    get_hr_Consultant,
    sub_get_currency_list,
    get_country_list,
    get_compensation_types,
    get_region_list,
    get_deal_types_types,
    get_client_list,
    get_pay_component_list,
    setAlert,
    reset,
    get_pension_plan_frequency_list,
    get_pension_plan_list,
    save_user_profile,
    update_isSave_user_profile
})(EAPForm);